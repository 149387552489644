export const enTranslations = {
    navbar: {
        restaurant_location: "Navigate to the place",
        make_reservations: "Book a table",
        search_product: "Search product",
        call_waiter: "Call waiter",
        change_language: "Change language",
        feedback: "Feedback",
        about_tabl: 'About TABL',
    },

    pages: {
        products: {
            choose: "Choose",
            choose_ingredients: "Choose ingredients",
            choose_variant: "Choose variant",
            alergens: "Allergens",
            out_of_stock: "Out of stock",
            variant_not_choosed: "Product variant is not selected!",
            min_count_addable_ingredients_reached: "You have to choose at least {value} ingredients!",
            max_count_addable_ingredients_reached: "You cant choose more than {value} ingredients!",
            additional_options: "Additional options",
            addable_options: "Add", // or: Add ingredients
            removable_options: "Remove",
            comment: "Product comment", // Or:  Add extra chili please...
            related_products: "Related products",
            add: "Add", // in context 'add to cart'
            add_to_cart: "Add to cart", // in context 'add to cart'
            choose_size: "Choose",
            save_for_order: "Save for ordering",
            order_disabled: "Currently this product cant be ordered.",
        },
        change_language: {
            title: "Please choose a language",
        },
        call_waiter: {
            clean_table: "Please clean the table",
            new_cutlery: "Please change the cutlery",
            more_napkins: "More napkins please",
            change_ashtray: "Please change the ashtray",
            bring_ice: "Please bring ice",
            other: "Other",
        },
        feedback: {
            title: "We will be happy if you rate us",
            customer_reviews: "Customer reviews",
            no_reviews_yet: "Be the first one to rate this service."
        },
        about_tabl: {
            title: "This service reaches you thanks to ",
            sub_title: "If you need more information about this system, or recommendations about TabL, please contact us.",
            about_link: "https://tabl.bg/"
        },
        cart_delivery: {
            minimal_order_price: "Minimal price for order is {value}",
            price_according_to_region: "Price according to region",
            payment_type_room: "Choose payment type",
            payment_type_pickup: "Choose pickup payment type",
            payment_type_delivery: "Choose delivery payment type",
            cutlery_needed: "Did you need cutlery?",
            cutlery_needed_yes: "Yes, please",
            cutlery_needed_no: "No, thanks",
            region: "Region",
            // you_can_order_between: "Може да поръчате от {from} до {to}",
            call_waiter_to_order: "Please call the waiter to order",
            you_can_order_between: "Ordering hours: {from} - {to} and {from_two} - {to_two}",
            restaurant_closed_label: "We are closed, thank you for your patience!",
            restaurant_closed_label_day: "We are closed, try again {day}: {value}",
            restaurant_closed_label_tommorow: "We are closed, try again tommorow at {value}",
            restaurant_closed_label_today: "We are closed, try again at {value}",
            delivery_price: "Delivery price",
            delivery_price_is_not_included: "+ delivery price",
            cart: "Cart",
            no_cart_items: "Your cart is empty.",
            orders: "Orders",
            order: "Order",
            min_delivery_price_text: 'For orders over {value} delivery is free',
            no_order_items: "You don't have active orders.",
            total: "Price",
            total_bill: "Total",

            status_ordered: 'Accepted',
            status_cooked_spot: 'Cooked',
            status_cooked_delivery: 'Cooked',
            status_cooked_preorder: 'Waiting pickup',
            status_served_spot: 'Served',
            status_on_the_way_delivery: 'Delivering',
            status_paid: 'Finished',

            make_order: "Order",
            want_the_bill: "Ask for BILL",
            finish_bill: "Finish the BILL",
            pay_in_cash: "Pay in cash",
            pay_with_card: "Pay with card",
            make_delivery_order: "Order with delivery",
            choose_order_type: "Choose order type",
            choose_payment_type: "Choose payment type",
            payment_cash: "In cash",
            payment_card: "With a card",
            delivery_price_included_in_total_price: "Delivery price included: {delivery_price_num} /For orders up to {min_price_free_delivery_num}/",
            order_type_restaurant: "In restaurant",
            scan_barcode: "Scan the QR code",
            order_type_delivery: "Door Delivery",
            order_type_preorder: "Address Pick up",
            order_type_room: "Order from room",
            first_and_last_name: "Name",
            phone: "Phone",
            order_address: "Address, street, town",
            delivery_time: "Date and Time for pickup",
            delivery_address: "Delivery address",
            room_number: "Room number",
            table: "Table",
            room: "Room",
            email: "Email",
            remember_me: "Remember me",
            promo_code: "Promocode",
            promo_code_placeholder: "Promocode...",
            apply: "Apply",
            cancel: "Cancel",
            bill_already_wanted: "/bill already wanted/",
            clear: "Clear",
            close: "Close",
            save: "Save",
            fast_order: "Fast order",
            in_the_region_delivery: "I am in the area of {region}",
        },
        search: {
            searchProduct: "Search a product",
            noSearchResults: "No products found",
            noSearchDescription: "Please change the criteria, to found the right product for you!",
        },
        choose_place: {
            title: "Pleace choose a place"
        },
        promotions: {
            title: "Promotion"
        },
        profile: {
            title: "My profile",
            full_name: "Names",
            first_name: "First name",
            last_name: "Last name",
            email: "Email",
            phone: "Phone",
            address: "Address",
            change_password: "Change password",
            password: "Password",
            new_password: "New Password",
            tabl_points: "TabL points",
            tabl_points_info: "Collect TabL points with every order and be ready for awesome surprizes ;)",
            my_profile: "My profile",
            profile: "Profile",
            logout: "Logout",
        }
    },
    orders: {
        statuses: {
            ordered: "Поръчана",
            cooked_spot: 'Приготвена',
            served_spot: 'Сервирана',
            cooked_delivery: 'Приготвена',
            on_the_way_delivery: 'Доставя се',
            cooked_preorder: 'Приготвена',
            cooked_room: "Приготвена",
            paid: "Платена",
        },
    },
    common: {
        hi: "Hello",
        home: "Home",
        free_del: "Free",
        subtotal: "Subtotal",
        final_price: "Final price",
        no_internet: "No internet connection.",
        count: 'pc.',
        box: "box",
        like_us: "Like us",
        follow_us: "Follow us",
        youtube: "YouTube",
        tipadvisor: "Tripadvisor",
        about: 'About',
        about_us: 'About us',
        discount: 'Discount',
        delivery: 'Delivery',
        book: 'Book',
        language: 'Language',
        navigate_me: "Navigete",
        navigation: 'Directions',
        search: 'Search',
        call_us: 'Call us',
        options: 'Options',
        product: "product",
        products: "products",
        user: "User",
        comment: "comment",
        contacts: "Contacts",
        working_hours: "Working hours",
        payment_methods: "Payment methods",
        gallery: "Gallery",
        change_table: "Change table",
        clear_cart: "Clear",
        modify_ingredients: "Change ingredients",
        date: {
            today: "Today",
            tommorow: "Tommorow",
        },

        action: {
            send: "Send",
            skip: "Skip",
            back: "Back",
        },

        label: {
            describe_here: "Type here",
        }

    },

    messages: {
        notifications: {
            waiter_notified_successfully: 'The waiter was notified successfully',
            order_sent_success: "Your order is being processed. Thank you!",
            feedback_thanks: "Thank you! We appreciate it.",
            bill_wanted_success: 'We have received your payment request.',
            user_need_success: 'We received your request.',
            create_order_first: "You first need to create an order.",
            bill_already_wanted: "Your already asked for the bill",
        },
        errors: {
            invalid_privacy_policy_and_terms: "Please accept the terms and the privacy policy",
            invalid_privacy_policy: "Please accept the privacy policy",
            invalid_terms: "Please accept the terms",
            invalid_email: 'Please enter valid Email',
            invalid_password: 'Please enter valid Password',
            invalid_name: 'Please enter valid Name (Between 3 and 40 symbols)',
            invalid_phone: 'Please enter valid Phone number',
            invalid_address: 'Please enter valid Address',
            invalid_regionId: 'Please select Region',
            invalid_orderDate: 'Please select Order date',
            invalid_orderTime: 'Please select Order time',
            invalid_room_number: 'Please select Room number',
            invalid_payment_method: 'Please select Payment method',
            invalid_cutlery_selected: "Please select cutlery",
            invalid_fields: "Please fill in all fields",
            order_sent_fail: "You have scanned invalid QR code.",
            bill_wanted_fail: "Something went wrong during your payment request.",
            user_need_fail: 'Something went wrong duringsending request.',
        }
    },

    modals: {
        upselling_products: {
            title: "Want to try?"
        }
    }
}