import { GET_SETTINGS, SET_BACKGROUND_IMAGE } from '../actions/types';

const initialState = {
    settings: {
        logo: {
            value: ""
        },
        logo_img_width: {
            value: 0
        },
        default_lang: {
            value: "bg"
        },
        translations_type: {
            value: 1
        },
        first_init_lang: {
            value: ""
        }
    },
    mainBackground: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SETTINGS:
            return {
                ...state,
                settings: action.payload
            }
        case SET_BACKGROUND_IMAGE:
            return {
                ...state,
                mainBackground: action.payload
            }
        default:
            return state;
    }
}