import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
// import StarRatings from './react-star-ratings';

const StyledRating = withStyles({
  iconFilled: {
    color: '#ff6a00',
  },
  iconHover: {
    color: '#ff6a00',
  },
})(Rating);

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon style={{fontSize: 35, padding: 5}} />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon style={{fontSize: 35, padding: 5}} />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon style={{fontSize: 35, padding: 5}} />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon style={{fontSize: 35, padding: 5}} />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon style={{fontSize: 35, padding: 5}} />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

class RatingStars extends Component {
	constructor(props) {
		super(props);
	}

    changeRating( newRating, name ) {
      this.setState({
        rating: newRating
      });
    }
 
    render() {

      return (
        <div>
				<StyledRating
					
					data-type='stars'
					getLabelText={value => customIcons[value].label}
					IconContainerComponent={IconContainer}
					onChange={(e, newV) => this.props.handleAnswerFill(e, 'stars', newV, this.props.id)}
          style={{ marginTop: 10, marginBottom: -2 }}
				/>
      </div>
        // <StarRatings
        //   rating={this.state.rating}
        //   starRatedColor="blue"
        //   changeRating={this.changeRating}
        //   numberOfStars={6}
        //   name='rating'
        // />
      );
    }
}
 
export default RatingStars;