import { makeStyles, withStyles, withTheme } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';

export const styles = theme => ({
    root: {
        maxHeight: "500px !important",
        bottom: "0px !important",
        top: "initial !important"
    },
    backdrop: {
        zIndex: 2,
        color: '#fff',
        position: 'absolute !important', //backdrop not on the full screen
    },
    appBar: {
        textAlign: 'center',
        position: "relative",
        backgroundColor: "rgb(203,65,2)",
        borderBottomLeftRadius: 11,
        borderBottomRightRadius: 11,
        boxShadow: '0px 0px 5px 0px black',
        height: '50px !important',
        '& .MuiToolbar-root': {
            minHeight: 'auto !important',
        }
    },
    list: {
        overflowY: "auto",
        maxHeight: "450px !important",
        padding: 0,
    },

    listItemText: {
        flex: 'none',
        display: 'block',
        width: 'calc(100% - 200px)',
        // borderLeft: '1px solid #ccc',
        // paddingLeft: 17,
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    listItemRightText: {
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        right: 5,
    },
    priceContainer: {
        marginLeft: 7,
    },
    title: {
        '&.MuiButton-root:hover': {
            backgroundColor: 'inherit',
        },
        backgroundColor: 'inherit',
        color: 'white',
        lineHeight: '1.3em',
        borderRadius: 0,
        borderBottomRightRadius: 11,
        borderBottomLeftRadius: 11,
        height: 50,
        width: 'calc(100% - 50px / 2)',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
    titleActive: {
        '&.MuiButton-root:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.74)',
        },
        backgroundColor: 'rgba(255, 255, 255, 0.74)',
        color: 'rgb(203,65,2)',
        lineHeight: '1.3em',
        borderRadius: 0,
        borderBottomRightRadius: 11,
        borderBottomLeftRadius: 11,
        height: 50,
        width: 'calc(100% - 50px / 2)',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
    productDivider: {
        background: '#ececec',
    },
    productCircularImage: {
        width: 50,
        height: 50,
        marginRight: 17,
        borderRadius: '50%',
        border: '1px solid #bdbdbd',
    },
    commentIcon: {
        height: 25,
        marginRight: 17,
        marginLeft: 14,
    },
    deliveryPricesInfo: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        textAlign: 'center',
    },
    deliveryPricesText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerContainer: {
        bottom: 0,
        position: "sticky",
        backgroundColor: "#f1f1f1",
        borderTop: "2px solid #ea4900",
        color: "#ea4900",
        display: 'grid',
        zIndex: 2,
        gridTemplateColumns: '50% 50%',
    },
    totalSum: {
        alignSelf: 'center',
        fontSize: 15,
        textAlign: 'center',
        borderRight: "1px solid #ccc",
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        paddingTop: 12,
        paddingBottom: 12,
    },
    totalSumSmallerPadding: {
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
    },
    footerButton: {
        alignSelf: 'center',
        fontWeight: "bold",
        borderRadius: 0,
        outline: 'none !important',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        // paddingTop: 12,
        // paddingBottom: 12,
        height: '100%',
        borderTop: '1px solid #bdbdbd',
        display: 'block',
    },
    billAlreadyWantedInfoLabel: {
        fontSize: 10,
        '@media screen and (max-width: 350px)': {
            '&': {
                fontSize: 9,
            },
        },
    },

    quantitySelect: {
        verticalAlign: 'bottom',
        marginRight: 5,
        '& > .MuiSelect-select': {
            paddingRight: 15,
            paddingLeft: 20,
            paddingTop: 20,
            paddingBottom: 20,
        },
        '&:before, &:after': {
            display: 'none',
        },
    },
    deleteOrderIcon: {
        verticalAlign: 'middle',
        color: '#555555',
        padding: 15,
    },
    noCartItemsContainer: {
        textAlign: 'center',
        // paddingTop: 20,
        paddingTop: 20,
        paddingBottom: 22,
    },
    noCartItemsWarningIcon: {
        fontSize: 40,
        color: '#dddddd',
        margin: '0 auto',
        display: 'block',
    },
    noItemsInCart: {
        padding: 20,
        fontSize: 20,
        color: '#ccc',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },

    orderedLastListItem: {
        marginBottom: 10,
        borderBottomRightRadius: 11,
        borderBottomLeftRadius: 11,
        borderBottom: '1px solid #ccc',
        backgroundColor: "rgb(248, 248, 248)",
        marginLeft: 10,
        width: 'calc(100% - 20px)',
    },
    orderedListItem: {
        backgroundColor: "rgb(248, 248, 248)",
        marginLeft: 10,
        width: 'calc(100% - 20px)',
    },
    orderedListItemText: {
        flex: 'none',
        display: 'block',
        width: 'calc(100% - 118px)',
        // borderLeft: '1px solid #ccc',
        // paddingLeft: 17,
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    orderedProductDivider: {
        background: '#ececec',
        marginLeft: 10,
        width: 'calc(100% - 20px)',
    },
    eachOrderHeaderContainer: {
        marginLeft: 10,
        width: 'calc(100% - 20px)',
        padding: '17px 10px',
        marginTop: 10,
        display: 'grid',
        fontWeight: 'bold',
        borderTopLeftRadius: 11,
        borderTopRightRadius: 11,
        // boxShadow: '0px -5px 7px -8px black;',
        borderTop: '1px solid #ccc',
        backgroundColor: "rgb(248, 248, 248)",
        gridTemplateColumns: '33% 33% 33%',
        textAlign: 'center',
        fontSize: 15,
        '& div': {
            wordBreak: 'break-word',
        },
        '& div.first': {
            textAlign: 'left',
        },
        '& div.last': {
            textAlign: 'right',
        }
    },
    eachOrderFooterContainer: {
        marginLeft: 10,
        width: 'calc(100% - 20px)',
        padding: '17px 10px',
        marginBottom: 10,
        display: 'grid',
        fontWeight: 'bold',
        borderBottomLeftRadius: 11,
        borderBottomRightRadius: 11,
        boxShadow: '0px 5px 7px -8px black;',
        // borderBottom: '1px solid #ccc',
        backgroundColor: "rgb(248, 248, 248)",
        textAlign: 'center',
        fontSize: 15,
        '& div': {
            wordBreak: 'break-word',
        },
    },
    eachOrderFooterContainerTwoColumns: {
        gridTemplateColumns: '50% 50%',
        padding: 0,
        // background: 'rgb(203,65,2)',
        backgroundImage: 'linear-gradient(119deg, rgb(255, 148, 74) 0%, rgb(255, 122, 31) 23%, rgb(255, 113, 47) 78%)',
        color: 'white',
        '& div.first': {
            borderRight: '1px solid white',
            textAlign: 'center',
        },
        '& div.last': {
            borderLeft: '1px solid white',
            textAlign: 'center',
        },
        '& .MuiButton-root': {
            width: '100%',
            color: 'white'
        }
    },
    eachOrderFooterContainerThreeColumns: {
        gridTemplateColumns: '33% 33% 33%',
        '& div.first': {
            textAlign: 'left',
        },
        '& div.last': {
            textAlign: 'right',
        }
    },
    orderTimeIcon: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fontSize: '1.3rem',
    },
    eachOrderTime: {
        verticalAlign: 'middle',
        marginLeft: 3,
    },
    stepperFirst: {
        padding: '20px 0 10px 0',
    },
    stepper: {
        padding: '0 0 10px 0',
        marginTop: '-10px',
    },

    addCommentIconBtn: {
        padding: '10px 20px',
        color: '#535353',
        fontSize: 28
    },
    activeAddCommentIconBtn: {
        color: 'rgba(234, 73, 0, 0.95)'
    },

    eachCartItemRow: {
        paddingLeft: 0,
    },

    deliveryContainer: {
        padding: 0,
        paddingBottom: 8,
        margin: 0,
        textAlign: 'center',
    },

    deliveryContent: {
        padding: 0,
        margin: 0,
    },
    // ingredientsListRequired: {
    //     color: 'rgb(254, 105, 2)',
    // },
    ingredientsListAddable: {
        color: '#008017',
    },
    ingredientsListRemovable: {
        color: '#c50000',
    },
});

export const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage: 'linear-gradient(119deg, rgb(255, 148, 74) 0%, rgb(255, 122, 31) 23%, rgb(255, 113, 47) 78%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage: 'linear-gradient(119deg, rgb(255, 148, 74) 0%, rgb(255, 122, 31) 23%, rgb(255, 113, 47) 78%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)', //
    },
});

export const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(255, 151, 87) 0%,rgb(255, 148, 56) 50%,rgb(255, 129, 0) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(255, 151, 87) 0%,rgb(255, 148, 56) 50%,rgb(255, 129, 0) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);
