import axios from 'axios';
import {
    ADD_USER_NEED_ERROR, ADD_USER_NEED_SUCCESS, RESET_USER_NEED, LOADING_USER_NEED_START,
    LOADING_USER_NEED_END, GET_USER_NEED, GET_USER_NEED_ERROR
} from './types';
import { API_URL } from '../../constants'
import Auth from '../../utils/Auth';

import Cookies from 'universal-cookie';
import { addErrorMessages, addSuccessMessage } from './../../messages/messages.actions';

export const getUserNeeds = () => dispatch => {
    // const userToken = Auth.getUserToken() || scannedToken;
    // const placeId = Auth.getOpenedPlace();

    // if (!placeId) {
    //     dispatch(addErrorMessages(["Възникна грешка при зареждане на услугите"]));
    //     return;
    // }
    //TODO
    // if(!Auth.getToken()) {
    //     history.push('/scanTable');
    //     return;
    // }

    dispatch({ type: LOADING_USER_NEED_START });

    axios
        .get(API_URL + 'user_needs')
        .then(res => {
            dispatch({
                type: GET_USER_NEED,
                payload: res.data
            });
            dispatch({ type: LOADING_USER_NEED_END });
        })
        .catch(e => {
            dispatch({
                type: GET_USER_NEED_ERROR,
                payload: "Възникна грешка при зареждането на услугите" //No translation needed
            });
            dispatch({ type: LOADING_USER_NEED_END });
        });
}



export const addUserNeed = (userNeedId, scannedToken) => dispatch => {
    const userToken = Auth.getUserToken() || scannedToken;
    if (!userToken) {
        return;
    }
    //TODO
    // if(!Auth.getToken()) {
    //     history.push('/scanTable');
    //     return;
    // }

    dispatch({ type: LOADING_USER_NEED_START });

    axios
        .post(API_URL + 'user_needs/by_session/' + userToken, {
            needId: userNeedId
        })
        .then(res => {
            dispatch({
                type: ADD_USER_NEED_SUCCESS,
                payload: "Сервитьорът беше нотифициран." //No translation needed
            });
            dispatch({ type: LOADING_USER_NEED_END });
        })
        .catch(e => {
            dispatch({
                type: ADD_USER_NEED_ERROR,
                payload: "Възникна грешка при изпращане на желанието ви." //No translation needed
            });
            dispatch({ type: LOADING_USER_NEED_END });
        });
}

export const resetUserNeedStatus = () => dispatch => {
    dispatch({
        type: RESET_USER_NEED,
    })
}
