import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReCAPTCHA from "react-google-recaptcha";
import { Paper, Grid, Input, InputLabel, InputAdornment, FormControl, TextField, Button, Collapse, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormHelperText } from '@material-ui/core';
import { AccountCircle, Room, PhoneInTalk, Mail, Lock, KeyboardArrowDown, KeyboardArrowLeft } from '@material-ui/icons';
import Utils from '../../../utils/Utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        background: 'rgba(255,255,255,0.72)',
    },
    textFieldContainer: {
        marginTop: 10,
        width: '100%',
    },
    textField: {
        width: '100%',
        '& label.Mui-error': {
            color: 'rgba(0, 0, 0, 0.54)'
        },
        '& label.Mui-focused': {
            color: 'rgba(254, 105, 2, 0.7)',
        },
        '& .MuiInput-underline:after': {
            // borderBottomColor: 'rgb(254, 105, 2)',
            borderBottomColor: 'rgb(117 117 117)',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: '#f44336',
        },
    },
    formContainer: {
        width: '100%'
    },
    saveButton: {
        width: '100%',
        maxWidth: 650,
        display: 'block',
        margin: '0 auto',
    }
}));


const RegisterForm = (props) => {
    const classes = useStyles();
    const [formSubmissionFailed, setFormSubmissionFailed] = useState(false);
    const [customErrors, setCustomErrors] = useState(false);

    const onChange = (e, checkbox = 0) => {
        props.handleChange(e, checkbox);
        formSubmissionFailed ? isFormValid({ [e.target.name]: checkbox ? e.target.checked : e.target.value }) : void (0);
    }


    const isFormValid = (updatedValue = {}) => {

        const checkMinLengthValid = (value, minLength = 3) => {
            return value && value.toString().length >= minLength;
        }

        let stateData = props.user;

        // Used for live validate onchange (Because state is not changed immediately)
        if (updatedValue && Object.keys(updatedValue).length > 0 && Object.keys(updatedValue)[0]) {
            stateData[Object.keys(updatedValue)[0]] = updatedValue[Object.keys(updatedValue)[0]];
        }

        // Name
        const fullnameValid = checkMinLengthValid(stateData.fullname) && stateData.fullname.toString().length <= 40;

        // Email
        const emailValid = checkMinLengthValid(stateData.email);

        // Phone
        let phoneValid = checkMinLengthValid(stateData.phone, 9);
        const phoneRegexMatch = Utils.getSettingsValue(props.settings, 'validate_phone_number_regex') ? (new RegExp(Utils.getSettingsValue(props.settings, 'validate_phone_number_regex'))).test(stateData.phone.replace(/ /g, '')) : true;
        phoneValid = phoneValid && phoneRegexMatch;

        // Passowrd
        const passwordValid = checkMinLengthValid(stateData.password, 6);

        // Terms
        const termsValid = stateData.terms == true;

        // Privacy Policy
        const privacyPolicyValid = stateData.privacy_policy == true;

        const isFormValid = fullnameValid && emailValid && phoneValid && passwordValid && termsValid && privacyPolicyValid;


        if (isFormValid) {
            setCustomErrors({})
        } else {
            setFormSubmissionFailed(true);
            setCustomErrors({
                fullname: !fullnameValid ? props.translations.messages.errors.invalid_name : '',
                email: !emailValid ? props.translations.messages.errors.invalid_email : '',
                phone: !phoneValid ? Utils.translate(props.translations.messages.errors.invalid_phone_min, { value: 9 }) : '',
                password: !passwordValid ? Utils.translate(props.translations.messages.errors.invalid_paddword_min, { value: 6 }) : '',
                terms: !termsValid ? props.translations.messages.errors.invalid_terms : '',
                privacy_policy: !privacyPolicyValid ? props.translations.messages.errors.invalid_privacy_policy : '',
            })
        }

        return isFormValid;
    }


    return (
        <form onSubmit={props.onSubmit}>
            <div className={classes.root}>

                <div className={classes.textFieldContainer}>
                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                        <Grid item xs={1}>
                            <AccountCircle />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField name="fullname" className={classes.textField}
                                error={customErrors && customErrors.fullname} helperText={customErrors && customErrors.fullname ? customErrors.fullname : ''}
                                label={props.translations.pages.profile.fullname}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </div>

                <br />

                {/* <div className={classes.textFieldContainer}>
                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                        <Grid item xs={1}>
                            <AccountCircle />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField name="lastName" className={classes.textField}
                                error={customErrors && customErrors.lastName} helperText={customErrors && customErrors.lastName ? customErrors.lastName : ''}
                                label={props.translations.pages.profile.last_name}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </div> */}

                <div className={classes.textFieldContainer}>
                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                        <Grid item xs={1}>
                            <AccountCircle />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField name="email" className={classes.textField}
                                error={customErrors && customErrors.email} helperText={customErrors && customErrors.email ? customErrors.email : ''}
                                label={props.translations.pages.profile.email}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </div>

                <br />

                <div className={classes.textFieldContainer}>
                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                        <Grid item xs={1}>
                            <AccountCircle />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField name="phone" className={classes.textField}
                                error={customErrors && customErrors.phone} helperText={customErrors && customErrors.phone ? customErrors.phone : ''}
                                label={props.translations.pages.profile.phone}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </div>

                <br />

                <div className={classes.textFieldContainer}>
                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                        <Grid item xs={1}>
                            <AccountCircle />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField name="password" className={classes.textField} type="password"
                                error={customErrors && customErrors.password} helperText={customErrors && customErrors.password ? customErrors.password : ''}
                                label={props.translations.pages.profile.password}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </div>

                <br />

                <FormControlLabel
                    className="privacy-policy-checkbox"
                    control={
                        <Checkbox
                            name="privacy_policy"
                            onClick={(e) => onChange(e, 1)}
                        />
                    }
                    label={<React.Fragment>Запознах се и съм съгласен с <Button onClick={() => props.setPrivacyPolicyDialogOpened(true)} className="underlined" style={{ display: 'contents' }} disableRipple>Политиките за поверителност</Button></React.Fragment>}
                />
                <FormHelperText style={{ color: '#f44336' }}>{customErrors && customErrors.privacy_policy ? customErrors.privacy_policy : ''}</FormHelperText>


                <FormControlLabel
                    className="terms-checkbox"
                    control={
                        <Checkbox
                            name="terms"
                            onClick={(e) => onChange(e, 1)}
                        />
                    }
                    label={<React.Fragment>Съгласен съм с <Button onClick={() => props.setTermsDialogOpened(true)} className="underlined" style={{ display: 'contents' }} disableRipple>Условията за ползване</Button></React.Fragment>}
                />
                <FormHelperText style={{ color: '#f44336' }}>{customErrors && customErrors.terms ? customErrors.terms : ''}</FormHelperText>


                <div style={{ margin: '0 auto', width: 'fit-content' }}>
                    <br />
                    <ReCAPTCHA
                        sitekey="6LdZnOIZAAAAAP1Ive0iuunRcmKxzvNsOQVHXaam"
                        onChange={props.recaptchaOnChange}
                    />
                </div>


                <br />


                <Button className={classes.saveButton} variant="contained" onClick={(e) => isFormValid() ? props.onSubmit(e) : props.addErrorMessages(["Моля попълнете всички полета коректно."])}>Регистрирай се</Button>

            </div>
        </form >
    );
}

export default RegisterForm;
