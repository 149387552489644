import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

import Utils from '../../utils/Utils';
import { setSearchOpened } from '../actions/search.actions';
import ProductVariantsModal from '../../common/modals/ProductVariant';
import ProductListItem from '../../products/components/ProductListItem'
import { showHeaderCategory } from '../../categories/actions/categories.action';
import { addProductTooltip, addToCart, setCommentModalData } from '../../products/actions/products.action';
import { setMainBackground } from '../../settings/actions/settings.action';

const styles = theme => ({
    searchedCategoryContainer: {
        background: 'rgba(255,255,255,0.7)',
        padding: 10,
        textAlign: 'center',
        width: '90%',
        display: 'block',
        margin: '0 auto',
        borderRadius: 3,
        fontWeight: 'bold',
    },
    productsContainer: {
        width: '90%',
        margin: '0 auto',
        paddingTop: 25,
    },
    noSearchResultsContainer: {
        position: 'absolute',
        top: '35%',
        color: 'white',
        textAlign: 'center',
        width: '100%',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
    noSearchResultsIcon: {
        color: '#ffffff73',
        fontSize: 75,
    },
    noSearchResultsTitle: {
        fontWeight: 'bold',
        fontSize: 20,
    },
    noSearchResultsDescription: {
        color: '#ccc',
    },
    noSearchResultsBackBtn: {
        color: 'white',
        fontSize: 20,
        marginTop: 20,
        borderBottom: '1px solid white',
        borderRadius: 0,
    }
});


class SearchPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            variantModalOpen: false,
            selectedProduct: null,
        }
    }

    componentDidMount() {
        // this.props.showHeaderCategory(this.props.translations && this.props.translations.common ? this.props.translations.common.action.back : '');
        this.props.setMainBackground('linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) )');
        // Open search
        if (!this.props.searchOpened) {
            this.props.setSearchOpened(true);
        }
    }

    // componentWillMo
    componentWillUnmount() {
        if (this.props.searchOpened) {
            this.props.setSearchOpened(false);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.translations) {
            this.props.showHeaderCategory(this.props.translations && this.props.translations.common ? this.props.translations.common.action.back : '');
        }
    }

    // DUPLICATE CODE - this code is ALMOST the same as in productPage
    // Opens variant modal or adds prod to cart
    addToCartBtnClickedWithVariant = (id, productVariantId = 0) => {
        const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];
        // const currProduct = products.filter(p => p.id === id)[0];

        if (this.props.settings && this.props.settings.variant_modal_with_comment && this.props.settings.variant_modal_with_comment.value == 1) {
            this.setState({ variantModalOpen: true, selectedProduct: currProduct, variantModalWithComment: true })
        } else {
            if (currProduct.product_variants) {
                if (currProduct.product_variants.length === 1 || productVariantId > 0) {
                    this.addProdToCart(currProduct.id, productVariantId ? productVariantId : currProduct.product_variants[0].id, currProduct)
                } else {
                    // Open variant modal
                    this.setState({ variantModalOpen: true, selectedProduct: currProduct })
                }
            }
        }

    }

    // Opens variant modal or adds prod to cart
    addToCartBtnClicked = (currProduct) => {
        if (currProduct.product_variants) {
            if (currProduct.product_variants.length === 1) {
                this.addProdToCart(currProduct.id, currProduct.product_variants[0].id, currProduct)
            } else {
                // Open variant modal
                this.setState({ variantModalOpen: true, selectedProduct: currProduct })
            }
        }
    }

    addProdToCart = (product_id, product_variant_id, product) => {
        if (this.state.variantModalOpen) {
            this.setState({ variantModalOpen: false });
        }
        const requiredProductIngridients = product.product_ingredients.filter(pi => pi.type == 'required');

        let defautRequiredProductIngridient = 0;
        if (requiredProductIngridients && requiredProductIngridients[0] && requiredProductIngridients[0].ingredientId) {
            defautRequiredProductIngridient = requiredProductIngridients[0].ingredientId;
        }

        const selectedIngredients = {
            required: defautRequiredProductIngridient,
            addable: [],
            removable: [],
        }


        this.showAddedProductTooltip(product_id, product_variant_id, product, selectedIngredients);
        this.props.addToCart(product_id, product_variant_id, product, selectedIngredients);
    };

    // Shows tooltip
    showAddedProductTooltip = (id, product_variant_id, product, selectedIngredients) => {
        let totalProdQuantity = 1;
        // if (this.props.products && this.props.products.to_order && Object.keys(this.props.products.to_order).length > 0) {
        //     const addedProductVariant = this.props.products.to_order.find(pv => pv.product_variant_id === product_variant_id);
        //     if (addedProductVariant) {
        //         totalProdQuantity = parseInt(addedProductVariant.quantity) + 1;
        //     }
        // }

        if (this.props.products && this.props.products.to_order && this.props.products.to_order[0] && this.props.products.to_order[0].length > 0) {
            totalProdQuantity = Utils.getCartProductQuantity(product_variant_id, selectedIngredients);
        }

        let choosedProductVariant = product.product_variants.filter(pv => pv.id === product_variant_id)[0];
        let tooltipText = totalProdQuantity + ' x ' + product.name + (choosedProductVariant.size && choosedProductVariant.size != ' ' ? ' - /' + choosedProductVariant.size + '/' : '');

        // Add to cart tooltip
        const addProdTooltip = (id, tooltipText) => this.props.addProductTooltip(id, tooltipText);
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null });
        }
        addProdTooltip(id, tooltipText);
        let timeOut = setTimeout(function () { addProdTooltip(0, tooltipText); }, 2500);
        this.setState({ timeout: timeOut });
    }
    // DUPLICATE CODE ^^


    filterProducts = (noSearchResults) => {
        let filteredProds = {};
        const searchTextLowerCase = this.props.searchText.toLowerCase();
        let k = 0;

        Object.values(this.props.categoriesHierarchy).map(cat => {
            if (cat.name.toLowerCase().indexOf(searchTextLowerCase) !== -1) {

                Object.values(this.props.allProducts).map(p => {

                    if (p.catHierarchyIds.includes(cat.id)) {
                        if (!filteredProds[cat.name]) {
                            filteredProds[cat.name] = [];
                        }

                        filteredProds[cat.name].push(
                            <ProductListItem
                                key={k++}
                                k={k}
                                product={p}
                                category={cat}
                                // catSlug={cat && cat.slug ? cat.slug : ''}
                                settings={this.props.settings}
                                addProdToCart={this.addProdToCart}
                                addToCartBtnClicked={(id, variantId) => this.addToCartBtnClickedWithVariant(id, variantId)}
                                translations={this.props.lang}
                                currLang={this.props.currLang}
                                setCommentModalProps={(commentModalTitle, commentVariantId) => { this.props.setCommentModalData(true, commentModalTitle, commentVariantId) }}

                            // showSubCatInProductHeader={true}
                            />
                        )
                    }
                })

            }
        })

        k = 0;

        Object.values(this.props.allProducts).map(p => {
            if ((p.active && p.active == 1) && p.is_promo === false && (p.name.toLowerCase().indexOf(searchTextLowerCase) !== -1 || p.description.toLowerCase().indexOf(searchTextLowerCase) !== -1 || (p.code && p.code.toLowerCase().indexOf(searchTextLowerCase) !== -1) || (p.slug && p.slug.toLowerCase().indexOf(searchTextLowerCase) !== -1))) {

                p.catHierarchyIds.map(catId => {

                    let productCatName = this.props.categoriesHierarchy && this.props.categoriesHierarchy[catId] && this.props.categoriesHierarchy[catId].name ? this.props.categoriesHierarchy[catId].name : "";

                    if (productCatName && !filteredProds[productCatName]) {
                        filteredProds[productCatName] = [];
                    }

                    if (filteredProds[productCatName]) {
                        filteredProds[productCatName].push(
                            <ProductListItem
                                key={k++}
                                k={k}
                                product={p}
                                settings={this.props.settings}
                                addProdToCart={this.addProdToCart}
                                addToCartBtnClicked={() => this.addToCartBtnClicked(p)}
                                translations={this.props.lang}
                                currLang={this.props.currLang}
                                setCommentModalProps={(commentModalTitle, commentVariantId) => { this.props.setCommentModalData(true, commentModalTitle, commentVariantId) }}
                            // showSubCatInProductHeader={true}
                            />
                        )
                    }


                })

            }
        })


        return Object.keys(filteredProds).length > 0 ? filteredProds : {};
    }

    render() {

        const { classes, settings, translations } = this.props;
        // console.log("SEARCH ", this.props.searchText)
        const noSearchResults = (
            <Grow in={true}>
                <div className={classes.noSearchResultsContainer}>
                    <YoutubeSearchedForIcon className={classes.noSearchResultsIcon} />
                    <p className={classes.noSearchResultsTitle}>
                        {translations.pages && translations.pages.search ? translations.pages.search.noSearchResults : ''}
                    </p>
                    <p className={classes.noSearchResultsDescription}>
                        {translations.pages && translations.pages.search ? translations.pages.search.noSearchDescription : ''}
                    </p>
                    <Button
                        onClick={() => {
                            // this.props.setSearchOpened(false);
                            Utils.navigateTo(`/${this.props.clientSlug}`)
                        }}
                        startIcon={<ArrowBackIosIcon />}
                        className={classes.noSearchResultsBackBtn}
                    >
                        {translations.common ? translations.common.action.back : ''}
                    </Button>
                </div>
            </Grow>
        );


        const productsElementByCat = this.props.searchText && this.props.searchText.length > 2 ? this.filterProducts(noSearchResults) : noSearchResults;
        // console.log("HERE ", productsElementByCat)
        // console.log(Object.keys(productsElementByCat));

        return (
            <React.Fragment>



                {this.props.searchText && this.props.searchText.length > 2 && Object.values(productsElementByCat).length > 0 ?
                    Object.keys(productsElementByCat).map(catName =>
                        <React.Fragment>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className={classes.searchedCategoryContainer}>
                                {catName}
                            </div>
                            <Grid
                                className={classes.productsContainer}
                                container
                                direction="row"
                                spacing={2}
                            >
                                {productsElementByCat[catName]}
                            </Grid>
                        </React.Fragment>
                    )
                    :
                    <div>
                        {noSearchResults}
                    </div>
                }

                {/* {productsElement} */}

                <ProductVariantsModal
                    open={this.state.variantModalOpen}
                    product={this.state.selectedProduct}
                    addProdToCart={this.addProdToCart}
                    closeProductVariantModalHandler={() => this.setState({ variantModalOpen: !this.state.variantModalOpen })}
                    settings={settings}
                    translations={translations}
                    currLang={this.props.currLang}
                />

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        searchText: state.search.search_text,
        allProducts: state.products.all_products,
        products: state.products || [],
        settings: state.settings.settings || [],
        lang: state.lang || [],
        translations: state.lang.translations || [],
        currLang: state.lang.currLang,
        categoriesHierarchy: state.categories.categories_hierarchy || [],
        clientSlug: state.client ? state.client.slug : "",
        searchOpened: state.search.search_opened || false,
    };
};

export default withStyles(styles)(connect(mapStateToProps, { setSearchOpened, showHeaderCategory, addProductTooltip, addToCart, setMainBackground, setCommentModalData })(SearchPage));
