import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import { setChosenPlace, verifyOpenedPlace } from './../../places/actions/places.actions';
import Utils from './../../utils/Utils';
import Auth from './../../utils/Auth';
import { getAllProducts, getProductIngredients } from './../../products/actions/products.action';
import { getSettings } from './../../settings/actions/settings.action';
import { getCategoriesHierarchy } from './../../categories/actions/categories.action';
import { getRegions } from './../../regions/regions.actions';
import clsx from 'clsx';
import Cookies from 'universal-cookie';

const cookies = new Cookies();


const styles = theme => ({
    modal: {
        '& .MuiPaper-root': {
            width: '350px !important',
        }
    },
    modalTitle: {
        textAlign: 'center',
        backgroundColor: "rgb(203,65,2)",
        color: 'white',
        boxShadow: '0 1px 5px 0px black',
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    dialogContent: {
        // minHeight: 100,
        'background-image': 'url(https://tabl.bg/img/white-shiny-background.jpg)',
        'background-position': 'center bottom',
        'background-attachment': 'fixed',
        'background-size': 'cover',
    },
    dialogContentText: {
        paddingTop: 10,
    },
    modalTypesBtns: {
        color: 'white',
        width: '100%',
        fontSize: 18,
        display: 'grid',
        // 'grid-template-rows': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        // color: '#575757',
        borderRadius: 0,
        borderBottom: '1px solid #ff7600',
        backgroundColor: '#f9f9f9',
        minWidth: 180,
        '@media screen and (max-width: 475px)': {
            minWidth: '80%',
        },

        'border': '1px solid #ebebeb',

        // 'border': '1px solid #ff5200',
        // 'border-top-right-radius': 7,
        // 'border-top-left-radius': 7,

        'border-top-left-radius': 20,
        'border-bottom-right-radius': 20,

        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: 'none',

        height: 80,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    modalTypesBtnsBorderBottom: {
        // 'border-bottom': '1px solid #ff5200 !important',
    },
    modalTypesIcons: {
        fontSize: 42,
        color: '#ff7600',
    },
    isMultipleOptionsModalContainer: {
        margin: '20px 0 20px 0',
        display: 'grid',
        // 'grid-template-columns': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        padding: 0,
        // '@media screen and (max-width: 475px)': {
        //     'grid-template-columns': 'none',
        //     // '& > *:first-child': {
        //     //     marginBottom: 40,
        //     // },
        //     margin: '20px 0 20px 0',
        // }
    },
});

class ChoosePlaceModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
        }
    }
    componentWillMount() {
        //Bugfix "When translation is enabled and choose place is triggered - it chashes"
        cookies.remove('googtrans', { path: '/' });
        cookies.remove('googtrans', { domain: '.tabl.bg', path: '/' });
    }

    componentDidMount() {
        this.handleModalState(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.handleModalState(nextProps);
    }

    handleModalState = (currProps) => {
        var places = currProps.places;
        var chosen_place = currProps.chosen_place;

        if (places.length > 0) {
            //if is tablet mode, then it should open the same place on refresh
            if (places.length > 1 && !chosen_place && !Auth.isTabletMode()) {
                this.setState({
                    opened: true
                })
            } else {
                this.setState({
                    opened: false
                })
            }
        }
    }

    choosePlace = (placeId) => {
        this.props.verifyOpenedPlace(placeId);

        this.props.setChosenPlace(placeId, true);
        this.props.getCategoriesHierarchy(placeId)
        this.props.getAllProducts(0, placeId)
        this.props.getSettings(placeId);
        this.props.getProductIngredients();
        this.props.getRegions();

    }

    render() {
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations } = this.props;
        return (
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={this.state.opened}
                // onClose={onClose}
                aria-labelledby="max-width-dialog-title"
                TransitionComponent={Grow}
                // className={classes.modal}
                data-class="dialog-container"
            >
                {/* <CircularProgress className="is-loading language-modal" /> */}
                <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                    {translations.pages && translations.pages.choose_place && translations.pages.choose_place.title ? translations.pages.choose_place.title : ''}
                </DialogTitle>

                <DialogContent
                    className={classes.dialogContent}
                >
                    <DialogContentText
                    // className={classes.dialogContentText}
                    >

                        <div className={classes.isMultipleOptionsModalContainer}>
                            {this.props.places.filter(p => p.visible_for_online_orders == 1).sort(Utils.sortPriority()).map((p, k) => {
                                return (
                                    <span key={k}>
                                        <Button size="large" className={clsx(classes.modalTypesBtns, classes.modalTypesBtnsBorderBottom)} onClick={() => this.choosePlace(p.id)}
                                            style={{ backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5) ), url('" + p.image + "')" }}>
                                            {p.name}
                                        </Button>
                                        {(k != this.props.places.length - 1) ?
                                            <br />
                                            :
                                            null
                                        }
                                    </span>
                                )
                            })}
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        places: state.places.places || [],
        chosen_place: state.places.chosen_place || null,
        translations: state.lang.translations,
    };
};

export default withStyles(styles)(connect(mapStateToProps, {
    setChosenPlace, getAllProducts, getProductIngredients,
    getCategoriesHierarchy, verifyOpenedPlace, getSettings, getRegions
})(ChoosePlaceModal)
)