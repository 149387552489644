import { SET_CLIENT } from './types';
import axios from 'axios';
import { API_URL } from './../constants';
import { addErrorMessages, addSuccessMessage } from './../messages/messages.actions';
import history from './../utils/history';

export const setClient = (client) => dispatch => {
    dispatch({
        type: SET_CLIENT,
        payload: client
    })
}

export const clientRequest = (data) => dispatch => {

    if (!data.owner_name) {
        dispatch(addErrorMessages(["Моля попълнете валидно име"]));
        return;
    }
    if (!data.phone) {
        dispatch(addErrorMessages(["Моля попълнете валиден телефон"]));
        return;
    }
    if (!data.email) {
        dispatch(addErrorMessages(["Моля попълнете валиден имейл"]));
        return;
    }
    if (!data.address) {
        dispatch(addErrorMessages(["Моля попълнете валиден адрес"]));
        return;
    }
    if (!data.place_type) {
        dispatch(addErrorMessages(["Моля попълнете валиден тип на обект"]));
        return;
    }
    if (!data.name) {
        dispatch(addErrorMessages(["Моля попълнете валидно име на обект"]));
        return;
    }
    if (!data.privacy_policy) {
        dispatch(addErrorMessages(["Моля съгласете се с политиката ни на поверителност"]));
        return;
    }
    if (!data.terms) {
        dispatch(addErrorMessages(["Моля съгласете се с общите условия"]));
        return;
    }

    axios.post(API_URL + 'clients/request-join',
        data
    ).then(res => {
        history.push("/thankyou")
    }).catch(error => {
        dispatch(addErrorMessages(["Моля попълнете коректно всички данни"]));
    });

}