import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import clsx from 'clsx';

import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PaymentIcon from '@material-ui/icons/Payment';

const styles = theme => ({
    backdrop: {
        zIndex: 2,
        color: '#fff',
        position: 'absolute !important', //backdrop not on the full screen
    },
    modal: {
        '& .MuiPaper-root': {
            width: '100% !important',
        },
        '& .MuiDialogContent-root': {
            paddingTop: 5,
            paddingBottom: 0,
        }
    },
    billAlreadyWantedContainer: {
        // isMultipleOptionsModalContainer: {
        margin: '20px 0 25px 0',
        // display: 'grid',
        // 'grid-template-columns': '50% 50%',
        // 'place-self': 'center',
        textAlign: 'center',
        padding: 0,
        // '@media screen and (max-width: 475px)': {
        //     'grid-template-columns': 'none',
        //     '& > *:first-child': {
        //         marginBottom: 40,
        //     },
        //     margin: '20px 0 45px 0',
        // }
        // },
    },
    isMultipleOptionsModalContainer: {
        margin: '40px 0 45px 0',
        display: 'grid',
        'grid-template-columns': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        padding: 0,
        '@media screen and (max-width: 475px)': {
            'grid-template-columns': 'none',
            '& > *:first-child': {
                marginBottom: 40,
            },
            margin: '20px 0 45px 0',
        }
    },
    modalTypesBtnsBorderBottom: {
        'border-bottom': '1px solid #ff5200 !important',
    },
    modalTypesBtns: {
        display: 'grid',
        // 'grid-template-rows': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        color: '#575757',
        borderRadius: 0,
        borderBottom: '1px solid #ff7600',
        backgroundColor: '#f9f9f9',
        minWidth: 180,
        '@media screen and (max-width: 475px)': {
            minWidth: '80%',
        },

        'border': '1px solid #ebebeb',

        // 'border': '1px solid #ff5200',
        'border-top-right-radius': 7,
        'border-top-left-radius': 7,

        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: 'none',
    },
    modalTypesIcons: {
        fontSize: 42,
        color: '#ff7600',
    }

});

class DeliveryModal extends Component {
    render() {
        const { classes, fullWidth = false, maxWidth = 'xs', open, onClose, translations, askForBillCash, askForBillCard } = this.props;

        return (
            <React.Fragment>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={onClose}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    className={classes.modal}
                    data-class="dialog-container"
                >
                    <DialogTitle id="max-width-dialog-title" style={{ textAlign: 'center', backgroundColor: "rgb(203,65,2)", color: 'white', boxShadow: '0 1px 5px 0px black' }} data-class="dialog-title-container">
                        {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.choose_payment_type : ''}
                    </DialogTitle>
                    <DialogContent style={{ padding: 0 }}>

                        {/* <Backdrop open={isLoadingOrder} className={classes.backdrop}>
                            <CircularProgress className="is-loading" />
                        </Backdrop> */}


                        {window.localStorage.getItem('bill_wanted') == 1 ?
                            <div className={classes.billAlreadyWantedContainer}>{translations && translations.messages && translations.messages.notifications && translations.messages.notifications.bill_already_wanted ? translations.messages.notifications.bill_already_wanted : ''}</div>
                            :
                            null
                        }

                        <div className={classes.isMultipleOptionsModalContainer}>

                            <Button size="large" className={clsx(classes.modalTypesBtns, classes.modalTypesBtnsBorderBottom)} onClick={askForBillCash}>
                                <div>
                                    <LocalAtmIcon className={classes.modalTypesIcons} />
                                </div>
                                <div>
                                    {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.payment_cash : ''}
                                </div>
                            </Button>

                            <Button size="large" className={clsx(classes.modalTypesBtns, classes.modalTypesBtnsBorderBottom)} onClick={askForBillCard}>
                                <div>
                                    <PaymentIcon className={classes.modalTypesIcons} />
                                </div>
                                <div>
                                    {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.payment_card : ''}
                                </div>
                            </Button>

                        </div>

                    </DialogContent>

                </Dialog >
            </React.Fragment >
        );
    }
}

export default withStyles(styles)(DeliveryModal);