import { ORDER_EXPIRE_TIME_HOURS } from '../constants';
import Cookies from 'universal-cookie';
import { clearCartLocal } from './../products/actions/products.action';
import setCustomPlaceHeader from './../utils/setCustomPlaceHeader';
import jwt from 'jsonwebtoken';

const cookies = new Cookies();

const TABL_USER_TOKEN_KEY = '_tut_';     // THE TOKEN FOR THE USER LOGIN   (table_user_token)
const TABL_ORDER_TOKEN_KEY = '_tot_';    // THE TOKEN FOR THE ORDER  (tabl_order_token)  // old value by denis - user_token 

class Auth {
	// ------------- tabl_user_token -> USER TOKENA - за логин -----------------------

	static authUser(userToken) {
		window.localStorage.setItem(TABL_USER_TOKEN_KEY, userToken)
	}
	static getDecodedUser() {
		const userJSON = window.localStorage.getItem(TABL_USER_TOKEN_KEY)
		if (userJSON) {
			return jwt.decode(userJSON)
		}
		// console.log('nqma user')
		return null
	}
	static deauthUser() {
		window.localStorage.removeItem(TABL_USER_TOKEN_KEY)
	}
	static getAuthUserToken() {
		return window.localStorage.getItem(TABL_USER_TOKEN_KEY)
	}



	// -------------- tabl_token -> MENU SESSION ( за поръчката ) ------------------
	static removeUserToken = () => {      // REMOVES ORDER TOKEN NOT USER TOKEN
		window.localStorage.removeItem(TABL_ORDER_TOKEN_KEY);
	}

	static ifTokenExpired() {
		let user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {
			user_token = JSON.parse(user_token);

			let expires = new Date(user_token.expires);
			const expires_diff = expires.getTime() - new Date().getTime();
			if (expires_diff > 0 && expires_diff < ORDER_EXPIRE_TIME_HOURS * 3600000) {
				return false;
			} else {
				return true;
			}
		}

		return true;
	}

	static setUserToken = function (token) {
		let tokenExpireDate = new Date();
		tokenExpireDate.setHours(tokenExpireDate.getHours() + ORDER_EXPIRE_TIME_HOURS);

		let user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {
			user_token = JSON.parse(user_token);
		} else {
			user_token = {};
		}

		user_token.token = token;
		user_token.expires = tokenExpireDate;

		window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));
	}

	static updateUserTokenExpire = (minutes = 15) => {
		const token = this.getUserToken();
		if (token) {
			const tokenExpireDate = new Date((new Date()).getTime() + minutes * 60000);
			window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify({ token: token, expires: tokenExpireDate }));
		}
	}

	static getUserToken = () => {
		let user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {
			user_token = JSON.parse(user_token);

			let expires = new Date(user_token.expires);
			const expires_diff = expires.getTime() - new Date().getTime();
			if (expires_diff > 0 && expires_diff < ORDER_EXPIRE_TIME_HOURS * 3600000) {
				return user_token.token;
			} else {
				return '';
			}
		}
		return '';
	}

	static setTokenOrderType(ordertypeId) {
		let user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {
			user_token = JSON.parse(user_token);
			user_token.ordertype = ordertypeId

			window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));
		}
	}

	static getTokenOrderType() {
		let user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {

			if (this.ifTokenExpired()) {
				this.setTokenOrderType(null);
				return null;
			}
			user_token = JSON.parse(user_token);

			if (user_token.ordertype) {
				return user_token.ordertype;
			}
		}

		return null;
	}

	static setTabletMode() {
		let user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		// console.log("SETTING TABLEt", user_token);
		if (user_token) {
			user_token = JSON.parse(user_token);
			user_token.tablet_mode = 1

			window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));
		}
	}
	static removeTabletMode() {
		let user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		// console.log("SETTING TABLEt", user_token);
		if (user_token) {
			user_token = JSON.parse(user_token);
			user_token.tablet_mode = 0

			window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));
		}
	}



	static isTabletMode() {
		let user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		if (user_token) {
			user_token = JSON.parse(user_token);

			if (user_token.tablet_mode) {
				return 1;
			}
		}

		return null;
	}

	static setOpenedPlace(placeId) {
		cookies.set('tabl_place', placeId, { path: "/" });

		// let user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		// if (user_token) {
		// 	user_token = JSON.parse(user_token);
		// 	user_token.place = placeId
		// } else {
		// 	user_token = {};
		// 	user_token.place = placeId;
		// }

		// window.localStorage.setItem(TABL_ORDER_TOKEN_KEY, JSON.stringify(user_token));			
	}

	static getOpenedPlace() {

		let c = cookies.get('tabl_place');

		if (c) {
			return c;
		}

		return null;

		// let user_token = window.localStorage.getItem(TABL_ORDER_TOKEN_KEY);
		// if (user_token) {
		// 	user_token = JSON.parse(user_token);

		// 	if(user_token.place) {
		// 		return user_token.place;
		// 	}
		// }

		// return null;
	}


	// static authenticateUser (token) {
	//  	window.localStorage.setItem('token', token)
	// }
	// static isUserAuthenticated () {
	//   	return window.localStorage.getItem('menuToken') !== null
	// }
	// static deauthenticateUser () {
	//   	window.localStorage.removeItem('token')
	// }
	// static setToken(token) {
	//   	localStorage.setItem('menuToken', token);
	// }
	// static getToken () {
	//   	return window.localStorage.getItem('menuToken')
	// }
	// static removeToken () {
	//   	return window.localStorage.removeItem('menuToken')    
	// }

	// static test() {
	// }
}

export default Auth
