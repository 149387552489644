import React, { Component } from "react";
import { connect } from "react-redux";
import QrReader from "react-qr-reader";
import Utils from "../../utils/Utils";
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {
        marginTop: 135,
        // backgroundColor: 'black', //down from settings
        width: '100%',

        // Categories underline indicator
        '& .MuiTabs-indicator': {
            backgroundColor: '#FE6902'
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: 'inherit',
            fontWeight: 'bold'
        },
        '& > .MuiPaper-root': {
            zIndex: 1099,
            position: 'absolute',
            paddingTop: 55,
            top: 0,
        },
    },
    categoryName: {
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        'flex-grow': 1,
        'max-width': 'none',
    },
    categoryIcon: {
        height: 35,
    },
    swipeableViews: {
        height: 'calc(100vh - 135px)',
        WebkitOverflowScrolling: 'touch',
    },
    tabsContainer: {
        '& .MuiTabs-flexContainer': {
            'justify-content': 'space-between', //space-around
        },
    },
    productsContainer: {
        WebkitOverflowScrolling: 'touch',
        width: '90%',
        margin: '0 auto',
        paddingTop: 15, //DIFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF
        paddingBottom: 70, // temp unable to hive navbar fix
    },
});

class ScanPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scanDelay: 200
        }

    }

    handleScan = (scannedTableToken) => {
        const { onClose, onSubmit, onError, translations } = this.props;

        




        if (scannedTableToken) {
            // this.props
            //     .verifyToken(scannedTableToken)
            //     .then((data) => {
            //         // let validToken = data.data;
            //         let validTokenSession = data.data;

            //         let currState = this.state;
            //         currState.scannedTableToken = validTokenSession;
            //         Utils.setUserToken(validTokenSession);
            //         onSubmit(currState, 1);
            //         this.setState({ modalType: 0 });
            //     })
            //     .catch(err => {
            //         Utils.removeUserToken();
            //         onError(translations.messages && translations.messages.errors && translations.messages.errors.order_sent_fail ? translations.messages.errors.order_sent_fail : 'Invalid token scanned');
            //         this.setState({ modalType: 0 });
            //     })
        }
    }

    handleScanError = (err) => {
        console.error(err);
    }


    render() {

        return (

            <QrReader ref="qrReader1"
                delay={this.state.scanDelay}
                onError={this.handleScanError}
                onScan={this.handleScan}
                style={{ width: "100%" }}
            //   legacyMode={scanLegacyMode}
            />

        )

    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        settings: state.settings
    };
};
export default withStyles(styles)(connect(mapStateToProps, {})(ScanPage));

// export default (connect(mapStateToProps)(ScanPage));