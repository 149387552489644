import React, { Component } from 'react'
import FlippingPages from 'flipping-pages'
import FlipPage from 'react-flip-page';
/* IMPORTANT */
import 'flipping-pages/FlippingPages.css'

import './../styles/imageCategoryImages.css';
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import Utils from '../../utils/Utils';
import ZoomGallery from 'react-zoom-carousel'
// import ImageMenu from '../../common/image-menu/index'
// import ZoomSlider from 'react-instagram-zoom-slider'

// const zoomGallery = [
//     'http://placehold.it/900x',
//     'http://placehold.it/900x',
//     'http://placehold.it/900x',
//     'http://placehold.it/900x',
//     'http://placehold.it/900x'
// ]


class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: true,
        }



        this.flipPageSettings = {
            orientation: "horisontal",
            responsive: true,
            uncutPages: false,
            animationDuration: 350,
            treshold: 3,
            pageBackground: "transparent",
            flipOnTouch: true
        };

        this.flipPage = null
        this.totalPages = 4
        this.state = {
            selected: 0,
            panningDisabled: true,
            currPage: 0,

        }
        this.clickTimeout = null
    }

    handleSelectedChange = (selected) => {
        this.setState({ selected })
    }

    previous = () => {
        this.setState(state => ({
            currPage: state.currPage - 1
        }))
    }

    next = () => {
        this.setState(state => ({
            currPage: state.currPage + 1
        }))
    }




    onZoomChange = (args) => {
        // let currPanningState = this.state.panningDisabled;
        // let newPanningState;
        // if(args.scale == 1) {
        //     newPanningState = true
        // } else {
        //     newPanningState = false;            
        // }

        // if(currPanningState != newPanningState) {
        //     this.setState({ panningDisabled: newPanningState })
        // }
    }

    onPinchingStop = (args) => {
        let currPanningState = this.state.panningDisabled;
        let newPanningState;
        if (args.scale > 1) {
            this.setState({ panningDisabled: false })
        } else {
            this.setState({ panningDisabled: true, scale: args.scale })
        }
    }

    onWheelStop = (args) => {
        if (args.scale > 1) {
            this.setState({ panningDisabled: false })
        } else {
            this.setState({ panningDisabled: true, scale: args.scale })


        }
    }

    componentWillMount = props => {
        this.clickTimeout = null
    }

    handleClicks = () => {
        if (this.clickTimeout !== null) {
            clearTimeout(this.clickTimeout)
            this.clickTimeout = null
            this.setState({ panningDisabled: true })

        } else {
            this.clickTimeout = setTimeout(() => {
                clearTimeout(this.clickTimeout)
                this.clickTimeout = null
            }, 200)
        }
    }


    render() {
        const screenHeight = window.innerHeight;

        const pageStyles = {
            height: screenHeight,
            minHeight: screenHeight,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            textAlign: "center",
            backgroundColor: "#ffffff",
            touchAction: "none"
        };

        let totalPages = Object.values(this.props.cat.imageCategoryImages).length;
        let images = Object.values(this.props.cat.imageCategoryImages);
        let currImage = images && images[this.state.currPage] && images[this.state.currPage].image || ''
        let imageCatImages = []
        let slides = []

        Object.values(this.props.cat.imageCategoryImages).map((i, k) => {
            imageCatImages.push(Utils.getImageCategoryImagePath(i.image))
            slides.push(<img src={i.image} alt={k + " slide"} />)
        })
        return (
            <div className={slides.length == 1 ? "single-image" : ''}>
                {/* <div style={{ width: '100%', height: '500px' }}> */}
                {/* {Object.values(this.props.cat.imageCategoryImages).map((item, k) => ( */}
                {/* <PinchZoomPan>
                        <img src={Utils.getImageCategoryImagePath(currImage)} style={{ height: "auto", width: "100%" }} />
                        <img src="https://i.pinimg.com/originals/bf/46/7d/bf467d3856164afc0b91f84b41241858.jpg" style={{ width: '100%', height: '100%' }}/>
                    </PinchZoomPan> */}
                {/* ))} */}
                {/* </div> */}

                {/* --- */}
                <ZoomGallery
                    open={true} // Conditionally show/hide the carousel
                    handleClose={() => void (0)} // Called when close button is clicked  // this.setState({ open: false })
                    images={imageCatImages} // Array of image urls
                    initialIndex={0} // Index to start the slider from
                />
                {/* 
                <ImageMenu
                    open={true} // Conditionally show/hide the carousel
                    handleClose={() => this.setState({ open: false })} // Called when close button is clicked
                    images={imageCatImages} // Array of image urls
                    initialIndex={1} // Index to start the slider from
                /> */}

                {/* <ZoomSlider slides={slides} /> */}


                {/* === */}
                {/* <br /><br />

                <button
                    onClick={this.previous}
                    disabled={!this.state.currPage}
                >Previous</button>
                <button
                    onClick={this.next}
                    disabled={this.state.currPage + 1 === Object.values(this.props.cat.imageCategoryImages).length}
                >Next</button> */}
            </div>
        )


        // return (
        //     <div className="App">
        //           {/* <FlipPage {...this.flipPageSettings}> */}
        //             {/* {this.state.currPage}----{images && images[this.state.currPage] && images[this.state.currPage].image || ''} */}
        //             <div style={{ width: '100%', height: '500px' }}>
        //                 {/* {IMAGE_CAT_IMAGES_PATH} */}
        //                 {/* {Object.values(this.props.cat.imageCategoryImages).map((item, k) => ( */}


        //                     <PinchZoomPan>
        //                      <img src={Utils.getImageCategoryImagePath(currImage)}
        //                         style={{height: "auto", width: "100%"}}/> 
        //                         {/* <img src="https://i.pinimg.com/originals/bf/46/7d/bf467d3856164afc0b91f84b41241858.jpg" style={{ width: '100%', height: '100%' }}/> */}
        //                     </PinchZoomPan> 
        //                  {/* ))} */}
        //             </div>
        //             {/* <div className="App-page App-page_red">0</div>
        //             <div className="App-page App-page_green">1</div>
        //             <div className="App-page App-page_blue">2</div>
        //             <div className="App-page App-page_orange">3</div> */}
        //         {/* </FlipPage> */}
        //         {/* Buttons are required for keyboard navigation */}
        //         <br/>
        //         <br/>
        //         <button
        //             onClick={this.previous}
        //             disabled={!this.state.currPage}
        //         >Previous</button>
        //         <button
        //             onClick={this.next}
        //             disabled={this.state.currPage + 1 === Object.values(this.props.cat.imageCategoryImages).length}
        //         >Next</button>
        //     </div>
        // )
    }

}

export default App
