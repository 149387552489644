import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Grid,
    Input,
    InputLabel,
    InputAdornment,
    FormControl,
    TextField,
    Button,
    Collapse
} from '@material-ui/core';
import { AccountCircle, Room, PhoneInTalk, Mail, Lock, KeyboardArrowDown, KeyboardArrowLeft } from '@material-ui/icons';

import Auth from './../../../utils/Auth';
import clsx from 'clsx';
import Utils from '../../../utils/Utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        background: 'rgba(255,255,255,0.72)',
        borderRadius: 11,
        margin: '75px auto 20px auto',
        maxWidth: 650,
    },
    textFieldContainer: {
        width: '85%',
        margin: '15px auto',
        '&:nth-of-type(1)': {
            paddingTop: 15,
        },
        '&:last-child': {
            marginBottom: 0,
        }
    },
    textField: {
        width: '100%',
        '& label.Mui-error': {
            color: 'rgba(0, 0, 0, 0.54)'
        },
        '& label.Mui-focused': {
            color: 'rgba(254, 105, 2, 0.7)',
        },
        '& .MuiInput-underline:after': {
            // borderBottomColor: 'rgb(254, 105, 2)',
            borderBottomColor: 'rgb(117 117 117)',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: '#f44336',
        },
    },
    formContainer: {
        width: '100%'

    },
    promoCodeBtn: {
        // width: '85%',
        color: 'rgba(0, 0, 0, 0.54)',
        // float: 'right',
        borderRadius: 0,
        padding: '10px 0',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    applyPromoCodeBtn: {
        textAlign: 'center',
        width: '100%',
        borderRadius: 0,
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    promoCodeRow: {
        marginTop: 10,
        marginBottom: 5,
    },
    tablPointsContainer: {
        background: '#ff6500',
        width: '100%',
        'border-bottom-left-radius': 11,
        'border-bottom-right-radius': 11,
        color: 'white',
        textAlign: 'center'
    },
    tablPoints: {
        fontSize: 20,
    },
    tablPointsDescription: {
        fontSize: 16,
        color: '#f0f0f0',
        padding: 10,
        paddingTop: 0,
    },
    saveButton: {
        width: '100%',
        maxWidth: 650,
        display: 'block',
        margin: '0 auto',
    }

}));

const ProfileEditForm = (props) => {

    const [formSubmissionFailed, setFormSubmissionFailed] = useState(false);
    const [changePasswordVisible, setChangePasswordVisible] = useState(false);
    const [customErrors, setCustomErrors] = useState(false);

    const classes = useStyles();

    const onChange = (e) => {
        props.onChange(e);
        formSubmissionFailed ? isFormValid({ [e.target.name]: e.target.value }) : void (0);
    }

    const isFormValid = (updatedValue = {}) => {

        const checkMinLengthValid = (value) => {
            const minLength = 3;
            return value && value.toString().length >= minLength;
        }

        let stateData = props.user;
        // Used for live validate onchange (Because state is not changed immediately)
        if (updatedValue && Object.keys(updatedValue).length > 0 && Object.keys(updatedValue)[0]) {
            stateData[Object.keys(updatedValue)[0]] = updatedValue[Object.keys(updatedValue)[0]];
        }

        // Name
        const fullnameValid = checkMinLengthValid(stateData.fullname) && stateData.fullname.toString().length <= 40;

        // Phone
        let phoneValid = checkMinLengthValid(stateData.phone);
        const phoneRegexMatch = Utils.getSettingsValue(props.settings, 'validate_phone_number_regex') ? (new RegExp(Utils.getSettingsValue(props.settings, 'validate_phone_number_regex'))).test(stateData.phone.replace(/ /g, '')) : true;
        phoneValid = phoneValid && phoneRegexMatch;

        // Address
        const addressValid = checkMinLengthValid(stateData.address);

        const isFormValid = fullnameValid && phoneValid && addressValid;

        if (isFormValid) {
            setCustomErrors({})
        } else {
            setFormSubmissionFailed(true);
            setCustomErrors({
                fullname: !fullnameValid ? props.translations.messages.errors.invalid_name : '',
                phone: !phoneValid ? props.translations.messages.errors.invalid_phone : '',
                address: !addressValid ? props.translations.messages.errors.invalid_address : '',
            })
        }

        return isFormValid;
    }


    return (
        <form onSubmit={props.onSubmit}>
            {/* <MessagesContainer /> */}

            <div className={classes.root}>

                <div className={classes.textFieldContainer}>
                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                        <Grid item xs={1}>
                            <AccountCircle />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField name="fullname" className={classes.textField}
                                error={customErrors && customErrors.fullname} helperText={customErrors && customErrors.fullname ? customErrors.fullname : ''}
                                label={props.translations.pages.profile.fullname}
                                onChange={onChange} value={props.user.fullname}
                            />
                        </Grid>
                    </Grid>
                </div>

                {/* <div className={classes.textFieldContainer}>
                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                        <Grid item xs={1}>
                            <AccountCircle />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField name="last_name" className={classes.textField}
                                error={customErrors && customErrors.last_name} helperText={customErrors && customErrors.last_name ? customErrors.last_name : ''}
                                label={props.translations.pages.profile.last_name}
                                onChange={onChange} value={props.user.last_name}
                            />
                        </Grid>
                    </Grid>
                </div> */}


                <div className={classes.textFieldContainer}>
                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                        <Grid item xs={1}>
                            <Room />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField name="address" className={classes.textField}
                                error={customErrors && customErrors.address} helperText={customErrors && customErrors.address ? customErrors.address : ''}
                                label={props.translations.pages.profile.address}
                                onChange={onChange} value={props.user.address}
                            />
                        </Grid>
                    </Grid>
                </div>

                <div className={classes.textFieldContainer}>
                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                        <Grid item xs={1}>
                            <PhoneInTalk />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField name="phone" className={classes.textField}
                                error={customErrors && customErrors.phone} helperText={customErrors && customErrors.phone ? customErrors.phone : ''}
                                label={props.translations.pages.profile.phone}
                                onChange={onChange} value={props.user.phone}
                            />
                        </Grid>
                    </Grid>
                </div>

                <div className={classes.textFieldContainer}>
                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                        <Grid item xs={1}>
                            <Mail />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField name="email" className={classes.textField} disabled
                                error={customErrors && customErrors.email} helperText={customErrors && customErrors.email ? customErrors.email : ''}
                                label={props.translations.pages.profile.email}
                                onChange={onChange} value={props.user.email}
                            />
                        </Grid>
                    </Grid>
                </div>


                <div className={classes.textFieldContainer}>
                    <Button
                        onClick={() => setChangePasswordVisible(!changePasswordVisible)}
                        className={classes.promoCodeBtn}
                        endIcon={changePasswordVisible ? <KeyboardArrowDown /> : <KeyboardArrowLeft />}
                    >
                        {props.translations.pages.profile.change_password}
                    </Button>

                    <div className={clsx(classes.textFieldContainer, classes.promoCodeRow)} style={{ marginTop: 0, paddingTop: 0, width: '100%' }}>
                        <Collapse in={changePasswordVisible} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                            <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                                <Grid item xs={1}>
                                    <Lock />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField name="password" className={classes.textField} type="password"
                                        error={customErrors && customErrors.password} helperText={customErrors && customErrors.password ? customErrors.password : ''}
                                        label={props.translations.pages.profile.new_password}
                                        onChange={onChange}
                                    />
                                </Grid>
                            </Grid>
                        </Collapse>
                    </div>
                </div>

                <div className={classes.tablPointsContainer}>
                    <div className={classes.tablPoints}><b>{props.translations.pages.profile.tabl_points}</b>: {Auth.getDecodedUser().tabl_points}</div>
                    <div className={classes.tablPointsDescription}>{props.translations.pages.profile.tabl_points_info}</div>
                </div>
            </div>

            <Button className={classes.saveButton} variant="contained" onClick={() => isFormValid() ? props.handleFormSubmit() : props.addErrorMessages(["Моля попълнете всички полета коректно."])}>Запази</Button>

            <br /><br />
            <br /><br />

        </form >
    )
}

export default ProfileEditForm;