import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Divider from '@material-ui/core/Divider'
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import Chip from '@material-ui/core/Chip';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HomeIcon from '@material-ui/icons/Home';
// TEST import Grow from '@material-ui/core/Grow';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import SearchIcon from '@material-ui/icons/Search';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import LanguageIcon from '@material-ui/icons/Language';
import TranslateIcon from '@material-ui/icons/Translate';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import CloseIcon from '@material-ui/icons/Close';


import { Fade, Slide, Grow, Zoom } from "@material-ui/core/";

import clsx from 'clsx';

import CartCommentModal from '../../common/modals/CartCommentModal';
import history from './../../utils/history';
import CartModal from '../modals/CartModal';
import PaymentTypeModal from '../modals/PaymentModal';
import LanguageModal from '../modals/LanguageModal';
import UserNeedsModal from '../modals/UserNeedsModal'
import ScanModal from '../../qr-scan/components/scanModal';
import OrderModal from '../modals/OrderModal'
import ChoosePlaceModal from './../modals/ChoosePlaceModal';
import InitialBannerFastOrder from './../modals/InitialBannerFastOrder';
import InitialHtmlBanner from './../modals/InitialHtmlBanner';
import SearchHeader from './SearchHeader'
import TopSpeedDialTooltip from '../buttons/TopSpeedDialTooltip'
import BottomSpeedDialTooltip from '../buttons/BottomSpeedDialTooltip'
import BottomSpeedDialTooltipUserNeedsAndBillWanted from '../buttons/BottomSpeedDialTooltipUserNeedsAndBillWanted'
import { updateProductQuantity, removeProductFromCart, makeOrder, getAllProducts, clearOrderStatus, clearCartLocal, changeSelectedCartTab, getOrders, askForBill, clearBillWanted, removeProductPageId, setOrderComment, setCommentModalData, closePaymentTypeModal, openPaymentTypeModal, setDisabledMakeOrderButton } from '../../products/actions/products.action'
import { addUserNeed, resetUserNeedStatus } from '../../user-needs/actions/userneeds.action';
import { addPlaceImage } from '../../places/actions/places.actions'
import { listLanguages, changeLanguage } from './../../language/actions/language.action';
import { setHierarchyLevel, setChosenCat, showHeaderCategory, setSelectedCatId } from './../../categories/actions/categories.action';

import { setSearchOpened, setSearchText } from '../../search/actions/search.actions';
import { setCurrOpenedModal, setOrderModalState, closeScanModal } from '../../qr-scan/actions/scan.actions';
import { setLoginModalOpened, setRegisterModalOpened, setProfileModalOpened } from './../../users/actions/users.actions';
import { setAlertMessage, setCartModalOpenedState, setTermsDialogOpened, setPrivacyPolicyDialogOpened } from './../../menu/menu.actions';

import Utils from "../../utils/Utils";
import Auth from '../../utils/Auth';
import { DEMO } from '../../constants';


import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DirectionsIcon from '@material-ui/icons/Directions';
import PublicIcon from '@material-ui/icons/Public';
import BeenhereIcon from '@material-ui/icons/Beenhere';

import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookIcon from '@material-ui/icons/Book';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import RoomIcon from '@material-ui/icons/Room';
import queryString from 'query-string';

// import ViewProductPage from '../../products/components/ViewProductPage';
import LoginModal from './../../users/components/auth/LoginModal';
import RegisterModal from './../../users/components/auth/RegisterModal';
import ProfileModal from './../../users/components/profile/ProfileModal';

import { set } from "lodash";
import MessagesContainer from './../../messages/MessagesContainer';
import PromoProductForCategoryModal from './../../promotions/components/PromoProductForCategoryModal';
import UpsellingProductModal from './../../products/components/modals/UpsellingProductModal';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Fade from '@material-ui/core/Fade';

import Button from '@material-ui/core/Button';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import { PRODUCT_IMG_URL } from '../../constants'
import ProductVariantsModal from './../modals/ProductVariantsModalTest';
import store from './../../store';

import MyPaypalBtn from './../../payments/paypal/MyPaypalBtn';
import CallIcon from '@material-ui/icons/Call';
import './../../initials.js';

const styles = theme => ({
	appBar: {
		padding: 0,
		position: 'fixed',
		borderBottomRightRadius: 11,
		borderBottomLeftRadius: 11,
		// backgroundColor: "#FE6902",
		// backgroundImage: 'linear-gradient(90deg, #e66200 0%, #fe6902 51%, #ff781e 100%)',
		background: 'rgb(254,105,2)',
		background: 'linear-gradient(90deg, rgb(254,105,2) 0%, rgb(203,65,2) 78%)',
	},
	toolbar: {
		minHeight: 55,
		padding: 0,
		display: 'grid',
		'grid-template-columns': '35% 30% 35%',
		'& *:nth-child(2)': {
			textAlign: 'center',
			'justify-self': 'center',
		},
		'& *:nth-child(3)': {
			textAlign: 'right'
		},
	},
	toolbarTabletMode: {
		minHeight: 65,
		'grid-template-columns': '34% 32% 34%',
	},
	title: {
		// flexGrow: 1,
		// textAlign: 'center',
		// 	    width: 100%;
		// position: absolute;
		// top: 0;
	},
	logo: {
		height: 40,
		maxWidth: 'none',
		marginTop: 7,
		'@media screen and (max-width: 370px)': {
			maxWidth: 160,
		},
		'@media screen and (min-width: 370px) and (max-width: 400px)': {
			maxWidth: 195,
		},
		// transform: 'translate(50%, 0%)',
	},
	tabletLogo: {
		height: 45
	},
	categoryContainer: {
		borderRadius: 8,
		fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
		// transform: 'translate(50%, 0%)',
		// marginLeft: 87,
		paddingLeft: 7,
		height: 35,
		textOverflow: 'ellipsis',
		maxWidth: 400,
		'@media screen and (max-width: 550px)': {
			maxWidth: 360,
		},
		'@media screen and (max-width: 525px)': {
			maxWidth: 320,
		},
		'@media screen and (max-width: 485px)': {
			maxWidth: 290,
		},
		'@media screen and (max-width: 450px)': {
			maxWidth: 250,
		},
		'@media screen and (max-width: 420px)': {
			maxWidth: 230,
		},
		'@media screen and (max-width: 400px)': {
			maxWidth: 215,
		},
		'@media screen and (max-width: 375px)': {
			maxWidth: 200,
		},
		'@media screen and (max-width: 350px)': {
			maxWidth: 160,
		},
		'& .MuiChip-label': {
			paddingLeft: 6,
			fontSize: 14,
		}
	},
	categoryContainerTabletMode: {
		height: 45,
		paddingTop: 1,
		margin: '0 10px'
	},
	userProfileIconContainer: {
		// width: 100,
		width: 'auto',
		paddingTop: 14,
		'@media screen and (min-width: 600px) and (max-width: 1100px)': {
			width: 100
		},
		'@media screen and (min-width: 355px)': {
			marginRight: 12,
		},
	},
	moneyIconContainer: {
		// width: 100,
		width: 'auto',
		'@media screen and (min-width: 600px)': {
			borderLeft: '1px solid #ffffff4d',
		},
		'@media screen and (min-width: 600px) and (max-width: 1100px)': {
			width: 100
		},
		'@media screen and (min-width: 340px)': {
			paddingRight: 16,
		},
		borderRadius: 0,
		paddingTop: 14,
	},
	moneyIconContainerTabletMode: {
		height: 65
	},
	headerProfileCartTexts: {
		fontSize: 16,
		paddingRight: 15,
		paddingBottom: 3,
		'@media screen and (max-width: 1100px)': {
			display: 'none'
		},
		fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
	},
	badgeMoneyIcon: {
		'& .MuiBadge-badge': {
			color: 'black',
			fontWeight: 'bold',
			background: 'white',
			fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
		}
	},
	moneyIcon: {
		fontSize: '1.8rem',
	},
	addedToCartTooltipBtn: {
		fontSize: '14px !important',

		'& .MuiTooltip-tooltip': {
			fontSize: '14px !important',
		}
	},

	bottomNavigation: {
		position: 'fixed',
		zIndex: 11,
		border: '1px solid #ccc',
		bottom: 0,
		right: 0,
		width: '100%',
		// maxWidth: 400,
		borderTopLeftRadius: 18,
		'& .MuiButtonBase-root:last-child': {
			borderLeft: '1px solid #ebebeb',
			width: 135,
			minWidth: 135,
			maxWidth: 135,
		},
	},

	bottomNavigationHiddenLastChildFourItems: {
		'& .MuiButtonBase-root:last-child': {
			'@media screen and (max-width: 450px)': {
				display: 'none',
			}
		}
	},

	bottomNavigationHiddenLastChildThreeItems: {
		'& .MuiButtonBase-root:last-child': {
			'@media screen and (max-width: 370px)': {
				display: 'none',
			}
		}
	},
	snackbarMessageStyles: {
		background: '#ccc',
		color: 'black',

		'& .MuiSnackbarContent-message': {
			width: '80% !important'
		}
	},
	snackbarSuccessMessageStyles: {
		background: '#4caf50',
		color: 'white',

		'& .MuiSnackbarContent-message': {
			width: '80% !important'
		}
	}


});

class AppNavbar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userNeedsModalOpened: false,
			changeLanguageModalOpened: false,
			// cartModalOpened: false,
			waiterModalOpened: false,
			orderModalOpened: false,
			preOrderModalOpened: false,
			fixedButtonVisible: false, //It is set with timeout
			scanModalOpened: false,
			scanModalCallback: () => void (0),
			currHierarchyLevel: 1,

			selectedCat: [],
			showCategoryBackBtn: false,
			categoryBackBtnText: '',

			loginModalOpened: false,
			registerModalOpened: false,

			comments: Object.values(this.props.products.order_comment) || [],
			openCommentModal: false,
			commentModalTitle: '',
			commentVariantId: '',
			// loginModalOpened: false,
			// registerModalOpened: false,

			galleryOpened: false,
		}
	}

	componentDidMount() {

		const checkForInternetConnection = () => {
			const { translations } = this.props; //This must be here...

			if (!navigator.onLine) {
				this.props.setAlertMessage(true, translations && translations.common && translations.common.no_internet ? translations.common.no_internet : '')
			} else {
				if (this.props.alertMessage && this.props.alertMessage.opened) {
					this.props.setAlertMessage(false)
				}
			}
		}
		setInterval(() => checkForInternetConnection(), 30000); //30 seconds


		setInterval(this.props.getOrders, 60000); //1 minute
		this.togglefixedButtonVisibility(1000);
		// this.props.listLanguages();
	}

	componentWillReceiveProps(nextProps) {
		const { translations } = this.props;
		// if(nextProps.selectedCat) {
		//     this.setState({
		//         currHierarchyLevel: nextProps.selectedCat.hierarchyLevel
		//     })
		// }

		if (nextProps.categoriesHierarchy) {
			let pathSplitted = history.location.pathname ? history.location.pathname.split("/") : [];
			let catSlug = pathSplitted[2] ? pathSplitted[2] : "";

			let catsHierarchy = nextProps.categoriesHierarchy;
			let selectedCat = catsHierarchy && Object.values(catsHierarchy).filter(c => c.slug === catSlug)[0] ? Object.values(catsHierarchy).filter(c => c.slug === catSlug)[0] : null;

			if (!selectedCat) {
				let mainParent = catsHierarchy && Object.values(catsHierarchy).filter(c => c.hierarchyLevel == 1)[0];
				selectedCat = mainParent || []; //catsHierarchy && Object.values(catsHierarchy)[0] ? Object.values(catsHierarchy)[0] : [];
			}

			if (selectedCat && selectedCat.id && nextProps.selected_cat_hierarchy_id == 0) {
				nextProps.setSelectedCatId(selectedCat.id);
				Utils.setDataForTrack(selectedCat.id, nextProps.view_product_page_id, nextProps.history.location.pathname)
			}

			if (nextProps.view_product_page_id) {
				Utils.setDataForTrack(selectedCat.id, nextProps.view_product_page_id, nextProps.history.location.pathname)
			}

			let showBackBtn = false;
			let backBtnText = '';
			if (selectedCat.hierarchyLevel > 1) {
				showBackBtn = true;
				backBtnText = translations && translations.common ? translations.common.action.back : '';
			}

			if (nextProps.categoryText && nextProps.show_category == true) {
				backBtnText = nextProps.categoryText;
			}

			// if (showBackBtn)

			this.setState({
				selectedCat: selectedCat,
				showCategoryBackBtn: showBackBtn,
				categoryBackBtnText: backBtnText
			})
		}

	}

	togglefixedButtonVisibility = (customTimeout = null) => {
		if (!customTimeout) {
			this.props.setSearchOpened(!this.props.searchOpened);
		}
		setTimeout(() => this.setState({ fixedButtonVisible: !this.state.fixedButtonVisible }), customTimeout ? customTimeout : 200);
	}

	componentDidUpdate() {
		const { translations } = this.props;


		// if (this.props.view_product_page_id) {
		// 	this.props.showHeaderCategory('asdd');
		// } else {
		// }

		// User needs
		if (this.props.user_need_added.length > 0 || this.props.user_need_failed.length > 0) {

			// Open snackbar with and show the response message
			this.props.setAlertMessage(true, (this.props.user_need_added.length > 0 ? (this.props.translations.messages ? this.props.translations.messages.notifications.user_need_success : '') : (this.props.translations.messages ? this.props.translations.messages.errors.user_need_fail : '')))
			this.props.resetUserNeedStatus();
			this.setState({ userNeedsModalOpened: false })
		}

		// Bull wanted messages
		if (/*this.state.cartModalOpened === true &&*/ this.props.billWanted == 'true') {
			// Open snackbar with and show the response message
			this.props.setAlertMessage(true, (this.props.billWantedStatus == 'true' ? (this.props.translations.messages ? this.props.translations.messages.notifications.bill_wanted_success : '') : (this.props.translations.messages ? this.props.translations.messages.errors.bill_wanted_fail : '')))
			this.props.clearBillWanted();
			this.props.closePaymentTypeModal()
			this.props.setCartModalOpenedState(false)
		}

		// Orders messages 
		// this.props.orderSent == true && this.props.orderMessage.length
		if (this.props.orderSent == 'true' && this.props.orderMessage.length > 0) {
			this.props.setOrderModalState(0);
			// this.props.setOrderModalState(0);

			// && 
			// (this.state.orderModalOpened === true || this.state.preOrderModalOpened === true || (this.props.settings && this.props.settings.order_type && this.props.settings.order_type.value && this.props.settings.order_type.value == 1))) {
			if (this.props.scan_modal_open != '1') {
				this.setState({ orderModalOpened: false, preOrderModalOpened: false }); // Close "delivery" and "pre-order" modals after request is done
			}
			// } else {
			//   this.setState({ orderModalOpened: true, preOrderModalOpened: true }); // Close "delivery" and "pre-order" modals after request is done        
			// }

			let orderSentMessage = this.props.orderMessage;
			let orderFailMessage = this.props.orderMessage;
			// let orderSentMessage = (this.props.translations.messages ? this.props.translations.messages.notifications.order_sent_success : '')
			// let orderFailMessage = (this.props.translations.messages ? this.props.translations.messages.errors.order_sent_fail : '');
			if (this.props.currentОrderТype) {
				if (this.props.currentОrderТype == 2) { // Delivery
					if (this.props.settings.seccess_message_delivery_order && this.props.settings.seccess_message_delivery_order.value && this.props.settings.seccess_message_delivery_order.value.length != 0) {
						orderSentMessage = this.props.settings.seccess_message_delivery_order.value;
					}
				} else if (this.props.currentОrderТype == 3) { // Pickup
					if (this.props.settings.seccess_message_pickup_order && this.props.settings.seccess_message_pickup_order.value && this.props.settings.seccess_message_pickup_order.value.length != 0) {
						orderSentMessage = this.props.settings.seccess_message_pickup_order.value;
					}
				}
			}

			// Open snackbar with and show the response message
			this.props.setAlertMessage(true, (this.props.orderSent == 'true' ? orderSentMessage : orderFailMessage), (this.props.orderSent == 'true'))

			this.props.clearOrderStatus();
			if (this.props.orderSent == 'true') { // Order is sent successfully
				this.props.getOrders(this.props.userToken);
				this.props.clearCartLocal();
				if (Utils.getSettingsValue(this.props.settings, 'close_order_modal_after_order') == 1) {
					this.props.setCartModalOpenedState(false);
				} else {
					this.props.changeSelectedCartTab(1);
				}
			}
		}

		// Bugfix when no search results & click "Go back" btn 
		if (this.state.fixedButtonVisible === false && this.props.searchOpened === false) {
			this.setState({ fixedButtonVisible: !this.state.fixedButtonVisible })
		}
	}


	openCartModalHandler = () => {
		this.props.setCartModalOpenedState(true) // Open cart modal
		const toOrderLocalStorage = window.localStorage.getItem('to_order');
		const orderedProductsLength = Object.keys(this.props.orderedProducts).length;

		if (toOrderLocalStorage && orderedProductsLength == 0) {
			this.props.changeSelectedCartTab(0)
		}

		if (orderedProductsLength && toOrderLocalStorage === null) {
			this.props.changeSelectedCartTab(1)
		}
	}

	doFixedBtnAction = (call_btn_instead_of_search, makeSearch = 0) => {
		if (call_btn_instead_of_search && makeSearch == 0 && !Auth.isTabletMode()) {
			window.location.href = 'tel:' + call_btn_instead_of_search;
		} else {
			this.props.allProducts.length === 0 ? this.props.getAllProducts() : void (0);
			this.togglefixedButtonVisibility();
		}
	}

	cartModalOrderAction = () => {
		var orderModalOpened = true;
		if (Auth.getTokenOrderType() || this.props.chosenPlaceOrderTypes.length == 1) {
			if (Auth.getTokenOrderType()) {
				var type = this.props.chosenPlaceOrderTypes.find(ot => ot.id === Auth.getTokenOrderType()).type;
				if (type == "spot_browse") {
					this.props.setAlertMessage(true, this.props.translations.pages.cart_delivery.call_waiter_to_order, true)
					return;
				}
			} else {
				var type = this.props.chosenPlaceOrderTypes[0].type;
			}

			if (type == 'spot') {
				if (Auth.getUserToken()) {
					this.props.makeOrder({}, this.props.products.order_comment, 1, 0, this.props.translations); // Restaurant order - Already scanned page
					orderModalOpened = false;//orderModalOpened
				} else {
					// for app functionality - close webview and go to app scan page
					if (window.localStorage.getItem("app")) {
						window.location.pathname = "/close-browser-app";
					} else {
						this.props.setCurrOpenedModal('scan')
					}
				}
			}

			this.props.setCurrOpenedModal(type)

		} else {
			this.props.setCurrOpenedModal('multiple')
		}

		this.props.setOrderModalState(orderModalOpened)
		// this.setState({ orderModalOpened: orderModalOpened })
	}

	addCommentToOrder = (variantId, comment) => {
		let stateComments = Object.values(this.props.products.order_comment);
		let validatedComments = Utils.validatedCommentObject(stateComments, variantId, comment)
		if (validatedComments) {
			this.props.setOrderComment(validatedComments)
		}
	}

	currLangOnChange = (e) => {
		this.props.changeLanguage(e.target.value);
	}

	handleHomeClick = () => {
		history.push(`/${this.props.clientSlug}`);
		this.props.showHeaderCategory(false)
	}

	handleBackClick = () => {
		const { translations } = this.props;
		// if (((window.location.hash).substr(1)).length > 0) {
		// 	window.location.hash = '';
		// }


		if (this.props.view_product_page_id != 0) {
			this.props.removeProductPageId();
			// history.goBack();
			// debugger;
			let product = Object.values(this.props.allProducts).find(p => p.id == this.props.view_product_page_id);
			if (product && this.props.selected_cat_hierarchy_id) {
				let prodCat = Object.values(this.props.categoriesHierarchy).find(c => c.id == this.props.selected_cat_hierarchy_id);
				if (prodCat) {
					// gabrovo.pizza-change
					history.push(`/${prodCat.slug}`);
				}
			}

			// this.props.showHeaderCategory(null)
		} else if (this.state.selectedCat && this.state.selectedCat.hierarchyLevel && this.state.selectedCat.hierarchyLevel > 1) {
			let newHierarchyLevel = this.state.selectedCat.hierarchyLevel - 1
			this.props.setHierarchyLevel(newHierarchyLevel);
			let catsAtThisLevel = Object.values(this.props.categoriesHierarchy).filter(cat => cat.hierarchyLevel == newHierarchyLevel);

			if (newHierarchyLevel > 1) {
				// this.props.showHeaderCategory(catsAtThisLevel[0].name)
				this.props.showHeaderCategory(translations && translations.common ? translations.common.action.back : '')
			} else {
				this.props.showHeaderCategory(false)
			}

			// this.props.setChosenCat(0);
			// this.setState({ selectedCat: catsAtThisLevel[0] });
			// history.push("/" + catsAtThisLevel[0].slug);

			let newCatIndex = catsAtThisLevel.findIndex(c => c.id == this.state.selectedCat.parentId);
			this.props.setChosenCat(newCatIndex);
			this.setState({ selectedCat: catsAtThisLevel[newCatIndex] });
			this.props.setSelectedCatId(catsAtThisLevel[newCatIndex].id);
			// gabrovo.pizza-change
			history.push(`/${catsAtThisLevel[newCatIndex].slug}`);

		} else {
			this.props.showHeaderCategory(false)
			// for other pages like /about -> just go back
			// gabrovo.pizza-change
			history.push(`/`);
		}

		const { action, location } = this.props.history;
		Utils.handleScrollToOnPop("POP", location, this.props.scrollData);
	}

	onSubmitImage = () => {

		// Image append
		const files = Array.from(document.querySelector('#gallery-upload-button').files)
		let formData = new FormData()

		files.forEach((file, i) => {
			formData.append("file", file)
		})


		formData.append("place_id", 1)

		//  this.props.addProduct(post, this.props.history, formData);
		this.props.addPlaceImage(formData); // TODO - this place must not be 1
	}

	render() {
		const { classes, addProductTooltip, translations, settings, alertMessage } = this.props;
		let { productsToOrder } = this.props;
		let { showCategory, categoryText } = this.props;
		if (this.state.categoryBackBtnText) {
			categoryText = this.state.categoryBackBtnText;
		}

		let showCatBtn = this.state.showCategoryBackBtn == true || showCategory == true ? true : false;
		showCategory = settings.disable_hide_header_logo && settings.disable_hide_header_logo.value && settings.disable_hide_header_logo.value == 1 ? false : showCatBtn; // showCategory;

		const call_btn_instead_of_search = settings && settings.call_btn_instead_of_search && settings.call_btn_instead_of_search.value.length > 0 ? settings.call_btn_instead_of_search.value : false;
		const second_call_btn = settings && settings.second_call_btn && settings.second_call_btn.value.length > 0 ? settings.second_call_btn.value : false;

		//
		const show_restaurant_location_position = Utils.getSettingsValue(settings, 'show_restaurant_location_position');
		const change_language_position = Utils.getSettingsValue(settings, 'change_language_position');
		const reservations_position = Utils.getSettingsValue(settings, 'reservations_position');
		const search_btn_position = Utils.getSettingsValue(settings, 'search_btn_position');
		const about_restaurant_position = Utils.getSettingsValue(settings, 'about_page_btn_position');

		const show_restaurant_location_position_footer = show_restaurant_location_position == 'footer' && (settings && settings.show_restaurant_location && settings.show_restaurant_location.value == 1)
		const change_language_position_footer = change_language_position == 'footer' //always is shown
		const reservations_position_footer = reservations_position == 'footer' && Utils.getSettingsValue(settings, 'reservations_url');
		const search_btn_position_footer = search_btn_position == 'footer' && (Utils.getSettingsValue(settings, 'call_btn_instead_of_search') != '');
		const about_restaurant_position_footer = about_restaurant_position == 'footer' // && (Utils.getSettingsValue(settings, 'about_page_html') != ''); - about_page_html is not used anymore (old ckeditor logic)

		let footerNavigatonCountLinks = 0;
		if (show_restaurant_location_position_footer) { footerNavigatonCountLinks++; }
		if (change_language_position_footer) { footerNavigatonCountLinks++; }
		if (reservations_position_footer) { footerNavigatonCountLinks++; }
		if (search_btn_position_footer) { footerNavigatonCountLinks++; }
		if (about_restaurant_position_footer) { footerNavigatonCountLinks++; }
		//

		let totalCountOrdered = '0';

		if (productsToOrder && Object.keys(productsToOrder).length > 0) {
			totalCountOrdered = parseInt(totalCountOrdered);
			Object.values(productsToOrder).map(pv => totalCountOrdered += parseInt(pv.quantity))
		} else {
			if (Auth.getUserToken()) {
				totalCountOrdered = -1;
			}
		}


		return (
			<React.Fragment>

				<MessagesContainer />

				<div className={classes.root}>
					<AppBar className={classes.appBar} id="fixed-header">
						<Toolbar className={Auth.isTabletMode() ? clsx(classes.toolbar, classes.toolbarTabletMode) : classes.toolbar} data-class="toolbar">

							{/* Left Menu icon */}
							<TopSpeedDialTooltip
								toggleSearch={() => { this.doFixedBtnAction(call_btn_instead_of_search, 1) }}
								openUserNeedsModal={() => { this.setState({ userNeedsModalOpened: !this.state.userNeedsModalOpened }); }}
								openLanguageModal={() => { this.setState({ changeLanguageModalOpened: !this.state.changeLanguageModalOpened }); }}
								openFeedback={() => { history.push(`/feedback`) }}
								openAboutUs={() => { history.push(`/about-tabl`) }}
								openAboutRestaurant={() => { history.push(`/about`) }}
								changeTable={() => { Utils.removeUserToken(); this.setState({ scanModalOpened: true }) }}
								openGallery={() => { this.setState({ galleryOpened: true }); }}
								clearCart={() => { this.props.clearCartLocal(); window.location.href = window.location.origin + '/' + this.props.clientSlug; }}
								translations={translations}
								settings={settings}
								chosenPlaceOrderTypes={this.props.chosenPlaceOrderTypes}
							/>

							{/* Logo / Back buttom */}
							<Typography component="h1" variant="h6" className={classes.title}>
								<Collapse in={showCategory} timeout={{ enter: 550, exit: 50 }}>
									{Auth.isTabletMode() ?
										<Chip
											className={clsx(classes.categoryContainer, classes.categoryContainerTabletMode)}
											clickable
											icon={<HomeIcon style={{ paddingRight: 5 }} />}
											label={(translations && translations.common && translations.common.home ? translations.common.home : '')}
											// onClick={() => history.push("")}
											onClick={this.handleHomeClick}
										/>
										:
										null
									}
									<Chip
										className={Auth.isTabletMode() ? clsx(classes.categoryContainer, classes.categoryContainerTabletMode) : classes.categoryContainer}
										clickable
										icon={<ArrowBackIosIcon />}
										label={categoryText}
										// onClick={() => history.push("")}
										onClick={this.handleBackClick}
									/>
								</Collapse>
								<Collapse in={!showCategory} timeout={{ enter: 550, exit: 50 }}>
									<img className={Auth.isTabletMode() ? clsx(classes.logo, classes.tabletLogo) : classes.logo}
										id="main-header-logo"
										src={settings.logo && settings.logo.value ? Utils.getSettingsPath(settings.logo.value) : (settings.tabl_logo && settings.tabl_logo.value ? Utils.getSettingsPath(settings.tabl_logo.value) : 'https://tabl.bg/img/tabl-transparent_gray-small.png')}
										alt={!DEMO && settings.meta_title_place_name && settings.meta_title_place_name.value && settings.meta_title_place_name.value.length > 0 ? settings.meta_title_place_name.value + ' logo' : 'TabL logo'}
										onClick={() => {
											settings.logo_opens_lang_modal && settings.logo_opens_lang_modal.value == 1 ?
												this.setState({ changeLanguageModalOpened: true })
												:
												((settings.disable_hide_header_logo && settings.disable_hide_header_logo.value && settings.disable_hide_header_logo.value == 1) ? history.push('/' + this.props.clientSlug) : void (0))
										}}
									/>
								</Collapse>
							</Typography>


							{/* <TranslateIcon className={clsx(classes.moneyIcon, (totalCountOrdered > 0 ? 'pulse' : ''))} /> */}
							<span>
								{/* LOGIN POPUP BUTTON */}
								{!Auth.isTabletMode() ?
									<span>
										{/* <MyPaypalBtn /> */}
										{Auth.getDecodedUser() ?
											<IconButton edge="start" color="inherit" className={classes.userProfileIconContainer} aria-label="profile" onClick={() => this.props.setProfileModalOpened(true)}>
												<span className={classes.headerProfileCartTexts}>
													Профил
												</span>
												<AccountCircleIcon />
											</IconButton>
											:
											<IconButton edge="start" color="inherit" className={classes.userProfileIconContainer} aria-label="login" onClick={() => this.props.setLoginModalOpened(true)}>
												<span className={classes.headerProfileCartTexts}>
													Вход / Регистрация
												</span>
												<AccountCircleIcon />
											</IconButton>
										}
									</span>
									:
									null
								}

								{/* Right Dollar/Money/Cart icon */}
								<Tooltip title={addProductTooltip.name} arrow TransitionComponent={Zoom} open={addProductTooltip.id > 0}>
									<IconButton edge="start" color="inherit" aria-label="menu" className={classes.moneyIconContainer} onClick={() => this.openCartModalHandler()}>
										<>
											<span className={classes.headerProfileCartTexts}>
												Количка
										</span>
											{totalCountOrdered != -1 ?
												<Badge badgeContent={totalCountOrdered} className={classes.badgeMoneyIcon}>
													{(Utils.getSettingsValue(settings, 'cart_icon') == 1 || Utils.getSettingsValue(settings, 'cart_icon') == 2) ?
														<ShoppingCartIcon className={clsx(classes.moneyIcon, (totalCountOrdered > 0 ? 'pulse bradius-0' : ''))} />
														:
														<AttachMoneyIcon className={clsx(classes.moneyIcon, (totalCountOrdered > 0 ? 'pulse' : ''))} />
													}
												</Badge>
												:
												<React.Fragment>
													{(Utils.getSettingsValue(settings, 'cart_icon') == 1 || Utils.getSettingsValue(settings, 'cart_icon') == 2) ?
														<ShoppingCartIcon className={classes.moneyIcon} />
														:
														<AttachMoneyIcon className={classes.moneyIcon} />
													}
												</React.Fragment>
											}
										</>
									</IconButton>
								</Tooltip>
							</span>

						</Toolbar>
					</AppBar>
				</div>


				<Snackbar
					maxSnack={3}
					autoHideDuration={100000}
					anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
					open={this.props.alertMessage && this.props.alertMessage.opened}
					TransitionComponent={Slide}
					ContentProps={{
						classes: {
							root: this.props.alertMessage && this.props.alertMessage.success ? classes.snackbarSuccessMessageStyles : classes.snackbarMessageStyles,
						}
					}}
					onClose={() => this.props.setAlertMessage(false)}
					message={
						<React.Fragment>
							{this.props.alertMessage && this.props.alertMessage.success ?
								<IconButton aria-label="ok" color="inherit" style={{ padding: '0 5px 2px 5px' }}>
									<CheckCircleOutlineIcon />
								</IconButton>
								:
								null
							}
							{this.props.alertMessage && this.props.alertMessage.message}
						</React.Fragment>
					}
					action={
						<React.Fragment>
							{/* <Button color="secondary" size="small" onClick={() => this.setState({ snackbarOpened: false })}>
							UNDO
						  </Button> */}
							<IconButton
								aria-label="close"
								color="inherit"
								onClick={() => this.props.setAlertMessage(false)}
							>
								<CloseIcon />
							</IconButton>
						</React.Fragment>
					}
				/>

				<SearchHeader
					toggleSearch={() => this.togglefixedButtonVisibility()}
					searchOpened={this.props.searchOpened}
					makeSearch={() => window.location.pathname !== `/search` ? history.push(`/search`) : void (0)}
					onChange={this.props.setSearchText}
					translations={translations}
				/>

				{this.props.chosenPlaceOrderTypes && this.props.chosenPlaceOrderTypes.find(ot => ot.type == 'spot') ?
					<>
						<UserNeedsModal
							open={this.state.userNeedsModalOpened}
							closeModal={() => { this.setState({ userNeedsModalOpened: !this.state.userNeedsModalOpened }) }}
							addUserNeed={(needId) => { Auth.getUserToken() ? this.props.addUserNeed(needId) : this.setState({ scanModalOpened: true, scanModalCallback: (scannedToken) => this.props.addUserNeed(needId, scannedToken) }) }}
							translations={translations}
						/>

						{/* USED ONLY WHEN USER NEEDS are clicked (CALL WAITER) i think - TODO: да се направи от 1 място */}
						<ScanModal
							open={this.state.scanModalOpened}
							onClose={() => this.setState({ scanModalOpened: false })}
							onError={(err_message) => this.props.setAlertMessage(true, err_message)}
							scanModalCallback={(scannedToken) => this.state.scanModalCallback(scannedToken)}
							translations={translations}
						/>

						<PaymentTypeModal
							open={this.props.payment_type_modal_opened}
							onClose={() => this.props.closePaymentTypeModal() /*this.setState({ openPaymentTypeModal: false })*/}
							translations={translations}
							currLang={this.props.currLang}
							askForBillCash={() => { this.props.openPaymentTypeModal(); /*this.setState({ openPaymentTypeModal: false });*/ this.props.askForBill('cash'); }}
							askForBillCard={() => { this.props.openPaymentTypeModal(); /*this.setState({ openPaymentTypeModal: false });*/ this.props.askForBill('card'); }}
						/>
					</>
					:
					null
				}


				<LanguageModal
					open={this.state.changeLanguageModalOpened}
					handleClose={() => { this.setState({ changeLanguageModalOpened: !this.state.changeLanguageModalOpened }) }}
					translations={translations}
					translationsType={this.props.translationsType}
					currLang={this.props.currLang}
					languages={this.props.languages}
					settings={settings}
					currLangOnChange={this.currLangOnChange}
				/>

				<CartModal
					open={this.props.cartModalOpened}
					onClose={() => this.props.setCartModalOpenedState(false)}
					onSubmit={() => this.cartModalOrderAction()}
					onError={(err_message) => this.props.setAlertMessage(true, err_message)}
					// productsToOrder={productsToOrder}
					// handleChange={this.props.updateProductQuantity}
					// handleRemove={this.props.removeProductFromCart}
					askForBill={this.props.askForBill}
					// translations={translations}
					// currLang={this.props.currLang}
					openPaymentTypeModal={this.props.openPaymentTypeModal}
					chosenPlaceOrderTypes={this.props.chosenPlaceOrderTypes}
				/>

				<CartCommentModal
					open={this.props.products.open_comment_modal}
					modalTitle={this.props.products.comment_modal_title}
					commentVariantId={this.props.products.comment_variant_id}
					comments={this.props.products.order_comment}
					onClose={() => { this.props.setCommentModalData(false, '', '') }}
					onSubmit={this.addCommentToOrder}
					translations={translations}
					settings={settings}
				/>


				{/* {this.state.orderModalOpened || this.state.preOrderModalOpened ? */}
				<OrderModal
					// open={this.state.orderModalOpened || this.state.preOrderModalOpened}
					open={this.props.orderModalState}
					onSubmit={(orderData, orderType = 2) => { this.props.makeOrder(orderData, this.props.products.order_comment, orderType, this.props.translations); this.props.setDisabledMakeOrderButton(false) }}
					onClose={() => { this.props.closeScanModal(); this.props.setOrderModalState(0); this.props.setDisabledMakeOrderButton(false) }}
					// onClose={() => this.setState({ orderModalOpened: false, preOrderModalOpened: false })}
					onError={(err_message) => this.props.setAlertMessage(true, err_message)}
					// translations={translations}
					currLang={this.props.currLang}
					chosenPlaceOrderTypes={this.props.chosenPlaceOrderTypes}
				/>
				{/* :
					null
				} */}

				<ChoosePlaceModal
					// open={this.state.changeLanguageModalOpened}
					// handleClose={() => { this.setState({ changeLanguageModalOpened: !this.state.changeLanguageModalOpened }) }}
					translations={translations}
					translationsType={this.props.translationsType}
					currLang={this.props.currLang}
					languages={this.props.languages}
					settings={settings}
					currLangOnChange={this.currLangOnChange}
				/>

				<PromoProductForCategoryModal />

				<ProductVariantsModal />

				<UpsellingProductModal />

				<Dialog
					// fullWidth={fullWidth}
					// maxWidth={maxWidth}
					open={this.state.galleryOpened}
					onClose={() => this.setState({ galleryOpened: false })}
					// onClose={onClose}
					// onClose={() => void (0)}
					aria-labelledby="max-width-dialog-title"
					TransitionComponent={Grow}
					className={classes.modal}
					data-class="dialog-container"
				>
					<DialogTitle id="max-width-dialog-title" style={{ textAlign: 'center', backgroundColor: "rgb(203,65,2)", color: 'white', boxShadow: '0 1px 5px 0px black' }} data-class="dialog-title-container">
						{this.props.places && this.props.places[0] && this.props.places[0].name}
					</DialogTitle>

					<DialogContent>

						<form onSubmit={this.onSubmitImage} encType="multipart/form-data">

							<input
								style={{ display: 'none' }}
								accept="image/*"
								id="gallery-upload-button"
								multiple
								type="file"
							/>

							<label htmlFor="gallery-upload-button">
								<IconButton color="primary" aria-label="upload picture" component="span">
									<PhotoCamera />
								</IconButton>
							</label>


							{/* <label htmlFor="gallery-upload-button"> */}
							<Button variant="contained" color="primary" component="span" onClick={() => this.onSubmitImage()}>
								Upload
                            </Button>
							{/* </label> */}

							{/* 
                                <input accept="image/*" style={{ display: 'none' }} id="icon-button-file" type="file" />
                                <label htmlFor="icon-button-file">
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <PhotoCamera />
                                    </IconButton>
                                </label>
                            */}


							<GridList cellHeight={160} className={classes.gridList} cols={3}>

								{this.props.places && this.props.places[0] && this.props.places[0].place_images ? this.props.places[0].place_images.map((tile) => (
									<GridListTile key={tile.path} cols={1}>
										<img src={PRODUCT_IMG_URL + tile.path} alt={''} />
									</GridListTile>
								)) : null}
							</GridList>
						</form>

					</DialogContent>
				</Dialog >

				{Utils.getSettingsValue(this.props.settings, 'show_initial_banner_fast_order') == 1 ?
					<InitialBannerFastOrder
						// open={this.state.changeLanguageModalOpened}
						// handleClose={() => { this.setState({ changeLanguageModalOpened: !this.state.changeLanguageModalOpened }) }}
						translations={translations}
						translationsType={this.props.translationsType}
						currLang={this.props.currLang}
						languages={this.props.languages}
						settings={settings}
						currLangOnChange={this.currLangOnChange}
						onSubmit={() => this.cartModalOrderAction()}
					/>
					:
					null
				}

				{Utils.getSettingsValue(this.props.settings, 'show_initial_html_banner') != 0 ?
					<InitialHtmlBanner
						// open={this.state.changeLanguageModalOpened}
						// handleClose={() => { this.setState({ changeLanguageModalOpened: !this.state.changeLanguageModalOpened }) }}
						translations={translations}
						translationsType={this.props.translationsType}
						currLang={this.props.currLang}
						languages={this.props.languages}
						settings={settings}
						currLangOnChange={this.currLangOnChange}
						onSubmit={() => this.cartModalOrderAction()}
					/>
					:
					null
				}

				{/* <ViewProductPage /> */}

				<LoginModal />
				<RegisterModal />
				<ProfileModal />



				<Dialog
					open={this.props.termsOpened}
					onClose={() => this.props.setTermsDialogOpened(false)}
					scroll={'paper'}
				>
					<DialogTitle>Условия за ползване</DialogTitle>
					<DialogContent dividers={true}>
						<DialogContentText>
							<div dangerouslySetInnerHTML={{ __html: Utils.getSettingsValue(settings, 'terms', 'Може да запознаете с общите условия на адрес: https://tabl.bg/terms.pdf') }}></div>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.props.setTermsDialogOpened(false)} color="primary">
							Затвори
						</Button>
					</DialogActions>
				</Dialog >

				<Dialog
					open={this.props.privacyPolicyOpened}
					onClose={() => this.props.setPrivacyPolicyDialogOpened(false)}
					scroll={'paper'}
				>
					<DialogTitle>Политика за поверителност</DialogTitle>
					<DialogContent dividers={true}>
						<DialogContentText>
							<div dangerouslySetInnerHTML={{ __html: Utils.getSettingsValue(settings, 'privacy_policy', 'Може да запознаете с политиката ни за поверителност на адрес: https://tabl.bg/privacy-policy.pdf') }}></div>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.props.setPrivacyPolicyDialogOpened(false)} color="primary">
							Затвори
						</Button>
					</DialogActions>
				</Dialog >


				{/* Search / Call me button/s */}
				{Utils.getSettingsValue(settings, 'user_needs_position') == 'footer' && !Auth.isTabletMode() /*|| Utils.getSettingsValue(this.props.settings, 'call_waiter_and_bill_footer_button') == '1'*/ ?
					<BottomSpeedDialTooltipUserNeedsAndBillWanted
						settings={this.props.settings}
						callWaiterFooterButtonHandler={() => { this.setState({ userNeedsModalOpened: true }) }}
						callWaiterAndBillFooterButtonHandler={() => Auth.getUserToken() ? this.props.openPaymentTypeModal() : this.props.setAlertMessage(true, 'Необходимо е първо да направите поръчка')}
						hidden={!this.state.fixedButtonVisible}
						footerNavigatonCountLinks={footerNavigatonCountLinks}
						translations={translations}
					/>
					:
					<BottomSpeedDialTooltip
						call_btn_instead_of_search={call_btn_instead_of_search}
						second_call_btn={second_call_btn}
						onClick={() => this.doFixedBtnAction(call_btn_instead_of_search)}
						footerNavigatonCountLinks={footerNavigatonCountLinks}
						hidden={!this.state.fixedButtonVisible}
						translations={translations}
					/>
				}

				<BottomNavigation
					className={footerNavigatonCountLinks == 4 ? clsx(classes.bottomNavigationHiddenLastChildFourItems, classes.bottomNavigation) : (footerNavigatonCountLinks == 3 ? clsx(classes.bottomNavigationHiddenLastChildThreeItems, classes.bottomNavigation) : classes.bottomNavigation)}
					style={footerNavigatonCountLinks == 0 ? { display: 'none' } : (footerNavigatonCountLinks == 1 ? { maxWidth: 300 } : (footerNavigatonCountLinks == 3 ? { maxWidth: 450 } : (footerNavigatonCountLinks == 4 ? { maxWidth: 550 } : (footerNavigatonCountLinks == 2 ? { maxWidth: 400 } : {}))))}
					// value={value}
					// onChange={(event, newValue) => {
					//   setValue( newValue);
					// }}
					showLabels
				// className={classes.root}
				>

					{show_restaurant_location_position_footer ?
						<BottomNavigationAction label={translations && translations.common && translations.common.navigation} icon={<DirectionsIcon />} onClick={() => { window.open('https://www.google.com/maps/dir/?api=1&destination=' + (settings.place_address ? settings.place_address.value : ''), '_blank'); }} data-class="bottom-footer-navigate-me-button" />
						:
						null
					}

					{reservations_position_footer ?
						<BottomNavigationAction label={translations && translations.common && translations.common.book} icon={<LocalDiningIcon />} onClick={() => window.open(Utils.getSettingsValue(settings, 'reservations_url'))} data-class="bottom-footer-reservation-button" />
						:
						null
					}

					{/* {translations && translations.common && translations.common.language} */}
					{change_language_position_footer ?
						<BottomNavigationAction label={<span className="skiptranslate">Language</span>} icon={<PublicIcon />} onClick={() => this.setState({ changeLanguageModalOpened: true })} data-class="bottom-footer-translate-button" />
						:
						null
					}

					{search_btn_position_footer ?
						<BottomNavigationAction label={translations && translations.common && translations.common.search ? translations.common.search : ''} icon={<SearchIcon />} onClick={() => this.doFixedBtnAction(call_btn_instead_of_search, 1)} data-class="bottom-footer-search-button" />
						:
						null
					}

					{about_restaurant_position_footer ?
						<BottomNavigationAction label={(settings ? (settings.about_page_title ? settings.about_page_title.value : '') : (settings.meta_title_place_name ? settings.meta_title_place_name.value : ''))} icon={(Utils.getSettingsValue(settings, 'is_hotel') == '1' ? <HomeWorkIcon className={classes.speedDialTooltipIcons} /> : (Utils.getSettingsValue(settings, 'is_hotel') == 'callbtn' ? <CallIcon className={classes.speedDialTooltipIcons} /> : <RestaurantMenuIcon className={classes.speedDialTooltipIcons} />))} onClick={() => history.push(`/${this.props.clientSlug}/about`)} data-class="bottom-footer-about-button" />
						:
						null
					}

					<BottomNavigationAction label="" />
				</BottomNavigation>

			</React.Fragment >
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let chosenPlaceOrderTypes = [];
	let chosenPlaceId = state.places.chosen_place;
	let chosenPlace = state.places.places.filter(p => p.id == chosenPlaceId);
	if (chosenPlace.length == 0) {
		chosenPlace = null;
	} else {
		chosenPlace = chosenPlace[0];
		chosenPlaceOrderTypes = chosenPlace.ordertypes;
	}

	// FOR CAT HIERARCHIES
	// var catsHierarchy = state.categories.categories_hierarchy || [];
	// let catSlug = history.location.pathname ?  history.location.pathname.substr(1) : ""
	// let selectedCat = catsHierarchy && Object.values(catsHierarchy).filter(c => c.slug === catSlug)[0] ? Object.values(catsHierarchy).filter(c => c.slug === catSlug)[0] : null;
	// if (!selectedCat) {
	//     selectedCat = catsHierarchy && Object.values(catsHierarchy)[0] ? Object.values(catsHierarchy)[0] : [];
	// }
	// var level = selectedCat.hierarchyLevel; // state.categories.curr_hierarchy_level;
	// var currCats = Object.values(catsHierarchy).filter(c => c.hierarchyLevel === level);
	// let catIndex = currCats && Object.values(currCats).findIndex(e => e.id === selectedCat.id);

	return {
		places: state.places.places || [],
		showCategory: state.categories.show_category || false,
		categoryText: state.categories.category_text || '',
		addProductTooltip: state.products.add_product_tooltip || {},
		productsToOrder: Utils.normalizeCartProducts(state.products.to_order) || [],
		orderedProducts: state.products.ordered || [],
		searchOpened: state.search.search_opened || false,
		allProducts: state.products.all_products || [],
		orderMessage: state.products.order_message || '',
		orderSent: state.products.order_sent || null,
		userToken: state.products.user_token || null,
		settings: state.settings.settings || [],
		translations: state.lang.translations,
		translationsType: state.settings.settings['translations_type'] && state.settings.settings['translations_type'].value,
		currLang: state.lang.currLang,
		languages: state.lang.languages,
		products: state.products || [],
		billWanted: state.products.bill_wanted || false,
		billWantedStatus: state.products.bill_wanted_status || false,
		user_need_failed: state.user_needs.user_need_failed || '',
		user_need_added: state.user_needs.user_need_added || '',
		currentОrderТype: state.products.current_order_type || 0,
		scan_modal_open: state.scan.scan_modal_open || 0,
		chosenPlaceOrderTypes: chosenPlaceOrderTypes,
		orderModalState: state.scan.order_modal_state,
		currOpenedModal: state.scan.curr_opened_modal,
		currCatHierarchyLevel: state.categories.curr_hierarchy_level || 1,
		categoriesHierarchy: state.categories.categories_hierarchy || [],
		selected_cat_hierarchy_id: state.categories.selected_cat_hierarchy_id || 0,
		view_product_page_id: state.products.view_product_page_id || 0,
		payment_type_modal_opened: state.products.payment_type_modal_opened,
		clientSlug: state.client.slug || "",
		scrollData: state.menu.scrollData || null,
		alertMessage: state.menu.alertMessage,
		cartModalOpened: state.menu.cartModalOpened || false,
		termsOpened: state.menu.termsOpened || false,
		privacyPolicyOpened: state.menu.privacyPolicyOpened || false,
		// selectedCat: selectedCat || []
	};
};

export default withStyles(styles)(connect(mapStateToProps, {
	updateProductQuantity, removeProductFromCart, makeOrder,
	getAllProducts, setSearchOpened, setSearchText,
	clearOrderStatus, clearCartLocal, changeSelectedCartTab,
	getOrders, askForBill, clearBillWanted, listLanguages,
	changeLanguage, addUserNeed, resetUserNeedStatus,
	setCurrOpenedModal, setOrderModalState, closeScanModal, setHierarchyLevel, setChosenCat, removeProductPageId,
	showHeaderCategory, setLoginModalOpened, setProfileModalOpened, setOrderComment, setCommentModalData, closePaymentTypeModal,
	openPaymentTypeModal, setDisabledMakeOrderButton, addPlaceImage, setSelectedCatId, setAlertMessage, setCartModalOpenedState,
	setTermsDialogOpened, setPrivacyPolicyDialogOpened
})(AppNavbar));
