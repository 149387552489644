import { SET_SCROLL_DATA, SET_ALERT_MESSAGE, SET_CART_MODAL_OPENED_STATE, SET_TERMS_DIALOG_OPENED, SET_PRIVACY_POLICY_OPENED } from './types';

const initialState = {
    scrollData: null,
    alertMessage: { opened: false, message: '', success: false },
    cartModalOpened: false,
    termsOpened: false,
    privacyPolicyOpened: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SCROLL_DATA:
            return {
                ...state,
                scrollData: action.payload
            }
        case SET_ALERT_MESSAGE:
            return {
                ...state,
                alertMessage: action.payload
            }
        case SET_CART_MODAL_OPENED_STATE:
            return {
                ...state,
                cartModalOpened: action.payload
            }
        case SET_TERMS_DIALOG_OPENED:
            return {
                ...state,
                termsOpened: action.payload
            }
        case SET_PRIVACY_POLICY_OPENED:
            return {
                ...state,
                privacyPolicyOpened: action.payload
            }
        default:
            return state;
    }
}