// import jwt from 'jsonwebtoken';
// import jwtDecode from 'jwt-decode';
import { SET_CURRENT_USER, LOGOUT, SET_LOGIN_MODAL_OPENED, SET_REGISTER_MODAL_OPENED, SET_PROFILE_MODAL_OPENED } from './types';
import setAuthorizationToken from './../../utils/setAuthorizationToken';
import axios from 'axios';
import Auth from './../../utils/Auth';
import jwt from 'jsonwebtoken';

import history from './../../utils/history';
// import { history } from './../store';
import { API_URL } from '../../constants';
import { addErrorMessages, addSuccessMessage } from './../../messages/messages.actions';
import store from './../../store';

export function logout() {
    return dispatch => {
        Auth.deauthUser();
        setAuthorizationToken(null);
        dispatch(setCurrentUser(null));

        let slug = store.getState().client.slug;
        
        if(slug) {
            history.push(`/`)
        }
        dispatch(addSuccessMessage("До скоро виждане :("));                
        //TODO redirect if on /profile page
        return {
            type: LOGOUT
        }
    }
}
 
export function login(data) {
    return dispatch => {
        axios.post(API_URL + 'login', data)
        .then(res => {
            const token = res.data.token;
            if (token) {
                Auth.authUser(token);
                setAuthorizationToken(token);
                dispatch(setCurrentUser(jwt.decode(token)));
                dispatch(addSuccessMessage("Успешно влязохте в профила си. Приятно хапване :)"));                
            } else {
                dispatch(addErrorMessages(["Невалидни данни за вход"]));
            }
        })
        .catch(err => {
            dispatch(addErrorMessages(["Невалидни данни за вход"]));
        })
    }
}

export const register = (data) => dispatch => {
    return axios.post(API_URL + 'register', data)
        // .then(res => {
        //     console.log(res.data);
        //     // const token = res.data.token;
        //     // if (token) {
        //     //     Auth.authUser(token);
        //     //     setAuthorizationToken(token);
        //     //     dispatch(setCurrentUser(jwt.decode(token)));
        //     // } else {
        //     //     dispatch(addErrorMessages(["Problem"]));
        //     // }
        // })
        // .catch(err => {
        //     dispatch(addErrorMessages(["Problem"]));
        // })
}

export const editProfile = (data) => dispatch => {

    axios.post(API_URL + 'users/editProfile', data)
    .then(res => {
        if(res.data.token) {
            let token = res.data.token;
            Auth.authUser(token);
            setAuthorizationToken(token);
            dispatch(setCurrentUser(jwt.decode(token)));
            dispatch(addSuccessMessage("Успешно променихте данните си :)"));                
        }
    })
    .catch(err => {
        if(err.response && err.response.data && err.response.data.error && err.response.data.error.errors) {
            dispatch(addErrorMessages(err.response.data.error.errors));
        } else {
            dispatch(addErrorMessages(["Грешка. Моля опитайте отново"]));
        }
    })
}

export function setCurrentUser(user) {
    return {
        type: SET_CURRENT_USER,
        user
    }
}

export function setLoginModalOpened(state) {
    return {
        type: SET_LOGIN_MODAL_OPENED,
        state
    }
}

export function setRegisterModalOpened(state) {
    return {
        type: SET_REGISTER_MODAL_OPENED,
        state
    }
}

export function setProfileModalOpened(state) {
    return {
        type: SET_PROFILE_MODAL_OPENED,
        state
    }
}

