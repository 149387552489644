import axios from 'axios';
import { GET_REGIONS } from './types';
import { API_URL } from '../constants';

import Utils from './../utils/Utils';
import Auth from './../utils/Auth';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getRegions = () => dispatch => {

    axios
        .get(API_URL + 'regions')
        .then(res => {
            dispatch({
                type: GET_REGIONS,
                payload: res.data
            });
        }).catch(error => {
            throw (error);
        });
}