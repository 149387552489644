import React, { useEffect, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import PostAddIcon from '@material-ui/icons/PostAdd';
import TextField from '@material-ui/core/TextField';
import { bgTranslations } from '../../language/translations/bg';
import Utils from '../../utils/Utils';
import clsx from 'clsx';

import { getAllProducts, addToCart, addProductTooltip, changeSelectedCartTab, setOrderComment, setCommentModalData } from '../../products/actions/products.action';

import { getSettings } from './../../settings/actions/settings.action';
import { addProdToCart, setVariantModalOpened } from './../../cart/actions/cart.actions';

const styles = theme => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto',
		width: 'fit-content',
	},
	formControl: {
		marginTop: 16,
		minWidth: 120,
	},
	formControlLabel: {
		marginTop: 8,
	},
	variantButton: {
		width: '100%',
		padding: 10,
		paddingLeft: 10,
		background: '#f6f6f6',
		marginTop: 10,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},
	borderedVariantButton: {
		border: '1px solid #ccc',
		borderBottom: '0',
	},
	variantCommentTextfield: {
		border: '1px solid #ccc',
		borderTop: '0',
		boxSizing: 'border-box',

		width: '100%',
		// Focused orange text color
		'& label.Mui-focused': {
			color: 'rgb(255, 148, 74)',
		},

		'& .MuiOutlinedInput-root': {
			height: '45px !important',
			borderTopLeftRadius: '0px !important',
			borderTopRightRadius: '0px !important',

			// Focused border color
			'&.Mui-focused fieldset': {
				borderColor: 'rgb(206, 206, 206)',
			},

			// Not focused textfield
			'& .MuiOutlinedInput-notchedOutline': {
				'border-color': 'rgb(246, 246, 246)',
				/* box-shadow: 0px 1px 1px #b8b8b8; */
				'border-width': '0',
			}
		},
		// Label center
		'& .MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled)': {
			transform: 'translate(14px, 15px) scale(1)',
		},
	},
	varaintPriceButton: {
		textAlign: 'center',
		backgroundColor: 'rgb(203, 65, 2)',
		color: 'white',
		padding: 5,
		borderRadius: 6,
		'box-shadow': '0 0 2px #747474',
	},
})



class ProductVariantsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			opened: false,
			fullWidth: true,
			maxWidth: 'xs',
			currProdComments: this.props.order_comment ? Object.values(this.props.order_comment) : []
		}
	}

	componentDidMount() {
		this.handleModalState(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.handleModalState(nextProps);
	}

	handleModalState = (currProps) => {
		if (currProps.variant_modal_opened) {
			this.setState({
				opened: true
			})
		} else {
			this.setState({
				opened: false
			})
		}
	}


	render() {

		let { translations, variantModalWithComment, order_comment, classes, settings } = this.props;
		translations = translations.translations ? translations.translations : translations;

		const { fullWidth, maxWidth, currProdComments } = this.state;
		// const classes = useStyles();
		// const [fullWidth, setFullWidth] = React.useState(true);
		// const [maxWidth, setMaxWidth] = React.useState('xs');

		// const [currProdComments, setCurrProdComments] = React.useState(order_comment ? Object.values(order_comment) : []);

		// Onchange set comment to state
		const setComment = (variantId, comment) => {
			let stateComments = Object.values(currProdComments);
			let validatedComments = Utils.validatedCommentObject(stateComments, variantId, comment)
			if (validatedComments) {
				this.setState({ currProdComments: stateComments });
				// setCurrProdComments(stateComments)
			}
		}

		const addCommentToOrder = (variantId) => {
			const currProdVariantComment = currProdComments.filter(c => c.variantId == variantId)[0];
			if (currProdVariantComment) {
				this.props.setOrderComment(Utils.validatedCommentObject(Object.values(order_comment), currProdVariantComment.variantId, currProdVariantComment.comment))
			}
		}
		// console.log('Utils.getSettingsValue(settings, variant_modal_with_comment)', Utils.getSettingsValue(settings, 'variant_modal_with_comment') == 1)

		return (
			<React.Fragment>
				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={this.state.opened}
					onClose={() => {
						this.setState({ currProdComments: [] }); this.setState({ opened: false }); this.props.setVariantModalOpened(false);
					}}
					aria-labelledby="max-width-dialog-title"
					TransitionComponent={Grow}
					data-class="dialog-container"
				>
					<DialogTitle id="max-width-dialog-title" style={{ textAlign: 'center', backgroundColor: "rgb(203,65,2)", color: 'white', boxShadow: '0 1px 5px 0px black' }} data-class="dialog-title-container">
						{Utils.truncate(this.props.selected_product && this.props.selected_product.name ? this.props.selected_product.name : (translations && translations.pages && translations.pages.products ? translations.pages.products.choose_variant : ''), 22, 20)}
					</DialogTitle>
					<DialogContent>
						<DialogContentText style={{ paddingTop: 10 }}>

							{this.props.selected_product && this.props.selected_product.product_variants && Object.values(this.props.selected_product.product_variants).map(pv => {
								let currentVariantComment = order_comment ? order_comment.filter(c => c.variantId == pv.id) : ''
								if (currentVariantComment && currentVariantComment[0] && currentVariantComment[0].comment) {
									currentVariantComment = currentVariantComment[0].comment;
								} else {
									currentVariantComment = '';
								}

								return (
									<React.Fragment>

										<Button className={Utils.getSettingsValue(settings, 'variant_modal_with_comment') == 1 ? clsx(classes.borderedVariantButton, classes.variantButton) : classes.variantButton} onClick={() => { this.setState({ currProdComments: [] }); this.props.addProdToCart(this.props.selected_product.id, pv.id, this.props.selected_product); addCommentToOrder(pv.id) }}>

											<Grid container direction="row" alignItems="center"> {/* justify="space-between" */}

												{Utils.getSettingsValue(settings, 'variant_modal_with_comment') == 1 ?
													null :
													<Grid item style={{ fontSize: 13, textAlign: 'left', width: 35 }}>
														<PostAddIcon />
													</Grid>
												}

												<Grid item style={{ fontSize: 13, textAlign: 'left', width: 'calc(100% - 110px)', textAlign: (Utils.getSettingsValue(settings, 'variant_modal_with_comment') == 1 ? 'left' : 'center') }}>
													{pv.translations[this.props.currLang] && pv.translations[this.props.currLang].size || pv.size}
												</Grid>

												<Grid item style={{ textAlign: 'right', fontSize: 13, width: (Utils.getSettingsValue(settings, 'variant_modal_with_comment') == 1 ? 110 : 75) }}>
													{Utils.getSettingsValue(settings, 'variant_modal_with_comment') == 1 ?
														<div className={classes.varaintPriceButton}>
															{pv.price} {this.props.settings && this.props.settings.default_currency_suffix && this.props.settings.default_currency_suffix.value ? this.props.settings.default_currency_suffix.value : 'лв.'}
														</div>
														:
														<React.Fragment>
															{pv.price} {this.props.settings && this.props.settings.default_currency_suffix && this.props.settings.default_currency_suffix.value ? this.props.settings.default_currency_suffix.value : 'лв.'}
														</React.Fragment>
													}
												</Grid>


											</Grid>

										</Button>

										{Utils.getSettingsValue(settings, 'variant_modal_with_comment') == 1 ?
											<TextField id="outlined-basic" label={translations && translations.common && translations.common.comment ? translations.common.comment : ''} variant="outlined" className={classes.variantCommentTextfield} onChange={(e) => setComment(pv.id, e.target.value)} defaultValue={currentVariantComment} />
											:
											null
										}

									</React.Fragment>
								)
							}
							)}
							<br />
						</DialogContentText>
					</DialogContent>
				</Dialog>
			</React.Fragment>
		);
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		places: state.places.places || [],
		chosen_place: state.places.chosen_place || null,
		translations: state.lang.translations,
		order_comment: state.products.order_comment || [],
		selected_product: state.cart.selected_product || [],
		variant_modal_opened: state.cart.variant_modal_opened || false,
		currLang: state.lang.currLang || "bg",
		settings: state.settings.settings || [],
	};
};

export default withStyles(styles)(connect(mapStateToProps, { getAllProducts, getSettings, addProdToCart, setOrderComment, setVariantModalOpened })(ProductVariantsModal))