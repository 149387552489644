import React, { Component } from "react";
import Utils from "../utils/Utils";

class NavigateToComponent extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        Utils.navigateTo('');
    }

    render() {
        return '';

    }
}


export default NavigateToComponent;