
export const styles = theme => ({
    root: {
        // maxWidth: "500px !important",
        // right: "0px !important",
        // position: 'absolute !important',
        // top: "initial !important"

        '@media screen and (max-width: 555px)': {
            '& .MuiPaper-root': {
                width: '100% !important'
            },
        },
        '@media screen and (min-width: 555px)': {
            '& .MuiPaper-root': {
                width: '500px !important'
            },
        },
    },
    goToRegister: {
        width: '100%',
        padding: 10,
        marginTop: 10,
        textAlign: 'center',
    },
    goToLogin: {
        width: '100%',
        padding: 10,
        marginTop: 10,
        textAlign: 'center',
    },
    modal: {
        '& .MuiPaper-root': {
            width: '350px !important',
        }
    },
    modalTitle: {
        position: 'relative',
        textAlign: 'center',
        backgroundColor: "rgb(203,65,2)",
        color: 'white',
        boxShadow: '0 1px 5px 0px black',
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    modalTitleProfile: {
        display: 'grid',
        'grid-template-columns': '15% 85%',
    },  
    modalTitleText: {
        'word-break': 'break-word',
        alignSelf: 'center',
    },
    dialogContent: {
        minHeight: 100,
    },
    dialogContentText: {
        paddingTop: 10,
    },
    modalTypesBtns: {
        color: 'black',
        width: '100%',
        fontSize: 16,
        // display: 'grid',
        // 'grid-template-rows': '50% 50%',
        // 'place-self': 'center',
        // textAlign: 'center',
        // color: '#575757',
        borderRadius: 0,
        // borderBottom: '1px solid #ff7600',
        // backgroundColor: '#f9f9f9',
        minWidth: 180,
        '@media screen and (max-width: 475px)': {
            minWidth: '80%',
        },

        // 'border': '1px solid #ebebeb',

        'border-bottom': '1px solid #ff5200',
        // 'border-top-right-radius': 7,
        // 'border-top-left-radius': 7,

        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,

        padding: 15,

        // // display: 'grid',
        // 'grid-template-columns': '70% 30%',
        // borderBottom: 'none',

        // height: 80,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
    },
    modalTypesBtnsBorderBottom: {
        'border-bottom': '1px solid #ff5200 !important',
    },
    modalTypesIcons: {
        fontSize: 42,
        color: '#ff7600',
    },
    isMultipleOptionsModalContainer: {
        // margin: '40px 0 45px 0',
        display: 'grid',
        // 'grid-template-columns': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        padding: 0,
        '@media screen and (max-width: 475px)': {
            'grid-template-columns': 'none',
            '& > *:first-child': {
                marginBottom: 40,
            },
            margin: '20px 0 45px 0',
        }
    },
    submitButton: {
        width: '100%',
        color: 'white',
        backgroundColor: '#ff5101',
    },
    closeDrawerButtonProfile: {
        width: 40,
        height: 40,
        'background-color': 'white',
        'align-self': 'center',
    },
    closeDrawerButton: {
        position: 'absolute',
        left: '10%',
        padding: 8,
        top: 'calc(50% - 20px)',
        backgroundColor: 'white',
    }
});