import { SET_CURRENT_USER, SET_LOGIN_MODAL_OPENED, SET_REGISTER_MODAL_OPENED, SET_PROFILE_MODAL_OPENED } from '../actions/types';
import isEmpty from 'lodash/isEmpty';

const initialState = {
    isAuthenticated: false,
    user: {},
    auth_error: "",
    login_modal_opened: false,
    register_modal_opened: false,
    profile_modal_opened: false
};

export default (state = initialState, action) => {
    switch(action.type) {
        case SET_CURRENT_USER:
            return {
                isAuthenticated: !isEmpty(action.user),
                user: action.user
            }
        case SET_LOGIN_MODAL_OPENED: 
            return {
                ...state,
                login_modal_opened: action.state
            }
        case SET_REGISTER_MODAL_OPENED: 
            return {
                ...state,
                register_modal_opened: action.state
            }
        case SET_PROFILE_MODAL_OPENED: 
            return {
                ...state,
                profile_modal_opened: action.state
            }
        default: return state;
    }
}