import React, {Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import Avatar from '@material-ui/core/Avatar';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Utils from '../../utils/Utils'
import { Typography } from '@material-ui/core';

import { IMAGE_CAT_IMAGES_PATH } from './../../constants';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ScrollArea from "react-scrollbar";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 700,
        margin: '0 auto',
    },
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridColumnGap: 10,
    itemTypography: {
        display: 'contents',
    },
    subcatRow: {
        '&:first-child': {
            marginTop: 15,
        },
        '&:last-child': { // temp unable to hive navbar fix
            marginBottom: 75,
        },
        background: 'rgba(255, 255, 255, 0.65)',
        width: '95%',
        margin: '0 auto',
        marginBottom: 10,
        borderRadius: 7,
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.71)',
        },
        padding: 8,
    },
    avatar: {
        width: 55,
        height: 55,
        marginRight: 15,
        border: '1px solid #ccc',
    },
    rightArrowIcon: {
        padding: 5,
        background: 'rgba(255, 255, 255, 0.16)',
        borderRadius: 25,
    },
}));

class ImageCategoryImages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            panningDisabled: true,
            scale: 2
        }
        this.clickTimeout = null

    }

    onZoomChange = (args) => {
        // let currPanningState = this.state.panningDisabled;
        // let newPanningState;
        // if(args.scale == 1) {
        //     newPanningState = true
        // } else {
        //     newPanningState = false;            
        // }

        // if(currPanningState != newPanningState) {
        //     this.setState({ panningDisabled: newPanningState })
        // }
    }

    onPinchingStop = (args) => {
        let currPanningState = this.state.panningDisabled;
        let newPanningState;
        if(args.scale > 1) {
            this.setState({ panningDisabled: false })
        } else {
            this.setState({ panningDisabled: true, scale: args.scale })
        }
    }

    onWheelStop = (args) => {
        if(args.scale > 1) {
            this.setState({ panningDisabled: false })
        } else {
            this.setState({ panningDisabled: true, scale: args.scale })

            
        }
    }

    componentWillMount = props => {
        this.clickTimeout = null
    }
      
    handleClicks = () => {
        if (this.clickTimeout !== null) {
            clearTimeout(this.clickTimeout)
            this.clickTimeout = null
            this.setState({ panningDisabled: true })
            
        } else {
            this.clickTimeout = setTimeout(()=>{
            clearTimeout(this.clickTimeout)
                this.clickTimeout = null
            }, 200)
        }
      }

    render() {
        let pan = {
            // disabled: true,
            lockAxisX:true,
            lockAxisY:true,
            // padding: false
        }
        let wheel = {
            step: 30
        }
        let scalePadding  = {
            disabled: true
        }
        // console.log("STATE ", this.state);
        return (
 
                <div className={""}  onClick={this.handleClicks}>

                
                    {Object.values(this.props.cat.imageCategoryImages).map((item, k) => (
                        <React.Fragment>

                            <TransformWrapper
                            
                                // onZoomChange={this.onZoomChange}
                                onPinchingStop={this.onPinchingStop}
                                onWheelStop={this.onWheelStop}
                                limitToWrapper={true}
                                // enablePadding={false}
                                wheel={{
                                    step: 50
                                }}
                                pan={{
                                    // lockAxisX:true,
                                    // lockAxisY:true,                    
                                    disabled: this.state.panningDisabled
                                }}
                                doubleClick={{
                                    mode: 'reset'
                                }}
                                options={{
                                    // minScale:2
                                }}
                                // scale={this.state.scale}
                            >
                            {({ zoomIn, zoomOut, resetTransform, scale, ...rest }) => (
                            <React.Fragment>
                                {/* <div className="tools">
                                <button onClick={zoomIn}>+ {scale}</button>
                                <button onClick={zoomOut}>-</button>
                                <button onClick={resetTransform}>x</button>
                                </div> */}
                                {/* {scale}--- */}
                                {/* {scale < 2 ? () => { resetTransform(); console.log("reseting", scale) } : null} */}
                                <button onClick={resetTransform}>x</button>

                                <TransformComponent>
                                    {/* <img src="https://i.pinimg.com/originals/bf/46/7d/bf467d3856164afc0b91f84b41241858.jpg" */}
                                        {/* style={{height: "auto", width: "100%"}} />  */}

                                    <img src={IMAGE_CAT_IMAGES_PATH + item.image}   style={{height: "auto", width: "100%"}}/> 
                                </TransformComponent>
                            </React.Fragment>
                            )}
                            </TransformWrapper>
                        
                        </React.Fragment>

                        // <TransformWrapper
                        //     limitToWrapper={true} 
                        //     pan={pan}
                        //     wheel={wheel}
                        //     scalePadding={scalePadding}
                        // >
                        //  {({
                        //     zoomIn,
                        //     zoomOut,
                        //     resetTransform,
                        //     setDefaultState,
                        //     positionX,
                        //     positionY,
                        //     scale,
                        //     previousScale,
                        //     options: { limitToBounds, transformEnabled, disabled },
                        //     ...rest
                        // }) => (
                        //     <React.Fragment>
                        //         {scale}
                        //         <img src="https://i.pinimg.com/originals/bf/46/7d/bf467d3856164afc0b91f84b41241858.jpg"
                        //             style={{width: "100%"}}
                        //     />
                        //         {/* <img src={IMAGE_CAT_IMAGES_PATH + item.image}  style={{width: "100%"}}/> */}
                        //     </React.Fragment>
                        // )}
                        // </TransformWrapper>
                    
                        
                        // <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTKfNuudEVu37dR_gy70a3ARxhSM7yap6L5og&usqp=CAU"
                        //     style={{width: "100%"}}
                        // />
    
                    ))}
    
                </div>
            
        )
    }

}

export default ImageCategoryImages;