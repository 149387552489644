import store from './store';
import axios from 'axios';
import Cookies from 'universal-cookie';
import history from './utils/history';

import { API_URL, DEFAULT_PRODUCT_IMAGE } from './constants';
import { getProductsToOrder, getAllProducts, getAllProductsIfOnePlace, clearCartLocal, getProductIngredients } from './products/actions/products.action';
import { getOrders, setDisabledMakeOrderButton } from './products/actions/products.action';
import { getCategoriesHierarchy } from './categories/actions/categories.action';
import { getRegions } from './regions/regions.actions';
import { listLanguages, changeLanguage } from './language/actions/language.action';
import { setCurrentUser } from './users/actions/users.actions';
import { getSettings } from './settings/actions/settings.action';
import { getPlaces, verifyOpenedPlace, setChosenPlace } from './places/actions/places.actions';
import { GET_PLACES } from './places/actions/types';
import { setClient } from './client/client.actions';
import { verifyToken } from './qr-scan/actions/scan.actions';
import { showHeaderCategory } from './categories/actions/categories.action';
import { setCartModalOpenedState, setTermsDialogOpened, setPrivacyPolicyDialogOpened } from './menu/menu.actions'
import { setLoginModalOpened, setRegisterModalOpened, setProfileModalOpened } from './users/actions/users.actions';
import { setOrderModalState } from './qr-scan/actions/scan.actions';

import Utils from './utils/Utils';
import Auth from './utils/Auth';
import jwt from 'jsonwebtoken';

import setCustomClientHeader from './utils/setCustomClientHeader';
import setCustomPlaceHeader from './utils/setCustomPlaceHeader';
import setTablHeader from './utils/setTablHeader';
import setAuthorizationToken from './utils/setAuthorizationToken';
import { getUserNeeds } from './user-needs/actions/userneeds.action'
const cookies = new Cookies();

setTablHeader();

//if user logged; Sets the token on every refresh
if (Auth.getAuthUserToken()) {
    setAuthorizationToken(Auth.getAuthUserToken());
    store.dispatch(setCurrentUser(jwt.decode(Auth.getAuthUserToken())));
}
// export default setup => () => {

// let clientSlug = document.location.pathname.split("/")[1];

history.listen(location => {
    if (history.action === 'POP') {

        store.dispatch(showHeaderCategory(false));

        store.dispatch(setLoginModalOpened(false));
        store.dispatch(setRegisterModalOpened(false));
        store.dispatch(setProfileModalOpened(false));

        store.dispatch(setOrderModalState(0)); //OrderModal
        store.dispatch(setDisabledMakeOrderButton(false)); //OrderModalButton
        store.dispatch(setCartModalOpenedState(false)); //CartModal
        store.dispatch(setTermsDialogOpened(false)); // Terms Dialog Modal
        store.dispatch(setPrivacyPolicyDialogOpened(false)); // Privacy Policy Dialog Modal


    } else if (history.action === 'PUSH') {
        // store.dispatch(verifyOpenedPlace(null, store.getState().places.chosen_place))
    }
})

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    let clientSlug = document.location.pathname.split("/")[1];
    clientSlug = "gabrovo-pizza"
    if(clientSlug) {
        axios.get(API_URL + 'clients/' + clientSlug)
            .then(res => {
                if (res.data) {
                    setCustomClientHeader(res.data.id);

                    let places = res.data.places;
                    store.dispatch({
                        type: GET_PLACES,
                        payload: places
                    });

                    store.dispatch(setClient(res.data));

                    if (places.length == 1 || (Auth.isTabletMode() && Auth.getOpenedPlace())) {
                        let placeId;
                        if (Auth.isTabletMode() && Auth.getOpenedPlace()) {
                            placeId = Auth.getOpenedPlace();
                        } else {
                            placeId = places[0].id
                        }

                        setCustomPlaceHeader(placeId);  // only for dev

                        store.dispatch(verifyOpenedPlace(placeId)); // if place is different -> clears local card, orders and token

                        store.dispatch(setChosenPlace(placeId));


                        store.dispatch(getAllProducts(0, placeId));
                        store.dispatch(getCategoriesHierarchy(placeId));
                        store.dispatch(getSettings(placeId));
                        store.dispatch(getProductIngredients());
                        store.dispatch(listLanguages());
                        store.dispatch(getRegions());
                        store.dispatch(getUserNeeds()); //only for dev, if prod the req is only if spot in UserNeedsModal.js
                    }
                }
            }).catch(error => {
                throw (error);
            });
    }

} else {
    // production code
    // console.log(store.getState())
    let places = store.getState().places.places;
    let chosenPlace = store.getState().places.chosen_place;

    chosenPlace = 1;
    // if(Object.keys(store.getState().client) == 0) {
    //     history.push("/404");
    // } else 
    if (places.length == 1 || chosenPlace != 0 || (Auth.isTabletMode() && Auth.getOpenedPlace())) {
        let placeId;
        if (Auth.isTabletMode() && Auth.getOpenedPlace()) {
            placeId = Auth.getOpenedPlace();
        } else {
            placeId = places.length == 1 ? places[0].id : chosenPlace;
        }

        store.dispatch(verifyOpenedPlace(placeId)); // if place is different -> clears local card, orders and token
        store.dispatch(setChosenPlace(placeId));

        // store.dispatch(getAllProducts(0, places[0].id));
        store.dispatch(getAllProducts(0));
        // store.dispatch(getCategoriesHierarchy(places[0].id))
        store.dispatch(getCategoriesHierarchy())

        // store.dispatch(getSettings(places[0].id));
        store.dispatch(getSettings());

        store.dispatch(getProductIngredients());
        store.dispatch(listLanguages());
        store.dispatch(getRegions());

    } else {
        store.dispatch({
            type: GET_PLACES,
            payload: places
        });
    }
}

store.dispatch(getProductsToOrder());
store.dispatch(getOrders());



// store.dispatch(getAllProducts());
// store.dispatch(getCategoriesHierarchy());

window.localStorage.setItem('hide_designes', 0); // OnRefresh reset "hide_designes" => Product themes options will be visible
window.localStorage.setItem('initial_translate', 1); // First initial translate flag

// }