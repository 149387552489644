import { GET_FEEDBACK_QUESTIONS, SEND_FEEDBACK_SUCCESS, SEND_FEEDBACK_ERROR, GET_FEEDBACK_ANSWERS } from './types';
import axios from 'axios';

import { API_URL } from '../../constants';

export const getFeedbackQuestions = () => dispatch => {
    axios.get(API_URL + 'feedbackquestions')
        .then(result => {
            dispatch({
                type: GET_FEEDBACK_QUESTIONS,
                payload: result.data
            })
        })
        .catch(err => {
            
        })
}

export const getFeedbackAnswers = () => dispatch => {
    axios.get(API_URL + 'feedbackanswers')
        .then(result => {
            dispatch({
                type: GET_FEEDBACK_ANSWERS,
                payload: result.data
            })
        })
        .catch(err => {
        })
}

export const sendFeedback = (answers) => dispatch => {
    // if(!Auth.getToken()) {
    //     history.push('/scanTable');
    //     return;
    // }

    axios
        .post(API_URL + 'feedbackanswers',
            answers
        )
        .then(res => {
            if (res.data.error) {
                //    dispatch({
                //         type: ORDER_FAILED,
                //         payload: "Възникна грешка при изпращане на поръчката."
                //     }) 
            } else {
                dispatch({
                    type: SEND_FEEDBACK_SUCCESS,
                })
                // dispatch(addSuccessMessage("Благодаря за обратната връзка :)"))
            }
            // history.push('/'); //todo maybe
        }
        ).catch(error => {
            throw (error);
        });
}
