import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import Utils from './../../utils/Utils';
import Auth from './../../utils/Auth';
import clsx from 'clsx';
import ProductListItem from "./../../products/components/ProductListItem";
import { withTracking } from 'react-tracker';
import { promotionClose, promotionClick } from './../../tracking/events/promotionsEvents';
import { pageView } from './../../tracking/events/productEvents';

import { addProdToCart, addToCartBtnClicked, setPromoProductForCategoryModalOpened } from './../../cart/actions/cart.actions';
import { getAllProducts, setCommentModalData } from './../../products/actions/products.action';
import { getSettings } from './../../settings/actions/settings.action';
import { getCategoriesHierarchy } from './../../categories/actions/categories.action';
import { verifyOpenedPlace } from './../../places/actions/places.actions';

import { styles } from './../styles/promoProductsForCategoryModal.styles.js';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class PromoProductForCategoryModal extends Component {
    constructor(props) {
        super(props);

        this.timer = null;
        this.state = {
            opened: false,
            timer: null

        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    componentWillReceiveProps(nextProps) {
        if (!cookies.get('tabl_promo')) {

            if (this.props.promo_product_for_category_modal_opened == 0 &&
                nextProps.promo_product_for_category_modal_opened == 0 && nextProps.promoProduct) {

                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => this.handleModalState(), this.props.timeToPop * 1000)
            }
        }


    }

    handleModalState = () => {
        this.props.setPromoProductForCategoryModalOpened(true);

        cookies.set('tabl_promo', '1', { path: '/', expires: new Date(Date.now() + 24 * 60 * 60 * 1000) });
        clearTimeout(this.timer);


        // if (this.props.currCat.id) {
        //     this.setState({
        //         opened: true
        //     })
        // } else {
        //     this.setState({
        //         opened: false
        //     })
        // }
    }

    onModalClose = () => {
        // this.setState({opened: false});
        this.props.setPromoProductForCategoryModalOpened(false);

        // console.log("THIS PROPS ", this.props);
        if (this.props.currCat && this.props.promoProduct) {
            this.props.trackPromotionClose(this.props.currCat.id, this.props.promoProduct.id)
        }
        // this.props.trackPageView(1, 1)
    }

    addToCartBtnClicked = (id, productVariantId = 0) => {
        this.props.addToCartBtnClicked(this.props.promoProduct, 0, this.props.settings);
        this.props.trackPromotionClick(this.props.currCat.id, this.props.promoProduct.id, 'add_to_cart');
    }

    render() {
        // console.log("PROMO STATE", this.state, this.timer)
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations } = this.props;
        return (
            <React.Fragment>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={this.props.promo_product_for_category_modal_opened}
                    onClose={this.onModalClose}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    // className={classes.modal}
                    data-class="dialog-container"
                >
                    {/* <CircularProgress className="is-loading language-modal" /> */}
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                        {this.props.currCat && this.props.currCat.promo_title || translations.pages.promotions.title}

                        {/* {translations.pages && translations.pages.choose_place && translations.pages.promotions.title ? translations.pages.promotions.title : ''} */}
                    </DialogTitle>

                    <DialogContent
                        className={classes.dialogContent}
                    >
                        <DialogContentText
                        // className={classes.dialogContentText}
                        >
                            <div className={classes.isMultipleOptionsModalContainer}>
                                {this.props.promoProduct ?
                                    <ProductListItem
                                        singleProductStyles={true}
                                        product={this.props.promoProduct}
                                        settings={this.props.settings}
                                        addProdToCart={this.props.addProdToCart}
                                        addToCartBtnClicked={this.addToCartBtnClicked}
                                        translations={this.props.translations}
                                        currLang={this.props.currLang}
                                        setCommentModalProps={(commentModalTitle, commentVariantId) => { this.props.setCommentModalData(true, commentModalTitle, commentVariantId) }}
                                        orderAllowed={true}

                                        // openCommentModal={() => { this.setState({ openCommentModal: true }) }}
                                    // modalTitle={() => { this.setState({ modalTitle: modalTitle }) }}
                                    // commentVariantId={() => { this.setState({ commentVariantId: commentVariantId }) }}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions style={{ display: 'block' }}>
                        <Button
                            onClick={this.onModalClose}
                            style={{
                                color: 'black',
                                'background-color': '#e1e1e1',
                                border: '1px solid #b2b2b2',
                                width: '100%',
                            }}
                        >
                            Затвори
                        </Button>
                    </DialogActions>

                </Dialog>
                {/* </Grow> */}
            </React.Fragment>
        );
    }

}


const mapStateToProps = (state, ownProps) => {

    let currCatId = state.categories && state.categories.selected_cat_hierarchy_id ? state.categories.selected_cat_hierarchy_id : 0;
    let currCat = null;
    if (currCatId) {
        currCat = state.categories.categories_hierarchy[currCatId] || [];
    }

    let promoProduct = null;
    if (currCat && currCat.children && currCat.children.length == 0 && currCat.promo_products) {
        let promoProductId = currCat.promo_products[0];

        promoProduct = state.products.all_products && state.products.all_products[promoProductId]

    }
    // console.log("PROMO ", currCat)

    return {
        currCat: currCat,
        promoProduct: promoProduct,
        translations: state.lang.translations,
        timeToPop: 3,
        currLang: state.lang.currLang,
        settings: state.settings.settings || [],
        promo_product_for_category_modal_opened: state.cart.promo_product_for_category_modal_opened || false
    };
};

const mapTrackingToProps = trackEvent => ({
    trackPromotionClose: (category_id, product_id) => trackEvent(promotionClose(category_id, product_id)),
    trackPromotionClick: (category_id, product_id, type) => trackEvent(promotionClick(category_id, product_id, type)),
    trackPageView: (pageId, userId) => trackEvent(pageView(pageId, userId))
});

export default withTracking(mapTrackingToProps)(withStyles(styles)(connect(mapStateToProps, {
    getAllProducts, getCategoriesHierarchy, verifyOpenedPlace, getSettings,
    addProdToCart, addToCartBtnClicked, setCommentModalData, setPromoProductForCategoryModalOpened
})(PromoProductForCategoryModal)))