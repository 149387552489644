import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import RoomIcon from '@material-ui/icons/Room';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import DirectionsIcon from '@material-ui/icons/Directions';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import PlaceIcon from '@material-ui/icons/Place';
import CameraIcon from '@material-ui/icons/Camera';
import CropFreeIcon from '@material-ui/icons/CropFree';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PieChartIcon from '@material-ui/icons/PieChart';
import { verifyToken, verifyTokenAndSession, closeScanModal, setCurrOpenedModal, setOrderModalState } from '../../../qr-scan/actions/scan.actions';
import OpenApp from "react-open-app";
import { setTermsDialogOpened, setPrivacyPolicyDialogOpened } from '../../../menu/menu.actions';
import { Radio, RadioGroup, FormControl, FormLabel, FormHelperText } from '@material-ui/core';

// import OrderModalSpot from 'OrderModal/OMSpot';
// import OrderModalDelivery from 'OrderModal/OMDelivery';
// import OrderModalPreorder from 'OrderModal/OMPreorder';

import QrReader from "react-qr-reader";
import Utils from "../../../utils/Utils";
import Auth from "../../../utils/Auth";
import history from '../../../utils/history';

import { styles } from '../styles/orderModal.styles';

class OMDelivery extends Component {
    render() {
        const { classes, fullWidth = false, maxWidth = 'xs', open, onSubmit, onError, isLoadingOrder, orderTotal, settings, modalData, translations } = this.props;
        const default_currency_suffix = (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.')

        let eachHoursCurrentDate = this.props.orderDates.find(p => p.value == (this.props.omdata.orderDate ? this.props.omdata.orderDate : this.props.defaultOrderDate))
        if (eachHoursCurrentDate && eachHoursCurrentDate.eachDateHours) {
            eachHoursCurrentDate = eachHoursCurrentDate.eachDateHours
        } else {
            eachHoursCurrentDate = []
        }

        return (
            <React.Fragment>

                <Fade in={true} timeout={{ enter: 185, exit: 190 }}>
                    <DialogContentText>

                        <Collapse in={Object.keys(this.props.omdata.customErrors).length > 0 || (this.props.orderSent == 'false' && this.props.orderMessage.length != 0)} timeout="auto" unmountOnExit>
                            <p className={classes.errorContainer}>
                                {Object.keys(this.props.omdata.customErrors).length > 0 ? this.props.translations.messages.errors.invalid_fields : this.props.orderMessage}
                            </p>
                        </Collapse>

                        <div className={classes.textFieldContainer}>
                            <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                                <Grid item xs={1}>
                                    <AccountCircle />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField name="name" className={classes.textField}
                                        error={this.props.omdata.customErrors && this.props.omdata.customErrors.name} helperText={this.props.omdata.customErrors && this.props.omdata.customErrors.name ? this.props.omdata.customErrors.name : ''}
                                        label={translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.first_and_last_name : ''}
                                        value={this.props.omdata.name} onChange={(e) => this.props.onChange(e)}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <div className={classes.textFieldContainer}>
                            <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                                <Grid item xs={1}>
                                    <PhoneInTalkIcon />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField name="phone" type="tel" className={classes.textField}
                                        error={this.props.omdata.customErrors && this.props.omdata.customErrors.phone} helperText={this.props.omdata.customErrors && this.props.omdata.customErrors.phone ? this.props.omdata.customErrors.phone : ''}
                                        label={translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.phone : ''}
                                        value={this.props.omdata.phone} onChange={(e) => this.props.onChange(e)}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <div className={classes.textFieldContainer}>
                            <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                                <Grid item xs={1}>
                                    <RoomIcon />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField name="address" className={classes.textField}
                                        error={this.props.omdata.customErrors && this.props.omdata.customErrors.address} helperText={this.props.omdata.customErrors && this.props.omdata.customErrors.address ? this.props.omdata.customErrors.address : ''}
                                        label={translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.order_address : ''}
                                        value={this.props.omdata.address} onChange={(e) => this.props.onChange(e)}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        {settings.order_email_visible && settings.order_email_visible.value && settings.order_email_visible.value == 1 ?
                            <div className={classes.textFieldContainer}>
                                <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                                    <Grid item xs={1}>
                                        <MailIcon />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField name="email" type="email" className={classes.textField}
                                            error={this.props.omdata.customErrors && this.props.omdata.customErrors.email} helperText={this.props.omdata.customErrors && this.props.omdata.customErrors.email ? this.props.omdata.customErrors.email : ''}
                                            label={translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.email : ''}
                                            value={this.props.omdata.email} onChange={(e) => this.props.onChange(e)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            :
                            null
                        }

                        {/* ----------------------- REGIONS ----------------------- */}
                        {this.props.regions.length > 0 ?
                            <div className={classes.textFieldContainer}>
                                <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                                    <Grid item xs={1}>
                                        <PieChartIcon />
                                    </Grid>
                                    <Grid item xs={11}>

                                        <TextField select name="regionId" className={classes.textField}
                                            error={this.props.omdata.customErrors && this.props.omdata.customErrors.regionId} helperText={this.props.omdata.customErrors && this.props.omdata.customErrors.regionId ? this.props.omdata.customErrors.regionId : ''}
                                            label={translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.region : ''}
                                            value={this.props.omdata.regionId ? this.props.omdata.regionId : ''} onChange={(e) => this.props.onChange(e)}
                                        >
                                            {this.props.regions.map((option) => (
                                                <MenuItem key={option.id} value={option.id} selected>
                                                    {option.name} {option.delivery_price != '0.00' ? ('- ' + option.delivery_price + ' ' + default_currency_suffix) : null}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </div>
                            :
                            null
                        }

                        {Utils.getSettingsValue(settings, 'show_payment_type_option_on_making_delivery_order') == '1' ?
                            <div className={clsx(classes.textFieldContainer, classes.paymentMethodContainer)}>
                                <FormControl component="fieldset" error={this.props.omdata.customErrors && this.props.omdata.customErrors.payment_method} >
                                    <FormLabel component="legend" className={classes.orderLabel}>{translations.pages.cart_delivery && translations.pages.cart_delivery.payment_type_delivery ? translations.pages.cart_delivery.payment_type_delivery : ''}</FormLabel>
                                    <RadioGroup row aria-label="position" name="payment_method" defaultValue={this.props.omdata.payment_method}> {/* defaultValue="cash" */}
                                        <FormControlLabel
                                            value="cash"
                                            control={<Radio className={classes.orderRadio} onClick={(e) => this.props.onChange(e)} />}
                                            label={translations.pages.cart_delivery && translations.pages.cart_delivery.payment_cash ? translations.pages.cart_delivery.payment_cash : ''}
                                            labelPlacement="cash"
                                        />
                                        <FormControlLabel
                                            value="card"
                                            control={<Radio className={classes.orderRadio} onClick={(e) => this.props.onChange(e)} />}
                                            label={translations.pages.cart_delivery && translations.pages.cart_delivery.payment_card ? translations.pages.cart_delivery.payment_card : ''}
                                            labelPlacement="card"
                                        />
                                    </RadioGroup>
                                    <FormHelperText>{this.props.omdata.customErrors && this.props.omdata.customErrors.payment_method ? this.props.omdata.customErrors.payment_method : ''}</FormHelperText>
                                </FormControl>
                            </div>
                            :
                            null
                        }

                        {Utils.getSettingsValue(settings, 'show_cutlery_option_on_making_delivery_order') == '1' ?
                            <div className={clsx(classes.textFieldContainer, classes.cutleryOptionContainer)}>
                                <FormControl component="fieldset" error={this.props.omdata.customErrors && this.props.omdata.customErrors.cutlery} >
                                    <FormLabel component="legend" className={classes.orderLabel}>{translations.pages.cart_delivery && translations.pages.cart_delivery.cutlery_needed ? translations.pages.cart_delivery.cutlery_needed : ''}</FormLabel>
                                    <RadioGroup row aria-label="position" name="cutlery" defaultValue={this.props.omdata.cutlery}> {/* defaultValue="1" */}
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio className={classes.orderRadio} onClick={(e) => this.props.onChange(e)} />}
                                            label={translations.pages.cart_delivery && translations.pages.cart_delivery.cutlery_needed_yes ? translations.pages.cart_delivery.cutlery_needed_yes : ''}
                                            labelPlacement="1"
                                        />
                                        <FormControlLabel
                                            value="0"
                                            control={<Radio className={classes.orderRadio} onClick={(e) => this.props.onChange(e)} />}
                                            label={translations.pages.cart_delivery && translations.pages.cart_delivery.cutlery_needed_no ? translations.pages.cart_delivery.cutlery_needed_no : ''}
                                            labelPlacement="0"
                                        />
                                    </RadioGroup>
                                    <FormHelperText>{this.props.omdata.customErrors && this.props.omdata.customErrors.cutlery ? this.props.omdata.customErrors.cutlery : ''}</FormHelperText>
                                </FormControl>
                            </div>
                            :
                            null
                        }

                        {Utils.getSettingsValue(settings, 'hide_order_delivery_pickup_date') != '1' ?
                            <React.Fragment>
                                <b>{translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.delivery_time : ''}:</b>

                                <div className={classes.orderTimeOptionsContainer}>

                                    <TextField select name="orderDate" className={classes.textField}
                                        error={this.props.omdata.customErrors && this.props.omdata.customErrors.orderDate} helperText={this.props.omdata.customErrors && this.props.omdata.customErrors.orderDate ? this.props.omdata.customErrors.orderDate : ''}
                                        value={this.props.omdata.orderDate ? this.props.omdata.orderDate : this.props.defaultOrderDate} onChange={(e) => this.props.onChange(e)}
                                    >
                                        {this.props.orderDates.map((option) =>
                                            (option && option.eachDateHours && Object.values(option.eachDateHours).length > 0) ?
                                                <MenuItem key={option.value} value={option.value} selected>
                                                    {option.label}
                                                </MenuItem>
                                                :
                                                null
                                        )}
                                    </TextField>

                                    <TextField select name="orderTime" className={classes.textField}
                                        error={this.props.omdata.customErrors && this.props.omdata.customErrors.orderTime} helperText={this.props.omdata.customErrors && this.props.omdata.customErrors.orderTime ? this.props.omdata.customErrors.orderTime : ''}
                                        value={this.props.omdata.orderTime ? this.props.omdata.orderTime : this.props.defaultOrderTime} onChange={(e) => this.props.onChange(e)}
                                    >
                                        {eachHoursCurrentDate.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                </div>
                            </React.Fragment>
                            :
                            null
                        }

                        <p style={{ fontSize: 13, margin: 0, marginTop: 13 }}>Със създаването на поръчката, вие се съгласявате с <Button onClick={() => this.props.setTermsDialogOpened(true)} className={clsx(classes.formButtons, "underlined")} disableRipple>Условията за ползване</Button> и <Button onClick={() => this.props.setPrivacyPolicyDialogOpened(true)} className={clsx(classes.formButtons, "underlined")} disableRipple>Политиката ни за поверителност</Button></p>

                        {/* <FormControlLabel
                            className="privacy-policy-checkbox"
                            control={
                                <Checkbox
                                    name="privacy_policy"
                                    className={classes.orderCheckbox}
                                    onClick={e => this.props.onChangeCheckbox(e)}
                                />
                            }
                            label={<React.Fragment>Запознах се и съм съгласен с  и <Button onClick={() => this.props.setTermsDialogOpened(true)} className={clsx(classes.formButtons, "underlined")}>Условията за ползване</Button></React.Fragment>}
                        />
                        <FormHelperText style={{ color: '#f44336' }}>{this.props.omdata.customErrors && this.props.omdata.customErrors.privacy_policy ? this.props.omdata.customErrors.privacy_policy : ''}</FormHelperText> */}


                        <div className={clsx(classes.textFieldContainer, classes.buttonsRow)} style={{ margin: 0 }}>
                            <FormControlLabel
                                style={{ marginRight: 0 }}
                                control={
                                    <Checkbox
                                        name="rememberMe"
                                        className={classes.orderCheckbox}
                                        defaultChecked={this.props.omdata.rememberMe}
                                        onChange={e => this.props.onChangeCheckbox(e)}
                                    />
                                }
                                label={translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.remember_me : ''}
                            />

                            {settings.promocode_visible && settings.promocode_visible.value && settings.promocode_visible.value == 1 ?
                                <Button
                                    onClick={() => this.props.onClick('inputPromoCodeVisible', !this.props.omdata.inputPromoCodeVisible)}
                                    className={classes.promoCodeBtn}
                                    endIcon={this.props.omdata.inputPromoCodeVisible ? <KeyboardArrowDownIcon /> : <KeyboardArrowLeftIcon />}
                                >
                                    {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.promo_code : ''}
                                </Button>
                                :
                                null
                            }
                        </div>


                        {settings.promocode_visible && settings.promocode_visible.value && settings.promocode_visible.value == 1 ?
                            <div className={clsx(classes.textFieldContainer, classes.promoCodeRow)}>
                                <Collapse in={this.props.omdata.inputPromoCodeVisible} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                                        <Grid item xs={8}>
                                            <TextField name="promoCode" label={translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.promo_code_placeholder : ''} className={classes.textField} value={this.props.omdata.promoCode} onChange={(e) => this.props.onChange(e)} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button
                                                // onClick={}
                                                className={classes.applyPromoCodeBtn}
                                            >
                                                {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.apply : ''}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </div>
                            :
                            null
                        }


                    </DialogContentText>
                </Fade>

            </React.Fragment >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let chosenPlaceOrderTypes = [];
    let chosenPlaceId = state.places.chosen_place;
    let chosenPlace = state.places.places.filter(p => p.id == chosenPlaceId);
    if (chosenPlace.length == 0) {
        chosenPlace = null;
    } else {
        chosenPlace = chosenPlace[0];
        chosenPlaceOrderTypes = chosenPlace.ordertypes;
    }

    return {
        isLoadingOrder: state.products.isLoadingOrder || false,
        settings: state.settings.settings || [],
        scan_modal_open: state.scan.scan_modal_open || 0,
        currOpenedModal: state.scan.curr_opened_modal || 'multiple',
        places: state.places.places || [],
        chosenPlace: chosenPlace,
        chosenPlaceOrderTypes: chosenPlaceOrderTypes,
        regions: state.regions.regions || [],
        productsToOrder: Utils.normalizeCartProducts(state.products.to_order) || [],
        productIngredients: state.products.productIngredients || [],
        translations: state.lang.translations,
    };
};

export default withStyles(styles)(connect(mapStateToProps, { verifyToken, verifyTokenAndSession, closeScanModal, setCurrOpenedModal, setOrderModalState, setTermsDialogOpened, setPrivacyPolicyDialogOpened })(OMDelivery));