import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import Fade from '@material-ui/core/Fade';
import { verifyToken } from '../../../qr-scan/actions/scan.actions';
import QrReader from "react-qr-reader";
import { styles } from '../styles/orderModal.styles';

class OMSpot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scanDelay: 200,
            scanLegacyMode: false,
        }
    }

    componentDidMount() {
        this.setState({ errors: '' });
    }

    getQrReader = () => {
        var scanner = "";

        // if(window.localStorage.getItem('app')) {
        //     // history.push("/close-browser-app")
        //     // scanner = <OpenApp
        //     //         href="https://maisons.tabl.bg"
        //     //         android="maisons://"
        //     //         ios="maisons://"
        //     //     />
        // } else  {
        scanner = <QrReader ref="qrReader1"
            delay={this.state.scanDelay}
            onError={this.props.handleScanError}
            onScan={this.props.handleScan}
            style={{ width: "100%" }}
        //legacyMode={scanLegacyMode}
        />

        // } 

        return scanner;
    }

    render() {
        return (
            <React.Fragment>
                <Fade in={true} timeout={{ enter: 385, exit: 190 }}>
                    <DialogContentText style={{ margin: 0 }}>

                        {this.getQrReader()}

                    </DialogContentText>
                </Fade>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state, ownProps) => {


    return {
        settings: state.settings.settings || [],
        translations: state.lang.translations,
    };
};

export default withStyles(styles)(connect(mapStateToProps, { verifyToken })(OMSpot));