
export const styles = theme => ({
    container: {
        // backgroundColor: 'rgba(147, 147, 147, 0.4)',
        // minHeight: 'calc(100vh + 55px)',
        minHeight: '100vh',
        // marginTop: 55,
        // paddingLeft: '10%',
        // paddingRight: '10%',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        'background-image': 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("https://tabl.bg/img/backgrounds/pexels-sebastian-coman-photography-3475617-min.jpg")',
        'background-size': 'cover',
        'background-attachment': 'fixed',
        // 'background-repeat': 'no-repeat',
        // 'background-size': 'contain',

    },
    logosContainer: {
        textAlign: 'center',
        '& img': {
            'vertical-align': 'middle'
        }
    },
    textField: {
        '& label': {
            color: '#eaeaea',
        },
        '& label.Mui-focused': {
            color: '#ccc',
        },
        '& .MuiInput-underline:before, &:hover .MuiInput-underline:before': {
            borderBottomColor: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#ccc',
        },
        '& .MuiInput-input': {
            color: 'white'
        },
        marginTop: 10,
    },
    submitButton: {
        width: '100%',
        maxWidth: 400,
        borderRadius: 0,
        borderColor: '#eaeaea',
        color: 'black',
        background: '#f9f9f9 !important',
        // marginLeft: 0,
        display: 'block',
        margin: '0 auto !important',
        // '@media screen and (min-width: 1000px)': {
        // width: '78%',
        // },
    },
    uploadButton: {
        width: '100%',
        borderRadius: 0,
        borderColor: '#eaeaea',
        color: 'white',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        textAlign: 'left',
        paddingLeft: 0,
        '& .MuiButton-label': {
            justifyContent: 'left'
        }
    },

    selectField: {
        marginTop: 10,
        minWidth: 175,
        '& svg': {
            color: 'white'
        },
        '& .MuiInput-underline:before, &:hover .MuiInput-underline:before, & .MuiInput-underline:after': {
            borderColor: 'white'
        },
        '& .MuiFormLabel-root, & .MuiInput-input': {
            color: 'white'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'white'
        },

    },

    fieldsWidth: {
        '@media screen and (min-width: 350px)': {
            width: 185
        },
        '@media screen and (min-width: 400px)': {
            width: 225
        },
        '@media screen and (min-width: 450px)': {
            width: 250
        },
        '@media screen and (min-width: 500px)': {
            width: 275
        },
        '@media screen and (min-width: 550px)': {
            width: 300
        },
        // '@media screen and (min-width: 680px)': {
        //     width: 400
        // },
    },

    checkboxWhite: {
        '& svg': {
            color: 'white'
        }
    },

    feedBackTitle: {
        color: '#efefef',
        textAlign: 'center',
        fontSize: 18,
    },
    thankYouContainer: {
        color: '#efefef',
        textAlign: 'center',
        fontSize: 18,
        width: 182,
        '@media screen and (min-width: 400px)': {
            width: 225
        },
        '@media screen and (min-width: 450px)': {
            width: 250
        },
        '@media screen and (min-width: 500px)': {
            width: 275
        },
        '@media screen and (min-width: 550px)': {
            width: 300
        },
        '@media screen and (min-width: 680px)': {
            width: 400
        },
    },
    feedBackTitleDark: {
        color: '#1e1e1e'
    },
    leftGridContainerForm: {
        '@media screen and (min-width: 1000px)': {
            margin: '0 auto !important'
        },
    },

    phoneCard: {
        zIndex: 123,
        '-webkit-animation': 'linear infinite alternate',
        '-webkit-animation-name': 'run',
        '-webkit-animation-duration': '5s',
        // 'border-radius': 106,

        // marginTop: 20,
        width: '100%',
        height: 'auto',
        // maxHeight: 350,
        // '@media screen and (min-width: 1561px)': {
        //     width: '45%',
        //     maxHeight: 500,
        // },
        // '@media screen and (min-height: 1000px)': {
        //     width: '45%',
        //     maxHeight: 600,
        // },
        // margin: 'auto',
        // position: 'absolute',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-size': 'contain',
        'background-position': 'center',
        // transform: 'rotate(-20deg)',
        // 'background-image': 'url(https://scontent.fsof3-1.fna.fbcdn.net/v/t1.15752-9/125346309_728622204413168_1334428645671416694_n.png?_nc_cat=109&ccb=2&_nc_sid=ae9488&_nc_ohc=yAodrpLyt7kAX9ICtpa&_nc_ht=scontent.fsof3-1.fna&oh=546695c86d2d66f4140266fd26b32fcc&oe=5FD1623E)',
        'background-image': 'url(https://tabl.bg/img/mockups/raffy/menu_portrait_viewproduct-min.png)',
        // 'background-image': 'url(https://maisons.tabl.bg/files/images/4f58b33a1d904cb673bb42a93c7303c1_1593990997577.png)',
        boxShadow: '-1px -1px 0 #cccccc00, -2px -2px 0 #c9c9c900, -3px -3px 0 #bbbbbb00, -4px -4px 0 #b9b9b900, -5px -5px 0 #aaaaaa00, -6px -6px 1px rgba(0,0,0,0), 0 0 5px rgba(0,0,0,0), -1px -1px 3px rgba(0,0,0,0), -3px -3px 5px rgba(0,0,0,0), -5px -5px 10px rgba(0,0,0,0), -10px -10px 10px rgba(0,0,0,0), -20px -20px 20px rgba(0,0,0,0); transition: all 1s ease-in-out;'

    },
    // phoneCardLeft: {
    //     transform: 'rotate(-20deg)',
    //     'background-image': 'url(https://scontent.fsof3-1.fna.fbcdn.net/v/t1.15752-9/125346309_728622204413168_1334428645671416694_n.png?_nc_cat=109&ccb=2&_nc_sid=ae9488&_nc_ohc=yAodrpLyt7kAX9ICtpa&_nc_ht=scontent.fsof3-1.fna&oh=546695c86d2d66f4140266fd26b32fcc&oe=5FD1623E)',
    //     // 'background-image': 'url(https://maisons.tabl.bg/files/images/4f58b33a1d904cb673bb42a93c7303c1_1593990997577.png)',
    //     boxShadow: '-1px -1px 0 #cccccc00, -2px -2px 0 #c9c9c900, -3px -3px 0 #bbbbbb00, -4px -4px 0 #b9b9b900, -5px -5px 0 #aaaaaa00, -6px -6px 1px rgba(0,0,0,0), 0 0 5px rgba(0,0,0,0), -1px -1px 3px rgba(0,0,0,0), -3px -3px 5px rgba(0,0,0,0), -5px -5px 10px rgba(0,0,0,0), -10px -10px 10px rgba(0,0,0,0), -20px -20px 20px rgba(0,0,0,0); transition: all 1s ease-in-out;'
    // },
    phoneCardRight: {
        top: 130,  //20-25
        left: '70%',  //70-75
        right: 0,
        bottom: 0,
        // transform: 'rotate(-10deg)',
        'background-image': 'url(https://tabl.bg/img/mockups/raffy/menu_portrait_viewproduct-min.png)',
        // 'background-image': 'url(https://urban.bambooclubs.bg/wp-content/uploads/2019/01/bamboo-urban-logo-white.png)',
        boxShadow: '1px -1px 0 #cccccc00, 2px -2px 0 #c9c9c900, 3px -3px 0 #bbbbbb00, 4px -4px 0 #b9b9b900, 5px -5px 0 #aaaaaa00, 6px -6px 1px rgba(0,0,0,0), 0 0 5px rgba(0,0,0,0), 1px -1px 3px rgba(0,0,0,0), 3px -3px 5px rgba(0,0,0,0), 5px -5px 10px rgba(0,0,0,.0), 10px -10px 10px rgba(0,0,0,0), 20px -20px 20px rgba(0,0,0,0); transition: all ease-in-out .66s;'
    },


    footerGrid: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        '@media screen and (max-width: 600px)': {
            gridTemplateColumns: 'none',
        }

    }



});
