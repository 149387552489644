import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import Avatar from '@material-ui/core/Avatar';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Utils from '../../utils/Utils'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 700,
        margin: '0 auto',
    },
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridColumnGap: 10,
    itemTypography: {
        display: 'contents',
    },
    subcatRow: {
        '&:first-child': {
            marginTop: 15,
        },
        '&:last-child': { // temp unable to hive navbar fix
            marginBottom: 75,
        },
        background: 'rgba(255, 255, 255, 0.65)',
        width: '95%',
        margin: '0 auto',
        marginBottom: 10,
        borderRadius: 7,
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.71)',
        },
        padding: 8,
    },
    avatar: {
        width: 55,
        height: 55,
        marginRight: 15,
        border: '1px solid #ccc',
    },
    rightArrowIcon: {
        padding: 5,
        background: 'rgba(255, 255, 255, 0.16)',
        borderRadius: 25,
    },
}));

export default function RowButton(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {Object.values(props.items).sort(Utils.sortPriority()).map((item, k) => (
                <Grow in={true} timeout={{ enter: Utils.calculateAnimationTimeout(k), exit: Utils.calculateAnimationTimeout(k) }} key={k}>

                    <ListItem className={classes.subcatRow} button onClick={() => props.categoryClickHandler(item.id)} >
                        <Typography component="h3" variant="span" className={classes.itemTypography}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar} src={Utils.getSubcategoryImagePath(item.icon, 1)} alt={item.translations[props.currLang] && item.translations[props.currLang].name || item.name} />
                            </ListItemAvatar>

                            <ListItemText primary={item.translations[props.currLang] && item.translations[props.currLang].name || item.name}
                                secondary={(item.description ? item.description : '')}
                            // secondary={(item.products_count ? item.products_count : 0) + (item.products_count == 1 ? (props.translations && props.translations.common ? (' ' + props.translations.common.product) : '') : (props.translations && props.translations.common ? (' ' + props.translations.common.products) : ''))}
                            />
                            <KeyboardArrowRightIcon className={classes.rightArrowIcon} />
                        </Typography>
                    </ListItem>

                </Grow>
            ))}
        </div>
    );
}
