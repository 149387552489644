import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Grow, Backdrop, CircularProgress, DialogContentText } from '@material-ui/core';
import { Home, Place, RestaurantMenu, KingBed } from '@material-ui/icons';
import clsx from 'clsx';

import { setAlertMessage } from '../../menu/menu.actions';
import { styles } from './styles/orderModal.styles';

import { verifyTokenAndSession, closeScanModal, setCurrOpenedModal, setOrderModalState } from './../../qr-scan/actions/scan.actions';
import { verifyOpenedPlace, setChosenPlace } from './../../places/actions/places.actions';

import OrderModalSpot from './OrderModal/OMSpot';
import OrderModalDelivery from './OrderModal/OMDelivery';
import OrderModalPreorder from './OrderModal/OMPreorder';
import OrderModalRoom from './OrderModal/OMRoom';
import Utils from "../../utils/Utils";
import Auth from "../../utils/Auth";


class DeliveryModal extends Component {
    constructor(props) {
        super(props);
        const order_credentials = window.localStorage.getItem('order_credentials');
        const loggedUser = this.props.loggedUser;
        this.state = {
            // termsOpened: false,
            // privacyPolicyOpened: false,

            name: order_credentials ? (JSON.parse(order_credentials).name || "") : (loggedUser ? loggedUser.fullname : ''),
            phone: order_credentials ? (JSON.parse(order_credentials).phone || "") : loggedUser ? loggedUser.phone : '',
            address: order_credentials ? (JSON.parse(order_credentials).address || "") : loggedUser ? loggedUser.address : '',
            email: order_credentials ? (JSON.parse(order_credentials).email || "") : loggedUser ? loggedUser.email : '',

            // name: loggedUser ? (loggedUser.fullname || "") : order_credentials ? JSON.parse(order_credentials).name : '',
            // phone: loggedUser ? loggedUser.phone : order_credentials ? JSON.parse(order_credentials).phone : '',
            // address: loggedUser ? loggedUser.address : order_credentials ? JSON.parse(order_credentials).address : '',
            // email: loggedUser ? loggedUser.email : order_credentials ? JSON.parse(order_credentials).email : '',

            room_number: order_credentials ? JSON.parse(order_credentials).room_number : '',
            promoCode: '',
            rememberMe: order_credentials ? true : false,
            inputPromoCodeVisible: false,
            payment_method: order_credentials ? JSON.parse(order_credentials).payment_method : '',
            cutlery: order_credentials ? JSON.parse(order_credentials).cutlery : '',
            // terms: false,
            // privacy_policy: false,
            errors: '',
            modalType: 0,

            orderDate: '',
            orderTime: '',

            regionId: order_credentials ? JSON.parse(order_credentials).regionId : '',

            scanDelay: 200,
            scannedTableToken: '',
            scanLegacyMode: false,
            scanned: false,

            customErrors: {},
            formSubmissionFailed: false,
        }
    }

    componentDidMount() {
        this.setState({ errors: '' });
        this.autoSetClientAddressOnPreOrder(this.props);
    }

    componentWillReceiveProps(nextProps) {
        // this.autoSetClientAddressOnPreOrder(props);
        // const { settings } = this.props;

        // if (this.props.orderSent == 'true' && this.props.orderMessage.length > 0 && this.state.privacy_policy) {
        //     this.setState({ privacy_policy: false });
        // }

        this.calculatePossibleOrderDateAndTimes();
        // console.log("STATE ", this.state, this.state.name && this.state.name.length == 0 && this.state.phone && this.state.phone.length == 0)
        // console.log('typeof this.state.name != "undefined"', typeof this.state.name, typeof this.state.name != "undefined");
        if ((typeof this.state.name == "undefined" || !this.state.name || this.state.name.length == 0) && (typeof this.state.phone == "undefined" || !this.state.phone || this.state.phone.length == 0)) {
            let order_credentials = window.localStorage.getItem('order_credentials');

            if (order_credentials) {
                order_credentials = JSON.parse(order_credentials);
            }
            // console.log("ORDER CRED ", order_credentials)
            const loggedUser = nextProps.loggedUser;
            this.setState({
                name: order_credentials ? (order_credentials.name || "") : loggedUser ? loggedUser.fullname : '',
                phone: order_credentials ? (order_credentials.phone || "") : loggedUser ? loggedUser.phone : '',
                address: order_credentials ? (order_credentials.address || "") : loggedUser ? loggedUser.address : '',
                email: order_credentials ? (order_credentials.email || "") : loggedUser ? loggedUser.email : '',

                // phone: loggedUser ? loggedUser.phone : order_credentials ? JSON.parse(order_credentials).phone : '',
                // address: loggedUser ? loggedUser.address : order_credentials ? JSON.parse(order_credentials).address : '',
                // email: loggedUser ? loggedUser.email : order_credentials ? JSON.parse(order_credentials).email : '',
            })
        }

        // Settings default payment_type - if needed add setting for default selected
        // if (this.state.payment_method == '') {
        //     if ((Utils.getSettingsValue(settings, 'show_payment_type_option_on_making_delivery_order') == '1' && this.props.currOpenedModal == 'delivery') ||
        //         (Utils.getSettingsValue(settings, 'show_payment_type_option_on_making_preorder_order') == '1' && this.props.currOpenedModal == 'preorder')
        //     ) {
        //         this.setState({ payment_method: 'cash' });
        //     }
        // }
    }

    // componentWillUnmount() {
    // }

    componentDidUpdate() {
        // this.handleOrderAction();
    }

    handleOrderAction = (orderType) => {
        const { settings, translations, productsToOrder, productIngredients } = this.props;
        const disabledOrdering = Utils.disabledOrdering(settings);

        if (disabledOrdering) {
            this.props.onError(Utils.getDisabledOrderingMessage(translations, settings))
            return;
        }

        const cartPrice = Utils.calculateTotalPriceForOrder(productsToOrder, settings, productIngredients)
        const minimal_order_price = parseFloat(Utils.getSettingsValue(settings, 'minimal_order_price'))
        if (orderType == 'delivery' && cartPrice < minimal_order_price) {
            this.props.setAlertMessage(true, Utils.translate(translations && translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.minimal_order_price ? translations.pages.cart_delivery.minimal_order_price : '', { value: minimal_order_price + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') }))
            return;
        }

        if (orderType == 'spot') {
            if (Auth.getUserToken()) {
                this.props.onSubmit(this.state, 1);
            } else {
                if (window.localStorage.getItem("app")) {
                    window.location.pathname = "/close-browser-app";
                    return;
                }
            }
        }
        this.props.setCurrOpenedModal(orderType);
    }

    autoSetClientAddressOnPreOrder = (props) => {
        const { open, settings } = props
        // Set place address if preorder


        if (open == true && (((this.props.chosenPlaceOrderTypes.findIndex(p => p.type == "preorder") > -1))) && (settings.place_address && settings.place_address.value) && ((!this.state.address) || this.state.address && this.state.address != settings.place_address.value)) {
            this.setState({ address: settings.place_address.value });
        }
    }

    isFormValid = (updatedValue = {}) => {
        const { settings } = this.props;

        const checkMinLengthValid = (value) => {
            const minLength = 3;
            return value && value.toString().length >= minLength;
        }

        let stateData = this.state;

        // Used for live validate onchange (Because state is not changed immediately)
        if (updatedValue && Object.keys(updatedValue).length > 0 && Object.keys(updatedValue)[0]) {
            stateData[Object.keys(updatedValue)[0]] = updatedValue[Object.keys(updatedValue)[0]];
        }

        // Name
        const nameValid = checkMinLengthValid(stateData.name) && stateData.name.toString().length <= 40;

        // Phone
        let phoneValid = checkMinLengthValid(stateData.phone);
        const phoneRegexMatch = Utils.getSettingsValue(settings, 'validate_phone_number_regex') && stateData.phone ? (new RegExp(Utils.getSettingsValue(settings, 'validate_phone_number_regex'))).test(stateData.phone.replace(/ /g, '')) : true;
        phoneValid = phoneValid && phoneRegexMatch;

        // Address - DELIVERY ONLY
        const addressValid = this.props.currOpenedModal == 'delivery' ? checkMinLengthValid(stateData.address) : true;

        // Region - DELIVERY ONLY
        const regionsValid = (this.props.currOpenedModal == 'delivery' && this.props.regions.length > 0) ? (stateData.regionId && this.props.regions.find(r => r.id == stateData.regionId) ? true : false) : true;

        // Order Date
        const orderDateValid = stateData.orderDate.toString().length > 0 || this.defaultOrderDate.toString().length > 0

        // Order Time
        let orderTimeValid = stateData.orderTime.toString().length > 0
        if (!orderTimeValid && Utils.getSettingsValue(settings, 'required_delivery_pickup_times') != '1') {
            orderTimeValid = this.defaultOrderTime;
        }

        // Room number - Room
        const roomNumberValid = this.props.currOpenedModal == 'room' ? stateData.room_number : true;


        // Payment type - Delivery
        const paymentValidDelivery = ((Utils.getSettingsValue(settings, 'show_payment_type_option_on_making_delivery_order') == '1' && this.props.currOpenedModal == 'delivery')) ? (stateData.payment_method.length > 0) : true

        // Payment type - Pickup
        const paymentValidPickup = ((Utils.getSettingsValue(settings, 'show_payment_type_option_on_making_preorder_order') == '1' && this.props.currOpenedModal == 'preorder')) ? (stateData.payment_method.length > 0) : true

        // Payment type - Room
        const paymentValidRoom = ((Utils.getSettingsValue(settings, 'show_payment_type_option_on_making_room_order') == '1' && this.props.currOpenedModal == 'room')) ? (stateData.payment_method.length > 0) : true

        // Cutlery - Delivery
        const cutlerySelectedDelivery = ((Utils.getSettingsValue(settings, 'show_cutlery_option_on_making_delivery_order') == '1' && this.props.currOpenedModal == 'delivery')) ? (stateData.cutlery != '') : true

        // Cutlery - Pickup
        const cutlerySelectedPickup = ((Utils.getSettingsValue(settings, 'show_cutlery_option_on_making_preorder_order') == '1' && this.props.currOpenedModal == 'preorder')) ? (stateData.cutlery != '') : true

        // Cutlery - Room
        const cutlerySelectedRoom = ((Utils.getSettingsValue(settings, 'show_cutlery_option_on_making_room_order') == '1' && this.props.currOpenedModal == 'room')) ? (stateData.cutlery != '') : true

        // // Terms
        // const termsValid = stateData.terms == true;

        // Privacy Policy
        // const privacyPolicyValid = stateData.privacy_policy == true;

        const isFormValid = nameValid && phoneValid && addressValid && regionsValid && orderDateValid && orderTimeValid && roomNumberValid && paymentValidDelivery && paymentValidPickup && paymentValidRoom && cutlerySelectedDelivery && cutlerySelectedPickup && cutlerySelectedRoom; /*&& termsValid && privacyPolicyValid*/

        if (isFormValid) {
            this.setState({ customErrors: {} });
        } else {
            this.setState({
                formSubmissionFailed: true,
                customErrors:
                {
                    name: !nameValid ? this.props.translations.messages.errors.invalid_name : '',
                    phone: !phoneValid ? this.props.translations.messages.errors.invalid_phone : '',
                    address: !addressValid ? this.props.translations.messages.errors.invalid_address : '',
                    regionId: !regionsValid ? this.props.translations.messages.errors.invalid_regionId : '',
                    orderDate: !orderDateValid ? this.props.translations.messages.errors.invalid_orderDate : '',
                    orderTime: !orderTimeValid ? this.props.translations.messages.errors.invalid_orderTime : '',
                    room_number: !roomNumberValid ? this.props.translations.messages.errors.invalid_room_number : '',
                    payment_method: !(paymentValidDelivery && paymentValidPickup && paymentValidRoom) ? this.props.translations.messages.errors.invalid_payment_method : '',
                    cutlery: !(cutlerySelectedDelivery && cutlerySelectedPickup && cutlerySelectedRoom) ? this.props.translations.messages.errors.invalid_cutlery_selected : '',
                    // terms: !termsValid ? this.props.translations.messages.errors.invalid_terms : '',
                    // privacy_policy: !privacyPolicyValid ? this.props.translations.messages.errors.invalid_privacy_policy_and_terms : '',
                }
            });
        }

        return isFormValid;
    }

    saveOrderInfoToLocal = () => {
        let order_credentials = {};
        order_credentials.name = this.state.name;
        order_credentials.phone = this.state.phone;
        order_credentials.room_number = this.state.room_number;
        order_credentials.address = this.state.address;
        order_credentials.email = this.state.email;
        order_credentials.regionId = this.state.regionId;
        order_credentials.payment_method = this.state.payment_method;
        order_credentials.cutlery = this.state.cutlery;
        window.localStorage.setItem('order_credentials', JSON.stringify(order_credentials));
    }

    removeOrderInfoFromLocal = () => {
        window.localStorage.removeItem('order_credentials');
    }

    handleScan = (scannedTableToken) => {
        const { onClose, onSubmit, onError, translations } = this.props;

        if (scannedTableToken) {
            if (this.state.scanned == false) {
                this.setState({ scanned: true });

                this.props
                    // .verifyToken(scannedTableToken)
                    .verifyTokenAndSession(scannedTableToken)
                    .then((data) => {
                        let validTokenSession = data.data.payload.session;

                        let placeId = data.data.payload.place_id;
                        this.props.verifyOpenedPlace(placeId); // if place is different -> clears local card, orders and token

                        Auth.setUserToken(validTokenSession);

                        this.props.setChosenPlace(placeId);

                        let currState = this.state;
                        currState.scannedTableToken = validTokenSession;
                        if (data.data.payload.ordertype) {
                            Auth.setTokenOrderType(data.data.payload.ordertype);
                        }

                        onSubmit(currState, 1);
                        this.setState({ modalType: 0 });
                        this.props.closeScanModal();
                        // this.props.setCurrOpenedModal('multiple');
                        // this.props.setOrderModalState(0);

                        if (this.props.chosenPlace && this.props.chosenPlace.ordertypes.length > 1) {
                            this.props.setCurrOpenedModal('multiple');
                            // } else {
                            //     this.props.onClose();
                            //     this.props.setOrderModalState(0);
                        }

                        this.setState({ scanned: false });
                    })
                    .catch(err => {
                        // console.log("ORDER MODAL handle scan ERROR", err);

                        Auth.removeUserToken();
                        onError(translations.messages && translations.messages.errors && translations.messages.errors.order_sent_fail ? translations.messages.errors.order_sent_fail : 'Invalid token scanned');
                        this.setState({ modalType: 0 });
                        this.props.closeScanModal();

                        if (this.props.chosenPlace.ordertypes.length > 1) {
                            this.props.setCurrOpenedModal('multiple');
                        } else {
                            this.props.onClose();
                            this.props.setOrderModalState(0);
                        }

                        this.setState({ scanned: false });

                        // this.props.setCurrOpenedModal('multiple');
                        // this.props.onClose(); // няма да го затваряме за сега модала, и аз нз защо - аз зн
                    })
            }
        }

    }

    handleScanError = (err) => {
        console.error(err);
    }

    getOrderButtonId = (orderType) => {
        if (orderType == 'spot') {
            return 'choose-order-type-modal-inrestaurant-button';
        } else if (orderType == 'delivery') {
            return 'choose-order-type-modal-delivery-button';
        } else if (orderType == 'preorder') {
            return 'choose-order-type-modal-pickup-button';
        }
    }


    getOrderOptions = (currentPrice, selectedRegionDeliveryPrice) => {
        const { classes, fullWidth = false, maxWidth = 'xs', open, onClose, onSubmit, onError, isLoadingOrder, settings, modalData, translations, chosenPlace, productsToOrder, productIngredients, chosenPlaceOrderTypes } = this.props;
        const default_currency_suffix = (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.')
        const userToken = Auth.getUserToken();
        const disabledOrdering = Utils.disabledOrdering(settings);
        var orderOptions = "";

        if (chosenPlace) {
            let totalOrderTypeLength = Object.keys(chosenPlaceOrderTypes).length;

            if (chosenPlaceOrderTypes.findIndex(o => o.type == "tablet_mode") != -1) {
                totalOrderTypeLength--;
            }
            let count = 0;
            orderOptions = chosenPlaceOrderTypes.map((orderType, k) => {
                if (orderType.type == "tablet_mode") { return null; }
                count++;

                // const currentPrice = parseFloat(Utils.calculateTotalPriceForOrder(productsToOrder, settings, productIngredients));
                const orderTypeDiscount = Utils.getOrderTypeDiscount(chosenPlace, orderType.type, currentPrice, 1)
                const orderTypePrice = Utils.getOrderTypePrice(chosenPlace, orderType.type, currentPrice, 1)

                let marginBottomOrderOptionButton = 0;

                if (Object.keys(orderTypeDiscount).length > 0) {
                    marginBottomOrderOptionButton += 15;
                }
                if ((this.props.regions.length > 0 && orderType.type == 'delivery') || (Object.keys(orderTypePrice).length > 0)) {
                    marginBottomOrderOptionButton += 15;
                }
                if (marginBottomOrderOptionButton == 50) {
                    marginBottomOrderOptionButton += 8
                }

                // (Object.keys(orderTypeDiscount).length > 0) && ((this.props.regions.length > 0 && orderType.type == 'delivery') || (Object.keys(orderTypePrice).length > 0)) ? 60 : ((Object.keys(orderTypeDiscount).length > 0 || Object.keys(orderTypePrice).length > 0) ? 40 : 20)
                return (
                    <Button key={k} id={this.getOrderButtonId(orderType.type)} size="large"
                        className={classes.modalTypesBtns}
                        style={{
                            borderBottom: (Object.keys(orderTypeDiscount).length == 0 && Object.keys(orderTypePrice).length == 0) || (this.props.regions.length > 0 && orderType.type == 'delivery' && this.state.regionId && selectedRegionDeliveryPrice == '0.00') ? '1px solid #ff5200' : 'none',
                            marginBottom: marginBottomOrderOptionButton,
                            gridColumn: (count == totalOrderTypeLength && count % 2 != 0) ? '1 / -1' : 'auto / span 1',
                        }}

                        onClick={() => this.handleOrderAction(orderType.type)}> {/* Go to scan page if not scanned, else make the order */}
                        <div>
                            {orderType.type == 'spot' ? <RestaurantMenu className={classes.modalTypesIcons} /> : null}
                            {orderType.type == 'delivery' ? <Home className={classes.modalTypesIcons} /> : null}
                            {orderType.type == 'preorder' ? <Place className={classes.modalTypesIcons} /> : null}
                            {orderType.type == 'room' ? <KingBed className={classes.modalTypesIcons} /> : null}
                        </div>
                        <div>
                            {orderType.type == 'spot' && translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.order_type_restaurant : ''}
                            {orderType.type == 'delivery' && translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.order_type_delivery : ''}
                            {orderType.type == 'preorder' && translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.order_type_preorder ? translations.pages.cart_delivery.order_type_preorder : ''}
                            {orderType.type == 'room' && translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.order_from_room ? translations.pages.cart_delivery.order_from_room : ''}
                        </div>

                        {Object.keys(orderTypeDiscount).length > 0 ?
                            <div className={classes.modalTypesTexts}>
                                {orderTypeDiscount.value_type == 'money' ? orderTypeDiscount.value : parseInt(orderTypeDiscount.value)}{orderTypeDiscount.value_type == 'money' ? ' ' + default_currency_suffix : '%'} {translations && translations.common && translations.common.discount ? translations.common.discount : ''}
                            </div>
                            :
                            null
                        }

                        {(orderTypePrice && Object.keys(orderTypePrice).length > 0 ?
                            <div className={classes.modalTypesTexts} style={{ bottom: Object.keys(orderTypeDiscount).length > 0 ? -40 : -20 }}> {/* THIS IS orderTypeDiscount ! (not a copy mistake) */}
                                {parseFloat(orderTypePrice.value) == 0 ?
                                    (translations && translations.common && translations.common.free_del ? translations.common.free_del : '')
                                    :
                                    <React.Fragment>
                                        + {orderTypePrice.value} {default_currency_suffix}
                                    </React.Fragment>
                                }
                                &nbsp;{translations && translations.common && translations.common.delivery ? translations.common.delivery : ''}
                            </div>
                            :
                            (this.props.regions.length > 0 && orderType.type == 'delivery' ?
                                (parseFloat(selectedRegionDeliveryPrice) != 0 ?
                                    <div className={classes.modalTypesTexts} style={{ bottom: Object.keys(orderTypeDiscount).length > 0 ? -40 : -20 }}> {/* THIS IS orderTypeDiscount ! (not a copy mistake) */}
                                        + {selectedRegionDeliveryPrice} {default_currency_suffix} {translations && translations.common && translations.common.delivery ? translations.common.delivery : ''}
                                    </div>
                                    :
                                    <div className={classes.modalTypesTexts} style={{ bottom: Object.keys(orderTypeDiscount).length > 0 ? -40 : -20 }}> {/* THIS IS orderTypeDiscount ! (not a copy mistake) */}
                                        {translations && translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.price_according_to_region ? translations.pages.cart_delivery.price_according_to_region : ''}
                                    </div>
                                )
                                :
                                null
                            )
                        )}


                    </Button>
                )

            })
        }
        return orderOptions;
    }

    calculatePossibleOrderDateAndTimes = () => {
        const { translations, settings } = this.props;

        let orderDates = [];

        let currentHour = new Date().getHours();
        let currentMinutes = new Date().getMinutes();
        let currentDayInt = new Date().getDay();
        if (currentDayInt == 0) {
            currentDayInt = 7
        }

        function addDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        // For each 5 days shown
        for (let i = 0; i < 5; i++) {
            let eachDate = addDays(new Date(), i);

            let eachDateValue = Utils.appendLeadingZeroes(parseInt(parseInt(eachDate.getMonth()) + 1)) + '.' + Utils.appendLeadingZeroes(eachDate.getDate());
            let eachDateLabel = Utils.appendLeadingZeroes(eachDate.getDate()) + '.' + Utils.appendLeadingZeroes(parseInt(parseInt(eachDate.getMonth()) + 1));

            let disabledOrderingEachDay = Utils.disabledOrdering(settings, eachDate);
            if (disabledOrderingEachDay) {
                continue;
            }

            let label = eachDateLabel;

            let showDayInt = parseInt(currentDayInt) + parseInt(i);
            if (showDayInt > 7) showDayInt -= 7;

            if (i == 0) {
                label = translations.common && translations.common.date && translations.common.date.today ? translations.common.date.today + ' /' + label + '/' : '';
            } else if (i == 1) {
                label = translations.common && translations.common.date && translations.common.date.tommorow ? translations.common.date.tommorow + ' /' + label + '/' : '';
            } else {
                label = translations.common && translations.common.date && translations.common.date.day && translations.common.date.day[showDayInt] ? translations.common.date.day[showDayInt] + ' /' + label + '/' : '';
            }

            let eachDateHours = [];
            if (i == 0) {
                eachDateHours.push('сега')
            }
            let minorderTimeHours = 0;
            let minorderTimeMinutes = 0;
            let maxorderTimeHours = 0;
            let maxorderTimeMinutes = 0;
            let currDayWorkingHours = Utils.getSettingsValue(settings, 'working_hours')[showDayInt];

            if (currDayWorkingHours && !disabledOrderingEachDay) {

                currDayWorkingHours = currDayWorkingHours.split('-');
                let minorderTime = currDayWorkingHours[0];
                minorderTimeHours = parseInt(minorderTime.split(':')[0]);
                minorderTimeMinutes = parseInt(minorderTime.split(':')[1]);

                let maxorderTime = currDayWorkingHours[1];
                maxorderTimeHours = parseInt(maxorderTime.split(':')[0]);
                maxorderTimeMinutes = parseInt(maxorderTime.split(':')[1]);

                // For each hour
                for (let j = 0; j <= (maxorderTimeHours - minorderTimeHours); j++) {

                    // If today and current time is bigger than other times - Skip them
                    if (i == 0 && currentHour > (minorderTimeHours + j)) {
                        continue;
                    }

                    // For each 15 mins 
                    for (let k = 0; k < 60; k += 15) {

                        let minK = 0;
                        let maxK = 60;
                        if (j == 0) {
                            minK = minorderTimeMinutes;
                        }
                        if (j == (maxorderTimeHours - minorderTimeHours)) {
                            maxK = maxorderTimeMinutes;
                        }

                        if ((k >= minK) && (k <= maxK)) {

                            if (currentHour == (minorderTimeHours + j) && (currentMinutes > k)) {
                                continue;
                            }

                            eachDateHours.push(minorderTimeHours + j + ':' + (Utils.appendLeadingZeroes(k)))
                        }

                    }

                }

                orderDates.push({
                    value: eachDateValue,
                    label: label,
                    eachDateHours: eachDateHours
                })
            }
        }

        // Remove first 2 rows for 15 mins, except "now"
        if (orderDates && orderDates[0] && orderDates[0].eachDateHours && orderDates[0].eachDateHours[1]) {
            delete orderDates[0].eachDateHours[1];
        }

        // Remove first 2 rows for 15 mins, except "now"
        if (orderDates && orderDates[0] && orderDates[0].eachDateHours && orderDates[0].eachDateHours[2]) {
            delete orderDates[0].eachDateHours[2];
        }

        let selectedDate = 0;
        if (this.state.orderDate) {
            selectedDate = orderDates.findIndex(d => d.value == this.state.orderDate)
        }

        let defaultOrderDate = '';
        let defaultOrderTime = '';
        defaultOrderDate = orderDates && orderDates[0] && orderDates[0].value ? orderDates[0].value : ''

        defaultOrderTime = Utils.getSettingsValue(settings, 'required_delivery_pickup_times') != '1' ?
            (orderDates && orderDates[selectedDate] && orderDates[selectedDate].eachDateHours && orderDates[selectedDate].eachDateHours[0] ? orderDates[selectedDate].eachDateHours[0] : '')
            : '';

        this.orderDates = orderDates;
        this.defaultOrderDate = defaultOrderDate;
        this.defaultOrderTime = defaultOrderTime;

        if (!this.state.orderDate && !this.state.orderTime && Utils.getSettingsValue(settings, 'required_delivery_pickup_times') != '1') {
            this.setState({ orderDate: defaultOrderDate, orderTime: defaultOrderTime });
        } else if (!this.state.orderDate) {
            this.setState({ orderDate: defaultOrderDate });
        } else if (!this.state.orderTime && Utils.getSettingsValue(settings, 'required_delivery_pickup_times') != '1') {
            this.setState({ orderTime: defaultOrderTime });
        }

    }

    onChange = (e) => {
        if (e.target.name == 'orderDate') {
            this.setState({
                [e.target.name]: e.target.value, orderTime:
                    (this.orderDates
                        && this.orderDates[this.orderDates.findIndex(d => d.value == e.target.value)]
                        && this.orderDates[this.orderDates.findIndex(d => d.value == e.target.value)].eachDateHours
                        && this.orderDates[this.orderDates.findIndex(d => d.value == e.target.value)].eachDateHours[0]
                        ? this.orderDates[this.orderDates.findIndex(d => d.value == e.target.value)].eachDateHours[0]
                        : this.state.orderTime
                    )
            })
        } else {
            this.setState({ [e.target.name]: e.target.value });
            this.state.formSubmissionFailed ? this.isFormValid({ [e.target.name]: e.target.value }) : void (0);
        }

    }

    onChangeCheckbox = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
        this.state.formSubmissionFailed ? this.isFormValid({ [e.target.name]: e.target.checked }) : void (0);
    }

    render() {
        const { classes, fullWidth = false, maxWidth = 'xs', open, onClose, onSubmit, onError, isLoadingOrder, settings, modalData, translations, productsToOrder, productIngredients, chosenPlace, regions, currOpenedModal } = this.props;
        const userToken = Auth.getUserToken();
        const default_currency_suffix = (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.')

        // Prices
        const currentPrice = chosenPlace ? parseFloat(Utils.calculateTotalPriceForOrder(productsToOrder, settings, productIngredients)) : null;
        const orderTypeDiscount = chosenPlace ? Utils.getOrderTypeDiscount(chosenPlace, currOpenedModal, currentPrice, 1) : null;
        const orderTypePrice = chosenPlace ? Utils.getOrderTypePrice(chosenPlace, currOpenedModal, currentPrice, 1) : null;
        const finalPrice = chosenPlace ? Utils.calculateFinalPriceForOrder(currOpenedModal, productsToOrder, settings, productIngredients, regions, this.state.regionId, chosenPlace) : null;
        // 

        let selectedRegionDeliveryPrice = 0;
        if (this.props.regions.length > 0 && this.state.regionId) {
            let selectedRegion = (this.props.regions).find(r => r.id == this.state.regionId);
            selectedRegionDeliveryPrice = selectedRegion && selectedRegion.delivery_price ? selectedRegion.delivery_price : selectedRegionDeliveryPrice
        }

        let currentOrderType = 2;
        if (this.props.currOpenedModal == 'spot') {
            currentOrderType = 1;
        } else if (this.props.currOpenedModal == 'delivery') {
            currentOrderType = 2;
        } else if (this.props.currOpenedModal == 'preorder') {
            currentOrderType = 3;
        } else if (this.props.currOpenedModal == 'room') {
            currentOrderType = 6;
        }

        const multipleOptionsModal = this.props.chosenPlaceOrderTypes.length > 1;
        const isMultipleOptionsModal = (multipleOptionsModal && this.state.modalType == 0) && this.props.scan_modal_open == 0;

        let disabledOrdering = Utils.disabledOrdering(settings);

        // 

        return (
            <React.Fragment>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={onClose}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    className={classes.modal}
                    data-class="dialog-container"
                >
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} style={{ textAlign: 'center', color: 'white', boxShadow: '0 1px 5px 0px black' }} data-class="dialog-title-container">
                        {this.props.currOpenedModal == 'spot' ? (translations.pages && translations.pages.cart_delivery ? <span style={this.props.currOpenedModal == 'spot' ? {} : { display: 'none' }}>{translations.pages.cart_delivery.scan_barcode}</span> : '') : ''}
                        {this.props.currOpenedModal == 'delivery' ? (translations.pages && translations.pages.cart_delivery ? <span style={this.props.currOpenedModal == 'delivery' ? {} : { display: 'none' }}>{translations.pages.cart_delivery.order_type_delivery}</span> : '') : ''}
                        {this.props.currOpenedModal == 'preorder' ? (translations.pages && translations.pages.cart_delivery ? <span style={this.props.currOpenedModal == 'preorder' ? {} : { display: 'none' }}>{translations.pages.cart_delivery.order_type_preorder}</span> : '') : ''}
                        {this.props.currOpenedModal == 'room' ? (translations.pages && translations.pages.cart_delivery ? <span style={this.props.currOpenedModal == 'room' ? {} : { display: 'none' }}>{translations.pages.cart_delivery.order_type_room}</span> : '') : ''}
                        {this.props.currOpenedModal == 'multiple' ? (translations.pages && translations.pages.cart_delivery ? <span style={this.props.currOpenedModal == 'multiple' ? {} : { display: 'none' }}>{translations.pages.cart_delivery.choose_order_type}</span> : '') : ''}
                    </DialogTitle>
                    <DialogContent style={this.props.currOpenedModal == 'multiple' || this.props.currOpenedModal == 'spot' ? { padding: 0 } : {}}>

                        <Backdrop open={isLoadingOrder} className={classes.backdrop}>
                            <CircularProgress className="is-loading" />
                        </Backdrop>


                        {(this.props.currOpenedModal == 'delivery' && Utils.getSettingsValue(settings, 'delivery_modal_info_text') && (Utils.getSettingsValue(settings, 'delivery_modal_info_text_position') == 'header')) ?
                            <div style={{ textAlign: 'center', marginTop: 25 }}
                                dangerouslySetInnerHTML={{
                                    __html: Utils.getSettingsValue(settings, 'delivery_modal_info_text')
                                }}></div>
                            :
                            null
                        }

                        {(this.props.currOpenedModal == 'preorder' && Utils.getSettingsValue(settings, 'preorder_modal_info_text') && (Utils.getSettingsValue(settings, 'preorder_modal_info_text_position') == 'header')) ?
                            <div style={{ textAlign: 'center', marginTop: 25 }}
                                dangerouslySetInnerHTML={{
                                    __html: Utils.getSettingsValue(settings, 'preorder_modal_info_text')
                                }}></div>
                            :
                            null
                        }


                        {this.props.currOpenedModal == 'multiple' ?
                            <React.Fragment>
                                {disabledOrdering ?
                                    <div className={classes.orderDisabledContainer}>
                                        {Utils.getDisabledOrderingMessage(translations, settings)}
                                    </div>
                                    :
                                    null
                                }

                                <div className={classes.isMultipleOptionsModalContainer}>
                                    {this.getOrderOptions(currentPrice, selectedRegionDeliveryPrice)}
                                </div>

                            </React.Fragment>
                            :
                            this.props.currOpenedModal == 'spot' || this.props.scan_modal_open ?
                                <OrderModalSpot
                                    onClose={this.props.onClose}
                                    onError={this.props.onError}
                                    handleScanError={this.handleScanError}
                                    handleScan={this.handleScan}
                                    orderMessage={this.props.orderMessage}
                                    orderSent={this.props.orderSent}
                                />
                                :
                                this.props.currOpenedModal == 'delivery' ?
                                    <OrderModalDelivery
                                        onChange={(e) => this.onChange(e)}
                                        onChangeCheckbox={(e) => this.onChangeCheckbox(e)}
                                        onClick={(name, value) => this.setState({ [name]: value })}
                                        onError={this.props.onError}
                                        omdata={this.state}
                                        orderDates={this.orderDates}
                                        defaultOrderDate={this.defaultOrderDate}
                                        defaultOrderTime={this.defaultOrderTime}
                                        orderMessage={this.props.orderMessage}
                                        orderSent={this.props.orderSent}
                                    />
                                    :
                                    this.props.currOpenedModal == 'preorder' ?
                                        <OrderModalPreorder
                                            onChange={(e) => this.onChange(e)}
                                            onChangeCheckbox={(e) => this.onChangeCheckbox(e)}
                                            onClick={(name, value) => this.setState({ [name]: value })}
                                            onError={this.props.onError}
                                            omdata={this.state}
                                            orderDates={this.orderDates}
                                            defaultOrderDate={this.defaultOrderDate}
                                            defaultOrderTime={this.defaultOrderTime}
                                            orderMessage={this.props.orderMessage}
                                            orderSent={this.props.orderSent}
                                        />
                                        :
                                        this.props.currOpenedModal == 'room' ?
                                            <OrderModalRoom
                                                onChange={(e) => this.onChange(e)}
                                                onChangeCheckbox={(e) => this.onChangeCheckbox(e)}
                                                onClick={(name, value) => this.setState({ [name]: value })}
                                                onError={this.props.onError}
                                                omdata={this.state}
                                                orderDates={this.orderDates}
                                                defaultOrderDate={this.defaultOrderDate}
                                                defaultOrderTime={this.defaultOrderTime}
                                                orderMessage={this.props.orderMessage}
                                                orderSent={this.props.orderSent}
                                            />
                                            :
                                            null
                        }

                        {/* {this.props.currOpenedModal != 'multiple' && this.props.currOpenedModal != 'spot' ?
                            <br />
                            :
                            null
                        } */}

                        {this.props.currOpenedModal != 'multiple' && this.props.currOpenedModal != 'spot' ?
                            <div className={classes.priceOrderContainer}>
                                <p>
                                    <span>{translations && translations.common && translations.common.subtotal ? translations.common.subtotal : ''}:</span>
                                    <span>{currentPrice.toFixed(2) + ' ' + default_currency_suffix}</span>
                                </p>

                                {orderTypeDiscount && Object.keys(orderTypeDiscount).length > 0 ?
                                    <p>
                                        <span>{translations && translations.common && translations.common.discount ? translations.common.discount : ''}:</span>
                                        <span>{orderTypeDiscount.value_type == 'money' ? orderTypeDiscount.value : parseInt(orderTypeDiscount.value)}{orderTypeDiscount.value_type == 'money' ? ' ' + default_currency_suffix : '%'}</span>
                                    </p>
                                    :
                                    null
                                }

                                {this.props.currOpenedModal == 'delivery' ?
                                    <p>
                                        <span>{translations && translations.common && translations.common.delivery ? translations.common.delivery : ''}:</span>
                                        <span>{orderTypePrice.value ? (parseFloat(orderTypePrice.value) == 0 ? (translations && translations.common && translations.common.free_del ? translations.common.free_del : '') : (orderTypePrice.value + ' ' + default_currency_suffix)) : selectedRegionDeliveryPrice + ' ' + default_currency_suffix}</span>
                                    </p>
                                    :
                                    null
                                }

                                <p style={{ fontWeight: 'bold' }}>
                                    <span>{translations && translations.common && translations.common.final_price ? translations.common.final_price : ''}:</span>
                                    <span>{finalPrice} {default_currency_suffix}</span>
                                </p>
                            </div>
                            :
                            null
                        }


                        {(this.props.currOpenedModal == 'delivery' && Utils.getSettingsValue(settings, 'delivery_modal_info_text') && (Utils.getSettingsValue(settings, 'delivery_modal_info_text_position') == 'footer')) ?
                            <div style={{ textAlign: 'center' }}
                                dangerouslySetInnerHTML={{
                                    __html: Utils.getSettingsValue(settings, 'delivery_modal_info_text')
                                }}></div>
                            :
                            null
                        }

                        {(this.props.currOpenedModal == 'preorder' && Utils.getSettingsValue(settings, 'preorder_modal_info_text') && (Utils.getSettingsValue(settings, 'preorder_modal_info_text_position') == 'footer')) ?
                            <div style={{ textAlign: 'center' }}
                                dangerouslySetInnerHTML={{
                                    __html: Utils.getSettingsValue(settings, 'preorder_modal_info_text')
                                }}></div>
                            :
                            null
                        }


                    </DialogContent>
                    {
                        this.props.currOpenedModal != 'multiple' && this.props.currOpenedModal != 'spot' ?
                            <DialogActions style={{ display: 'block' }}>

                                {!Auth.getTokenOrderType() && this.props.chosenPlaceOrderTypes.length > 1 ?
                                    <Button
                                        id="selected-order-type-modal-cancel-button"
                                        onClick={() => {
                                            this.setState({ errors: '' });
                                            multipleOptionsModal ? this.setState({ modalType: 0 }) : onClose();
                                            this.props.closeScanModal();
                                            this.props.setCurrOpenedModal('multiple')
                                        }}
                                        style={{ color: 'black' }}
                                    >
                                        {multipleOptionsModal ? (translations.common ? translations.common.action.back : '') : (translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.cancel : '')}
                                    </Button>
                                    :
                                    null
                                }

                                {this.props.currOpenedModal != 'spot' ?
                                    <Button
                                        id="selected-order-type-modal-submit-button"
                                        onClick={() => {
                                            Utils.disabledOrdering(settings) ? this.props.onError(Utils.getDisabledOrderingMessage(translations, settings)) : (this.isFormValid() ? onSubmit(this.state, currentOrderType) : void (0))
                                            this.state.rememberMe ? this.saveOrderInfoToLocal() : this.removeOrderInfoFromLocal();
                                        }}
                                        style={{ color: '#FE6902', fontWeight: 'bold', float: 'right' }}
                                    >
                                        {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.make_order : ''}
                                        {/* {Utils.calculateFinalPriceForOrder(this.props.currOpenedModal, this.props.productsToOrder, this.props.settings, this.props.productIngredients, this.props.regions, this.state.regionId, this.props.chosenPlace, 1)} */}
                                    </Button>
                                    :
                                    null
                                }
                            </DialogActions>
                            :
                            null
                    }

                </Dialog >

            </React.Fragment >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let chosenPlaceOrderTypes = [];
    let chosenPlaceId = state.places.chosen_place;
    let chosenPlace = state.places.places.filter(p => p.id == chosenPlaceId);
    if (chosenPlace.length == 0) {
        chosenPlace = null;
    } else {
        chosenPlace = chosenPlace[0];
        chosenPlaceOrderTypes = chosenPlace.ordertypes;
    }

    return {
        isLoadingOrder: state.products.isLoadingOrder || false,
        settings: state.settings.settings || [],
        scan_modal_open: state.scan.scan_modal_open || 0,
        currOpenedModal: state.scan.curr_opened_modal || 'multiple',
        places: state.places.places || [],
        chosenPlace: chosenPlace,
        chosenPlaceOrderTypes: chosenPlaceOrderTypes,
        regions: state.regions.regions || [],
        productsToOrder: Utils.normalizeCartProducts(state.products.to_order) || [],
        productIngredients: state.products.productIngredients || [],
        translations: state.lang.translations,
        loggedUser: state.users.user && Object.keys(state.users.user).length !== 0 ? state.users.user : null,
        orderMessage: state.products.order_message || '',
        orderSent: state.products.order_sent || null,
    };
};

export default withStyles(styles)(connect(mapStateToProps, { verifyTokenAndSession, closeScanModal, setCurrOpenedModal, setOrderModalState, verifyOpenedPlace, setAlertMessage, setChosenPlace })(DeliveryModal));