import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import RatingStars from './RatingStars'
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Input, createMuiTheme, MuiThemeProvider } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        '& .MuiRating-iconEmpty': {
            color: 'rgba(0, 0, 0, 0.5)',
        },
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            color: '#ff6a00',
        },
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            color: 'rgba(255, 106, 0, 0.72)',
        },
        '& .MuiStepConnector-root.MuiStepConnector-vertical.Mui-disabled': {
            paddingBottom: 0,
        },
        '& .MuiStepConnector-lineVertical': {
            minHeight: 15,
            marginBottom: 7,
        },
        // Step label
        '& .MuiStepLabel-label': {
            fontSize: '1rem',
        },
        // '& .MuiStepLabel-label.MuiStepLabel-active': {
        //     fontWeight: 'bold',
        // },
        // '& .MuiStepConnector-root.MuiStepConnector-vertical.MuiStepConnector-completed': {
        '& .MuiStepConnector-vertical': {
            paddingBottom: 0,
        },
    },
    stepperContainer: {
        backgroundColor: 'rgba(213, 213, 213, .8)',
        borderRadius: 11,
        boxShadow: '0px 3px 12px -7px black',
        marginBottom: 20,
        marginTop: 10,
        borderBottom: '1px solid #FE6902',
    },
    questionContainer: {
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        },
    },
    
    questionOptionsContainer: {
        '@media screen and (max-width: 350px)': {
            marginLeft: -25,
            border: 'none'
        },
    },

    textFieldAnswer: {
        '& .MuiInputBase-input': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        },
    },

    skipSendButton: {
        marginBottom: 15,

        '& .MuiButton-label': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        },
        '& .MuiButton-text': {
            padding: 0,
        },
    },

    resetContainer: {
        backgroundColor: 'transparent',
        padding: theme.spacing(3),
        paddingTop: 0,
    },
    resetContainerText: {
        fontWeight: 'bold',
        color: '#212121',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
}));

const color = "#333";
const theme = createMuiTheme({
    palette: {
        common: { black: color, white: color },
        primary: { main: color, dark: color, light: color },
        text: { primary: color, secondary: color }
    },
    overrides: {
        MuiInput: {
            underline: {
                "&:before": {
                    borderBottom: `1px solid #f2f2f2`
                }
            }
        }
    }
});

const QuestionContainer = (props) => {
    const { translations } = props;
    const [activeButton, setActiveButton] = React.useState(false);
    const [textValue, setTextValue] = React.useState();
    const classes = useStyles();

    return (
        <React.Fragment>
            {props.type === 'stars' ?
                <RatingStars
                    id={props.qId}
                    handleAnswerFill={(e, newV, a, b) => { props.handleAnswerFill(e, newV, a, b); setTimeout(() => props.handleNext(e, newV), 75); }}
                />
                :
                <React.Fragment>
                    <MuiThemeProvider theme={theme}>
                        <TextField id="standard-basic" label={translations && translations.common ? translations.common.label.describe_here : ''} className={classes.textFieldAnswer} style={{ width: '100%', marginTop: 0, marginBottom: 15, maxWidth: 300, paddingRight: 15, color: 'white' }} onChange={(e) => { setTextValue(e.target.value); setActiveButton(e.target.value.length > 0) }} />
                    </MuiThemeProvider>
                    <Button size="medium" style={{ verticalAlign: 'bottom' }} className={classes.skipSendButton} onClick={(e) => { props.handleAnswerFill(e, 'text', textValue, props.qId); props.handleNext(); }}>
                        {activeButton ? (translations && translations.common ? translations.common.action.send : '') : (translations && translations.common ? translations.common.action.skip : '')}
                    </Button>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default function VerticalLinearStepper(props) {
    const { translations } = props;

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (e, newV) => {
        if (parseInt(activeStep) + 1 === Object.keys(props.feedbackQuestions).length) {
            props.handleFormSubmit();
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} className={classes.stepperContainer} orientation="vertical">
                {props.feedbackQuestions.map((question, k) =>
                    <Step key={k}>
                        <StepLabel className={classes.questionContainer}>{question.text}</StepLabel>
                        <StepContent className={classes.questionOptionsContainer}>
                            <QuestionContainer handleNext={handleNext} k={k} type={question.type} qId={question.id} handleAnswerFill={props.handleAnswerFill} translations={translations} />
                        </StepContent>
                    </Step>
                )}
            </Stepper>
            {activeStep === Object.keys(props.feedbackQuestions).length &&
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Fade in={true} timeout={{ enter: 800, exit: 550 }}>
                        <Typography className={classes.resetContainerText}>{translations.messages ? translations.messages.notifications.feedback_thanks : ''}</Typography>
                    </Fade>
                </Paper>
            }
        </div>
    );
}
