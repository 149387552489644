export const bgTranslations = {
    navbar: {
        restaurant_location: "Упътване до обекта",
        make_reservations: "Направи резервация",
        search_product: "Търсене на продукт",
        call_waiter: "Повикай сервитьор",
        change_language: "Смяна на език",
        feedback: "Оцени ни",
        about_tabl: "За TABL",
    },

    pages: {
        products: {
            choose: "Избери",
            choose_ingredients: "Избери съставките",
            choose_variant: "Моля изберете",
            alergens: "Алергени",
            out_of_stock: "Неналичен",
            variant_not_choosed: "Не сте избрали вариант!",
            min_count_addable_ingredients_reached: "Трябва да изберете поне {value} добавки!",
            max_count_addable_ingredients_reached: "Не може да изберете повече от {value} добавки!",
            additional_options: "Допълнителни опции",
            addable_options: "Добави",
            removable_options: "Премахни",
            comment: "Коментар към продукта",
            related_products: "Други предложения",
            add: "Добави",
            add_to_cart: "Добави в количката", // in context 'add to cart'
            choose_size: "Изберете",
            save_for_order: "Запази за поръчка",
            order_disabled: "В момента този продукт не може да бъде поръчан!",
        },
        change_language: {
            title: "Моля изберете език",
        },
        call_waiter: {
            clean_table: "Моля почистете масата",
            new_cutlery: "Моля за нови прибори",
            more_napkins: "Моля за повече салфетки",
            change_ashtray: "Моля за смяна на пепелник",
            bring_ice: "Моля донесете лед",
            other: "Друго",
        },
        feedback: {
            title: "Отделете минутка, за да ни оцените",
            customer_reviews: "Мнения на клиенти",
            no_reviews_yet: "Бъди един от първите, оценили тази услуга."
        },
        about_tabl: {
            title: "Тази услуга достига до Вас благодарение на ",
            sub_title: "При необходимост от повече информация, въпроси или препоръки относно TabL, моля свържете се с нас.",
            about_link: "https://tabl.bg/"
        },
        cart_delivery: {
            minimal_order_price: "Минималната цена за поръчка е {value}",
            price_according_to_region: "Цена според региона",
            payment_type_room: "Начин на плащане при сервиране",
            payment_type_pickup: "Начин на плащане при взимане от обекта",
            payment_type_delivery: "Начин на плащане при доставка",
            cutlery_needed: "Желаете ли прибори?",
            cutlery_needed_yes: "Да, бих желал",
            cutlery_needed_no: "Не, благодаря",
            region: "Регион",
            // you_can_order_between: "Може да поръчате от {from} до {to}",
            call_waiter_to_order: "Моля извикайте сервитьора, за да поръчате",
            you_can_order_between: "Може да поръчате от {from} до {to} и от {from_two} до {to_two}",
            restaurant_closed_label: "В момента не приемаме поръчки. Благодарим за разбирането!",
            restaurant_closed_label_day: "В момента не приемаме поръчки, моля опитайте отново {day}: {value}",
            restaurant_closed_label_tommorow: "В момента не приемаме поръчки, моля опитайте отново утре в {value}",
            restaurant_closed_label_today: "В момента не приемаме поръчки, моля опитайте отново по-късно в {value}",
            delivery_price: "Цена на доставка",
            delivery_price_is_not_included: "+ цена на доставка",
            cart: "Количка",
            no_cart_items: "Няма добавени продукти в количката",
            orders: "Поръчки",
            order: "Поръчка",
            min_delivery_price_text: 'При поръчки над {value} доставката е безплатна',
            no_order_items: "Нямате активни поръчки към момента",
            total: "Цена",
            total_bill: "Всичко",

            status_ordered: 'Приета',
            status_cooked_spot: 'Приготвена',
            status_cooked_delivery: 'Приготвена',
            status_cooked_preorder: 'Чака клиент',
            status_served_spot: 'Сервирана',
            status_on_the_way_delivery: 'Доставя се',
            status_paid: 'Завършена',

            pay_in_cash: "Плати в брой",
            pay_with_card: "Плати с карта",
            make_order: "Поръчай",
            want_the_bill: "Поискай сметката",
            finish_bill: "Приключи сметката",
            make_delivery_order: "Поръчай с доставка",
            choose_order_type: "Изберете начин на поръчка",
            choose_payment_type: "Изберете начин на плащане",
            payment_cash: "В брой",
            payment_card: "С карта",
            delivery_price_included_in_total_price: "С включена цена на доставка {delivery_price_num} за поръчки до {min_price_free_delivery_num}",
            order_type_restaurant: "В ресторанта",
            scan_barcode: "Сканирай QR кода",
            order_type_delivery: "Доставка до вкъщи",
            order_type_preorder: "Вземи от обекта",
            order_type_room: "Поръчай от стаята",
            order_from_room: "Поръчка от стая",
            first_and_last_name: "Име и Фамилия",
            phone: "Тел. номер",
            order_address: "Адрес: ул. № ет. ап. гр.",
            delivery_time: "Дата и час за получаване",
            delivery_address: "Адрес за получаване",
            room_number: "Номер на стая",
            table: "Маса",
            room: "Стая",
            email: "Имейл",
            remember_me: "Запомни ме",
            promo_code: "Промокод",
            promo_code_placeholder: "Промокод...",
            apply: "Приложи",
            cancel: "Откажи",
            bill_already_wanted: "/сметката ви е поискана/",
            clear: "Изчисти",
            close: "Затвори",
            save: "Запази",
            fast_order: "Fast order", // NOT TRANSLATED
            in_the_region_delivery: "Намирам се в района на {region}",
        },
        search: {
            searchProduct: "Търси продукт",
            noSearchResults: "Няма намерени продукти",
            noSearchDescription: "Моля променете критерийте, за да намерите подходящия за Вас продукт!",
        },
        choose_place: {
            title: "Моля, изберете локация"
        },
        promotions: {
            title: "Промоция"
        },
        profile: {
            title: "Моят профил",
            fullname: "Име и Фамилия",
            first_name: "Име",
            last_name: "Фамилия",
            email: "Имейл",
            phone: "Телефон",
            address: "Адрес",
            change_password: "Смени парола",
            password: "Парола",
            new_password: "Нова Парола",
            tabl_points: "TABL точки",
            tabl_points_info: "Събирай TABL точки с всяка поръчка и бъди готов за супер изненади ;)",
            my_profile: "Моят профил",
            profile: "Профил",
            logout: "Изход",
        }
    },
    orders: {
        statuses: {
            ordered: "Поръчана",
            cooked_spot: 'Приготвена',
            served_spot: 'Сервирана',
            cooked_delivery: 'Приготвена',
            on_the_way_delivery: 'Доставя се',
            cooked_preorder: 'Приготвена',
            cooked_room: "Приготвена",
            paid: "Платена",
        },
    },
    common: {
        hi: "Здравей",
        home: "Начало",
        free_del: "Безплатна", // А не "безплатен"
        subtotal: "Междинна сума",
        final_price: "Крайна цена",
        no_internet: "Липсва връзка с интернет.",
        count: 'бр.',
        box: "кутия",
        like_us: "Харесай ни",
        follow_us: "Последвай ни",
        youtube: "YouTube",
        tipadvisor: "Tripadvisor",
        about: 'За',
        about_us: 'За нас',
        discount: 'Отстъпка',
        delivery: 'Доставка',
        book: 'Резервирай',
        language: 'Език',
        navigate_me: "Заведи ме",
        navigation: 'Навигация',
        search: 'Търси',
        call_us: 'Обади се',
        options: 'Опции',
        product: "продукт",
        products: "продукта",
        user: "Потребител",
        comment: "Коментар",
        contacts: "Контакти",
        working_hours: "Работно време",
        payment_methods: "Начин на плащане",
        gallery: "Галерия",
        change_table: "Смяна на маса",
        clear_cart: "Изчисти",
        modify_ingredients: "Промени съставките",
        about_page_facilities: "Удобства",
        about_page_household: "Домакинство",
        about_page_technical_support: "Техническа поддръжка",
        date: {
            today: "Днес",
            tommorow: "Утре",
            day: {
                1: 'Понеделник',
                2: 'Вторник',
                3: 'Сряда',
                4: 'Четвъртък',
                5: 'Петък',
                6: 'Събота',
                7: 'Неделя',
            }
        },

        action: {
            send: "Изпрати",
            skip: "Пропусни",
            back: "Назад",
        },

        label: {
            describe_here: "Опишете тук",
        }
    },

    messages: {
        notifications: {
            waiter_notified_successfully: 'Сервитьора е нотифициран успешно',
            order_sent_success: "Поръчката ви е приета успешно.",
            feedback_thanks: "Благодарим за отделеното време!",
            bill_wanted_success: 'Вашата сметка е поискана успешно.',
            user_need_success: 'Вашата заявка е получена и се обработва',
            create_order_first: "Необходимо е първо да направите поръчка",
            bill_already_wanted: "Вашата сметка е вече поискана",
        },
        errors: {
            invalid_privacy_policy_and_terms: "Необходимо е да се съгласите с политиката ни за поверителност и общите условия",
            invalid_privacy_policy: "Необходимо е да се съгласите с политиката ни за поверителност",
            invalid_terms: "Необходимо е да се съгласите с общите условия",
            invalid_email: 'Моля въведете валиден Имейл',
            invalid_password: 'Моля въведете валидна Парола',
            invalid_paddword_min: 'Моля въведете Парола поне {value} символа',
            invalid_name: 'Моля въведете валидно Име /от 3 до 40 символа/',
            invalid_phone: 'Моля въведете валиден Телефон',
            invalid_phone_min: 'Моля въведете Телефон поне {value} символа',
            invalid_address: 'Моля въведете валиден Адрес',
            invalid_regionId: 'Моля изберете Регион',
            invalid_orderDate: 'Моля въведете Дата на получаване',
            invalid_orderTime: 'Моля въведете Час на получаване',
            invalid_room_number: 'Моля въведете Номер на стая',
            invalid_payment_method: 'Моля изберете Метод на плащане',
            invalid_cutlery_selected: "Моля изберете желаете ли прибори",
            invalid_fields: "Моля попълнете всички полета коректно",
            order_sent_fail: "Mоля сканирайте отново!",
            bill_wanted_fail: "Упс, сметката не може да бъде поискана!",
            user_need_fail: 'Упс, възникна грешка при изпращането на заявката',
        }
    },

    modals: {
        upselling_products: {
            title: "А защо не пробваш и"
        }
    }
}