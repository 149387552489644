import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
// import FeedbackAnswersList from './FeedbackAnswersList';
// import FeedbackQuestions from './FeedbackQuestions';
import Fade from '@material-ui/core/Fade';
import { getFeedbackQuestions, sendFeedback, getFeedbackAnswers } from '../../../feedback/actions/feedback.actions';
import { clientRequest } from '../../../client/client.actions';
import { setMainBackground } from '../../../settings/actions/settings.action';
import { } from '../../../settings/actions/settings.action';
import { showHeaderCategory } from './../../../categories/actions/categories.action'
import Utils from "../../../utils/Utils";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ReCAPTCHA from "react-google-recaptcha";
import MessagesContainer from './../../../messages/MessagesContainer';
import { setAlertMessage, setTermsDialogOpened, setPrivacyPolicyDialogOpened } from '../../../menu/menu.actions'


import clsx from 'clsx';

import { styles } from './../styles/styles';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from "@material-ui/core";

class ClientRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneCartLeftStyle: {
                top: 130,
                left: '70%',
                // right: '70%',
                // bottom: '20%',
            },
            phoneCartRightStyle: {
                top: 130,
                left: '70%',
                // right: '70%',
                // bottom: '20%',
            },

            owner_name: "",
            phone: "",
            email: "",
            address: "",
            place_type: "",
            name: "",
            recaptcha: "",
            terms: false,
            privacy_policy: false,
            images: []
        }
    }

    componentWillMount() {
        // this.props.getFeedbackQuestions();
        // this.props.getFeedbackAnswers();
    }


    componentDidMount() {
        const changeShadow = (evt) => {
            if (evt.gamma < 0 && evt.beta < 0) {
                // right-bottom
                let rightBottomShadow = '-1px -1px 0 #cccccc00, -2px -2px 0 #c9c9c900, -3px -3px 0 #bbbbbb00, -4px -4px 0 #b9b9b900, -5px -5px 0 #aaaaaa00, -6px -6px 1px rgba(0,0,0,0), 0 0 5px rgba(0,0,0,0), -1px -1px 3px rgba(0,0,0,0), -3px -3px 5px rgba(0,0,0,0), -5px -5px 10px rgba(0,0,0,0), -10px -10px 10px rgba(0,0,0,0), -20px -20px 20px rgba(0,0,0,0), transition: all 1s ease-in-out;'
                this.setState({
                    phoneCartLeftStyle: { top: 130, left: '70%', boxShadow: rightBottomShadow },
                    phoneCartRightStyle: { top: 130, left: '70%', boxShadow: rightBottomShadow }
                })
            }

            if (evt.gamma > 0 && evt.beta > 0) {
                // left-top
                let leftTopShadow = '1px 1px 0 #cccccc00, 2px 2px 0 #c9c9c900, 3px 3px 0 #bbbbbb00, 4px 4px 0 #b9b9b900, 5px 5px 0 #aaaaaa00, 6px 6px 1px rgba(0,0,0,0), 0 0 5px rgba(0,0,0,0), 1px 1px 3px rgba(0,0,0,0), 3px 3px 5px rgba(0,0,0,0), 5px 5px 10px rgba(0,0,0,0), 10px 10px 10px rgba(0,0,0,0), 20px 20px 20px rgba(0,0,0,0), transition: all ease-in-out .66s;';
                this.setState({
                    phoneCartLeftStyle: { top: 160, left: '75%', boxShadow: leftTopShadow },
                    phoneCartRightStyle: { top: 160, left: '75%', boxShadow: leftTopShadow }
                })
            }

            if (evt.gamma > 0 && evt.beta < 0) {
                // left-bottom
                let leftBottomShadow = '1px -1px 0 #cccccc00, 2px -2px 0 #c9c9c900, 3px -3px 0 #bbbbbb00, 4px -4px 0 #b9b9b900, 5px -5px 0 #aaaaaa00, 6px -6px 1px rgba(0,0,0,0), 0 0 5px rgba(0,0,0,0), 1px -1px 3px rgba(0,0,0,0), 3px -3px 5px rgba(0,0,0,0), 5px -5px 10px rgba(0,0,0,0), 10px -10px 10px rgba(0,0,0,0), 20px -20px 20px rgba(0,0,0,0), transition: all ease-in-out .66s;'
                this.setState({
                    phoneCartLeftStyle: { top: 130, left: '75%', boxShadow: leftBottomShadow },
                    phoneCartRightStyle: { top: 130, left: '75%', boxShadow: leftBottomShadow }
                })
            }

            if (evt.gamma < 0 && evt.beta > 0) {
                // right-top
                let rightTopShadow = '-1px 1px 0 #cccccc00, -2px 2px 0 #c9c9c900, -3px 3px 0 #bbbbbb00, -4px 4px 0 #b9b9b900, -5px 5px 0 #aaaaaa00, -6px 6px 1px rgba(0,0,0,0),  0 0 5px rgba(0,0,0,0), -1px 1px 3px rgba(0,0,0,0), -3px 3px 5px rgba(0,0,0,0), -5px 5px 10px rgba(0,0,0,0), -10px 10px 10px rgba(0,0,0,0), -20px 20px 20px rgba(0,0,0,0),  transition: all ease-in-out .66s;'
                this.setState({
                    phoneCartLeftStyle: { top: 160, left: '70%', boxShadow: rightTopShadow },
                    phoneCartRightStyle: { top: 160, left: '70%', boxShadow: rightTopShadow }
                })
            }
        }

        window.addEventListener("deviceorientation", changeShadow);
        // this.props.setMainBackground('linear-gradient( rgba(147, 147, 147, 0.6), rgba(147, 147, 147, 0.6) )');
    }

    onChange = (e, checkbox = 0) => {
        this.setState({ [e.target.name]: checkbox ? e.target.checked : (e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value) });
    }

    recaptchaOnChange = (val) => {
        this.setState({ recaptcha: val })
    }

    handleFormSubmit = () => {
        let post = {
            owner_name: this.state.owner_name,
            phone: this.state.phone,
            email: this.state.email,
            address: this.state.address,
            place_type: this.state.place_type,
            name: this.state.name,
            terms: this.state.terms,
            privacy_policy: this.state.privacy_policy,
            "g-recaptcha-response": this.state.recaptcha
        }

        this.props.clientRequest(post);

    }


    render() {
        const { classes, translations, settings } = this.props;

        // if (settings && settings.about_page_title && settings.about_page_title.value) { //Bugfixed
        this.props.showHeaderCategory(this.props.translations && this.props.translations.common ? this.props.translations.common.action.back : '');
        // }

        return (
            <React.Fragment>
                <MessagesContainer />

                <Fade in={true} timeout={{ enter: 350, exit: 350 }}>

                    <div className={classes.container}>
                        <br />
                        {/* ---{this.state.phoneCartLeftStyle.top}--- */}
                        <h2 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.feedBackTitle, classes.feedBackTitleDark) : classes.feedBackTitle}>
                            Станете част от най-добрите !
                        </h2>

                        <div className={classes.logosContainer}>
                            <img src='https://tabl.bg/img/partners/maisons-logo-light-1.png' style={{ width: '45%', maxWidth: 220, padding: '0 20px 0 0' }} />
                            <img src='https://tabl.bg/img/partners/raffy-logo.png' style={{ width: '15%', maxWidth: 100, padding: '0' }} />
                            <img src='https://tabl.bg/img/partners/bamboo-urban-logo.png' style={{ width: '25%', maxWidth: 130, padding: '0 0 0 20px' }} />
                        </div>

                        <br />
                        <hr />

                        <div style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}>
                            <div style={{ marginLeft: 20 }} className={classes.leftGridContainerForm}>


                                <TextField id="standard-basic" label="Вашите имена" name="owner_name" onChange={this.onChange} className={clsx(classes.textField, classes.fieldsWidth)} />
                                <br />
                                <TextField id="standard-basic" label="Телефон" type="tel" name="phone" onChange={this.onChange} className={clsx(classes.textField, classes.fieldsWidth)} />
                                <br />
                                <TextField id="standard-basic" label="Имейл" type="email" name="email" onChange={this.onChange} className={clsx(classes.textField, classes.fieldsWidth)} />
                                <br />
                                <TextField id="standard-basic" label="Град" name="address" onChange={this.onChange} className={clsx(classes.textField, classes.fieldsWidth)} />
                                <br />
                                {/* <TextField id="standard-basic" label="Тип обект" className={clsx(classes.textField, classes.fieldsWidth)} /> */}

                                <FormControl
                                    className={clsx(classes.selectField, classes.fieldsWidth)}
                                >
                                    <InputLabel id="demo-simple-select-label">Тип обект</InputLabel>
                                    <Select
                                        name="place_type"
                                        onChange={(e) => this.onChange(e, 0)}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.place_type}
                                    // onChange={hand   leChange}
                                    >
                                        <MenuItem value="">-</MenuItem>
                                        <MenuItem value={"restaurant"}>Ресторант</MenuItem>
                                        <MenuItem value={"bar"}>Бар</MenuItem>
                                        <MenuItem value={"hotel"}>Хотел</MenuItem>
                                        <MenuItem value={"other"}>Друго</MenuItem>
                                    </Select>
                                </FormControl>


                                <br />
                                <TextField id="standard-basic" label="Име на обект" name="name" onChange={this.onChange} className={clsx(classes.textField, classes.fieldsWidth)} />
                                <br />
                                {/* <TextField id="standard-basic" label="" className={classes.textField} /> */}
                                <br />

                                {/* 
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                /> 
                                */}

                                {/* 
                                <label htmlFor="contained-button-file">
                                    <Button variant="outlined" className={clsx(classes.uploadButton, classes.fieldsWidth)} component="span">
                                        Файлове / Меню
                                    </Button>
                                </label> 
                                */}


                                <FormControlLabel
                                    className="privacy-policy-checkbox"
                                    control={
                                        <Checkbox
                                            className={classes.checkboxWhite}
                                            name="privacy_policy"
                                            onClick={(e) => this.onChange(e, 1)}
                                        />
                                    }
                                    label={<span style={{ color: 'white' }}>Запознах се и съм съгласен с <Button onClick={() => this.props.setPrivacyPolicyDialogOpened(true)} className="underlined" style={{ display: 'contents', color: 'white' }} disableRipple>Политиките за поверителност</Button></span>}
                                />
                                {/* <FormHelperText style={{ color: '#f44336' }}>{customErrors && customErrors.privacy_policy ? customErrors.privacy_policy : ''}</FormHelperText> */}

                                <br />

                                <FormControlLabel
                                    className="terms-checkbox"
                                    control={
                                        <Checkbox
                                            className={classes.checkboxWhite}
                                            name="terms"
                                            onClick={(e) => this.onChange(e, 1)}
                                        />
                                    }
                                    label={<span style={{ color: 'white' }}>Съгласен съм с <Button onClick={() => this.props.setTermsDialogOpened(true)} className="underlined" style={{ display: 'contents', color: 'white' }} disableRipple>Условията за ползване</Button></span>}
                                />
                                {/* <FormHelperText style={{ color: '#f44336' }}>{customErrors && customErrors.terms ? customErrors.terms : ''}</FormHelperText> */}


                                <br />
                                <br />


                                <ReCAPTCHA
                                    sitekey="6LdZnOIZAAAAAP1Ive0iuunRcmKxzvNsOQVHXaam"
                                    onChange={this.recaptchaOnChange}
                                />
                                <br />


                            </div>
                            {/* <div> */}
                            <div className={classes.phoneCard}
                            //  className={clsx(classes.phoneCard, classes.phoneCardRight)}
                            //  style={this.state.phoneCartRightStyle}
                            ></div>
                            {/* </div> */}
                        </div>

                        <br />

                        <Button variant="outlined" className={classes.submitButton} onClick={this.handleFormSubmit}>Изпрати</Button>


                        <h2 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.feedBackTitle, classes.feedBackTitleDark) : classes.feedBackTitle}
                            style={{
                                background: '#000',
                                padding: '10px 0',
                                margin: '0 auto',
                                'margin-top': 50,
                                'max-width': 700,
                            }}
                        >
                            Или се свържете с нас
                            </h2>

                        <br />



                        <div className={classes.footerGrid}>
                            <div style={{ margin: 10 }}>
                                <a href="https://tabl.bg" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
                                    <h2 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.feedBackTitle, classes.feedBackTitleDark) : classes.feedBackTitle}>
                                        ДИГИТАЛНО МЕНЮ И СИСТЕМА ЗА ПОРЪЧКИ - TABL.BG
                                    </h2>

                                    <br />
                                    <img src={"https://tabl.bg/img/tabl-transparent-min.png"} style={{ width: 150, display: 'block', margin: '0 auto' }} />
                                    <br />
                                </a>
                            </div>
                            <div>

                                <h3 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.feedBackTitle, classes.feedBackTitleDark) : classes.feedBackTitle}>
                                    <a href="tel:+359898779277" style={{ color: 'white' }}>+359 898 779 277</a>
                                </h3>

                                <h3 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.feedBackTitle, classes.feedBackTitleDark) : classes.feedBackTitle}>
                                    <a href="tel:+359878801797" style={{ color: 'white' }}>+359 878 801 797</a>
                                </h3>

                                {/* <h3 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.feedBackTitle, classes.feedBackTitleDark) : classes.feedBackTitle}>
                                    <a href="tel:+359884014689" style={{ color: 'white' }}>+359 884 014 689</a>
                                </h3> */}

                                <h3 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.feedBackTitle, classes.feedBackTitleDark) : classes.feedBackTitle}>
                                    <a href="mailto:info@tabl.bg" style={{ color: 'white' }}>info@tabl.bg</a>
                                </h3>

                                {/* 
                                <a href="https://tabl.bg"
                                    // className={clsx(classes.submitButton, classes.fieldsWidth)}
                                    style={{
                                        color: 'black',
                                        'border-bottom': '1px solid #ccc',
                                        'text-decoration': 'none',
                                        padding: '10px 0px',
                                        // width: '132px',
                                        display: 'block',
                                        'text-align': 'center',
                                        'margin': '0 auto !important',
                                        // 'font-size': '17px',
                                    }}
                                >
                                    ПОВЕЧЕ ЗА ДИГИТАЛНО МЕНЮ TABL
                                </a> */}



                            </div>


                        </div>

                        {/* <div className={clsx(classes.phoneCard, classes.phoneCardLeft)} style={this.state.phoneCartLeftStyle}></div> */}



                        <Dialog
                            open={this.props.termsOpened}
                            onClose={() => this.props.setTermsDialogOpened(false)}
                            scroll={'paper'}
                        >
                            <DialogTitle>Условия за ползване</DialogTitle>
                            <DialogContent dividers={true}>
                                <DialogContentText>
                                    <div dangerouslySetInnerHTML={{ __html: Utils.getSettingsValue(settings, 'terms', 'Може да запознаете с общите условия на адрес: https://tabl.bg/terms.pdf') }}></div>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.props.setTermsDialogOpened(false)} color="primary">
                                    Затвори
						</Button>
                            </DialogActions>
                        </Dialog >

                        <Dialog
                            open={this.props.privacyPolicyOpened}
                            onClose={() => this.props.setPrivacyPolicyDialogOpened(false)}
                            scroll={'paper'}
                        >
                            <DialogTitle>Политика за поверителност</DialogTitle>
                            <DialogContent dividers={true}>
                                <DialogContentText>
                                    <div dangerouslySetInnerHTML={{ __html: Utils.getSettingsValue(settings, 'privacy_policy', 'Може да запознаете с политиката ни за поверителност на адрес: https://tabl.bg/privacy-policy.pdf') }}></div>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.props.setPrivacyPolicyDialogOpened(false)} color="primary">
                                    Затвори
						</Button>
                            </DialogActions>
                        </Dialog >


                    </div>
                </Fade>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state, ownProps) => {

    return {
        translations: state.lang.translations || [],
        settings: state.settings.settings || [],
        termsOpened: state.menu.termsOpened || false,
        privacyPolicyOpened: state.menu.privacyPolicyOpened || false,
        // message: state.feedback.message || null
    };
    // catName: state.name,
    // products: state.products
};

export default withStyles(styles)(connect(mapStateToProps, { showHeaderCategory, setMainBackground, clientRequest, setTermsDialogOpened, setPrivacyPolicyDialogOpened, setAlertMessage })(ClientRequest));
