import React, { useState, useEffect, Component } from 'react';
import { connect } from "react-redux";

import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SwipeableDrawer, Grow, CircularProgress, List, ListItem, ListItemText, IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ChevronRight } from '@material-ui/icons';

import { setLoginModalOpened, login, setRegisterModalOpened } from './../../actions/users.actions';
import { addErrorMessages, addSuccessMessage } from './../../../messages/messages.actions';
import { styles } from './../../styles/loginModal.css'
import LoginForm from './LoginForm';

class LoginModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (!!this.state.email && !!this.state.password) {
            this.props.login(this.state)
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    goToRegister = () => {
        this.props.setLoginModalOpened(false);
        this.props.setRegisterModalOpened(true);
    }

    render() {
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations, allProducts } = this.props;

        return (
            <React.Fragment>
                <SwipeableDrawer
                    open={this.props.loginModalOpened}
                    anchor={'right'}
                    onClose={() => this.props.setLoginModalOpened(false)}
                    onOpen={() => this.props.setLoginModalOpened(true)}
                    aria-labelledby="max-width-dialog-title"
                    data-class="dialog-container"
                    className={classes.root}
                    disableSwipeToOpen
                    swipeAreaWidth={0}
                >
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                        <IconButton onClick={() => this.props.setLoginModalOpened(false)} className={classes.closeDrawerButton}>
                            <ChevronRight />
                        </IconButton>

                        <div>
                            Влез в профила си
                            <div style={{ fontSize: 16 }}>
                                и запази своите данни
                            </div>
                        </div>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            <LoginForm
                                state={this.state}
                                handleChange={this.handleChange}
                                onSubmit={this.onSubmit}
                                translations={this.props.translations}
                                addErrorMessages={this.props.addErrorMessages}
                                addSuccessMessage={this.props.addSuccessMessage}
                            />

                            <List>
                                <ListItem button onClick={this.goToRegister} className={classes.goToRegister}>
                                    <ListItemText primary={'Нямаш профил? Регистрирай се'} />
                                </ListItem>
                            </List>
                        </DialogContentText>
                    </DialogContent>

                </SwipeableDrawer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        translations: state.lang.translations || [],
        loginModalOpened: state.users.login_modal_opened
    };
};

export default withStyles(styles)(connect(mapStateToProps, { setLoginModalOpened, login, setRegisterModalOpened, addErrorMessages, addSuccessMessage })(LoginModal))