import React, { Component } from "react";
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/styles';
import { DEFAULT_USER_ICON } from '../../constants';
import clsx from 'clsx';

const styles = theme => ({
    root: {
        padding: 0,
        paddingBottom: 20,
        margin: 0,
    },
    title: {
        color: '#efefef',
        textAlign: 'center',
        fontSize: 18,
        marginBottom: 20,
        paddingTop: 10,
    },
    titleDark: {
        color: '#1e1e1e',
    },
    eachFeedbackContainer: {
        backgroundColor: 'rgba(213, 213, 213, .8)',
        listStyleType: 'none',
        // borderBottomLeftRadius: 11,
        // borderBottomRightRadius: 11,
        borderRadius: 11,
        borderBottom: '1px solid #FE6902',
        marginTop: 20,
        padding: 10,
        width: '94%',
        margin: '0 auto',
        boxShadow: '0px 3px 12px -7px black',
    },
    userIcon: {
        width: 40,
        verticalAlign: 'middle',
    },
    userName: {
        marginLeft: 10,
        fontWeight: 'bold',
    },
    answerText: {
        fontSize: 15,
    },
    noReviewsContainer: {
        color: '#ebebeb',
    },
    noReviewsContainerDark: {
        color: '#1e1e1e',
    }
});

class FeedbackAnswersList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, translations, settings } = this.props;
        return (
            <ul className={classes.root} >
                <h3 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.title, classes.titleDark) : classes.title}>{translations.pages && translations.pages.feedback ? translations.pages.feedback.customer_reviews : ''}</h3>
                <Grid container spacing={1}>
                    {this.props.answers.length == 0 ?
                        <div style={{ textAlign: 'center', margin: '0 auto', marginTop: 50, paddingBottom: 70 }}>
                            {/* TODO <NoDataContainer/> */}
                            <p className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.noReviewsContainer, classes.noReviewsContainerDark) : classes.noReviewsContainer}>{translations.pages && translations.pages.feedback ? translations.pages.feedback.no_reviews_yet : ''}</p>
                            <img src='https://tabl.bg/img/default-no-rating-icon.svg' style={{ maxWidth: 100 }} />
                        </div>
                        : null}

                    {this.props.answers.map(a => (
                        <React.Fragment>
                            {a.feedbackquestion.type == 'text' && a.text ?
                                <Grid item xs={12} sm={6} key={a.id} >
                                    <li className={classes.eachFeedbackContainer}>
                                        <img src={DEFAULT_USER_ICON} alt="user" className={classes.userIcon} />
                                        <span className={classes.userName}>{translations.common && translations.common.user ? translations.common.user : ''}</span>
                                        <p className={classes.answerText}>{a.text}</p>
                                    </li>
                                </Grid>
                                :
                                null
                            }
                        </React.Fragment>
                    ))}

                </Grid>
            </ul>
        )
    }

}

export default withStyles(styles)(FeedbackAnswersList);
