import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Input, InputLabel, InputAdornment, FormControl, TextField, Button, Collapse } from '@material-ui/core';
import { AccountCircle, Room, PhoneInTalk, Mail, Lock, KeyboardArrowDown, KeyboardArrowLeft } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        background: 'rgba(255,255,255,0.72)',
    },
    textFieldContainer: {
        marginTop: 10,
        width: '100%',
    },
    textField: {
        width: '100%',
        '& label.Mui-error': {
            color: 'rgba(0, 0, 0, 0.54)'
        },
        '& label.Mui-focused': {
            color: 'rgba(254, 105, 2, 0.7)',
        },
        '& .MuiInput-underline:after': {
            // borderBottomColor: 'rgb(254, 105, 2)',
            borderBottomColor: 'rgb(117 117 117)',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: '#f44336',
        },
    },
    formContainer: {
        width: '100%'
    },
    saveButton: {
        width: '100%',
        maxWidth: 650,
        display: 'block',
        margin: '0 auto',
    }
}));


const LoginForm = (props) => {
    const classes = useStyles();
    const [formSubmissionFailed, setFormSubmissionFailed] = useState(false);
    const [customErrors, setCustomErrors] = useState(false);

    const onChange = (e) => {
        props.handleChange(e);
        formSubmissionFailed ? isFormValid({ [e.target.name]: e.target.value }) : void (0);
    }

    const isFormValid = (updatedValue = {}) => {

        const checkMinLengthValid = (value) => {
            const minLength = 3;
            return value && value.toString().length >= minLength;
        }

        let stateData = props.state;
        // Used for live validate onchange (Because state is not changed immediately)
        if (updatedValue && Object.keys(updatedValue).length > 0 && Object.keys(updatedValue)[0]) {
            stateData[Object.keys(updatedValue)[0]] = updatedValue[Object.keys(updatedValue)[0]];
        }

        // Email
        const emailValid = checkMinLengthValid(stateData.email)

        // Password
        const passwordValid = checkMinLengthValid(stateData.password);

        const isFormValid = emailValid && passwordValid;

        if (isFormValid) {
            setCustomErrors({})
        } else {
            setFormSubmissionFailed(true);
            setCustomErrors({
                email: !emailValid ? props.translations.messages.errors.invalid_email : '',
                password: !passwordValid ? props.translations.messages.errors.invalid_password : '',
            })
        }

        return isFormValid;
    }

    return (
        <form onSubmit={props.onSubmit}>
            <div className={classes.root}>

                <div className={classes.textFieldContainer}>
                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                        <Grid item xs={1}>
                            <AccountCircle />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField name="email" className={classes.textField}
                                error={customErrors && customErrors.email} helperText={customErrors && customErrors.email ? customErrors.email : ''}
                                label={props.translations.pages.profile.email}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>

                    <br />

                    <Grid container spacing={1} alignItems="flex-end" className={"no-padding"}>
                        <Grid item xs={1}>
                            <Lock />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField name="password" className={classes.textField} type="password"
                                error={customErrors && customErrors.password} helperText={customErrors && customErrors.password ? customErrors.password : ''}
                                label={props.translations.pages.profile.password}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>

                    <br />

                    <Button className={classes.saveButton} variant="contained" onClick={(e) => isFormValid() ? props.onSubmit(e) : props.addErrorMessages(["Моля попълнете всички полета коректно."])}>Вход</Button>

                </div>

            </div>
        </form>
    );
}

export default LoginForm;
