import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Switch, Route, Router } from 'react-router-dom';
import history from './utils/history';
import AppNavbar from './common/navbar/AppNavbar'

// import CategoriesPage from './categories/components/CategoriesPage';
import ProductsPage from './products/components/ProductsPage';
import ClientRequest from './landing-pages/vkluchi-se/components/ClientRequest';
import Thankyou from './landing-pages/vkluchi-se/components/Thankyou';

// import SignInPage from './components/auth/signInPage';
// import ScannerPage from './components/qr_scanner/scannerPage';
// import LogoutPage from './components/auth/logoutPage';
import Page404 from './common/errors/404';
// import PreorderPage from './components/preorder/PreorderPage';
import FeedbackPage from './feedback/components/FeedbackPage';
import SearchPage from './search/components/SearchPage';
import AboutUsPage from './aboutus/components/AboutUsPage';
import AboutRestaurantPage from './aboutus/components/AboutRestaurantPage';
// import AllProductsPage from './products/components/AllProductsPage';
// import SubcatsWithProductsPage from './products/components/SubcatsWithProductsPage';
// import SubcatsWithProductsSluggedPage from './products/components/SubcatsWithProductsSluggedPage';
import NavigateToComponent from './common/NavigateToComponent';
import ScanPage from './qr-scan/components/scanPage';
import VerifyTokenPage from './qr-scan/components/VerifyTokenPage';
import CategoriesHierarchyPage from './categories/components/CategoriesHierarchyPage';
import ViewProductPage from './products/components/ViewProductPage';
import ProfilePage from './users/components/profile/ProfilePage';
import DynamicLayoutRoute from './DynamicLayoutRoute';

class MyRouter extends React.Component {

    
    render() {


        //     useEffect(() => {
        // if(!props.fetched) {
        //     props.fetchRules();
        // }
        //     }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour

        let mainPage;
        let additionalBeginRoute = "";
        // if (props.settings.no_categories_view) {

        // if (props.settings.no_categories_view.value == 0) {
        //     mainPage = <Route exact path='/' component={CategoriesPage} />
        // } else if (props.settings.no_categories_view.value == 1) {
        //     mainPage = <Route exact path='/' component={AllProductsPage} />
        // } else if (props.settings.no_categories_view.value == 2) {
        //     mainPage = <Route exact path='/' component={SubcatsWithProductsPage} />
        // } else if (props.settings.no_categories_view.value == 3) {
        //     mainPage = <Route path='/:subcatSlug' component={SubcatsWithProductsSluggedPage} />
        //     additionalBeginRoute = <Route exact path='/' component={SubcatsWithProductsSluggedPage} />
        // } else {
        mainPage = <Route exact path='/:place/:catSlug' component={CategoriesHierarchyPage} />
        additionalBeginRoute = <Route exact path='/:place' component={CategoriesHierarchyPage} />
        // }


        // }
        // mainPage = <Route exact path='/:catSlug' component={CategoriesHierarchyPage} />
        // additionalBeginRoute = <Route exact path='/' component={CategoriesHierarchyPage} />

        return (
            <div>
                <Router history={history}>
                    <div>
                        {/* <AppNavbar history={history}/> */}
                        <Switch>
                            <DynamicLayoutRoute exact path="/404" component={Page404} layout="DEFAULT_NAV" />
                            {/* <DynamicLayoutRoute exact path="/join" component={ClientRequest} layout="DEFAULT_NAV" /> */}
                            {/* <DynamicLayoutRoute exact path="/thankyou" component={Thankyou} layout="DEFAULT_NAV" />
                            <DynamicLayoutRoute exact path="/close-browser-app" component={Thankyou} layout="DEFAULT_NAV" /> */}

                            {/* <DynamicLayoutRoute exact path="/:place/feedback" component={FeedbackPage} layout="MENU_NAV" /> */}

                            <DynamicLayoutRoute exact path='/:place/feedback' component={FeedbackPage} layout="MENU_NAV" />
                            <DynamicLayoutRoute exact path='/:place/search' component={SearchPage} layout="MENU_NAV" />
                            <DynamicLayoutRoute exact path='/:place/about-tabl' component={AboutUsPage} layout="MENU_NAV" />
                            <DynamicLayoutRoute exact path='/:place/about' component={AboutRestaurantPage} layout="MENU_NAV" />
                            <DynamicLayoutRoute exact path='/:place/profile' component={ProfilePage} layout="MENU_NAV" />                            
                            {/* <Route exact path='/:place/subcategories/:subcat_id/products' component={NavigateToComponent} /> */}
                            <DynamicLayoutRoute exact path='/:place/scan' component={ScanPage} layout="MENU_NAV" />
                            <DynamicLayoutRoute exact path='/:place/t/:token' component={VerifyTokenPage} layout="MENU_NAV" />
                            <DynamicLayoutRoute exact path='/:place/:catSlug' component={CategoriesHierarchyPage} layout="MENU_NAV" />
                            <DynamicLayoutRoute exact path='/:place/:catSlug/:prodSlug' component={ViewProductPage} layout="MENU_NAV" />


                            <DynamicLayoutRoute exact path='/:place' component={CategoriesHierarchyPage} layout="MENU_NAV" />

                        </Switch>
                    </div>

                </Router>
            </div>
        )
    }
}

const mapStateToProops = (state, ownProps) => {
    return {
        settings: state.settings.settings || [],
    };
};

export default (connect(mapStateToProops, {})(MyRouter));