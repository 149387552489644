import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles, withTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import SwipeableViews from 'react-swipeable-views';
import { changeSelectedCartTab, setOrderComment, removeUserTokenAndOrder, setCommentModalData, setDisabledMakeOrderButton } from '../../products/actions/products.action';
import { setOrderModalState } from '../../qr-scan/actions/scan.actions'
import CartModalEachCartItem from './CartModal/CMEachCartItem';
import CartModalEachOrderedItem from './CartModal/CMEachOrderedItem';

import { styles } from './styles/cardModal.styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CartModal extends Component {

    componentDidUpdate() {
        const { orderSent, orderMessage } = this.props;
        if (this.swipeableActions) this.swipeableActions.updateHeight();
    }

    render() {
        const { classes, open, handleChange, handleRemove, onClose, onSubmit, onError, askForBill, settings, translations, isLoadingCart } = this.props;

        return (
            <div>
                {/* <PaymentTypeModal
                    open={this.state.openPaymentTypeModal}
                    onClose={() => this.setState({ openPaymentTypeModal: false })}
                    translations={translations}
                    currLang={this.props.currLang}
                    askForBillCash={() => { this.setState({ openPaymentTypeModal: false }); askForBill('cash'); }}
                    askForBillCard={() => { this.setState({ openPaymentTypeModal: false }); askForBill('card'); }}
                /> */}

                <Dialog
                    fullScreen
                    open={open}
                    onClose={onClose}
                    TransitionComponent={Transition}
                    className={classes.root}
                    data-class="dialog-container"
                >

                    <Backdrop open={isLoadingCart} className={classes.backdrop}>
                        <CircularProgress className="is-loading-colored-only" />
                    </Backdrop>

                    <AppBar className={classes.appBar} data-class="dialog-title-container">
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={onClose}
                                aria-label="close"
                                style={{ marginRight: 5 }}
                            >
                                <CloseIcon />
                            </IconButton>

                            <Button className={this.props.selectedCartTab === 0 ? classes.titleActive : classes.title} onClick={() => this.props.changeSelectedCartTab(0)} data-class={this.props.selectedCartTab === 0 ? "active-cart-modal-button" : "inactive-cart-modal-button"}>
                                {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.cart : ''}
                            </Button>

                            <Button className={this.props.selectedCartTab === 1 ? classes.titleActive : classes.title} onClick={() => this.props.changeSelectedCartTab(1)} data-class={this.props.selectedCartTab === 1 ? "active-cart-modal-button" : "inactive-cart-modal-button"}>
                                {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.orders : ''}
                            </Button>

                        </Toolbar>
                    </AppBar>
                    <SwipeableViews index={this.props.selectedCartTab} onChangeIndex={(i) => this.props.changeSelectedCartTab(i)} enableMouseEvents animateHeight action={actions => (this.swipeableActions = actions)}>
                        <div>

                            {/* CART PRODUCTS (TO ORDER) */}
                            <CartModalEachCartItem
                                onSubmit={this.props.onSubmit}
                                onError={this.props.onError}
                            />

                        </div>

                        <div>

                            {/* ORDERED PRODUCTS */}
                            <CartModalEachOrderedItem
                                onSubmit={this.props.onSubmit}
                                onError={this.props.onError}
                                openPaymentTypeModal={this.props.openPaymentTypeModal}
                                onClose={this.props.onClose}
                            />

                        </div>

                    </SwipeableViews>

                </Dialog>
            </div >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let chosenPlaceOrderTypes = [];
    let chosenPlaceId = state.places.chosen_place;
    let chosenPlace = state.places.places.filter(p => p.id == chosenPlaceId);
    if (chosenPlace.length == 0) {
        chosenPlace = null;
    } else {
        chosenPlace = chosenPlace[0];
        chosenPlaceOrderTypes = chosenPlace.ordertypes;
    }

    return {
        selectedCartTab: state.products.selected_cart_tab || 0,
        orderedProducts: state.products.ordered || [],
        orderSent: state.products.order_sent || null,
        settings: state.settings.settings || [],
        products: state.products || [],
        productsById: state.products.all_products || [],
        isLoadingCart: state.products.isLoadingCart || false,
        orderMessage: state.products.order_message || '',
        orderModalState: state.scan.order_modal_state,
        chosenPlaceOrderTypes: chosenPlaceOrderTypes,
        disabledMakeOrderButton: state.products.disabledMakeOrderButton,
        productIngredients: state.products.productIngredients || [],

        translations: state.lang.translations,
        currLang: state.lang.currLang,
    };
};

export default withStyles(styles)(connect(mapStateToProps, { changeSelectedCartTab, setOrderComment, removeUserTokenAndOrder, setOrderModalState, setCommentModalData, setDisabledMakeOrderButton })(CartModal))