import React, { useState, useEffect, Component } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SwipeableDrawer, Grow, Slide, CircularProgress, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import clsx from 'clsx';
// import axios from 'axios';
import { withStyles } from '@material-ui/styles';
import { setProfileModalOpened, logout } from './../../actions/users.actions';
import Utils from './../../../utils/Utils';
import history from '../../../utils/history';
import { styles } from './../../styles/loginModal.css'
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import { ChevronRight } from '@material-ui/icons';

class ProfileModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations, allProducts, loggedUser } = this.props;

        if (!loggedUser) return null; // Prevent "opening onOpen"

        return (
            <React.Fragment>
                <SwipeableDrawer
                    open={this.props.profileModalOpened}
                    anchor={'right'}
                    onClose={() => this.props.setProfileModalOpened(false)}
                    onOpen={() => this.props.setProfileModalOpened(true)}
                    data-class="dialog-container"
                    className={classes.root}
                    disableSwipeToOpen
                    swipeAreaWidth={0}
                >
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                        <div className={classes.modalTitleProfile}>
                            <IconButton onClick={() => this.props.setProfileModalOpened(false)} className={classes.closeDrawerButtonProfile}>
                                <ChevronRight />
                            </IconButton>
                            <div className={classes.modalTitleText}>{this.props.translations.common.hi}{this.props.user.fullname ? ", " + this.props.user.fullname : ""}</div>
                        </div>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            <List>
                                <ListItem button onClick={() => { history.push(`/profile`); this.props.setProfileModalOpened(false); }}>
                                    <ListItemIcon><PersonIcon /></ListItemIcon>
                                    <ListItemText primary={this.props.translations.pages.profile.my_profile} />
                                </ListItem>
                            </List>

                            <Divider />

                            <List>
                                <ListItem button onClick={() => { this.props.logout(); this.props.setProfileModalOpened(false); }}>
                                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                    <ListItemText primary={this.props.translations.pages.profile.logout} />
                                </ListItem>
                            </List>

                            <Divider />
                        </DialogContentText>
                    </DialogContent>
                </SwipeableDrawer>

            </React.Fragment>
        );
    }

}


const mapStateToProps = (state, ownProps) => {

    return {
        profileModalOpened: state.users.profile_modal_opened,
        clientSlug: state.client.slug || "",
        translations: state.lang.translations || [],
        user: state.users.user || [],
        loggedUser: state.users.user && Object.keys(state.users.user).length !== 0 ? state.users.user : null
    };
};

export default withStyles(styles)(connect(mapStateToProps, { setProfileModalOpened, logout })(ProfileModal)
)