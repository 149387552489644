import axios from 'axios';
import { GET_PLACES } from './types';
import { LOADING_START, LOADING_END, PLACE_IMAGE_UPLOADED } from '../../products/actions/types';
import { SET_CHOSEN_PLACE } from './../actions/types';
import { API_URL } from '../../constants';
import { clearCartLocal, removeUserTokenAndOrder} from './../../products/actions/products.action';

import Utils from './../../utils/Utils';
import Auth from './../../utils/Auth';
import Cookies from 'universal-cookie';
import setCustomPlaceHeader from './../../utils/setCustomPlaceHeader';

const cookies = new Cookies();

export const getPlaces = () => dispatch => {
    dispatch({ type: LOADING_START });

    axios
        .get(API_URL + 'places?t=' + (new Date().getTime()))
        .then(res => {
            dispatch({
                type: GET_PLACES,
                payload: res.data
            });
            dispatch({ type: LOADING_END });
        }).catch(error => {
            throw (error);
        });
}


export const addPlaceImage = (file, placeId) => dispatch => {

    if (file == null) {
        dispatch({
            type: PLACE_IMAGE_UPLOADED,
            payload: false
        });

        // history.push();
        return;
    }

    axios
        .post(API_URL + `places/gallery`, file)
        .then(function (res) {
            dispatch({
                type: PLACE_IMAGE_UPLOADED,
                payload: true
            });

            dispatch(getPlaces());
            // if (res.error) {
            //     //TODO image not uploaded Toastr
            // }
            // else {
            //     // history.push(ADMIN_URL + 'products_admin');
            // }
            // // Error msg
            // history.push(API_URL + 'products');
        })
        .catch(e =>
            // history.push(API_URL + 'products')
            dispatch({
                type: PLACE_IMAGE_UPLOADED,
                payload: false
            })
        );


}
export const setChosenPlace = (place_id, should_clear_local_cart = false) => dispatch => {
    // if(getChosenPlace() != place_id && should_clear_local_cart) {
    //     dispatch(clearCartLocal());
    // }
    // window.localStorage.setItem('place', place_id);
    Auth.setOpenedPlace(place_id);

    dispatch({ 
        type: SET_CHOSEN_PLACE,
        payload: place_id
     });
}

export const getChosenPlace = () => {
    // return window.localStorage.getItem('place');
}

export const verifyOpenedPlace = (placeId, placeIdFromStore=null) => dispatch => {
    if(placeId != null) {
        setCustomPlaceHeader(placeId);
        if(cookies.get('tabl_place') != placeId) { // on refresh - if place is different - clear cart
            dispatch(clearCartLocal());
    
            if(!Auth.isTabletMode()) {
                dispatch(removeUserTokenAndOrder());
            }
            
            Utils.clearDataForTrack();
            // Auth.removeUserToken();
        }
        
        cookies.set('tabl_place', placeId, { path: "/" });
    } else {

        // verifies if more than 1 places are open (in multiple tabs) -> cookies are changed -> sets the correct cookie
        // допълнителната проверка (close-browser-app) е за страницата в която затваря менюто в аппа(МЕЙСЪНС) и не трябва да сетва различно place_id защото ако е различно, после ще изчисти LocalStorage-a и при повторно сканиране от аппа ще му създаде нова сесия и няма да му седят поръчките до момента 
        if(cookies.get('tabl_place') != placeIdFromStore && !window.location.pathname.includes("close-browser-app")    ) {
            Utils.clearDataForTrack();

            dispatch(clearCartLocal());
    
            if(!Auth.isTabletMode()) {
                // console.log("DELETING ORDER TOKEN ")
                dispatch(removeUserTokenAndOrder());
            }

            cookies.set('tabl_place', placeIdFromStore, { path: "/" });
        } 
        
    }
}