export const styles = theme => ({
    modal: {
        '& .MuiPaper-root': {
            width: '350px !important',
        }
    },
    modalTitle: {
        textAlign: 'center',
        backgroundColor: "rgb(203,65,2)",
        color: 'white',
        boxShadow: '0 1px 5px 0px black',
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    dialogContent: {
        // minHeight: 100,
        'background-image': 'url(https://tabl.bg/img/white-shiny-background.jpg)',
        'background-position': 'center bottom',
        'background-attachment': 'fixed',
        'background-size': 'cover',
    },
    dialogContentText: {
        paddingTop: 10,
    },
    modalTypesBtns: {
        color: 'white',
        width: '100%',
        fontSize: 18,
        display: 'grid',
        // 'grid-template-rows': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        // color: '#575757',
        borderRadius: 0,
        borderBottom: '1px solid #ff7600',
        backgroundColor: '#f9f9f9',
        minWidth: 180,
        '@media screen and (max-width: 475px)': {
            minWidth: '80%',
        },

        'border': '1px solid #ebebeb',

        // 'border': '1px solid #ff5200',
        // 'border-top-right-radius': 7,
        // 'border-top-left-radius': 7,

        'border-top-left-radius': 20,
        'border-bottom-right-radius': 20,

        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: 'none',

        height: 80,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    modalTypesBtnsBorderBottom: {
        'border-bottom': '1px solid #ff5200 !important',
    },
    modalTypesIcons: {
        fontSize: 42,
        color: '#ff7600',
    },
    isMultipleOptionsModalContainer: {
        margin: '20px 0 20px 0',
        display: 'grid',
        // 'grid-template-columns': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        padding: 0,
        // '@media screen and (max-width: 475px)': {
        //     'grid-template-columns': 'none',
        //     // '& > *:first-child': {
        //     //     marginBottom: 40,
        //     // },
        //     margin: '20px 0 20px 0',
        // }
    },
});
