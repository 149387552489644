import React, {Component} from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { isObject } from 'util';
import {logout} from './../users/actions/users.actions';

class MessagesContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			opened: false
		}
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.errors) {
			this.setState({ opened: true })
		}
	}

	handleClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
	
		this.setState({opened: false})
	};

	render() {
		
		let errors = [];
		
		if(this.props.data == 'Unauthorized') {
			this.props.logout();
			return ;
		} else {

			if(this.props.errors.length > 0 && typeof this.props.errors[0] === 'object' && this.props.errors[0] !== null) {
				this.props.errors.map(errObj => {
					errors.push(errObj.message);
				})
			} else if(this.props.errors.length > 0 && typeof this.props.errors[0] === 'string') {
				errors = this.props.errors;
			}
	
		}
		let k = 0;

		return (
			<React.Fragment>

				{errors.map(e => (
					<Snackbar open={this.state.opened} autoHideDuration={6000} onClose={this.handleClose}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						key={e}
					>
						<Alert onClose={this.handleClose} severity="error">
							{e}
						</Alert>
					</Snackbar>
				))}
					
				{this.props.success.length > 0 ?
					<Snackbar open={this.state.opened} autoHideDuration={6000} onClose={this.handleClose}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					>
						<Alert onClose={this.handleClose} severity="success">
							{this.props.success}
						</Alert>
					</Snackbar>
				:
					''
				}
			</React.Fragment>
			
		// </div>
		)
	}
};

const mapStateToProps = (state, dispatch) => {
    return {
		data: state.messages.data,
		errors: state.messages.errors || [],
		success: state.messages.success || ''
    }
};

export default connect(mapStateToProps, {logout})(MessagesContainer);
