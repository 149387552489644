import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducer';
import setCustomClientHeader from './utils/setCustomClientHeader';
import trackingMiddleware from './tracking/trackingMiddleware';

import Cookies from 'universal-cookie';
const cookies = new Cookies();


let initialState = {};
//, trackingMiddleware
const middleware = [thunk];

initialState = window.__INITIAL_STATE__

// Allow the passed state to be garbage-collected
delete window.__INITIAL_STATE__

var element = document.getElementById("toDelete");
if(element && element.parentNode.hasChildNodes()) element.parentNode.removeChild(element);

if(initialState && initialState.places) {
    
    setCustomClientHeader(initialState.client)
    // cookies.set('tabl_client', initialState.client);  -- SET FROM SERVER ON INITIAL REQUEST
    // window.localStorage.setItem('client', initialState.client);
    
} else {
    initialState = {};
}

// const composeEnhancers = process.env.NODE_ENV && process.env.NODE_ENV == "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, initialState, composeEnhancers(
    applyMiddleware(...middleware),
));

// const store = createStore(rootReducer, initialState, compose(
//     applyMiddleware(...middleware),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// ));

export default store;