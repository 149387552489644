import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SearchIcon from '@material-ui/icons/Search';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import Auth from '../../utils/Auth';

const useStyles = makeStyles(theme => ({
    fixedButtonNoShadow: {
        '& .MuiButtonBase-root': {
            boxShadow: 'none',
        },
    },
    fixedButton: {
        position: 'fixed',
        bottom: 30,
        right: 37,
        // zIndex: 5,
        '& .MuiButtonBase-root': {
            backgroundImage: 'linear-gradient(119deg, rgb(255, 148, 74) 0%, rgb(255, 122, 31) 23%, rgb(255, 113, 47) 78%)',
        },
        '& .MuiFab-root:active': {
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)', //same as initial
        },
        '& .MuiFab-root:hover': {
            backgroundImage: 'linear-gradient(119deg, rgb(255, 148, 74) 0%, rgb(255, 122, 31) 23%, rgb(255, 104, 34) 78%)',
        },

        // Option label
        '& .MuiSpeedDialAction-staticTooltipLabel': {
            whiteSpace: 'nowrap',
            fontSize: 16,
            background: '#eaeaea',
            borderBottom: '2px solid #FE6902',
            boxShadow: '2px 2px 5px 1px #555',
            color: '#464646',
            fontWeight: 'bold',
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        },
    },

    fixedButtonThreeNavigationItems: {
        '@media screen and (max-width: 370px)': {
            bottom: 80,
            right: 27,
        }
    },

    fixedButtonFourNavigationItems: {
        '@media screen and (max-width: 450px)': {
            bottom: 80,
            right: 27,
        }
    },

    actionButtonText: {
        width: 135,
        position: 'fixed',
        bottom: 10,
        right: 0,
        zIndex: 1,
        textAlign: 'center',
        fontSize: '0.75rem',
        transition: 'font-size 0.2s, opacity 0.2s',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        transitionDelay: '0.1s',
        color: 'rgba(0, 0, 0, 0.54)',
    },

    actionButtonTextThreeItems: {
        '@media screen and (max-width: 370px)': {
            display: 'none'
        }
    },
    actionButtonTextFourItems: {
        '@media screen and (max-width: 450px)': {
            display: 'none'
        }
    },

    pulseButton: {
        '& .MuiButtonBase-root': {
            'border-radius': '50%',
            cursor: 'pointer',
            'box-shadow': '0 0 0 #ffffff29',
            animation: 'pulse 2s infinite',
        }
    },

    // Option button
    speedDialAction: {
        width: 55,
        height: 55,
        borderRadius: '50%',
        backgroundColor: '#FF5900',
        // // backgroundColor: 'rgb(254,105,2)',
        // backgroundColor: 'rgb(203,65,2)',
        color: 'white',
    },
    // Option button icon
    speedDialTooltipIcons: {
        fontSize: '1.7rem',
    }
}));


const SpeedDialTooltipOpen = (props) => {
    const { translations, call_btn_instead_of_search, second_call_btn, footerNavigatonCountLinks } = props;

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    let actions = []

    // Phone 2
    if (!Auth.isTabletMode() && call_btn_instead_of_search && second_call_btn) {
        actions.push({ icon: <PhoneInTalkIcon className={classes.speedDialTooltipIcons} />, name: second_call_btn, onClick: () => { window.location.href = 'tel:' + second_call_btn; } })

        // Phone 1
        actions.push({ icon: <PhoneInTalkIcon className={classes.speedDialTooltipIcons} />, name: call_btn_instead_of_search, onClick: () => { window.location.href = 'tel:' + call_btn_instead_of_search; } })
    }

    const toggleOpen = () => {
        setOpen(!open)
    };

    const handleClose = (e) => {
        setOpen(false);
    };


    let fixedButtonStyles = classes.fixedButton;
    if (footerNavigatonCountLinks == 3) {
        fixedButtonStyles = clsx(classes.fixedButtonThreeNavigationItems, classes.fixedButton);
    }
    if (footerNavigatonCountLinks == 4) {
        fixedButtonStyles = clsx(classes.fixedButtonFourNavigationItems, classes.fixedButton);
    }

    let actionButtonTextStyles = classes.actionButtonText;
    if (footerNavigatonCountLinks == 3) {
        actionButtonTextStyles = clsx(classes.actionButtonTextThreeItems, actionButtonTextStyles);
    }
    if (footerNavigatonCountLinks == 4) {
        actionButtonTextStyles = clsx(classes.actionButtonTextFourItems, actionButtonTextStyles);
    }

    return (
        <div className={classes.root}>

            <SpeedDial
                ariaLabel="SpeedDial"
                icon={!Auth.isTabletMode() && call_btn_instead_of_search ? (second_call_btn && open ? <ClearIcon /> : <PhoneInTalkIcon />) : <SearchIcon />}
                className={!Auth.isTabletMode() && call_btn_instead_of_search && open == false ? clsx(fixedButtonStyles, classes.pulseButton) : (footerNavigatonCountLinks > 0 ? clsx(fixedButtonStyles, classes.fixedButtonNoShadow) : fixedButtonStyles)}
                onClose={handleClose}
                onClick={() => { !Auth.isTabletMode() && second_call_btn ? toggleOpen() : props.onClick(call_btn_instead_of_search) }}
                open={open}
                direction={"up"}
                size="large"
                hidden={footerNavigatonCountLinks == 0 ? props.hidden : false}
                data-class="bottom-speed-dial-container"
            >
                <Backdrop open={open} />

                {actions.map((action, i) => (
                    <SpeedDialAction
                        className={classes.speedDialAction}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        tooltipPlacement="left"
                        onClick={() => { action.onClick(); handleClose(); }}
                        data-class="each-bottom-speed-dial-button"
                    />
                ))}
            </SpeedDial>
            <div className={actionButtonTextStyles} style={footerNavigatonCountLinks == 0 ? { display: 'none' } : (second_call_btn ? { width: 145, zIndex: 123 } : { zIndex: 123 })}>
                {call_btn_instead_of_search ? translations && translations.common && translations.common.call_us ? translations.common.call_us : '' : translations && translations.common && translations.common.search ? translations.common.search : ''}
            </div>
        </div >
    );
}

export default SpeedDialTooltipOpen;