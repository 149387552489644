import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Utils from '../../utils/Utils';

import { setSearchText } from '../../search/actions/search.actions';

const styles = theme => ({
  root: {
    top: 0,
    height: 55,
    width: '100%',
    zIndex: 1100,
    borderRadius: 0,
    position: 'fixed',
    backgroundColor: '#eee',
    borderBottomRightRadius: 11,
    borderBottomLeftRadius: 11,
    borderBottom: '1px solid rgb(254,105,2)',
  },
  input: {
    width: 'calc(100% - 120px)',
    fontWeight: 'bold',
    color: 'rgb(203,65,2)',
    marginLeft: 12,
    marginTop: 10,
    fontSize: 18,
    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
  },
  iconButton: {
    padding: 14,
  },
  divider: {
    height: 35,
    marginTop: 8,
    marginLeft: -15,
    position: 'absolute',
    display: 'inline-block',
  },
});

class SearchHeader extends Component {
  constructor(props) {
    super(props);
    this.searchInputRef = React.createRef();
  }

  serachInputChange = (searchText) => {
    this.props.setSearchText(searchText)
    if (searchText.length > 2) {
      this.props.makeSearch(searchText);
    }
  }

  componentDidUpdate() {
    if (this.props.searchOpened) {
      this.searchInputRef.focus();
    }
  }

  render() {
    const { classes, translations } = this.props;

    return (
      <Fade in={this.props.searchOpened} timeout={{ enter: 350, exit: 350 }}>
        <Paper className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder={translations.pages && translations.pages.search && translations.pages.search.searchProduct ? translations.pages.search.searchProduct : ''}
            inputProps={{ 'aria-label': 'search product' }}
            onChange={(e) => this.serachInputChange(e.target.value)}
            inputRef={(r) => this.searchInputRef = r}
            autoFocus
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={this.props.makeSearch}>
            <SearchIcon />
          </IconButton>
          <IconButton className={classes.iconButton} aria-label="directions" onClick={this.props.toggleSearch}>
            <CloseIcon />
          </IconButton>
        </Paper>
      </Fade >
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  // return {
  //   showCategory: state.categories.show_category,
  // };
};

export default withStyles(styles)(connect(mapStateToProps, { setSearchText })(SearchHeader));