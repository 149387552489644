import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import { showHeaderCategory } from '../../categories/actions/categories.action';
import { setMainBackground } from './../../settings/actions/settings.action';
import EmailIcon from '@material-ui/icons/Email';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import DirectionsIcon from '@material-ui/icons/Directions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Utils from "../../utils/Utils";

import clsx from 'clsx';

const styles = theme => ({
    aboutRestaurantContainer: {
        height: 'calc(100vh - 110px)',
        paddingTop: 75,
        paddingBottom: 25,
        paddingLeft: '10%',
        paddingRight: '10%',
        height: '100%'

    },
    eachRowAboutRestaurant: {
        display: 'grid',
        'grid-template-columns': '48% 48%',
        gridColumnGap: '4%',
        // gridRowGap: '2%',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            // alignItems: center, //Vertical
        },
        '@media screen and (max-width: 1000px)': {
            '&': {
                'grid-template-columns': 'none',
            },
        },

    },
    cellTitle: {
        color: 'white',
        fontSize: '2em',
    },
    cellDescription: {
        color: '#ddd',
    },

    contactIcons: {
        fontSize: 30,
        color: '#ddd',
    },
    contactIconsText: {
        verticalAlign: 9,
        color: '#ddd',
        fontSize: 16,
    },

    colorBlack: {
        color: 'black !important',
    },

    backBtn: {
        color: 'white',
        fontSize: 20,
        borderBottom: '1px solid white',
        borderRadius: 0,
        width: '95%',
        margin: '0 auto',
        marginTop: 5,
        marginBottom: 100,
        display: 'flex',
    }

});

class AboutRestaurantPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleContainerTimeout: false
        }
    }

    componentDidMount() {
        const { settings } = this.props;
        setTimeout(() => this.setState({ visibleContainerTimeout: true }), 100);
        this.props.setMainBackground('linear-gradient( rgba(0, 0, 0, 0.8), rgba(147, 147, 147, 0.6) )');
    }

    componentDidUpdate() {
        let { translations } = this.props;
        this.props.showHeaderCategory(translations && translations.translations && translations.translations.common ? translations.translations.common.action.back : '')
    }

    render() {
        let { classes, translations, settings } = this.props;
        translations = translations.translations;


        return (
            <Fade in={true} timeout={{ enter: 350, exit: 350 }}>
                <div>

                    <div className={clsx(classes.aboutRestaurantContainer, classes.eachRowAboutRestaurant)}>

                        <div data-class="about-page-logo-container">
                            <Grow in={this.state.visibleContainerTimeout} timeout={{ enter: 450, exit: 350 }} data-class="about-page-logo-animation-container" style={{ alignSelf: 'center' }}>
                                <div style={{ textAlign: 'center' }}>
                                    {Utils.getSettingsValue(settings, 'about_restaurant_page_title_in_left') == 1 ? <h2 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{settings.meta_title_place_name && settings.meta_title_place_name.value ? settings.meta_title_place_name.value : ''}</h2> : null}
                                    <img src={settings.about_page_logo && settings.about_page_logo.value ? Utils.getSettingsPath(settings.about_page_logo.value) : (settings.logo && settings.logo.value ? Utils.getSettingsPath(settings.logo.value) : (settings.tabl_logo && settings.tabl_logo.value ? Utils.getSettingsPath(settings.tabl_logo.value) : ''))} style={{ width: '100%', maxWidth: 570 }} data-class="about-page-logo" />
                                </div>
                            </Grow>
                        </div>

                        <div>
                            <Grow in={this.state.visibleContainerTimeout} timeout={{ enter: 450, exit: 350 }}>
                                <div style={{ textAlign: 'center' }}>
                                    {Utils.getSettingsValue(settings, 'about_restaurant_page_title_in_left') == 1 ? null : <h2 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{settings.meta_title_place_name && settings.meta_title_place_name.value ? settings.meta_title_place_name.value : ''}</h2>}

                                    {Utils.getSettingsValue(settings, 'about_page_description') ?
                                        <h3 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}
                                            dangerouslySetInnerHTML={{
                                                __html: Utils.getSettingsValue(settings, 'about_page_description')
                                            }}>
                                        </h3>
                                        :
                                        <h3 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}>{Utils.getSettingsValue(settings, 'meta_description')}</h3>
                                    }
                                </div>
                            </Grow>
                        </div>

                        <div>
                            <Grow in={this.state.visibleContainerTimeout} timeout={{ enter: 450, exit: 350 }}>
                                <div style={{ textAlign: 'center' }}>

                                    <h2 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{translations && translations.common && translations.common.contacts ? translations.common.contacts : ''}</h2>
                                    <hr />

                                    {Utils.getSettingsValue(settings, 'call_btn_instead_of_search') ?
                                        <h3 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}>
                                            <a href={'tel:' + (Utils.getSettingsValue(settings, 'call_btn_instead_of_search').replace(' ', ''))} className="text-decoration-none">
                                                <PhoneInTalkIcon className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.contactIcons, classes.colorBlack) : classes.contactIcons} />
                                                <span className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.contactIconsText, classes.colorBlack) : classes.contactIconsText}>
                                                    &nbsp;
                                                        {Utils.getSettingsValue(settings, 'call_btn_instead_of_search')}
                                                </span>
                                            </a>
                                            <br />
                                        </h3>
                                        :
                                        null
                                    }

                                    {Utils.getSettingsValue(settings, 'second_call_btn') ?
                                        <h3 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}>
                                            <a href={'tel:' + (Utils.getSettingsValue(settings, 'second_call_btn').replace(' ', ''))} className="text-decoration-none">
                                                <PhoneInTalkIcon className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.contactIcons, classes.colorBlack) : classes.contactIcons} />
                                                <span className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.contactIconsText, classes.colorBlack) : classes.contactIconsText}>
                                                    &nbsp;
                                                        {Utils.getSettingsValue(settings, 'second_call_btn')}
                                                </span>
                                            </a>
                                            <br />
                                        </h3>
                                        :
                                        null
                                    }

                                    {Utils.getSettingsValue(settings, 'place_address') ?
                                        <h3 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}>
                                            <a href={"https://www.google.com/maps/dir/?api=1&destination=" + Utils.getSettingsValue(settings, 'place_address')} target="_blank" rel="noopener noreferrer" className="text-decoration-none skiptranslate">
                                                <DirectionsIcon className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.contactIcons, classes.colorBlack) : classes.contactIcons} />
                                                <span className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.contactIconsText, classes.colorBlack) : classes.contactIconsText}>
                                                    &nbsp;
                                                        {Utils.getSettingsValue(settings, 'place_address')}
                                                </span>
                                            </a>
                                        </h3>
                                        :
                                        null
                                    }

                                </div>
                            </Grow>
                        </div>


                        {Utils.getSettingsValue(settings, 'about_page_working_time') ?
                            <div>
                                <Grow in={this.state.visibleContainerTimeout} timeout={{ enter: 450, exit: 350 }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{translations && translations.common && translations.common.working_hours ? translations.common.working_hours : ''}</h2>
                                        <hr />
                                        <div className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}
                                            dangerouslySetInnerHTML={{
                                                __html: Utils.getSettingsValue(settings, 'about_page_working_time')
                                            }}>
                                        </div>
                                    </div>
                                </Grow>
                            </div>
                            :
                            null
                        }


                        {Utils.getSettingsValue(settings, 'about_page_delivery_prices') ?
                            <div>
                                <Grow in={this.state.visibleContainerTimeout} timeout={{ enter: 450, exit: 350 }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{translations && translations.common && translations.common.delivery ? translations.common.delivery : ''}</h2>
                                        <hr />
                                        <div className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}
                                            dangerouslySetInnerHTML={{
                                                __html: Utils.getSettingsValue(settings, 'about_page_delivery_prices')
                                            }}>
                                        </div>
                                    </div>
                                </Grow>
                            </div>
                            :
                            null
                        }


                        {Utils.getSettingsValue(settings, 'about_page_payment_types') ?
                            <div>
                                <Grow in={this.state.visibleContainerTimeout} timeout={{ enter: 450, exit: 350 }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{translations && translations.common && translations.common.payment_methods ? translations.common.payment_methods : ''}</h2>
                                        <hr />
                                        <div className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}
                                            dangerouslySetInnerHTML={{
                                                __html: Utils.getSettingsValue(settings, 'about_page_payment_types')
                                            }}>
                                        </div>
                                    </div>
                                </Grow>
                            </div>
                            :
                            null
                        }


                        {Utils.getSettingsValue(settings, 'about_page_facilities') ?
                            <div>
                                <Grow in={this.state.visibleContainerTimeout} timeout={{ enter: 450, exit: 350 }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{translations && translations.common && translations.common.about_page_facilities ? translations.common.about_page_facilities : ''}</h2>
                                        <hr />
                                        <div className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}
                                            dangerouslySetInnerHTML={{
                                                __html: Utils.getSettingsValue(settings, 'about_page_facilities')
                                            }}>
                                        </div>
                                    </div>
                                </Grow>
                            </div>
                            :
                            null
                        }

                        {Utils.getSettingsValue(settings, 'about_page_household') ?
                            <div>
                                <Grow in={this.state.visibleContainerTimeout} timeout={{ enter: 450, exit: 350 }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{translations && translations.common && translations.common.about_page_household ? translations.common.about_page_household : ''}</h2>
                                        <hr />
                                        <div className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}
                                            dangerouslySetInnerHTML={{
                                                __html: Utils.getSettingsValue(settings, 'about_page_household')
                                            }}>
                                        </div>
                                    </div>
                                </Grow>
                            </div>
                            :
                            null
                        }


                        {Utils.getSettingsValue(settings, 'about_page_technical_support') ?
                            <div>
                                <Grow in={this.state.visibleContainerTimeout} timeout={{ enter: 450, exit: 350 }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellTitle, classes.colorBlack) : classes.cellTitle}>{translations && translations.common && translations.common.about_page_technical_support ? translations.common.about_page_technical_support : ''}</h2>
                                        <hr />
                                        <div className={Utils.getSettingsValue(settings, 'white_background') == 1 ? clsx(classes.cellDescription, classes.colorBlack) : classes.cellDescription}
                                            dangerouslySetInnerHTML={{
                                                __html: Utils.getSettingsValue(settings, 'about_page_technical_support')
                                            }}>
                                        </div>
                                    </div>
                                </Grow>
                            </div>
                            :
                            null
                        }

                    </div >

                    <Button
                        onClick={() => Utils.navigateTo('/' + this.props.clientSlug)}
                        startIcon={<ArrowBackIosIcon />}
                        className={classes.backBtn}
                    >
                        Към менюто
                    </Button>

                </div >
            </Fade >
        )
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        translations: state.lang,
        settings: state.settings.settings || [],
        clientSlug: state.client.slug || ""
    };
};

export default withStyles(styles)(connect(mapStateToProps, { showHeaderCategory, setMainBackground })(AboutRestaurantPage));
