import { GET_PLACES, SET_CHOSEN_PLACE, PLACE_IMAGE_UPLOADED } from '../actions/types';

const initialState = {
    places: [],
    chosen_place: 0,
    placeImageUploaded: false,

}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PLACES:
            return {
                ...state,
                places: action.payload
            }
        case SET_CHOSEN_PLACE: {
            return {
                ...state,
                chosen_place: action.payload
            }
        }

        case PLACE_IMAGE_UPLOADED:
            return {
                ...state,
                placeImageUploaded: action.payload
            }


        default:
            return state;
    }
}