import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, Grow } from '@material-ui/core/';
import CircularProgress from '@material-ui/core/CircularProgress';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import Utils from './../../utils/Utils';
import { getAllProducts, addToCart, changeSelectedCartTab, removeProductFromCart } from './../../products/actions/products.action';
import { getCategoriesHierarchy } from './../../categories/actions/categories.action';
import { setVariantModalOpened, setSelectedProduct } from './../../cart/actions/cart.actions';

import { withTracking } from 'react-tracker';
import { productClick } from './../../tracking/events/productEvents';

import clsx from 'clsx';;

const HOW_MANY_TIMES_TO_CLICK_CLOSE_FOR_STOP_SHOWING_ON_THE_SAME_DAY = 2;
// const currentDate = new Date();

const styles = theme => ({
    modal: {
        '& .MuiPaper-root': {
            width: '350px !important',
        }
    },
    modalTitle: {
        textAlign: 'center',
        backgroundColor: "rgb(203,65,2)",
        color: 'white',
        boxShadow: '0 1px 5px 0px black',
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    dialogContent: {
        minHeight: 100,
        padding: 0,
    },
    dialogContentText: {
        padding: 0,
    },
    fastOrderButtonProduct: {
        color: 'black',
        width: '100%',
        fontSize: 16,
        // display: 'grid',
        // 'grid-template-rows': '50% 50%',
        // 'place-self': 'center',
        // textAlign: 'center',
        // color: '#575757',
        borderRadius: 0,
        // borderBottom: '1px solid #ff7600',
        // backgroundColor: '#f9f9f9',
        minWidth: 180,
        '@media screen and (max-width: 475px)': {
            minWidth: '80%',
        },

        // 'border': '1px solid #ebebeb',

        'border-bottom': '1px solid #ff5200',
        // 'border-top-right-radius': 7,
        // 'border-top-left-radius': 7,

        // paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,

        padding: 15,

        // // display: 'grid',
        // 'grid-template-columns': '70% 30%',
        // borderBottom: 'none',

        // height: 80,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        'border-bottom': '1px solid #ff5200 !important',
        margin: '0 !important',
    },
    activeFastOrderButtonProduct: {
        background: 'rgb(204, 204, 204)'
    },
    // modalTypesBtnsBorderBottom: {
    //     'border-bottom': '1px solid #ff5200 !important',
    // },
    modalTypesIcons: {
        fontSize: 42,
        color: '#ff7600',
    },
    isMultipleOptionsModalContainer: {
        padding: 0,
        // margin: '40px 0 45px 0',
        display: 'grid',
        // 'grid-template-columns': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        padding: 0,
        '@media screen and (max-width: 475px)': {
            'grid-template-columns': 'none',
            // '& > *:first-child': {
            //     marginBottom: 40,
            // },
            // margin: '20px 0 45px 0',
        }
    },
    submitButton: {
        width: '100%',
        color: 'white',
    },
    closeButton: {
        width: '100%',
        color: 'black',
        background: '#dbdbdb',
    },
    variantCheckbox: {
        margin: 0,
        color: 'rgb(254, 105, 2)',
        '&.Mui-checked': {
            color: 'rgb(254, 105, 2)',
        },
    },
    singleProductVariantLabel: {
        background: '#dfdfdf',
        padding: 5,
        borderRadius: 6,
        fontSize: 15,
        fontWeight: 'normal !important',
    },
    productImage: {
        // marginBottom: 10,
        maxHeight: 75,
        maxWidth: 100,
        width: 'auto',
        margin: '0 auto',
    }
});


class InitialBannerFastOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            initialLoad: 1,
            // selectedProducts: [],
        }
    }


    componentWillReceiveProps(nextProps) {
        let showModal = true;
        const initial_banner_html_closed_date = parseInt(window.localStorage.getItem('initial_banner_html_closed_date'));

        if (this.state.initialLoad) {
            this.setState({ initialLoad: 0 })
            if (
                (
                    window.localStorage.getItem('initial_banner_html_closed') == 1
                    && ((new Date(initial_banner_html_closed_date).getDate()) != (new Date().getDate()))
                    // && ((new Date(initial_banner_html_closed_date).getMonth()) != (new Date().getMonth()))
                    // && ((new Date(initial_banner_html_closed_date).getFullYear()) != (new Date().getFullYear()))
                )
                ||
                (!window.localStorage.getItem('initial_banner_html_closed'))
            ) {
                // localStorage.removeItem("initial_banner_html_closed_times");
                // localStorage.removeItem("initial_banner_html_closed_date");

                // if (Object.values(nextProps.allProducts).length > 0) {
                // this.setState({ opened: true });
                // setTimeout(() => this.setState({ opened: true }), 5000)
                setTimeout(() => this.setState({ opened: true }), 1000)
                // }
            } else {
                this.setState({
                    opened: false
                })
            }
        }
    }

 
    handleSubmitButton = () => {
        this.setState({ opened: false });
    }

    productAlreadyAddedToCart = (pId) => {
        const { to_order } = this.props;

        let prodFound = false;
        // Object.values(to_order).map(o => prodFound = prodFound ? prodFound : parseInt(o.product_id) == parseInt(pId))
        Object.values(to_order).map(o => {
            if (o && o[0] && o[0].product_id) {
                prodFound = prodFound ? prodFound : parseInt(o[0].product_id) == parseInt(pId)
            }
        })

        // Object.values(to_order).map(o => console.log('devet', parseInt(o.product_id) == parseInt(pId)))

        return prodFound;

    }

    onClose = () => {
        let closedTimes = window.localStorage.getItem('initial_banner_html_closed_times');
        if (window.localStorage.getItem('initial_banner_html_closed_times')) {
            window.localStorage.setItem('initial_banner_html_closed_times', parseInt(closedTimes) + 1);
        } else {
            window.localStorage.setItem('initial_banner_html_closed_times', 1);
        }
        if (parseInt(closedTimes) + 1 > (parseInt(HOW_MANY_TIMES_TO_CLICK_CLOSE_FOR_STOP_SHOWING_ON_THE_SAME_DAY) - 1)) {
            window.localStorage.setItem('initial_banner_html_closed', 1);
            window.localStorage.setItem('initial_banner_html_closed_date', Date.now());
            window.localStorage.removeItem('initial_banner_html_closed_times');
        }
        this.setState({ opened: false });
        // this.props.trackProductClick('Fast order popup', 'close');
    }

    render() {
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations, allProducts } = this.props;

        return (
            <React.Fragment>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={this.state.opened}
                    onClose={this.onClose}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    // className={classes.modal}
                    data-class="dialog-container"
                >
                    {/* <CircularProgress className="is-loading language-modal" /> */}
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                        {/* {translations.pages && translations.pages.choose_place ? translations.pages.choose_place.title : ''} */}
                         Добре дошли
                    </DialogTitle>

                    <DialogContent className={classes.dialogContent} >
                        <DialogContentText className={classes.dialogContentText} >
                            <div className={classes.isMultipleOptionsModalContainer}>
                                <img src={Utils.getSettingsValue(this.props.settings, 'show_initial_html_banner')} width="100%" />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button
                            // background-color: #dbdbdb;
                            // color: #2a2a2a;
                            // style={{ backgroundColor: Object.values(this.props.productsToOrder).length == 0 ? '#dbdbdb' : '#ff5101', }}

                            className={classes.closeButton}
                            onClick={this.onClose}

                        // onClick={() => this.makeTheOrder()}
                        // color="red"
                        // data-class="initial-banner-fast-order-submit-button"
                        >
                            Затвори
                        </Button> */}
                        <Button
                            // disabled={Object.values(this.props.productsToOrder).length == 0}
                            // background-color: #dbdbdb;
                            // color: #2a2a2a;
                            // style={{ backgroundColor: Object.values(this.props.productsToOrder).length == 0 ? '#dbdbdb' : '#ff5101', }}
                            style={{ backgroundColor: '#ff5101' }}
                            className={classes.submitButton}
                            onClick={this.onClose}
                            color="red"
                            data-class="initial-banner-fast-order-submit-button">
                            Към менюто
                        </Button>
                    </DialogActions>

                </Dialog>
                {/* </Grow> */}
            </React.Fragment >
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        places: state.places.places || [],
        chosen_place: state.places.chosen_place || null,
        allProducts: state.products.all_products || [],
        products: state.products || [],
        productsToOrder: state.products.to_order || [],
        settings: state.settings.settings || [],
        to_order: state.products.to_order || [],
    };
};

const mapTrackingToProps = trackEvent => ({
    trackProductClick: (page, action_type, category_id = null, product_id = null) => trackEvent(productClick(page, action_type, category_id, product_id)),
    // trackPageView: (pageId, userId) => trackEvent(pageView(pageId, userId))
});

export default withTracking(mapTrackingToProps)(withStyles(styles)(connect(mapStateToProps, { getAllProducts, addToCart, changeSelectedCartTab, getCategoriesHierarchy, removeProductFromCart, setVariantModalOpened, setSelectedProduct })(InitialBannerFastOrder)
))