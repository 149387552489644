import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import Utils from './../../../utils/Utils';
import Auth from './../../../utils/Auth';
import clsx from 'clsx';
import ProductListItem from "./../../../products/components/ProductListItem";
import { withTracking } from 'react-tracker';
import { promotionClose, promotionClick } from './../../../tracking/events/promotionsEvents';
import { pageView } from './../../../tracking/events/productEvents';

import { addProdToCart, addToCartBtnClicked, setPromoProductForCategoryModalOpened } from './../../../cart/actions/cart.actions';
import { getAllProducts, setCommentModalData, setUpsellingProductsModalOpened } from './../../../products/actions/products.action';
import { getSettings } from './../../../settings/actions/settings.action';
import { getCategoriesHierarchy } from './../../../categories/actions/categories.action';

import { styles } from './../styles/upsellingProductModal.styles';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class UpsellingProductModal extends Component {
    constructor(props) {
        super(props);

        this.timer = null;
        this.state = {
            opened: false,
            upsellingProducts: [],
            timer: null

        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.upsellingProductsModalOpened == 1 && nextProps.productToUpsell) {
            let prods = Object.values(nextProps.products).filter(p => nextProps.productToUpsell.related_products.includes(p.id));

            this.setState({ opened: true, upsellingProducts: prods });
        } else {
            this.setState({ opened: false })                
        }
    }

    onModalClose = () => {
        this.setState({opened: false});
        this.props.setUpsellingProductsModalOpened(false, []);
        // this.props.setPromoProductForCategoryModalOpened(false);

        // if (this.props.currCat && this.props.promoProduct) {
        //     this.props.trackPromotionClose(this.props.currCat.id, this.props.promoProduct.id)
        // }
        // this.props.trackPageView(1, 1)
    }

    addToCartBtnClicked = (id, productVariantId = 0) => {
        // console.log("ID ", id, productVariantId);
        this.props.addToCartBtnClicked(this.props.products[id], productVariantId, this.props.settings);

        this.setState({opened: false});
        this.props.setUpsellingProductsModalOpened(false, []);
        // this.props.trackPromotionClick(this.props.currCat.id, this.props.promoProduct.id, 'add_to_cart');
    }

    addProdToCart = (id, variant_id, product) => {
        this.props.addProdToCart(id, variant_id, product);

        this.setState({opened: false});
        this.props.setUpsellingProductsModalOpened(false, []);
    }

    render() {
        // console.log("PROMO STATE", this.state, this.timer)
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations } = this.props;
        return (
            <React.Fragment>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={this.state.opened}
                    onClose={this.onModalClose}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    // className={classes.modal}
                    data-class="dialog-container"
                >
                    {/* <CircularProgress className="is-loading language-modal" /> */}
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                        {translations.modals && translations.modals.upselling_products.title ? translations.modals.upselling_products.title : ''}
                    </DialogTitle>

                    <DialogContent
                        className={classes.dialogContent}
                    >
                        <DialogContentText
                        // className={classes.dialogContentText}
                        >
                            <div className={classes.isMultipleOptionsModalContainer}>
                                {/* {this.props.promoProduct ? */}
                                {this.state.upsellingProducts.map(prod => (
                                    <ProductListItem
                                        singleProductStyles={true}
                                        product={prod}
                                        settings={this.props.settings}
                                        addProdToCart={this.addProdToCart}
                                        addToCartBtnClicked={this.addToCartBtnClicked}
                                        translations={this.props.translations}
                                        currLang={this.props.currLang}
                                        setCommentModalProps={(commentModalTitle, commentVariantId) => { this.props.setCommentModalData(true, commentModalTitle, commentVariantId) }}
                                        orderAllowed={true}

                                        // openCommentModal={() => { this.setState({ openCommentModal: true }) }}
                                    // modalTitle={() => { this.setState({ modalTitle: modalTitle }) }}
                                    // commentVariantId={() => { this.setState({ commentVariantId: commentVariantId }) }}
                                    />
                                ))}
                                    
                            </div>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions style={{ display: 'block' }}>
                        <Button
                            onClick={this.onModalClose}
                            style={{
                                color: 'black',
                                'background-color': '#e1e1e1',
                                border: '1px solid #b2b2b2',
                                width: '100%',
                            }}
                        >
                            Затвори
                        </Button>
                    </DialogActions>

                </Dialog>
                {/* </Grow> */}
            </React.Fragment>
        );
    }

}


const mapStateToProps = (state, ownProps) => {

    let currCatId = state.categories && state.categories.selected_cat_hierarchy_id ? state.categories.selected_cat_hierarchy_id : 0;
    let currCat = null;
    if (currCatId) {
        currCat = state.categories.categories_hierarchy[currCatId] || [];
    }

    return {
        upsellingProductsModalOpened: state.products.upsellingProductsModalOpened,
        productToUpsell: state.products.productToUpsell,
        products: state.products.all_products,
        currCat: currCat,
        translations: state.lang.translations,
        timeToPop: 3,
        currLang: state.lang.currLang,
        settings: state.settings.settings || [],
    };
};

const mapTrackingToProps = trackEvent => ({
    // trackPromotionClose: (category_id, product_id) => trackEvent(promotionClose(category_id, product_id)),
    // trackPromotionClick: (category_id, product_id, type) => trackEvent(promotionClick(category_id, product_id, type)),
    trackPageView: (pageId, userId) => trackEvent(pageView(pageId, userId))
});

export default withTracking(mapTrackingToProps)(withStyles(styles)(connect(mapStateToProps, {
    addProdToCart, addToCartBtnClicked, setCommentModalData, setUpsellingProductsModalOpened
})(UpsellingProductModal)));