import React, { useState, useEffect, Component } from 'react';
import { connect } from "react-redux";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SwipeableDrawer, Grow, CircularProgress, List, ListItem, ListItemText, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ReCAPTCHA from "react-google-recaptcha";
import { setTermsDialogOpened, setPrivacyPolicyDialogOpened } from '../../../menu/menu.actions'
import { setLoginModalOpened, login, setRegisterModalOpened, register } from './../../actions/users.actions';
import { addErrorMessages, addSuccessMessage } from './../../../messages/messages.actions';
import { styles } from './../../styles/loginModal.css'
import RegisterForm from './RegisterForm';
import { ChevronRight } from '@material-ui/icons';

class RegisterModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullname: '',
            email: '',
            password: '',
            phone: '',
            terms: false,
            "g-recaptcha-response": "",
        }
    }

    handleChange = (e, checkbox) => {
        const { name } = e.target;
        this.setState({ [name]: (checkbox ? e.target.checked : e.target.value) });
    }

    recaptchaOnChange = (val) => {
        this.setState({ "g-recaptcha-response": val })
    }

    goToLogin = () => {
        this.props.setRegisterModalOpened(false);
        this.props.setLoginModalOpened(true);

    }

    // validate = () => {
    //     let errors = [];
    //     if (this.state.email.length == 0) {
    //         errors.push("Невалидем имейл");
    //     }
    //     if (this.state.firstName.length == 0) {
    //         errors.push("Трябва да попълните име");
    //     }
    //     if (this.state.lastName.length == 0) {
    //         errors.push("Трябва да попълните фамилия");
    //     }
    //     if (this.state.password.length < 6) {
    //         errors.push("Паролата трябва да бъде поне 6 символа");
    //     }
    //     if (this.state.phone.length < 9) {
    //         errors.push("Моля попълнете валиден телефон");
    //     }

    //     return errors;
    // }

    onSubmit = (e) => {
        e.preventDefault();
        // let errors = this.validate();
        // if (errors.length > 0) {
        //     this.props.addErrorMessages(errors);
        //     return;
        // }

        this.props.register(this.state)
            .then(res => {
                this.goToLogin();
                this.props.addSuccessMessage("Успешна регистрация. Моля влезте в акаунта си :)")
            })
            .catch(err => {
                // console.log(err.response);
                if (err && err.response && err.response.data) {
                    this.props.addErrorMessages(err.response.data.error.errors);

                }
            })
    }

    render() {
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations, allProducts } = this.props;

        return (
            <React.Fragment>

                <SwipeableDrawer
                    open={this.props.registerModalOpened}
                    anchor={'right'}
                    onClose={() => this.props.setRegisterModalOpened(false)}
                    onOpen={() => this.props.setRegisterModalOpened(true)}
                    data-class="dialog-container"
                    className={classes.root}
                    disableSwipeToOpen
                    swipeAreaWidth={0}
                >
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                        <IconButton onClick={() => this.props.setRegisterModalOpened(false)} className={classes.closeDrawerButton}>
                            <ChevronRight />
                        </IconButton>

                        <div>
                            Регистрирай се
                            <div style={{ fontSize: 16 }}>
                                и събирай TABL точки
                            </div>
                        </div>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            <RegisterForm
                                translations={this.props.translations}
                                settings={this.props.settings}
                                user={this.state}
                                handleChange={this.handleChange}
                                recaptchaOnChange={this.recaptchaOnChange}
                                onSubmit={this.onSubmit}
                                addErrorMessages={this.props.addErrorMessages}
                                addSuccessMessage={this.props.addSuccessMessage}
                                setTermsDialogOpened={this.props.setTermsDialogOpened}
                                setPrivacyPolicyDialogOpened={this.props.setPrivacyPolicyDialogOpened}
                            />

                            <List>
                                <ListItem button onClick={this.goToLogin} className={classes.goToLogin}>
                                    <ListItemText primary={'Вече имаш профил? Влез'} />
                                </ListItem>
                            </List>
                        </DialogContentText>
                    </DialogContent>

                </SwipeableDrawer>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state, ownProps) => {

    return {
        settings: state.settings.settings || [],
        translations: state.lang.translations || [],
        loginModalOpened: state.users.login_modal_opened,
        registerModalOpened: state.users.register_modal_opened,
    };
};

export default withStyles(styles)(connect(mapStateToProps, { setLoginModalOpened, login, setRegisterModalOpened, register, addErrorMessages, addSuccessMessage, setTermsDialogOpened, setPrivacyPolicyDialogOpened })(RegisterModal))