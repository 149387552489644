import axios from 'axios';
import {
    SET_VARIANT_MODAL_OPENED, SET_SELECTED_PRODUCT, SET_PROMO_PRODUCT_FOR_CATEGORY_MODAL_OPENED
} from './types';
import { API_URL, DEFAULT_PRODUCT_IMAGE, BILL_WANTED_EXPIRE_TIME_MINUTES } from '../../constants'
import { openScanModal, setCurrOpenedModal, closeScanModal, setOrderModalState } from './../../qr-scan/actions/scan.actions';
import _ from 'lodash';
import Utils from '../../utils/Utils';
import Auth from '../../utils/Auth';
import history from './../../utils/history';
import { isEqual, sortBy, difference } from 'lodash';
import { changeSelectedCartTab, addToCart } from './../../products/actions/products.action';

export const addProdToCart = (product_id, product_variant_id, product) => dispatch => {
    const requiredProductIngridients = product.product_ingredients.filter(pi => pi.type == 'required');

    let defautRequiredProductIngridient = 0;
    if (requiredProductIngridients && requiredProductIngridients[0] && requiredProductIngridients[0].ingredientId) {
        defautRequiredProductIngridient = requiredProductIngridients[0].ingredientId;
    }

    const selectedIngredients = {
        required: defautRequiredProductIngridient,
        addable: [],
        removable: [],
    }

    // this.showAddedProductTooltip(product_id, product_variant_id, product, selectedIngredients);
    // this.props.changeSelectedCartTab(0); // select default tab "Cart"
    // this.props.addToCart(product_id, product_variant_id, product, selectedIngredients);

    dispatch(changeSelectedCartTab(0));
    dispatch(addToCart(product_id, product_variant_id, product, selectedIngredients));
    dispatch(setVariantModalOpened(false));

    dispatch(setPromoProductForCategoryModalOpened(false));
};

export const addToCartBtnClicked = (currProduct, productVariantId, settings) => dispatch => {
    if (settings && settings.variant_modal_with_comment && settings.variant_modal_with_comment.value == 1) {
        // this.setState({ variantModalOpen: true, selectedProduct: currProduct, variantModalWithComment: true })
        dispatch(setVariantModalOpened(true));
        dispatch(setSelectedProduct(currProduct));
        
    } else {
        if (currProduct.product_variants) {
            if (currProduct.product_variants.length === 1 || productVariantId > 0) {
                // this.addProdToCart(currProduct.id, productVariantId ? productVariantId : currProduct.product_variants[0].id, currProduct)
                dispatch(addProdToCart(currProduct.id, productVariantId ? productVariantId : currProduct.product_variants[0].id, currProduct));
            } else {
                // Open variant modal
                dispatch(setVariantModalOpened(true));
                dispatch(setSelectedProduct(currProduct));
        
                // this.setState({ variantModalOpen: true, selectedProduct: currProduct })
            }
        }
    }

}

export const setVariantModalOpened = (opened) => dispatch => {
    dispatch({
        type: SET_VARIANT_MODAL_OPENED,
        payload: opened
    })
}

export const setSelectedProduct = (product) => dispatch => {
    dispatch({
        type: SET_SELECTED_PRODUCT,
        payload: product
    })
}

export const setPromoProductForCategoryModalOpened = (opened) => dispatch => {
    dispatch({
        type: SET_PROMO_PRODUCT_FOR_CATEGORY_MODAL_OPENED,
        payload: opened
    })
}