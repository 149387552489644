import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Promotions from '../../common/containers/Promotions'
import Grid from '@material-ui/core/Grid';
import CartCommentModal from '../../common/modals/CartCommentModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import ProductVariantsModal from '../../common/modals/ProductVariant'
import ProductListItem from "../../products/components/ProductListItem";
import { getProductsBySubcat, addToCart, addProductTooltip, changeSelectedCartTab, setOrderComment, setCommentModalData } from '../../products/actions/products.action';
import { showHeaderCategory, setChosenCat, setCategoriesLoadedFlag, getCategories, setHierarchyLevel, setSelectedCatId } from '../actions/categories.action';
import { setMainBackground } from '../../settings/actions/settings.action';
import Utils from '../../utils/Utils';
import ImageButton from './ImageButton';
import RowButtonHierarchy from './RowButtonHierarchy';
import ImageCategoryImages from './ImageCategoryImages';
import ImageCategoryImagesNew from './ImageCategoryImagesNew';
import ProductsFooterContainer from '../../common/containers/ProductsFooterContainer'

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';


import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Redirect } from 'react-router-dom'
import Auth from "../../utils/Auth";
import clsx from 'clsx';
let scrollData = {};

const styles = theme => ({
    root: {
        // marginTop: 129,
        margin: '55px 0 0',
        // backgroundColor: 'black', //down from settings
        width: '100%',

        // Categories underline indicator
        '& .MuiTabs-indicator': {
            backgroundColor: '#FE6902'
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: 'inherit',
            fontWeight: 'bold'
        },
        '& > .MuiPaper-root': {
            zIndex: 1099,
            top: 0,
            position: 'relative',
            padding: '0'

        },
    },
    rootTabletMode: {
        margin: '65px 0 0',
        '& > .MuiPaper-root': {
            paddingTop: 10,
        },
    },
    initialTopWhiteContainerBugfix: {
        width: '100vw',
        height: '100px',
        background: 'white',
        position: 'absolute',
        margin: 0,
        top: 0,
    },
    categoryName: {
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        'flex-grow': 1,
        'max-width': 'none',
        lineHeight: 'initial',
        '&.Mui-selected': {
            color: '#fff !important',
            background: 'rgba(0, 0, 0, 0.74)',
        },
        'border-radius': 3,
        margin: '10px 5px 10px 0',
        color: '#fff',
        padding: 10,
        'min-height': 'auto',
        color: '#1f1f1f'
    },
    categoryNameTabletMode: {
        padding: 15,
    },
    categoryIcon: {
        height: 35,
    },
    swipeableViews: {
        height: 'calc(100vh - 114px)',
        WebkitOverflowScrolling: 'touch',
    },
    tabsContainer: {
        '& .MuiTabs-flexContainer': {
            'justify-content': 'space-between', //space-around
        },
    },
    productsContainer: {
        WebkitOverflowScrolling: 'touch',
        width: '90%',
        margin: '0 auto',
        paddingTop: 15, //DIFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF
        paddingBottom: 70, // temp unable to hive navbar fix
    },
    appBar: {
        zIndex: 1
    },
    backdrop: {
        zIndex: 2,
        color: '#fff',
        position: 'absolute !important', //backdrop not on the full screen
    },

    // Image gallery
    gridList: {
        // width: 500,
        height: 450,
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


class CategoriesHierarchyPage extends Component {
    constructor(props) {
        super(props);
        this.props.setCategoriesLoadedFlag(1); // Used for check in products
        this.state = {
            variantModalOpen: false,
            selectedProduct: null,
            variantModalWithComment: false,
            currHierarchyLevel: 1,
            openCommentModal: false,
            commentModalTitle: '',
            commentVariantId: '',
        }

    }

    componentDidUpdate() {
        const { action, location } = this.props.history;
        Utils.handleScrollToOnPop(action, location, this.props.scrollData);

        // if (action === "POP") {
        //     console.log('acscrollData[location.pathname]tion',scrollData[location.pathname])

        //     if (this.props.scrollData && this.props.scrollData[location.pathname]) {
        //         console.log('SCROLL TO: ', this.props.scrollData[location.pathname])
        //         setTimeout(() =>
        //             document.querySelector('[data-class="swipeable-container"]').scrollTo({
        //                 left: 0,
        //                 top: this.props.scrollData[location.pathname],
        //                 behavior: "smooth"
        //             })
        //         );
        //     } else {
        //          console.log('SCROLL TO TOP :(')
        //          if (document.querySelector('[data-class="swipeable-container"]')) {
        //              document.querySelector('[data-class="swipeable-container"]').scrollTo({ left: 0, top: 0 });
        //          }
        //     }
        // } else {
        //     console.log('SCROLL TO TOP  222222 :(')
        //     if (document.querySelector('[data-class="swipeable-container"]')) {
        //          setTimeout(document.querySelector('[data-class="swipeable-container"]').scrollTo({ left: 0, top: 0 }));
        //     }
        // }
    }



    componentWillMount() {
        // this.props.getCategories();
        this.props.showHeaderCategory(null);
    }

    componentDidMount() {
        this.props.setMainBackground('linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) )');
    }

    componentWillReceiveProps(nextProps) {
        // if(process.env == "production" && (!nextProps.client || Object.keys(nextProps.client).length === 0 && nextProps.client.constructor === Object)) {
        //     nextProps.history.push("/404");
        // }
        // initially setting the curr cat id in global store
        // console.log("CAT NEXT PROPS _________", nextProps, nextProps.history);
        // if(nextProps.selectedCatId == 0 && nextProps.selectedCat.id) {
        //     this.props.setSelectedCatId(nextProps.selectedCat.id);
        //     Utils.setDataForTrack(nextProps.selectedCat.id, nextProps.openedProductId, nextProps.history.location.pathname )
        // }
    }

    handleChange = (event, newValue) => {
        const { translations } = this.props;

        this.props.setChosenCat(newValue);
        // let catSlug = this.props.allCatsHierarchy && Object.values(this.props.allCatsHierarchy)[newValue] ? Object.values(this.props.allCatsHierarchy)[newValue].slug : "";
        let cat = this.props.categories && this.props.categories[newValue] ? this.props.categories[newValue] : [];
        if (cat) {
            this.props.setSelectedCatId(cat.id);

            if (cat.hierarchyLevel > 1) {
                // this.props.showHeaderCategory(cat.name)
                this.props.showHeaderCategory(translations && translations.translations && translations.translations.common ? translations.translations.common.action.back : '')
            }
            // gabrovo.pizza-change
            this.props.history.push("/" + cat.slug);
        }
        // let catSlug = this.props.categories && this.props.categories[newValue] ?  this.props.categories[newValue].slug : "";
        // let catLevel = this.props.categories && this.props.categories[newValue] ?  this.props.categories[newValue].hierarchyLevel : 1;
    };

    // On swipe event
    handleSwipeChangeIndex = index => {
        this.props.setChosenCat(index);
        // let catSlug = this.props.allCatsHierarchy && Object.values(this.props.allCatsHierarchy)[index] ? Object.values(this.props.allCatsHierarchy)[index].slug : "";
        let cat = this.props.categories && this.props.categories[index] ? this.props.categories[index] : null;

        let catSlug = cat ? cat.slug : "";
        let catId = cat ? cat.id : 0;
        this.props.setSelectedCatId(catId);
        // gabrovo.pizza-change
        this.props.history.push("/" + catSlug);
        Utils.handleScrollToOnPop("SWIPE")
    };

    categoryClickHandler = (id) => {
        const { translations } = this.props;
        this.props.setSelectedCatId(id);
        // const { subcategory } = this.props;
        var newLevel = this.props.allCatsHierarchy[id].hierarchyLevel;

        this.props.setHierarchyLevel(newLevel);
        if (parseInt(newLevel) > 1) {
            // this.props.showHeaderCategory(this.props.allCatsHierarchy[id].name)
            this.props.showHeaderCategory(translations && translations.translations && translations.translations.common ? translations.translations.common.action.back : '')
        }
        this.setState({ currHierarchyLevel: newLevel });
        this.props.setChosenCat(0);
        // gabrovo.pizza-change
        this.props.history.push("/" + this.props.allCatsHierarchy[id].slug);
    }


    // addToCartBtnClicked = (id) => {
    //     const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];

    //     if (currProduct.product_variants) {
    //         if (currProduct.product_variants.length === 1) {
    //             this.addProdToCart(currProduct.id, currProduct.product_variants[0].id, currProduct)
    //         } else {
    //             // Open variant modal
    //             this.setState({ variantModalOpen: true, selectedProduct: currProduct })
    //         }
    //     }

    // }



    // SAME
    // Opens variant modal or adds prod to cart
    addToCartBtnClicked = (id, productVariantId = 0) => {
        const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];
        // const currProduct = products.filter(p => p.id === id)[0];

        if (this.props.settings && this.props.settings.variant_modal_with_comment && this.props.settings.variant_modal_with_comment.value == 1) {
            this.setState({ variantModalOpen: true, selectedProduct: currProduct, variantModalWithComment: true })
        } else {
            if (currProduct.product_variants) {
                if (currProduct.product_variants.length === 1 || productVariantId > 0) {
                    this.addProdToCart(currProduct.id, productVariantId ? productVariantId : currProduct.product_variants[0].id, currProduct)
                } else {
                    // Open variant modal
                    this.setState({ variantModalOpen: true, selectedProduct: currProduct })
                }
            }
        }

    }

    addProdToCart = (product_id, product_variant_id, product) => {
        if (this.state.variantModalOpen) {
            this.setState({ variantModalOpen: false });
        }

        const requiredProductIngridients = product.product_ingredients.filter(pi => pi.type == 'required');

        let defautRequiredProductIngridient = 0;
        if (requiredProductIngridients && requiredProductIngridients[0] && requiredProductIngridients[0].ingredientId) {
            defautRequiredProductIngridient = requiredProductIngridients[0].ingredientId;
        }

        const selectedIngredients = {
            required: defautRequiredProductIngridient,
            addable: [],
            removable: [],
        }

        this.showAddedProductTooltip(product_id, product_variant_id, product, selectedIngredients);
        this.props.changeSelectedCartTab(0); // select default tab "Cart"
        this.props.addToCart(product_id, product_variant_id, product, selectedIngredients, this.props.selectedCat.id);
    };


    // Shows tooltip
    showAddedProductTooltip = (id, product_variant_id, product, selectedIngredients) => {
        // let totalProdQuantity = 1;
        // if (this.props.products && this.props.products.to_order && Object.keys(this.props.products.to_order).length > 0) {
        //     const addedProductVariant = this.props.products.to_order.find(pv => pv.product_variant_id === product_variant_id);
        //     if (addedProductVariant) {
        //         totalProdQuantity = parseInt(addedProductVariant.quantity) + 1;
        //     }
        // }

        let totalProdQuantity = 1;
        // if (this.props.products && this.props.products.to_order && this.props.products.to_order[0] && this.props.products.to_order[0].length > 0) {
        totalProdQuantity = Utils.getCartProductQuantity(product_variant_id, selectedIngredients);
        // }


        let choosedProductVariant = product.product_variants.filter(pv => pv.id === product_variant_id)[0];
        let tooltipText = totalProdQuantity + ' x ' + product.name + (choosedProductVariant.size && choosedProductVariant.size != ' ' ? ' - /' + choosedProductVariant.size + '/' : '');

        // Add to cart tooltip
        const addProdTooltip = (id, tooltipText) => this.props.addProductTooltip(id, tooltipText);
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null });
        }
        addProdTooltip(id, tooltipText);
        let timeOut = setTimeout(function () { addProdTooltip(0, tooltipText); }, 2500);
        this.setState({ timeout: timeOut });
    }
    // SAME ^


    addCommentToOrder = (variantId, comment) => {
        let stateComments = Object.values(this.props.products.order_comment);
        let validatedComments = Utils.validatedCommentObject(stateComments, variantId, comment)
        if (validatedComments) {
            this.props.setOrderComment(validatedComments)
        }
    }


    render() {
        const { classes, categories, chosenCat, settings, translations, isLoadingHierarchy } = this.props;
        const subcategories_design = settings.subcategories_design && settings.subcategories_design.value ? settings.subcategories_design.value : 1;

        const hasSubcategories = Object.values(this.props.allCatsHierarchy).findIndex(c => c.children.length > 0);

        return (
            <div className={Auth.isTabletMode() ? clsx(classes.root, classes.rootTabletMode) : classes.root}
                style={subcategories_design == '1'
                    && this.props.selectedCat && this.props.selectedCat.children && this.props.selectedCat.children.length > 0
                    && Utils.getSettingsValue(settings, 'remove_background_color_image_categories') != '1'
                    && Utils.getSettingsValue(settings, 'pdf_menu_enabled') != '1' ? { backgroundColor: '#e6e6e6' } : {}} data-class="all-products-container">

                {subcategories_design == '1' ?
                    <div className={classes.initialTopWhiteContainerBugfix}></div>
                    :
                    null
                }

                <Backdrop open={isLoadingHierarchy} className={classes.backdrop}>
                    <CircularProgress className="is-loading" />
                </Backdrop>

                <AppBar position="static" color="default" className={classes.appBar}
                    style={
                        (subcategories_design == '1') ?
                            {
                                backgroundImage: 'url(https://tabl.bg/img/white-shiny-background.jpg)',
                                backgroundPosition: 'bottom',
                                backgroundAttachment: 'fixed',
                                backgroundSize: 'cover',
                            }
                            :
                            {
                                background: 'rgba(241, 241, 241, 0.85)'
                            }
                    }>
                    <Tabs
                        value={this.props.catIndex}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        // variant="fullWidth"
                        scrollButtons="on"
                        variant="scrollable"
                        aria-label="full width tabs example"
                        className={classes.tabsContainer}
                    >
                        {Object.values(categories).map((category, k) => (
                            <Tab key={k} component="h2"
                                label={Utils.splitCatSubcatOnTwoRowsBySpace((category.translations[this.props.currLang] && category.translations[this.props.currLang].name || category.name), settings)}
                                // icon={<img className={classes.categoryIcon} src={Utils.getCategoryImagePath(category.icon)} />}
                                {...a11yProps(++k)}
                                className={Auth.isTabletMode() ? clsx(classes.categoryName, classes.categoryNameTabletMode) : classes.categoryName}
                                data-class="each-category"
                            />
                        ))}
                    </Tabs>
                </AppBar>

                {/* {Object.values(categories).filter(c => c.is_image_cat == 1).length > 0 ? */}

                {Utils.getSettingsValue(settings, 'pdf_menu_enabled') == 1 ?
                    <React.Fragment>

                        {Object.values(categories).map((category, k) => (
                            <TabPanel key={k} value={this.props.catIndex} index={k}>

                                {category.is_image_cat && category.is_image_cat == 1 ?
                                    <ImageCategoryImagesNew
                                        cat={category}
                                    />
                                    :
                                    <React.Fragment key={k}>
                                        {category.children.length == 0 ?
                                            <React.Fragment>
                                                <Promotions settings={settings} translations={translations.translations} />

                                                <Grid
                                                    className={classes.productsContainer}
                                                    container
                                                    direction="row"
                                                    //   justify="space-between"
                                                    //   justify="center"
                                                    // alignItems="center"
                                                    spacing={3}
                                                >

                                                    {Object.values(this.props.allProducts).sort(Utils.sortPriority())
                                                        .filter(product => product.catHierarchyIds.includes(category.id)).map((product, kkk) => (
                                                            <ProductListItem
                                                                key={kkk}
                                                                k={kkk}
                                                                // hideProductImage={1}
                                                                // showSubCatInProductHeader={1}
                                                                category={category}
                                                                catSlug={this.props.catSlug}
                                                                product={product}
                                                                settings={settings}
                                                                addProdToCart={this.addProdToCart}
                                                                addToCartBtnClicked={this.addToCartBtnClicked}
                                                                translations={translations}
                                                                currLang={this.props.currLang}
                                                                setCommentModalProps={(commentModalTitle, commentVariantId) => { this.props.setCommentModalData(true, commentModalTitle, commentVariantId) }}
                                                            // openCommentModal={() => { this.setState({ openCommentModal: true }) }}
                                                            // modalTitle={() => { this.setState({ modalTitle: modalTitle }) }}
                                                            // commentVariantId={() => { this.setState({ commentVariantId: commentVariantId }) }}
                                                            />
                                                        ))
                                                    }
                                                </Grid>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                {subcategories_design == '1' ?
                                                    <React.Fragment>
                                                        <Promotions settings={settings} translations={translations.translations} />
                                                        <ImageButton
                                                            items={category.children}
                                                            categoryClickHandler={this.categoryClickHandler}
                                                            currLang={this.props.currLang}
                                                        />
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <Promotions settings={settings} translations={translations.translations} />
                                                        <RowButtonHierarchy
                                                            items={category.children}
                                                            categoryClickHandler={this.categoryClickHandler}
                                                            translations={translations.translations}
                                                            currLang={this.props.currLang}
                                                        />
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                    </React.Fragment>

                                }
                            </TabPanel>
                        ))}

                    </React.Fragment>
                    :

                    <SwipeableViews className={classes.swipeableViews}
                        data-class="swipeable-container"
                        index={this.props.catIndex}
                        onChangeIndex={this.handleSwipeChangeIndex}
                        enableMouseEvents
                        animateTransitions={false}
                    >


                        {Object.values(categories).map((category, k) => (
                            <TabPanel key={k} value={this.props.catIndex} index={k}>

                                {category.is_image_cat && category.is_image_cat == 1 ?
                                    <ImageCategoryImagesNew
                                        cat={category}
                                    />
                                    :
                                    <React.Fragment key={k}>
                                        {category.children.length == 0 ?
                                            <React.Fragment>
                                                {hasSubcategories == -1 ?
                                                    <Promotions settings={settings} translations={translations.translations} />
                                                    :
                                                    null
                                                }

                                                <Grid
                                                    className={classes.productsContainer}
                                                    container
                                                    direction="row"
                                                    //   justify="space-between"
                                                    //   justify="center"
                                                    // alignItems="center"
                                                    spacing={3}
                                                >

                                                    {Object.values(this.props.allProducts).sort(Utils.sortPriority())
                                                        .filter(product => product.catHierarchyIds.includes(category.id)).map((product, kkk) => (
                                                            <ProductListItem
                                                                key={kkk}
                                                                k={kkk}
                                                                // hideProductImage={1}
                                                                // showSubCatInProductHeader={1}
                                                                category={category}
                                                                catSlug={this.props.catSlug}
                                                                product={product}
                                                                settings={settings}
                                                                addProdToCart={this.addProdToCart}
                                                                addToCartBtnClicked={this.addToCartBtnClicked}
                                                                translations={translations}
                                                                currLang={this.props.currLang}
                                                                setCommentModalProps={(commentModalTitle, commentVariantId) => { this.props.setCommentModalData(true, commentModalTitle, commentVariantId) }}
                                                                orderAllowed={category.order_allowed}
                                                            // openCommentModal={() => { this.setState({ openCommentModal: true }) }}
                                                            // modalTitle={() => { this.setState({ modalTitle: modalTitle }) }}
                                                            // commentVariantId={() => { this.setState({ commentVariantId: commentVariantId }) }}
                                                            />
                                                        ))
                                                    }

                                                </Grid>

                                                {Utils.getSettingsValue(settings, 'show_footer') != '' ?
                                                    <ProductsFooterContainer settings={settings} translations={translations} />
                                                    :
                                                    null
                                                }

                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                {subcategories_design == '1' ?
                                                    <React.Fragment>
                                                        <Promotions settings={settings} translations={translations.translations} />
                                                        <ImageButton
                                                            items={category.children}
                                                            categoryClickHandler={this.categoryClickHandler}
                                                            currLang={this.props.currLang}
                                                        />
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <Promotions settings={settings} translations={translations.translations} />
                                                        <RowButtonHierarchy
                                                            items={category.children}
                                                            categoryClickHandler={this.categoryClickHandler}
                                                            translations={translations.translations}
                                                            currLang={this.props.currLang}
                                                        />
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                    </React.Fragment>

                                }
                            </TabPanel>
                        ))}

                    </SwipeableViews>
                }

                {
                    this.state.openCommentModal ?
                        <CartCommentModal
                            open={this.state.openCommentModal}
                            modalTitle={this.state.commentModalTitle}
                            commentVariantId={this.state.commentVariantId}
                            comments={this.props.products.order_comment}
                            onClose={() => { this.setState({ openCommentModal: false }) }}
                            onSubmit={this.addCommentToOrder}
                        />

                        :
                        null
                }
                <ProductVariantsModal
                    open={this.state.variantModalOpen}
                    variantModalWithComment={this.state.variantModalWithComment}
                    product={this.state.selectedProduct}
                    addProdToCart={this.addProdToCart}
                    order_comment={this.props.products.order_comment}
                    setOrderComment={this.props.setOrderComment}
                    closeProductVariantModalHandler={() => this.setState({ variantModalOpen: !this.state.variantModalOpen })}
                    settings={settings}
                    translations={translations}
                    currLang={this.props.currLang}
                />

            </div >
        );

    }
}

const mapStateToProops = (state, ownProps) => {
    var catsHierarchy = state.categories.categories_hierarchy || [];
    let catSlug = ownProps.match.params.catSlug !== "null" ? ownProps.match.params.catSlug : "";

    let selectedCat = catsHierarchy && Object.values(catsHierarchy).filter(c => c.slug === catSlug)[0] ? Object.values(catsHierarchy).filter(c => c.slug === catSlug).sort(Utils.sortPriority())[0] : null;

    if (!selectedCat) {
        let mainParent = catsHierarchy && Object.values(catsHierarchy).filter(c => c.hierarchyLevel == 1).sort(Utils.sortPriority())[0];
        selectedCat = mainParent || []; //catsHierarchy && Object.values(catsHierarchy)[0] ? Object.values(catsHierarchy)[0] : [];
    }


    var level = selectedCat.hierarchyLevel; // state.categories.curr_hierarchy_level;
    var currCats = Object.values(catsHierarchy).filter(c => c.hierarchyLevel === level && c.parentId === selectedCat.parentId);
    currCats = currCats.sort(Utils.sortPriority());
    let catIndex = currCats && Object.values(currCats).findIndex(e => e.id === selectedCat.id);

    if (catIndex === -1) catIndex = 0;

    let selectedCatChildren = Object.values(catsHierarchy).filter(c => c.hierarchyLevel === selectedCat.hierarchyLevel + 1 && c.parentId === selectedCat.id);
    selectedCat.children = selectedCatChildren;

    // if(selectedCat) {
    //     setHierarchyLevel(selectedCat.hierarchyLevel);
    // }


    return {
        catSlug: catSlug || "",
        placeSlug: ownProps.match.params.place !== "null" ? ownProps.match.params.place : "",
        allCatsHierarchy: catsHierarchy,
        categories: currCats, //state.categories.categories,
        catIndex: catIndex,
        selectedCat: selectedCat,
        selectedCatId: state.categories.selected_cat_hierarchy_id || 0,
        openedProductId: state.products.view_product_page_id || 0,
        client: state.client || null,

        isLoadingHierarchy: state.categories.hierarchy_loading || false,
        subcategory: state.products.subcat_products,
        chosenCat: state.categories.chosen_cat || 0,
        settings: state.settings.settings || [],
        translations: state.lang,
        currLang: state.lang.currLang,
        products: state.products || [],
        allProducts: state.products.all_products || [],
        scrollData: state.menu.scrollData || null
    };
};

export default withStyles(styles)(connect(mapStateToProops, {
    showHeaderCategory, getProductsBySubcat, setChosenCat, setCategoriesLoadedFlag,
    getCategories, addToCart, addProductTooltip, changeSelectedCartTab, setMainBackground, setOrderComment,
    setHierarchyLevel, setCommentModalData, setSelectedCatId
})(CategoriesHierarchyPage));
