import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import Fade from '@material-ui/core/Fade';
import { showHeaderCategory } from '../../../categories/actions/categories.action';
import { setMainBackground } from '../../../settings/actions/settings.action';
import { editProfile } from './../../actions/users.actions';
import { addErrorMessages, addSuccessMessage } from '../../../messages/messages.actions';
import Utils from "../../../utils/Utils";
import clsx from 'clsx';
import ProfileEditForm from './ProfileEditForm';

const styles = theme => ({
    profilePageContainer: {
        // backgroundColor: 'rgba(147, 147, 147, 0.4)',
        minHeight: 'calc(100vh - 55px)',
        marginTop: 55,
        paddingLeft: '10%',
        paddingRight: '10%',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    },
    feedBackTitle: {
        color: '#efefef',
        textAlign: 'center',
        fontSize: 18,
        marginTop: 72,
    },
    feedBackTitleDark: {
        color: '#1e1e1e',
        marginTop: 72,
    }
});

class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullname: this.props.user.fullname || "",
            email: this.props.user.email || "",
            phone: this.props.user.phone || "",
            address: this.props.user.address || "",
        }
    }

    componentDidMount() {
        this.props.setMainBackground('linear-gradient( rgba(147, 147, 147, 0.6), rgba(147, 147, 147, 0.6) )');

    }

    componentDidUpdate() {
        let { translations } = this.props;
        this.props.showHeaderCategory(translations && translations.common ? translations.common.action.back : '')
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user.id) {
            this.setState({
                fullname: nextProps.user.fullname || "",
                email: nextProps.user.email || "",
                phone: nextProps.user.phone || "",
                address: nextProps.user.address || "",
            })
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value });
    }

    handleFormSubmit = () => {
        let post = {
            fullname: this.state.fullname || "",
            phone: this.state.phone || "",
            address: this.state.address || "",
            email: this.state.email || "",
            password: this.state.password || null
        }
        this.props.editProfile(post);
    }

    render() {
        const { classes, feedback_questions, feedback_answers, translations, settings } = this.props;
        this.props.showHeaderCategory(translations && translations.common ? translations.common.action.back : '')

        if (!this.props.user.id) {
            return null;
        }


        return (
            <Fade in={true} timeout={{ enter: 350, exit: 350 }}>

                <div className={classes.profilePageContainer}>
                    {/* <br /> */}

                    {/* <h2 className="">{translations.pages && translations.pages.profile ? translations.pages.profile.title : ''}</h2> */}
                    {/* <h2 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.feedBackTitle, classes.feedBackTitleDark) : classes.feedBackTitle}>
                        {translations.common.hi}, {this.state.first_name} !
                    </h2> */}

                    <ProfileEditForm
                        user={this.state}
                        onChange={this.onChange}
                        handleFormSubmit={this.handleFormSubmit}
                        translations={this.props.translations}
                        settings={this.props.settings}
                        addErrorMessages={this.props.addErrorMessages}
                        addSuccessMessage={this.props.addSuccessMessage}
                    />
                </div>
            </Fade>
        )
    }

}


const mapStateToProps = (state, ownProps) => {

    return {
        translations: state.lang.translations || [],
        settings: state.settings.settings || [],
        user: state.users.user || [],
        clientSlug: state.client.slug || "",
        // message: state.feedback.message || null
    };
    // catName: state.name,
    // products: state.products
};

export default withStyles(styles)(connect(mapStateToProps, { showHeaderCategory, setMainBackground, editProfile, addErrorMessages, addSuccessMessage })(ProfilePage));
