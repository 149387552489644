import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles, withTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import WarningIcon from '@material-ui/icons/Warning';
import SwipeableViews from 'react-swipeable-views';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SettingsIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
// import StepConnector from '@material-ui/core/StepConnector';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CachedIcon from '@material-ui/icons/Cached';
import InfoIcon from '@material-ui/icons/Info';
import RateReviewIcon from '@material-ui/icons/RateReview';
import AddCommentIcon from '@material-ui/icons/AddComment';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import CartModalEachCartItem from './CartModalEachCartItem'

import { changeSelectedCartTab, setOrderComment, removeUserTokenAndOrder, setCommentModalData, setDisabledMakeOrderButton, removeProductFromCart, updateProductQuantity } from '../../../products/actions/products.action';

import { setOrderModalState } from '../../../qr-scan/actions/scan.actions';
import { setAlertMessage } from '../../../menu/menu.actions';
import { DEFAULT_PRODUCT_IMAGE } from '../../../constants';
import CartCommentModal from '../CartCommentModal';
import PaymentTypeModal from '../PaymentModal';
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth';

import { styles, useColorlibStepIconStyles, ColorlibConnector } from '../styles/cardModal.styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CartModal extends Component {

    callMakeOrder = (eachCartItem) => {
        const { settings, onSubmit, onError, translations } = this.props;
        const disabledOrdering = Utils.disabledOrdering(settings);

        if (this.props.chosenPlaceOrderTypes.length == 0) {
            onError(translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.call_waiter_to_order ? translations.pages.cart_delivery.call_waiter_to_order : '');
        } else if (disabledOrdering && this.props.chosenPlaceOrderTypes.length == 1) {
            onError(Utils.getDisabledOrderingMessage(translations, settings));
        } else {
            if (eachCartItem.length > 0) {
                onSubmit()
            }
        }
    }

    productVariantCounts = (selectedQuantity) => {
        let menuItems = [];
        let maxShowQuantity = 10;
        maxShowQuantity = Math.max(maxShowQuantity, selectedQuantity)

        for (let i = 1; i <= maxShowQuantity; i++) {
            menuItems.push(< MenuItem value={i}>{i}</MenuItem >)
        }

        return menuItems;
    }

    makeOrderButtonHandler = (eachCartItem) => {
        const { settings, productIngredients, productsToOrder, translations } = this.props;

        const cartPrice = Utils.calculateTotalPriceForOrder(productsToOrder, settings, productIngredients)
        const minimal_order_price = parseFloat(Utils.getSettingsValue(this.props.settings, 'minimal_order_price'))

        if (cartPrice < minimal_order_price && (this.props.chosenPlaceOrderTypes.length == 1 && this.props.chosenPlaceOrderTypes[0] && this.props.chosenPlaceOrderTypes[0].type == 'delivery')) {
            this.props.setAlertMessage(true, Utils.translate(translations && translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.minimal_order_price ? translations.pages.cart_delivery.minimal_order_price : '', { value: minimal_order_price + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') }))
        } else {
            this.props.setDisabledMakeOrderButton(true);
            this.callMakeOrder(eachCartItem);
        }
    }

    render() {
        const { classes, open, handleRemove, onClose, onSubmit, onError, askForBill, settings, translations, isLoadingCart, productIngredients, productsToOrder } = this.props;
        const productsToOrderLength = productsToOrder ? Object.keys(productsToOrder).length : 0;
        let eachCartItem = [];

        const noCartItemsText = <p className={classes.noCartItemsContainer}>
            <WarningIcon className={classes.noCartItemsWarningIcon} />
            <div className={classes.noItemsInCart}>{translations && translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.no_cart_items : ''}</div>
        </p>


        Object.values(productsToOrder).map((item, k) => {
            eachCartItem.push(
                <React.Fragment>
                    <ListItem button key={k} className={clsx(classes.eachCartItemRow, k === 0 ? "mt-1" : '')}>
                        {/* <img src={Utils.getProductImagePath(item.image)} alt="" className={classes.productCircularImage} /> */}
                        <img src={'https://tabl.bg/img/icons/blogging' + (this.props.products.order_comment.findIndex(p => p.variantId === item.product_variant_id && p.comment.length > 0) !== -1 ? '-active' : '') + '.svg'} alt="" className={classes.commentIcon} onClick={() => this.props.setCommentModalData(true, item.name, item.product_variant_id) /*this.setState({ openCommentModal: true, commentModalTitle: item.name, commentVariantId: item.product_variant_id })*/} />
                        {/* <AddCommentIcon className={clsx(classes.addCommentIconBtn, (this.state.comments.findIndex(p => p.variantId === item.product_variant_id && p.comment.length > 0) !== -1 ? classes.activeAddCommentIconBtn : ''))} onClick={() => this.setState({ openCommentModal: true, commentModalTitle: item.name, commentVariantId: item.product_variant_id })} /> */}
                        <ListItemText
                            primary={(item.product_translations && item.product_translations[this.props.currLang] && item.product_translations[this.props.currLang].name || item.name) + (((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && ((parseFloat(item.additional_price)).toFixed(2) != '0.00')) ? (' + ' + (translations.common && translations.common.box ? translations.common.box : '') + ' ' + item.additional_price + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.')) : '')}
                            secondary={
                                <React.Fragment>
                                    <span>{item.variant_translations && item.variant_translations[this.props.currLang] && item.variant_translations[this.props.currLang].size || item.size}</span>
                                    {item.ingredients ?
                                        <React.Fragment>
                                            {item.ingredients.required ?
                                                <div className={classes.ingredientsListRequired}>{Utils.getIngredientNamesByIds(item.ingredients.required, this.props.productIngredients)}</div>
                                                :
                                                null
                                            }

                                            {item.ingredients.addable && Object.values(item.ingredients.addable).length > 0 ?
                                                <div className={classes.ingredientsListAddable}>+ {Utils.getIngredientNamesByIds(item.ingredients.addable, this.props.productIngredients)}</div>
                                                :
                                                null
                                            }

                                            {item.ingredients.removable && Object.values(item.ingredients.removable).length > 0 ?
                                                <div className={classes.ingredientsListRemovable}>- {Utils.getIngredientNamesByIds(item.ingredients.removable, this.props.productIngredients)}</div>
                                                :
                                                null
                                            }
                                        </React.Fragment>
                                        :
                                        null
                                    }
                                </React.Fragment>
                            }
                            // secondary={item.size} 
                            className={classes.listItemText} />
                        <ListItemSecondaryAction className={classes.listItemRightText}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={item.quantity}
                                className={classes.quantitySelect}
                                onChange={(e) => this.props.updateProductQuantity(item, e.target.value)}
                            >
                                {this.productVariantCounts(item.quantity)}
                            </Select>

                            <span>
                                x <span className={classes.priceContainer}>
                                    {Utils.calculateProductPriceOrder(productsToOrder[k], settings, productIngredients, 1)}
                                    &nbsp;
                                    {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}</span>
                            </span>

                            <IconButton aria-label="delete" className={classes.deleteOrderIcon} onClick={() => this.props.removeProductFromCart(item)} data-class="remove-product-from-cart-btn">
                                <HighlightOffIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {(k + 1) !== productsToOrderLength ? <Divider className={classes.productDivider} /> : null}
                </React.Fragment>
            );
        });

        // let delivery_price_num = 0
        // let min_price_free_delivery_num = 0

        // if (settings.delivery_price && settings.delivery_price.value && settings.delivery_price.value > 0) {
        //     const default_currency_suffix = (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.')

        //     delivery_price_num = (settings.delivery_price && settings.delivery_price.value) ? parseFloat(settings.delivery_price.value).toFixed(2) : '';
        //     const deliveryPrice = delivery_price_num + default_currency_suffix;

        //     min_price_free_delivery_num = (settings.min_price_free_delivery && settings.min_price_free_delivery.value) ? parseFloat(settings.min_price_free_delivery.value).toFixed(2) : '';
        //     const minPriceFreeDelivery = min_price_free_delivery_num + default_currency_suffix;

            // Its not used temporarry

            // if ((this.props.chosenPlaceOrderTypes.findIndex(p => p.type == "delivery") > -1) && (this.props.chosenPlaceOrderTypes.length == 1)) {// Only if delivery
            //     eachCartItem.push(
            //         <React.Fragment>
            //             <ListItem button className={classes.deliveryContainer}>
            //                 <ListItemText
            //                     primary={(translations.common && translations.common.delivery ? translations.common.delivery + ': ' : '') + deliveryPrice}
            //                     secondary={translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.min_delivery_price_text ? Utils.translate(translations.pages.cart_delivery.min_delivery_price_text, { value: minPriceFreeDelivery }) : ''}
            //                     className={classes.deliveryContent}
            //                 />
            //             </ListItem>
            //         </React.Fragment>
            //     );
            // }
        // }

        return (


            <List className={classes.list}>

                {productsToOrderLength == 1 ? <br /> : null}
                {productsToOrderLength > 0 ? eachCartItem : noCartItemsText}
                {productsToOrderLength == 1 ? <br /> : null}

                {

                    // Show delivery prices information
                    (
                        (settings.visible_about_page_delivery_prices_text_in_cart && settings.visible_about_page_delivery_prices_text_in_cart.value && settings.visible_about_page_delivery_prices_text_in_cart.value == 1)
                        && (settings.about_page_delivery_prices && settings.about_page_delivery_prices.value)
                        && (this.props.chosenPlaceOrderTypes.findIndex(p => p.type == "delivery") > -1)
                        && (productsToOrderLength > 0)
                    ) ?
                        <div className={classes.deliveryPricesInfo}>
                            <div className={classes.deliveryPricesText}>{translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.delivery_price ? translations.pages.cart_delivery.delivery_price : ''}</div>

                            <div
                                dangerouslySetInnerHTML={{
                                    __html: settings.about_page_delivery_prices && settings.about_page_delivery_prices.value ? settings.about_page_delivery_prices.value.replace('<strong>', '').replace('</strong>', '') : null
                                }}>
                            </div>
                        </div>
                        :
                        null
                }

                {productsToOrderLength > 0 ?
                    <React.Fragment>
                        <div className="ordered-arrow-up"></div>

                        <div className={classes.footerContainer} data-class="cart-modal-footer-container" style={{ display: Auth.isTabletMode() ? 'block' : 'grid' }}>
                            <div className={settings.show_delivery_price_not_included_text && settings.show_delivery_price_not_included_text.value && settings.show_delivery_price_not_included_text.value == 1 ? clsx(classes.totalSum, classes.totalSumSmallerPadding) : classes.totalSum}>
                                {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.total : ''}: <span className="skiptranslate">
                                    {Utils.calculateTotalPriceForOrder(productsToOrder, settings, productIngredients)}
                                </span> {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}

                                {settings.show_delivery_price_not_included_text && settings.show_delivery_price_not_included_text.value && settings.show_delivery_price_not_included_text.value == 1 ? <React.Fragment><br />{translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.delivery_price_is_not_included : ''}</React.Fragment> : ''}
                            </div>

                            {!Auth.isTabletMode() ?
                                <Button
                                    color="inherit"
                                    className={clsx(classes.footerButton, 'shy')}
                                    onClick={() => this.makeOrderButtonHandler(eachCartItem)}
                                    disabled={this.props.disabledMakeOrderButton}
                                >
                                    <div>{this.props.chosenPlaceOrderTypes.length == 1 && this.props.chosenPlaceOrderTypes[0] && this.props.chosenPlaceOrderTypes[0].type == 'delivery' ? (translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.make_delivery_order : '') : (translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.make_order : '')}</div>

                                    {(window.localStorage.getItem('bill_wanted') && Auth.getUserToken()) ?
                                        <div className={classes.billAlreadyWantedInfoLabel}>
                                            {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.bill_already_wanted : ''}
                                        </div>
                                        :
                                        null
                                    }
                                </Button>
                                :
                                null
                            }

                        </div>
                    </React.Fragment>
                    :
                    null
                }

            </List>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let chosenPlaceOrderTypes = [];
    let chosenPlaceId = state.places.chosen_place;
    let chosenPlace = state.places.places.filter(p => p.id == chosenPlaceId);
    if (chosenPlace.length == 0) {
        chosenPlace = null;
    } else {
        chosenPlace = chosenPlace[0];
        chosenPlaceOrderTypes = chosenPlace.ordertypes;
    }

    return {
        selectedCartTab: state.products.selected_cart_tab || 0,
        orderedProducts: state.products.ordered || [],
        orderSent: state.products.order_sent || null,
        settings: state.settings.settings || [],
        products: state.products || [],
        productsById: state.products.all_products || [],
        isLoadingCart: state.products.isLoadingCart || false,
        orderMessage: state.products.order_message || '',
        orderModalState: state.scan.order_modal_state,
        chosenPlaceOrderTypes: chosenPlaceOrderTypes,
        disabledMakeOrderButton: state.products.disabledMakeOrderButton,
        productsToOrder: Utils.normalizeCartProducts(state.products.to_order) || [],
        productIngredients: state.products.productIngredients || [],
        translations: state.lang.translations,
        currLang: state.lang.currLang,
    };
};

export default withStyles(styles)(connect(mapStateToProps, { changeSelectedCartTab, setOrderComment, removeUserTokenAndOrder, setOrderModalState, setCommentModalData, setDisabledMakeOrderButton, removeProductFromCart, updateProductQuantity, setAlertMessage })(CartModal))