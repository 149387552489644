import React, { Component } from "react";
import { connect } from "react-redux";
import { verifyToken, verifyTokenAndSession } from './../actions/scan.actions';
import { setChosenPlace, verifyOpenedPlace } from './../../places/actions/places.actions';
import { getAllProducts, getProductIngredients } from './../../products/actions/products.action';
import { getRegions } from './../../regions/regions.actions'

import { getCategoriesHierarchy } from './../../categories/actions/categories.action';
import { getSettings } from './../../settings/actions/settings.action';

import QrReader from "react-qr-reader";
import Utils from "../../utils/Utils";
import Auth from "../../utils/Auth";
import { withStyles } from '@material-ui/styles';
import queryString from 'query-string';

class VerifyTokenPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }

    }

    componentDidMount() {
        // console.log("SENDING TOKEN ", this.props.token);

        var self = this;

        this.props
            .verifyTokenAndSession(this.props.token)
            .then(res => {
                if (res.data.payload) {
                    let placeId = res.data.payload.place_id;
                    self.props.verifyOpenedPlace(placeId); // if place is different -> clears local card, orders and token; !IMPORTANT - set it first cuz it may remove the token 
                    
                    Auth.setUserToken(res.data.payload.session);
                    
                    self.props.setChosenPlace(placeId)
                    if(res.data.payload.ordertype) {
                        Auth.setTokenOrderType(res.data.payload.ordertype);

                        // TABLET MODE
                        if(res.data.payload.ordertype == 5) {
                            Auth.setTabletMode();
                        } else {
                            Auth.removeTabletMode();
                        }
                    }

                    if((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && res.data.payload.place_id) {
                        this.props.setChosenPlace(placeId);
                        this.props.getCategoriesHierarchy(placeId)
                        this.props.getAllProducts(0, placeId)
                        this.props.getSettings(placeId);
                        this.props.getProductIngredients();
                        this.props.getRegions();
                    } 
                    
                    // self.props.getAllProducts(0);
                    // self.props.getCategoriesHierarchy();
                }
                this.props.history.push(`/${this.props.placeSlug}`);
            })
            .catch(err => {
                console.log("REMOVING USER TOKEN")
                Auth.removeUserToken();

                // for app functionality - close webview and go to app scan page
                if (window.localStorage.getItem("app")) {
                    window.location.pathname = "/close-browser-app";
                } else {
                    this.props.history.push(`/${this.props.placeSlug}`);
                }

            })
    }

    render() {

        return (

            <div></div>

        )

    }

}


const mapStateToProps = (state, ownProps) => {
    let token = ownProps.match.params.token;
    const queryStringParams = queryString.parse(window.location.search);
    if (queryStringParams.app) {
        window.localStorage.setItem("app", true);
    } else {

    }

    return {
        token: token,
        placeSlug:  ownProps.match.params.place !== "null" ? ownProps.match.params.place : "",
        placesCount: state.client.places && state.client.places.length || 0
    };
};
export default connect(mapStateToProps, { verifyToken, verifyTokenAndSession, setChosenPlace, 
    getAllProducts, getCategoriesHierarchy, verifyOpenedPlace, getSettings, getProductIngredients, getRegions })(VerifyTokenPage);

// export default (connect(mapStateToProps)(ScanPage));