import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";
// import FeedbackAnswersList from './FeedbackAnswersList';
// import FeedbackQuestions from './FeedbackQuestions';
import Fade from '@material-ui/core/Fade';
import { getFeedbackQuestions, sendFeedback, getFeedbackAnswers } from '../../../feedback/actions/feedback.actions';
import { clientRequest } from '../../../client/client.actions';
import { setMainBackground } from '../../../settings/actions/settings.action';
import { } from '../../../settings/actions/settings.action';
import { showHeaderCategory } from './../../../categories/actions/categories.action'
import Utils from "../../../utils/Utils";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ReCAPTCHA from "react-google-recaptcha";
import MessagesContainer from './../../../messages/MessagesContainer';

import clsx from 'clsx';

import { styles } from './../styles/styles'

class FeedbackPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneCartLeftStyle: {
                top: 130,
                left: '70%',
                // right: '70%',
                // bottom: '20%',
            },
            phoneCartRightStyle: {
                top: 130,
                left: '70%',
                // right: '70%',
                // bottom: '20%',
            },

        }
    }


    componentDidMount() {
        const changeShadow = (evt) => {
            if (evt.gamma < 0 && evt.beta < 0) {
                // right-bottom
                let rightBottomShadow = '-1px -1px 0 #cccccc00, -2px -2px 0 #c9c9c900, -3px -3px 0 #bbbbbb00, -4px -4px 0 #b9b9b900, -5px -5px 0 #aaaaaa00, -6px -6px 1px rgba(0,0,0,0), 0 0 5px rgba(0,0,0,0), -1px -1px 3px rgba(0,0,0,0), -3px -3px 5px rgba(0,0,0,0), -5px -5px 10px rgba(0,0,0,0), -10px -10px 10px rgba(0,0,0,0), -20px -20px 20px rgba(0,0,0,0), transition: all 1s ease-in-out;'
                this.setState({
                    phoneCartLeftStyle: { top: 130, left: '70%', boxShadow: rightBottomShadow },
                    phoneCartRightStyle: { top: 130, left: '70%', boxShadow: rightBottomShadow }
                })
            }

            if (evt.gamma > 0 && evt.beta > 0) {
                // left-top
                let leftTopShadow = '1px 1px 0 #cccccc00, 2px 2px 0 #c9c9c900, 3px 3px 0 #bbbbbb00, 4px 4px 0 #b9b9b900, 5px 5px 0 #aaaaaa00, 6px 6px 1px rgba(0,0,0,0), 0 0 5px rgba(0,0,0,0), 1px 1px 3px rgba(0,0,0,0), 3px 3px 5px rgba(0,0,0,0), 5px 5px 10px rgba(0,0,0,0), 10px 10px 10px rgba(0,0,0,0), 20px 20px 20px rgba(0,0,0,0), transition: all ease-in-out .66s;';
                this.setState({
                    phoneCartLeftStyle: { top: 160, left: '75%', boxShadow: leftTopShadow },
                    phoneCartRightStyle: { top: 160, left: '75%', boxShadow: leftTopShadow }
                })
            }

            if (evt.gamma > 0 && evt.beta < 0) {
                // left-bottom
                let leftBottomShadow = '1px -1px 0 #cccccc00, 2px -2px 0 #c9c9c900, 3px -3px 0 #bbbbbb00, 4px -4px 0 #b9b9b900, 5px -5px 0 #aaaaaa00, 6px -6px 1px rgba(0,0,0,0), 0 0 5px rgba(0,0,0,0), 1px -1px 3px rgba(0,0,0,0), 3px -3px 5px rgba(0,0,0,0), 5px -5px 10px rgba(0,0,0,0), 10px -10px 10px rgba(0,0,0,0), 20px -20px 20px rgba(0,0,0,0), transition: all ease-in-out .66s;'
                this.setState({
                    phoneCartLeftStyle: { top: 130, left: '75%', boxShadow: leftBottomShadow },
                    phoneCartRightStyle: { top: 130, left: '75%', boxShadow: leftBottomShadow }
                })
            }

            if (evt.gamma < 0 && evt.beta > 0) {
                // right-top
                let rightTopShadow = '-1px 1px 0 #cccccc00, -2px 2px 0 #c9c9c900, -3px 3px 0 #bbbbbb00, -4px 4px 0 #b9b9b900, -5px 5px 0 #aaaaaa00, -6px 6px 1px rgba(0,0,0,0),  0 0 5px rgba(0,0,0,0), -1px 1px 3px rgba(0,0,0,0), -3px 3px 5px rgba(0,0,0,0), -5px 5px 10px rgba(0,0,0,0), -10px 10px 10px rgba(0,0,0,0), -20px 20px 20px rgba(0,0,0,0),  transition: all ease-in-out .66s;'
                this.setState({
                    phoneCartLeftStyle: { top: 160, left: '70%', boxShadow: rightTopShadow },
                    phoneCartRightStyle: { top: 160, left: '70%', boxShadow: rightTopShadow }
                })
            }
        }

        window.addEventListener("deviceorientation", changeShadow);
        // this.props.setMainBackground('linear-gradient( rgba(147, 147, 147, 0.6), rgba(147, 147, 147, 0.6) )');
    }

    render() {
        const { classes, translations, settings } = this.props;

        this.props.showHeaderCategory(this.props.translations && this.props.translations.common ? this.props.translations.common.action.back : '');

        return (
            <Fade in={true} timeout={{ enter: 350, exit: 350 }}>

                <div className={classes.container}>
                    <br />
                    {/* ---{this.state.phoneCartLeftStyle.top}--- */}
                    <h2 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.feedBackTitle, classes.feedBackTitleDark) : classes.feedBackTitle}>
                        Станете част от най-добрите !
                    </h2>

                    <div className={classes.logosContainer}>
                        <img src='https://tabl.bg/img/partners/maisons-logo-light-1.png' style={{ width: '45%', maxWidth: 220, padding: '0 15px 0 0' }} />
                        <img src='https://tabl.bg/img/partners/raffy-logo.png' style={{ width: '15%', maxWidth: 100, padding: '0' }} />
                        <img src='https://tabl.bg/img/partners/bamboo-urban-logo.png' style={{ width: '25%', maxWidth: 130, padding: '0 0 0 15px' }} />
                    </div>
                    <br /><br /><br /><br />
                    <h3 className={settings.white_background && settings.white_background.value && settings.white_background.value == 1 ? clsx(classes.thankYouContainer, classes.feedBackTitleDark) : classes.thankYouContainer}
                        style={{ textAlign: 'center', margin: '0 auto' }}>
                        Благодарим Ви. Ще се свържем с Вас скоро!
                    </h3>

                    <a href="https://tabl.bg" style={{
                        color: 'white',
                        border: '1px solid #ccc',
                        'text-decoration': 'none',
                        padding: '10px 20px',
                        width: '132px',
                        display: 'block',
                        'text-align': 'center',
                        margin: '0 auto',
                        'margin-top': '50px',
                        'font-size': '18px',
                    }}>Към сайта</a>

                    {/* <div className={clsx(classes.phoneCard, classes.phoneCardLeft)} style={this.state.phoneCartLeftStyle}></div> */}

                    <div className={clsx(classes.phoneCard, classes.phoneCardRight)} style={this.state.phoneCartRightStyle}></div>

                </div>
            </Fade>
        )
    }
}


const mapStateToProps = (state, ownProps) => {

    return {
        translations: state.lang.translations || [],
        settings: state.settings.settings || [],
        // message: state.feedback.message || null
    };
    // catName: state.name,
    // products: state.products
};

export default withStyles(styles)(connect(mapStateToProps, { showHeaderCategory, setMainBackground, clientRequest })(FeedbackPage));
