import React from 'react';
// import {BrowserRouter as Route} from 'react-router-dom';
import { Switch, Route, Router } from 'react-router-dom';

// import Header from './../components/Header';
// import MainNavigation from '../components/MainNavigation';
import AppNavbar from './common/navbar/AppNavbar'
import history from './utils/history';
import store from './store';

const DynamicLayoutRoute = props => {
  const { component: RoutedComponent, layout, ...rest } = props;
  
    const actualRouteComponent = (
      <Route
        {...rest}
        render={props => (
           <RoutedComponent {...props} />
        )}
      />
    );
    switch (layout) {
      case 'MENU_NAV': {
        if(process.env.NODE_ENV == 'production' && Object.keys(store.getState().client) == 0) {
            history.push("/404");
        }
        return (
          <div>
            <AppNavbar history={history}/>
            {actualRouteComponent}
          </div>
        )
      }
      case 'DEFAULT_NAV': {
        return (
          <div>
            {actualRouteComponent}
          </div>
        )
      }
    //   default: {
    //     return (
    //       <div>
    //          <AppNavbar history={history}/>
    //          {actualRouteComponent}
    //       </div>
    //     )
    //   }
    }
  };    

  export default DynamicLayoutRoute;