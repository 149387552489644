import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, Grow } from '@material-ui/core/';
import CircularProgress from '@material-ui/core/CircularProgress';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import Utils from './../../utils/Utils';
import { getAllProducts, addToCart, changeSelectedCartTab, removeProductFromCart } from './../../products/actions/products.action';
import { getCategoriesHierarchy } from './../../categories/actions/categories.action';
import { setVariantModalOpened, setSelectedProduct } from './../../cart/actions/cart.actions';

import { withTracking } from 'react-tracker';
import { productClick } from './../../tracking/events/productEvents';

import clsx from 'clsx';;

const HOW_MANY_TIMES_TO_CLICK_CLOSE_FOR_STOP_SHOWING_ON_THE_SAME_DAY = 2
// const currentDate = new Date();

const styles = theme => ({
    modal: {
        '& .MuiPaper-root': {
            width: '350px !important',
        }
    },
    modalTitle: {
        textAlign: 'center',
        backgroundColor: "rgb(203,65,2)",
        color: 'white',
        boxShadow: '0 1px 5px 0px black',
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    dialogContent: {
        minHeight: 100,
    },
    dialogContentText: {
        paddingTop: 10,
    },
    fastOrderButtonProduct: {
        color: 'black',
        width: '100%',
        fontSize: 16,
        // display: 'grid',
        // 'grid-template-rows': '50% 50%',
        // 'place-self': 'center',
        // textAlign: 'center',
        // color: '#575757',
        borderRadius: 0,
        // borderBottom: '1px solid #ff7600',
        // backgroundColor: '#f9f9f9',
        minWidth: 180,
        '@media screen and (max-width: 475px)': {
            minWidth: '80%',
        },

        // 'border': '1px solid #ebebeb',

        'border-bottom': '1px solid #ff5200',
        // 'border-top-right-radius': 7,
        // 'border-top-left-radius': 7,

        // paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,

        padding: 15,

        // // display: 'grid',
        // 'grid-template-columns': '70% 30%',
        // borderBottom: 'none',

        // height: 80,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        'border-bottom': '1px solid #ff5200 !important',
        margin: '0 !important',
    },
    activeFastOrderButtonProduct: {
        background: 'rgb(204, 204, 204)'
    },
    // modalTypesBtnsBorderBottom: {
    //     'border-bottom': '1px solid #ff5200 !important',
    // },
    modalTypesIcons: {
        fontSize: 42,
        color: '#ff7600',
    },
    isMultipleOptionsModalContainer: {
        // margin: '40px 0 45px 0',
        display: 'grid',
        // 'grid-template-columns': '50% 50%',
        'place-self': 'center',
        textAlign: 'center',
        padding: 0,
        '@media screen and (max-width: 475px)': {
            'grid-template-columns': 'none',
            '& > *:first-child': {
                marginBottom: 40,
            },
            margin: '20px 0 45px 0',
        }
    },
    submitButton: {
        width: '100%',
        color: 'white',
    },
    closeButton: {
        width: '100%',
        color: 'black',
        background: '#dbdbdb',
    },
    variantCheckbox: {
        margin: 0,
        color: 'rgb(254, 105, 2)',
        '&.Mui-checked': {
            color: 'rgb(254, 105, 2)',
        },
    },
    singleProductVariantLabel: {
        background: '#dfdfdf',
        padding: 5,
        borderRadius: 6,
        fontSize: 15,
        fontWeight: 'normal !important',
    },
    productImage: {
        // marginBottom: 10,
        maxHeight: 75,
        maxWidth: 100,
        width: 'auto',
        margin: '0 auto',
    }
});


class InitialBannerFastOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            initialLoad: 1,
            // selectedProducts: [],
        }
    }


    makeTheOrder = () => {
        const { productsToOrder, settings } = this.props;
        this.props.onSubmit()
        // window.localStorage.setItem('initial_banner_fast_order_closed', 1);
        this.setState({ opened: false });
        // this.setState({})

    }

    // SAME
    // Opens variant modal or adds prod to cart
    // THIS WILL NOT WORK WITH MORE THAN 1 VARIANT ------------------------------------
    // addToCartBtnClicked = (id) => {
    //     // const currProduct = product;
    //     // Object.values(this.props.products.all_products).filter(p => p.id === id)[0];
    //     const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];

    //     // if (this.props.settings && this.props.settings.variant_modal_with_comment && this.props.settings.variant_modal_with_comment.value == 1) {
    //     //     // THIS WILL NOT WORK WITH MORE THAN 1 VARIANT ------------------------------------
    //     //     // this.setState({ variantModalOpen: true, selectedProduct: currProduct, variantModalWithComment: true })
    //     // } else {
    //     if (currProduct.product_variants) {
    //         if (currProduct.product_variants.length === 1) {
    //             this.addProdToCart(currProduct.id, currProduct.product_variants[0].id, currProduct)
    //         } else {
    //             // THIS WILL NOT WORK WITH MORE THAN 1 VARIANT ------------------------------------
    //             // Open variant modal
    //             // this.setState({ variantModalOpen: true, selectedProduct: currProduct })
    //         }
    //     }
    //     // }

    // }

    // Opens variant modal or adds prod to cart
    addToCartBtnClicked = (id, productVariantId = 0) => {
        const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];
        // const currProduct = products.filter(p => p.id === id)[0];

        // if (this.props.settings && this.props.settings.variant_modal_with_comment && this.props.settings.variant_modal_with_comment.value == 1) {
        //     this.setState({ variantModalOpen: true, selectedProduct: currProduct, variantModalWithComment: true })
        // } else {
        if (currProduct.product_variants) {
            if (currProduct.product_variants.length === 1 || productVariantId > 0) {
                this.addProdToCart(currProduct.id, productVariantId ? productVariantId : currProduct.product_variants[0].id, currProduct)
                // } else {
                //     // Open variant modal
                //     this.setState({ variantModalOpen: true, selectedProduct: currProduct })
                // }
            }
        }

    }



    addProdToCart = (product_id, product_variant_id, product) => {
        // if (this.state.variantModalOpen) {
        //     this.setState({ variantModalOpen: false });
        // }

        const requiredProductIngridients = product.product_ingredients.filter(pi => pi.type == 'required');

        let defautRequiredProductIngridient = 0;
        if (requiredProductIngridients && requiredProductIngridients[0] && requiredProductIngridients[0].ingredientId) {
            defautRequiredProductIngridient = requiredProductIngridients[0].ingredientId;
        }

        const selectedIngredients = {
            required: defautRequiredProductIngridient,
            addable: [],
            removable: [],
        }

        // this.showAddedProductTooltip(product_id, product_variant_id, product, selectedIngredients);
        this.props.changeSelectedCartTab(0); // select default tab "Cart"
        this.props.addToCart(product_id, product_variant_id, product, selectedIngredients);
    };



    // addProdToCart = (product_id, product_variant_id, product) => {
    //     // this.showAddedProductTooltip(product_id, product_variant_id, product); // DO NOT SHOW ------------------------------------
    //     // if (this.state.variantModalOpen) {
    //     //     this.setState({ variantModalOpen: false });
    //     // }
    //     this.props.changeSelectedCartTab(0); // select default tab "Cart"
    //     this.props.addToCart(product_id, product_variant_id, product);
    // };


    // Shows tooltip
    // showAddedProductTooltip = (id, product_variant_id, product) => {
    //     let totalProdQuantity = 1;
    //     if (this.props.products && this.props.products.to_order && Object.keys(this.props.products.to_order).length > 0) {
    //         const addedProductVariant = this.props.products.to_order.find(pv => pv.product_variant_id === product_variant_id);
    //         if (addedProductVariant) {
    //             totalProdQuantity = parseInt(addedProductVariant.quantity) + 1;
    //         }
    //     }

    //     let choosedProductVariant = product.product_variants.filter(pv => pv.id === product_variant_id)[0];
    //     let tooltipText = totalProdQuantity + ' x ' + product.name + ' - /' + choosedProductVariant.size + '/';

    //     // Add to cart tooltip
    //     const addProdTooltip = (id, tooltipText) => this.props.addProductTooltip(id, tooltipText);
    //     if (this.state.timeout) {
    //         clearTimeout(this.state.timeout);
    //         this.setState({ timeout: null });
    //     }
    //     addProdTooltip(id, tooltipText);
    //     let timeOut = setTimeout(function () { addProdTooltip(0, tooltipText); }, 2500);
    //     this.setState({ timeout: timeOut });
    // }
    // SAME ^

    // componentWillReceiveProps(nextProps) {
    componentDidMount() {
        // var places = nextProps.places;
        // var chosen_place = nextProps.chosen_place;

        // if (!window.localStorage.getItem('initial_banner_fast_order_closed')) {
        //     // && !Utils.getUserToken()
        //     // if (places.length > 1 && !chosen_place) {
        //     this.setState({ opened: true });
        //     // setTimeout(() => this.setState({ opened: true }), 2000)
        // } else {
        //     this.setState({
        //         opened: false
        //     })
        // }
    }

    componentWillReceiveProps(nextProps) {
        let showModal = true;
        const initial_banner_fast_order_closed_date = parseInt(window.localStorage.getItem('initial_banner_fast_order_closed_date'));

        if (this.state.initialLoad && Object.values(nextProps.allProducts).length > 0) {
            this.setState({ initialLoad: 0 })
            if (
                (
                    window.localStorage.getItem('initial_banner_fast_order_closed') == 1
                    && ((new Date(initial_banner_fast_order_closed_date).getDate()) != (new Date().getDate()))
                    // && ((new Date(initial_banner_fast_order_closed_date).getMonth()) != (new Date().getMonth()))
                    // && ((new Date(initial_banner_fast_order_closed_date).getFullYear()) != (new Date().getFullYear()))
                )
                ||
                (!window.localStorage.getItem('initial_banner_fast_order_closed'))
            ) {
                // localStorage.removeItem("initial_banner_fast_order_closed_times");
                // localStorage.removeItem("initial_banner_fast_order_closed_date");

                if (Object.values(nextProps.allProducts).length > 0) {
                    // this.setState({ opened: true });
                    setTimeout(() => this.setState({ opened: true }), 5000)
                }
            } else {
                this.setState({
                    opened: false
                })
            }
        }
    }

    //removeProductFromCart
    handleSelectedProducts = (pId, product) => {

        if (this.productAlreadyAddedToCart(pId)) {
            // selProdsCopy.splice(index, 1);

            // tuk tr go mapna i da podam vseki ariant
            const { to_order } = this.props;

            let currProd = Object.values(this.props.allProducts).find(p => p.id == pId);

            // product.product_variants.map(pv => {
            //     if (to_order && to_order[pv.id] && to_order[pv.id].length > 0)
            //         this.props.removeProductFromCart(product, pv.id)
            //     else
            const requiredProductIngridients = product.product_ingredients.filter(pi => pi.type == 'required');

            let defautRequiredProductIngridient = 0;
            if (requiredProductIngridients && requiredProductIngridients[0] && requiredProductIngridients[0].ingredientId) {
                defautRequiredProductIngridient = requiredProductIngridients[0].ingredientId;
            }

            const selectedIngredients = {
                required: defautRequiredProductIngridient,
                addable: [],
                removable: [],
            }
            currProd.ingredients = selectedIngredients;
            this.props.removeProductFromCart(currProd)
            // })
            this.props.trackProductClick('Fast order popup', 'remove_from_cart', product.catHierarchyId, pId);

        } else {


            if (product.product_variants && Object.values(product.product_variants).length == 1) {

                // let selProdsCopy = this.state.selectedProducts;
                // const index = this.state.selectedProducts.indexOf(pId);
                // selProdsCopy.push(pId);
                this.addToCartBtnClicked(pId, product.product_variants[0].id);
                this.props.trackProductClick('Fast order popup', 'add_to_cart', product.catHierarchyId, pId);


                // this.setState({ selectedProducts: selProdsCopy })
            } else if (product.product_variants && Object.values(product.product_variants).length > 1) {
                this.props.setVariantModalOpened(true)
                this.props.setSelectedProduct(product)
                // dispatch(setVariantModalOpened(true));
                // dispatch(setSelectedProduct(currProduct));
                this.props.trackProductClick('Fast order popup', 'add_to_cart', product.catHierarchyId, pId);

            }
        }
    }

    handleSubmitButton = () => {
        // this.makeTheOrder()
        this.setState({ opened: false });
    }

    productAlreadyAddedToCart = (pId) => {
        const { to_order } = this.props;

        let prodFound = false;
        // Object.values(to_order).map(o => prodFound = prodFound ? prodFound : parseInt(o.product_id) == parseInt(pId))
        Object.values(to_order).map(o => {
            if (o && o[0] && o[0].product_id) {
                prodFound = prodFound ? prodFound : parseInt(o[0].product_id) == parseInt(pId)
            }
        })

        // Object.values(to_order).map(o => console.log('devet', parseInt(o.product_id) == parseInt(pId)))

        return prodFound;

    }

    onClose = () => {
        let closedTimes = window.localStorage.getItem('initial_banner_fast_order_closed_times');
        if (window.localStorage.getItem('initial_banner_fast_order_closed_times')) {
            window.localStorage.setItem('initial_banner_fast_order_closed_times', parseInt(closedTimes) + 1);
        } else {
            window.localStorage.setItem('initial_banner_fast_order_closed_times', 1);
        }
        if (parseInt(closedTimes) + 1 > (parseInt(HOW_MANY_TIMES_TO_CLICK_CLOSE_FOR_STOP_SHOWING_ON_THE_SAME_DAY) - 1)) {
            window.localStorage.setItem('initial_banner_fast_order_closed', 1);
            window.localStorage.setItem('initial_banner_fast_order_closed_date', Date.now());
            window.localStorage.removeItem('initial_banner_fast_order_closed_times');
        }
        this.setState({ opened: false });
        this.props.trackProductClick('Fast order popup', 'close');
    }

    render() {
        const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations, allProducts } = this.props;

        return (
            <React.Fragment>

                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={this.state.opened}
                    onClose={this.onClose}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    // className={classes.modal}
                    data-class="dialog-container"
                >
                    {/* <CircularProgress className="is-loading language-modal" /> */}
                    <DialogTitle id="max-width-dialog-title" className={classes.modalTitle} data-class="dialog-title-container">
                        {/* {translations.pages && translations.pages.choose_place ? translations.pages.choose_place.title : ''} */}
                        {/* Бърза поръчка */}
                        Бързо добавяне в количката
                         {/* на напитка */}
                    </DialogTitle>

                    <DialogContent
                    // className={classes.dialogContent}
                    >
                        <DialogContentText
                        // className={classes.dialogContentText}
                        >

                            <div className={classes.isMultipleOptionsModalContainer}>

                                {((Object.values(allProducts).filter(p => p.fast_order == true)).slice(0, 10)).map((p, k) => {

                                    return (
                                        <React.Fragment>
                                            <Button size="small" className={this.productAlreadyAddedToCart(p.id) ? clsx(classes.fastOrderButtonProduct, classes.activeFastOrderButtonProduct) : classes.fastOrderButtonProduct}
                                                onClick={() => this.handleSelectedProducts(p.id, p)}
                                                style={{ background: this.productAlreadyAddedToCart(p.id) ? '#ccc' : 'transparent' }}
                                            // style={{ backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('" + p.image + "')" }}
                                            >
                                                <div style={{ display: 'grid', 'grid-template-columns': '10% 60% 30%', width: '100%', 'align-items': 'center' }}>

                                                    <Checkbox
                                                        className={classes.variantCheckbox}
                                                        checked={this.productAlreadyAddedToCart(p.id)}
                                                        data-class="variant-checkbox" />

                                                    <span style={{ fontWeight: this.productAlreadyAddedToCart(p.id) ? 'bold' : 'normal' }}>
                                                        {p.name}
                                                        <br />
                                                        {p.product_variants && Object.values(p.product_variants).length == 1 && p.product_variants[0] && p.product_variants[0].size && p.product_variants[0].price ?
                                                            <span className={classes.singleProductVariantLabel}>{p.product_variants[0].size} / {p.product_variants[0].price} {Utils.getSettingsValue(this.props.settings, 'default_currency_suffix', 'лв.')}</span>
                                                            :
                                                            null
                                                        }
                                                    </span>

                                                    {(p.product_images && p.product_images[0] && p.product_images[0].path && p.product_images[0] !== "undefined" && typeof p.product_images[0].path !== "undefined" && p.product_images[0].path) ?
                                                        <img src={Utils.getProductImagePath(p.product_images[0].path)} className={classes.productImage} />
                                                        :
                                                        null
                                                    }
                                                </div>

                                            </Button>
                                        </React.Fragment>
                                    )

                                })}
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            // background-color: #dbdbdb;
                            // color: #2a2a2a;
                            // style={{ backgroundColor: Object.values(this.props.productsToOrder).length == 0 ? '#dbdbdb' : '#ff5101', }}

                            className={classes.closeButton}
                            onClick={this.onClose}

                        // onClick={() => this.makeTheOrder()}
                        // color="red"
                        // data-class="initial-banner-fast-order-submit-button"
                        >
                            Затвори
                        </Button>
                        <Button
                            // disabled={Object.values(this.props.productsToOrder).length == 0}
                            // background-color: #dbdbdb;
                            // color: #2a2a2a;
                            // style={{ backgroundColor: Object.values(this.props.productsToOrder).length == 0 ? '#dbdbdb' : '#ff5101', }}
                            style={{ backgroundColor: '#ff5101' }}

                            className={classes.submitButton}
                            onClick={this.handleSubmitButton}
                            color="red"
                            data-class="initial-banner-fast-order-submit-button">
                            {/* Поръчай */}
                            Запази
                        </Button>
                    </DialogActions>

                </Dialog>
                {/* </Grow> */}
            </React.Fragment >
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        places: state.places.places || [],
        chosen_place: state.places.chosen_place || null,
        allProducts: state.products.all_products || [],
        products: state.products || [],
        productsToOrder: state.products.to_order || [],
        settings: state.settings.settings || [],
        to_order: state.products.to_order || [],
    };
};

const mapTrackingToProps = trackEvent => ({
    trackProductClick: (page, action_type, category_id = null, product_id = null) => trackEvent(productClick(page, action_type, category_id, product_id)),
    // trackPageView: (pageId, userId) => trackEvent(pageView(pageId, userId))
});

export default withTracking(mapTrackingToProps)(withStyles(styles)(connect(mapStateToProps, { getAllProducts, addToCart, changeSelectedCartTab, getCategoriesHierarchy, removeProductFromCart, setVariantModalOpened, setSelectedProduct })(InitialBannerFastOrder)
))