import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import { verifyToken, verifyTokenAndSession } from './../../qr-scan/actions/scan.actions';
import { getOrders } from './../../products/actions/products.action';

import QrReader from "react-qr-reader";
import Utils from "../../utils/Utils";
import Auth from './../../utils/Auth';

const styles = theme => ({
    modal: {
        '& .MuiPaper-root': {
            width: '100% !important',
        },
        '& .MuiDialogContent-root': {
            paddingTop: 5,
            paddingBottom: 0,
        }
    },
});

class ScanModal extends Component {
    constructor(props) {
        super(props);
        const order_credentials = window.localStorage.getItem('order_credentials');
        this.state = {
            name: order_credentials ? JSON.parse(order_credentials).name : '',
            phone: order_credentials ? JSON.parse(order_credentials).phone : '',
            address: order_credentials ? JSON.parse(order_credentials).address : '',
            email: order_credentials ? JSON.parse(order_credentials).email : '',
            promoCode: '',
            rememberMe: order_credentials ? true : false,
            inputPromoCodeVisible: false,
            errors: '',
            modalType: 0,

            scanDelay: 200,
            scannedTableToken: '',
            scanLegacyMode: false,
            scanned: false,

        }
    }

    componentDidMount() {
        this.setState({ errors: '' });
    }

    handleScan = (scannedTableToken) => {
        const { onClose, onSubmit, onError, translations, scanModalCallback } = this.props;
        
        if (scannedTableToken) {
            if (this.state.scanned == false) {
                this.setState({ scanned: true });

               this.props
                .verifyTokenAndSession(scannedTableToken)
                .then((data) => {
                    // let validToken = data.data;
                    let validTokenSession = data.data.payload.session;

                    let currState = this.state;
                    currState.scannedTableToken = validTokenSession;
                    Auth.setUserToken(validTokenSession);

                    //TODO ще тр да се вика и GetORdersByToken за случая когато ще сканира нова маса и ще върне различен(нов) session и ще трябва да махне продуктите ако му седят от стария
                    this.props.getOrders();


                    scanModalCallback(validTokenSession);
                    onClose();

                })
                .catch(err => {
                    Auth.removeUserToken();
                    onError(translations.messages && translations.messages.errors && translations.messages.errors.order_sent_fail ? translations.messages.errors.order_sent_fail : 'Invalid token scanned');
                    this.setState({ modalType: 0 });
                })
            }
        }
    }

    handleScanError = (err) => {
        console.error(err);
    }

    render() {
        const { classes, fullWidth = false, maxWidth = 'xs', open, onClose, onSubmit, isLoadingOrder, orderTotal, settings, modalData, translations } = this.props;
        const userToken = Auth.getUserToken();

        return (
            <React.Fragment>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={onClose}
                    aria-labelledby="max-width-dialog-title"
                    TransitionComponent={Grow}
                    className={classes.modal}
                    data-class="dialog-container"
                >
                    <DialogTitle id="max-width-dialog-title" style={{ textAlign: 'center', backgroundColor: "rgb(203,65,2)", color: 'white', boxShadow: '0 1px 5px 0px black' }} data-class="dialog-title-container">
                        {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.scan_barcode : ''}
                    </DialogTitle>
                    <DialogContent style={{ padding: 0 }}>


                        <Fade in={true} timeout={{ enter: 385, exit: 190 }}>
                            <DialogContentText style={{ margin: 0 }}>
                                <QrReader ref="qrReader1"
                                    delay={this.state.scanDelay}
                                    onError={this.handleScanError}
                                    onScan={this.handleScan}
                                    style={{ width: "100%" }}
                                //   legacyMode={scanLegacyMode}
                                />
                            </DialogContentText>
                        </Fade>


                    </DialogContent>

                </Dialog >
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoadingOrder: state.products.isLoadingOrder || false,
    };
};

export default withStyles(styles)(connect(mapStateToProps, { verifyToken, verifyTokenAndSession, getOrders })(ScanModal));