import { PAGE_VIEW, PROMOTION_CLOSE, PROMOTION_CLICK } from './../constants';


/**
 * get page view event object!
 * @param {Number} friendId 
 */
export const promotionClose = (category_id, product_id) => ({
    type: PROMOTION_CLOSE,
    data: {
        page: 'Promotion popup',
        category_id: category_id,
        product_id: product_id
    }
})

/**
 * get page view event object!
 * @param {Number} friendId 
 */
export const promotionClick = (category_id, product_id, type) => ({
    type: PROMOTION_CLICK,
    data: {
        page: 'Promotion popup',
        category_id: category_id,
        product_id: product_id,
        type: type  // add_to_cart/click ...
    }
})