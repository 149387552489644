import { SEARCH_OPENED, SEARCH_TEXT } from './types';

export const setSearchOpened = (opened) => dispatch => {
    dispatch({
        type: SEARCH_OPENED,
        payload: opened
    })
}

export const setSearchText = (searchText) => dispatch => {
    dispatch({
        type: SEARCH_TEXT,
        payload: searchText
    })
}
