import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles, withTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import WarningIcon from '@material-ui/icons/Warning';
import SwipeableViews from 'react-swipeable-views';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SettingsIcon from '@material-ui/icons/Settings';
import WhereToVoteIcon from '@material-ui/icons/WhereToVote';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import FastfoodIcon from '@material-ui/icons/Fastfood';
// import StepConnector from '@material-ui/core/StepConnector';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import CachedIcon from '@material-ui/icons/Cached';
import InfoIcon from '@material-ui/icons/Info';
import RateReviewIcon from '@material-ui/icons/RateReview';
import AddCommentIcon from '@material-ui/icons/AddComment';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { changeSelectedCartTab, setOrderComment, removeUserTokenAndOrder, setCommentModalData, setDisabledMakeOrderButton } from '../../../products/actions/products.action';
import { setOrderModalState } from '../../../qr-scan/actions/scan.actions'
import { DEFAULT_PRODUCT_IMAGE } from '../../../constants'
import CartCommentModal from '../CartCommentModal';
import PaymentTypeModal from '../PaymentModal';
import Utils from '../../../utils/Utils';
import Auth from '../../../utils/Auth';

import { styles, useColorlibStepIconStyles, ColorlibConnector } from '../styles/cardModal.styles';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CartModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderTooltipOpened: [],
        }
    }


    productVariantCounts = (selectedQuantity) => {
        let menuItems = [];
        let maxShowQuantity = 10;
        maxShowQuantity = Math.max(maxShowQuantity, selectedQuantity)

        for (let i = 1; i <= maxShowQuantity; i++) {
            menuItems.push(< MenuItem value={i}>{i}</MenuItem >)
        }

        return menuItems;
    }

    componentDidUpdate() {
        const { orderSent, orderMessage } = this.props;

        if (orderSent == 'true') {
            this.props.setOrderComment([]);
            this.props.setDisabledMakeOrderButton(false);
        }

    }

    ColorlibStepIcon(props, orderStatus) {
        const classes = useColorlibStepIconStyles();
        const { active, completed } = props;

        const icons = {
            ordered: <ThumbUpIcon />,
            cooked_spot: <FastfoodIcon />,
            cooked_delivery: <FastfoodIcon />,
            cooked_room: <FastfoodIcon />,
            cooked_preorder: <WhereToVoteIcon />,
            served_spot: <EmojiFoodBeverageIcon />,
            on_the_way_delivery: <LocalTaxiIcon />,
            paid: <DoneAllIcon />,
        };

        return (
            <div className={clsx(classes.root, { [classes.active]: active, [classes.completed]: completed })}>
                {icons[String(orderStatus)]}
            </div>
        );
    }



    finish_bill = () => {
        this.props.removeUserTokenAndOrder();
        this.props.onClose();
    }


    addIngredientsPriceInOrder = (item) => {
        let ingredientsPrice = 0;


        if (item && item.opv_ingredients) {
            item.opv_ingredients.map(ing => {
                if (ing.ingredient_type == "required" || ing.ingredient_type == "addable") {
                    if (this.props.productIngredients[parseInt(ing.ingredientId)]) {
                        ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(ing.ingredientId)].price);
                    }
                }
            })
        }


        // if (item && item.ingredients && item.product_ingredients && Object.values(item.product_ingredients).length > 0 && this.props.productIngredients) {
        //     if (item.ingredients.required) {
        //         if (this.props.productIngredients && this.props.productIngredients[parseInt(item.ingredients.required)] && this.props.productIngredients[parseInt(item.ingredients.required)].price) {
        //             // console.log('adding: this.props.productIngredients[parseInt(item.ingredients.required)].price', this.props.productIngredients[parseInt(item.ingredients.required)].price)
        //             ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(item.ingredients.required)].price);
        //         }
        //     }

        //     if (item.ingredients.addable) {
        //         Object.values(item.ingredients.addable).map(ingrId => {
        //             if (this.props.productIngredients && this.props.productIngredients[parseInt(ingrId)] && this.props.productIngredients[parseInt(ingrId)].price) {
        //                 // console.log('adding: this.props.productIngredients[parseInt(ingrId)].price', this.props.productIngredients[parseInt(ingrId)].price)
        //                 ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(ingrId)].price);
        //             }
        //         })
        //     }

        // if (item.ingredients.removable) {
        //     Object.values(item.ingredients.removable).map(ingrId => {
        //         if (this.props.productIngredients && this.props.productIngredients[parseInt(ingrId)] && this.props.productIngredients[parseInt(ingrId)].price) {
        //             console.log('adding: this.props.productIngredients[parseInt(ingrId)].price', this.props.productIngredients[parseInt(ingrId)].price)
        //             ingredientsPrice += parseFloat(this.props.productIngredients[parseInt(ingrId)].price);
        //         }
        //     })
        // }

        // }

        return ingredientsPrice;
    }


    hideOrderTooltip = (itemId) => {
        let stateCopy = this.state.orderTooltipOpened;
        let index = stateCopy.indexOf(itemId);
        if (index > -1) {
            stateCopy.splice(index, 1);
        }
        this.setState({ orderTooltipOpened: stateCopy })
    }


    showOrderTooltip = (itemId) => {
        let stateCopy = this.state.orderTooltipOpened;
        stateCopy.push(itemId)
        this.setState({ orderTooltipOpened: stateCopy });
    }


    getActiveStatuses = (orderType = 'spot') => {
        if (orderType == 1) orderType = 'spot';
        if (orderType == 2) orderType = 'delivery';
        if (orderType == 3) orderType = 'preorder';
        if (orderType == 6) orderType = 'room';

        var statuses = this.props.orderActiveStatuses[orderType] || {};
        var activeStatuses = [];

        Object.keys(statuses).map(s => {
            if (statuses[s] === 1) {
                activeStatuses[s] = this.props.translations.orders.statuses[s];
            }
        })

        return activeStatuses;
    }


    render() {
        const { classes, open, handleChange, handleRemove, onClose, onSubmit, onError, askForBill, settings, translations, isLoadingCart } = this.props;

        let totalOrderCurrentPersonPrice = 0;

        const orderedProductsLength = this.props.orderedProducts.length;
        let eachOrderedItem = [];

        const noOrderedItemsText = <p className={classes.noCartItemsContainer}>
            <WarningIcon className={classes.noCartItemsWarningIcon} />
            <div className={classes.noItemsInCart}>{translations && translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.no_order_items : ''}</div>
        </p>

        Object.values(this.props.orderedProducts).map((item, k) => {
            let orderDate = new Date(item.createdAt);
            orderDate = Utils.appendLeadingZeroes(orderDate.getHours()) + ':' + Utils.appendLeadingZeroes(orderDate.getMinutes()); // + ':' + Utils.appendLeadingZeroes(orderDate.getSeconds());


            let itemOrderType = "";
            const currentOrderOrderType = this.props.chosenPlaceOrderTypes.find(ot => ot.id == item.ordertypeId);
            if (currentOrderOrderType && currentOrderOrderType.type) {
                itemOrderType = currentOrderOrderType.type;
            }
            let currentOrderStatuses = this.getActiveStatuses(itemOrderType);
            let orderActiveStep = Object.keys(currentOrderStatuses).indexOf(item.status);

            let eachOrderedVariant = [];

            {
                item.order_productvariants.map((opv, count) => {


                    if (item.ordertypeId == 1) {
                        totalOrderCurrentPersonPrice += (parseFloat(opv.product_variant.price) + parseFloat(this.addIngredientsPriceInOrder(opv))) * 1 * parseInt(opv.quantity);
                    }

                    let productsById = this.props.productsById;
                    let productImage = productsById[opv.product_variant.productId] && productsById[opv.product_variant.productId].product_images && productsById[opv.product_variant.productId].product_images[0] && productsById[opv.product_variant.productId].product_images[0].path ? Utils.getProductThumbImagePath(productsById[opv.product_variant.productId].product_images[0].path) : DEFAULT_PRODUCT_IMAGE;
                    let productName =
                        (productsById[opv.product_variant.productId]
                            && productsById[opv.product_variant.productId].translations
                            && productsById[opv.product_variant.productId].translations[this.props.currLang]
                            && productsById[opv.product_variant.productId].translations[this.props.currLang].name) ? productsById[opv.product_variant.productId].translations[this.props.currLang].name :
                            (productsById && productsById[opv.product_variant.productId] && productsById[opv.product_variant.productId].name ? productsById[opv.product_variant.productId].name : '');

                    // --- DO NOT REMOVE // Use this when start working on translations

                    // let variant = productsById[opv.product_variant.productId]
                    //     && productsById[opv.product_variant.productId].product_variants.filter(v => v.id == opv.product_variant.productId);
                    //     console.log('variant', variant)
                    // variant = variant && variant[0] || null;

                    // let productSize = (variant && variant.translations && variant.translations[this.props.currLang]
                    //     && variant.translations[this.props.currLang].size) ? variant.translations[this.props.currLang].size : (variant && variant.size ? variant.size : '');

                    // --- DO NOT REMOVE ^^^

                    let variant = opv.product_variant || null;
                    let productSize = variant && variant.size || null;
                    let productQuantity = opv.quantity ? opv.quantity : '';
                    opv.discount_percent = opv.product_variant && opv.product_variant.productId && productsById[opv.product_variant.productId] && productsById[opv.product_variant.productId].discount_percent || 0;

                    // let productVariant = productSize + ' - ' + productQuantity + ' x ' +
                    //     (item.ordertypeId == 3 && Utils.getSettingsValue(settings, 'preorder_percent_discount') > 0 ?
                    //     (parseFloat(parseFloat(productPrice) + parseFloat(this.addIngredientsPriceInOrder(opv))) - ((parseFloat(Utils.getSettingsValue(settings, 'preorder_percent_discount')) / 100) * parseFloat(parseFloat(productPrice) + parseFloat(this.addIngredientsPriceInOrder(opv))))).toFixed(2)
                    //     :
                    //     Utils.normalizePrice(parseFloat(productPrice) + parseFloat(this.addIngredientsPriceInOrder(opv))))
                    //     + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.');
                    let productVariant = productSize + ' - ' + productQuantity + ' x ' + Utils.calculateProductPriceOrder(opv, settings, opv.opv_ingredients, 1) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.');
                    let productRequiredIngredient = [];
                    let productAddablengredient = [];
                    let productRemovableIngredient = [];

                    opv.opv_ingredients.map(ing => {
                        if (ing.ingredient_type == 'required') {
                            productRequiredIngredient.push(ing.ingredientId)
                        } else if (ing.ingredient_type == 'addable') {
                            productAddablengredient.push(ing.ingredientId)
                        } else if (ing.ingredient_type == 'removable') {
                            productRemovableIngredient.push(ing.ingredientId)
                        }
                    })

                    eachOrderedVariant.push(
                        <React.Fragment key={count}>
                            <ListItem button key={count} className={classes.orderedListItem} style={item.ordertypeId == 1 && count == Object.values(item.order_productvariants).length - 1 ? { borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderBottom: '1px solid #ccc' } : {}}>
                                <img src={productImage} alt="" className={classes.productCircularImage} />
                                <ListItemText
                                    primary={productName + (((settings.show_variant_additional_price && settings.show_variant_additional_price.value && settings.show_variant_additional_price.value == 1) && ((parseFloat(opv.product_variant.additional_price)).toFixed(2) != '0.00')) ? (' /+ ' + productQuantity + ' ' + (translations.common && translations.common.count ? translations.common.count : '') + ' ' + (translations.common && translations.common.box ? translations.common.box : '') + ' ' + ((parseFloat(opv.product_variant.additional_price) * 1 * parseInt(productQuantity)).toFixed(2)) + ' ' + (settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.') + '/') : '')}
                                    secondary={
                                        <>
                                            {productVariant}

                                            {productRequiredIngredient.length > 0 ?
                                                <div className={classes.ingredientsListRequired}>{Utils.getIngredientNamesByIds(productRequiredIngredient, this.props.productIngredients)}</div>
                                                :
                                                null
                                            }

                                            {productAddablengredient.length > 0 ?
                                                <div className={classes.ingredientsListAddable}>+ {Utils.getIngredientNamesByIds(productAddablengredient, this.props.productIngredients)}</div>
                                                :
                                                null
                                            }

                                            {productRemovableIngredient.length > 0 ?
                                                <div className={classes.ingredientsListRemovable}>- {Utils.getIngredientNamesByIds(productRemovableIngredient, this.props.productIngredients)}</div>
                                                :
                                                null
                                            }
                                        </>
                                    } className={classes.orderedListItemText}
                                />
                                <ListItemSecondaryAction>
                                    {Utils.calculateProductPriceOrder(opv, settings, opv.opv_ingredients)} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                                </ListItemSecondaryAction>
                            </ListItem>
                            {count != Object.values(item.order_productvariants).length - 1 ? <Divider className={classes.orderedProductDivider} /> : null}
                        </React.Fragment >
                    )

                });

            }

            eachOrderedItem.push(
                <React.Fragment key={k}>
                    <Stepper alternativeLabel activeStep={orderActiveStep} connector={<ColorlibConnector />} className={k == 0 ? classes.stepperFirst : classes.stepper}>
                        {Object.values(currentOrderStatuses).map((label, k) => {
                            return <Step key={k}>
                                <StepLabel StepIconComponent={(p) => this.ColorlibStepIcon(p, Object.keys(currentOrderStatuses)[k])}>{label}</StepLabel>
                            </Step>
                        })}
                    </Stepper>

                    <ListItem button className={classes.eachOrderHeaderContainer}>
                        <div className="first">
                            <span>
                                {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.order : ''} #{item && item.place_order_number ? item.place_order_number : item.id}
                            </span>
                        </div>
                        <div>
                            <ScheduleIcon className={classes.orderTimeIcon} />
                            <span className={classes.eachOrderTime}>
                                {orderDate}
                            </span>
                        </div>
                        <div className="last tooltip-text-align-center">
                            <span>
                                {(item.discount_price && item.discount_price != 0) ||
                                    (item.delivery_price && item.delivery_price != 0)
                                    ?
                                    <React.Fragment>

                                        <ClickAwayListener
                                            onClickAway={() => { this.hideOrderTooltip(item.id) }}
                                        >
                                            <div>
                                                <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    // onClose={() => this.hideOrderTooltip(item.id)}
                                                    open={this.state.orderTooltipOpened.includes(item.id)}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title={<div>
                                                        {item.total_price ?
                                                            <div>{translations && translations.common && translations.common.final_price ? translations.common.final_price : ''}: {item.total_price} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}</div>
                                                            :
                                                            null
                                                        }

                                                        {item.discount_price && item.discount_price != 0 ?
                                                            <div>{translations && translations.common && translations.common.discount ? translations.common.discount : ''}: {item.discount_price} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'} {item.discount_type == 'percent' && item.discount_value ? <span>({item.discount_value} %)</span> : null}</div>
                                                            :
                                                            null
                                                        }

                                                        {item.delivery_price && item.delivery_price != 0 ?
                                                            <div>{translations && translations.common && translations.common.delivery ? translations.common.delivery : ''}: {item.delivery_price} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}</div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    }
                                                >
                                                    <span
                                                        onClick={() => this.state.orderTooltipOpened.includes(item.id) ? void (0) : this.showOrderTooltip(item.id)}
                                                        onMouseOver={() => this.showOrderTooltip(item.id)}
                                                    // onMouseOut={() => this.hideOrderTooltip(item.id)}
                                                    >
                                                        {item.total_price} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                                                &nbsp;
                                                <InfoIcon style={{ verticalAlign: '-6', marginRight: 3 }} />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        </ClickAwayListener>
                                    </React.Fragment>
                                    :
                                    <span>
                                        {item.total_price} {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                                    </span>
                                }
                            </span>
                        </div>
                    </ListItem >

                    <Divider className={classes.orderedProductDivider} />

                    {eachOrderedVariant}

                    {
                        item.ordertypeId == 1 ?
                            <React.Fragment>
                                {/* 
                                    <ListItem className={clsx(classes.eachOrderFooterContainer, classes.eachOrderFooterContainerTwoColumns)}>
                                        <div className="first">
                                            <Button onClick={() => askForBill('cash')}>{translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.pay_in_cash : ''}</Button>
                                        </div>
                                        <div className="last">
                                            <Button onClick={() => askForBill('card')}>{translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.pay_with_card : ''}</Button>
                                        </div>
                                    </ListItem> 
                                */}
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Divider className={classes.orderedProductDivider} />

                                <ListItem button className={clsx(classes.eachOrderFooterContainer, classes.eachOrderFooterContainerThreeColumns)}>
                                    <div className="first">
                                        <span>
                                            {item.client_name}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {item.client_phone}
                                        </span>
                                    </div>
                                    <div className="last">
                                        <span>
                                            {item.tableNum ?
                                                <span>
                                                    {(translations && translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.table ? translations.pages.cart_delivery.table : '')} #{item.tableNum}
                                                </span>
                                                :
                                                item.room_number ?
                                                    <span>
                                                        {(translations && translations.pages && translations.pages.cart_delivery && translations.pages.cart_delivery.room ? translations.pages.cart_delivery.room : '')} #{item.room_number}
                                                    </span>
                                                    :
                                                    (
                                                        item.ordertypeId == 3 ?
                                                            <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/dir/?api=1&destination=' + (Utils.getSettingsValue(settings, 'place_address'))} target="_blank" style={{ color: 'initial' }}>
                                                                {Utils.getSettingsValue(settings, 'place_address')}
                                                            </a>
                                                            :
                                                            item.client_address
                                                    )
                                            }
                                        </span>
                                    </div>
                                </ListItem>
                            </React.Fragment>

                    }

                    {(k !== orderedProductsLength - 1) ? < hr className="progress" /> : <div style={{ marginBottom: 20 }} />}


                </React.Fragment >
            );
        });

        // this.props.chosenPlaceOrderTypes.length == 1 && this.props.chosenPlaceOrderTypes.type == 'delivery'

        return (

            <List className={classes.list} >

                { eachOrderedItem.length > 0 ? eachOrderedItem : noOrderedItemsText}

                {/* If there are orders and at least one of them is with order type 1 => then show ask for bill button */}
                { eachOrderedItem.length > 0 && (this.props.orderedProducts.findIndex(p => p.ordertypeId == 1) != -1) && Utils.getSettingsValue(settings, "ask_for_bill_hidden") == "0" ?
                    <React.Fragment>
                        <div className="ordered-arrow-up"></div>

                        <div className={classes.footerContainer}>
                            <div className={classes.totalSum}>
                                {/* Todo - calc price total */}
                                {translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.total_bill : ''}: <span className="skiptranslate">{Utils.normalizePrice(parseFloat(totalOrderCurrentPersonPrice))}</span> {settings.default_currency_suffix && settings.default_currency_suffix.value ? settings.default_currency_suffix.value : 'лв.'}
                            </div>
                            <Button
                                color="inherit"
                                className={clsx(classes.footerButton, 'shy')}
                                onClick={() => window.localStorage.getItem('bill_wanted') ? this.finish_bill() : this.props.openPaymentTypeModal()}
                            >
                                <div>
                                    {window.localStorage.getItem('bill_wanted') ?
                                        (translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.finish_bill : '')
                                        :
                                        (translations.pages && translations.pages.cart_delivery ? translations.pages.cart_delivery.want_the_bill : '')
                                    }
                                </div>
                            </Button>
                        </div>
                    </React.Fragment>
                    :
                    null
                }

            </List >
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let chosenPlaceOrderTypes = [];
    let chosenPlaceId = state.places.chosen_place;
    let chosenPlace = state.places.places.filter(p => p.id == chosenPlaceId);
    if (chosenPlace.length == 0) {
        chosenPlace = null;
    } else {
        chosenPlace = chosenPlace[0];
        chosenPlaceOrderTypes = chosenPlace.ordertypes;
    }

    return {
        selectedCartTab: state.products.selected_cart_tab || 0,
        orderedProducts: state.products.ordered || [],
        orderSent: state.products.order_sent || null,
        settings: state.settings.settings || [],
        products: state.products || [],
        productsById: state.products.all_products || [],
        isLoadingCart: state.products.isLoadingCart || false,
        orderMessage: state.products.order_message || '',
        orderModalState: state.scan.order_modal_state,
        chosenPlace: chosenPlace,
        chosenPlaceOrderTypes: chosenPlaceOrderTypes,
        disabledMakeOrderButton: state.products.disabledMakeOrderButton,
        productIngredients: state.products.productIngredients || [],
        orderActiveStatuses: state.settings && state.settings.settings && state.settings.settings && state.settings.settings.order_active_statuses && state.settings.settings.order_active_statuses['value'] || {},
        regions: state.regions.regions || [],
        translations: state.lang.translations,
        currLang: state.lang.currLang,
    };
};

export default withStyles(styles)(connect(mapStateToProps, { changeSelectedCartTab, setOrderComment, removeUserTokenAndOrder, setOrderModalState, setCommentModalData, setDisabledMakeOrderButton })(CartModal))