import { SET_SCROLL_DATA, SET_ALERT_MESSAGE, SET_CART_MODAL_OPENED_STATE, SET_TERMS_DIALOG_OPENED, SET_PRIVACY_POLICY_OPENED } from './types'

export const setScrollData = (sc) => dispatch => {
    dispatch({
        type: SET_SCROLL_DATA,
        payload: sc
    })
}

export const setAlertMessage = (opened, message = '', success = false) => dispatch => {
    dispatch({
        type: SET_ALERT_MESSAGE,
        payload: { opened, message, success }
    })
}



export const setCartModalOpenedState = (state) => dispatch => {
    dispatch({
        type: SET_CART_MODAL_OPENED_STATE,
        payload: state
    })
}


export const setTermsDialogOpened = (state) => dispatch => {
    dispatch({
        type: SET_TERMS_DIALOG_OPENED,
        payload: state
    })
}

export const setPrivacyPolicyDialogOpened = (state) => dispatch => {
    dispatch({
        type: SET_PRIVACY_POLICY_OPENED,
        payload: state
    })
}

